import moment from 'moment';
import pluralize from 'pluralize';

import { AccountType, UIEvent } from '@sparkplug/lib';

import { TChipVariant } from '@components/chips/Chip';

import { TStandardColors } from '@app/types/UITypes';

export type EventDisplayStatus =
    | 'complete'
    | 'active'
    | 'expired'
    | 'pending'
    | 'declined'
    | 'upcoming';

export const getEventDisplayStatus = ({
    event,
    accountType,
}: {
    event: UIEvent;
    accountType?: AccountType;
}): EventDisplayStatus => {
    const isEventExpired =
        event.isExpired ||
        (event.requestStatus === 'pending' && moment(event.endTime).isBefore(moment(), 'minute'));

    const isEventApproved =
        accountType === 'brand'
            ? event.requestStatus === 'approved'
            : !event.requestStatus || event.requestStatus === 'approved';

    const isEventComplete = isEventApproved && moment(event.endTime).isBefore(moment(), 'minute');

    if (isEventExpired) return 'expired';
    if (event.requestStatus === 'pending') return 'pending';
    if (event.requestStatus === 'declined') return 'declined';

    if (isEventApproved) {
        if (isEventComplete) return 'complete';

        const now = moment();
        // const isToday = moment(event.startTime).isSame(now, 'day');
        const isInProgress = now.isBetween(event.startTime, event.endTime, 'minute', '[]');

        if (isInProgress) return 'active';

        const daysUntilStart = moment(event.startTime).diff(now.startOf('day'), 'days');
        if (daysUntilStart > 0) return 'upcoming';
    }

    return 'pending'; // fallback status
};

interface EventStatusTagConfig {
    color: TStandardColors;
    variant: TChipVariant;
    label: string;
}

export const getEventStatusTagConfig = (
    status: EventDisplayStatus,
    startTime: string,
): EventStatusTagConfig => {
    const now = moment();
    const daysUntilStart = moment(startTime).diff(now.startOf('day'), 'days');

    const tagConfig: Record<EventDisplayStatus, EventStatusTagConfig> = {
        complete: {
            color: 'neutral',
            variant: 'flat',
            label: 'Complete',
        },
        active: {
            color: 'green',
            variant: 'flat',
            label: 'Active',
        },
        expired: {
            color: 'light-gray',
            variant: 'flat',
            label: 'Expired',
        },
        pending: {
            color: 'neutral',
            variant: 'outlined',
            label: 'Pending',
        },
        declined: {
            color: 'red',
            variant: 'flat',
            label: 'Declined',
        },
        upcoming: {
            color: 'yellow',
            variant: 'flat',
            label: `In ${pluralize('Day', daysUntilStart, true)}`,
        },
    };

    return tagConfig[status];
};

interface EventDateInfo {
    startTime: string;
    endTime: string;
}

export const getEventDateString = ({ startTime, endTime }: EventDateInfo): string => {
    const startDate = moment(startTime);
    const endDate = moment(endTime);

    const isMultiDayEvent = !startDate.isSame(endDate, 'day');

    if (!isMultiDayEvent) {
        return startDate.format('MMM D, YYYY');
    }

    // For multi-day events in the same month and year
    if (startDate.isSame(endDate, 'month') && startDate.isSame(endDate, 'year')) {
        return `${startDate.format('MMM D')} - ${endDate.format('D')}, ${startDate.format('YYYY')}`;
    }

    // For multi-day events spanning different months or years
    return `${startDate.format('MMM D')} - ${endDate.format('MMM D')}, ${startDate.format('YYYY')}`;
};

interface Location {
    _id?: string;
    name: string;
}

export const getEventLocationsString = ({
    eventLocations,
    employeeLocations,
    totalRetailerLocationCount,
}: {
    eventLocations: Location[];
    employeeLocations?: Location[];
    totalRetailerLocationCount?: number;
}): string => {
    // Return "All Locations" for admin facing views
    if (
        totalRetailerLocationCount &&
        eventLocations.length === totalRetailerLocationCount &&
        eventLocations.length > 1
    ) {
        return 'All Locations';
    }

    // If employee locations provided, filter to only matching locations
    const relevantLocations = employeeLocations
        ? eventLocations.filter((location) =>
              employeeLocations.some(
                  (empLocation) => String(empLocation._id) === String(location._id),
              ),
          )
        : eventLocations;

    return relevantLocations.map((location) => location.name).join(', ');
};
