import { useEffect, useMemo } from 'react';

import { Typography } from '@mui/material';

import { getEventDisplayStatus } from '@features/events/helpers/eventUtils';
import { useEventNavigation } from '@features/events/hooks/useEventNavigation';
import { useApproveEventMutation } from '@features/events/mutations';
import { useGetEventQuery } from '@features/events/queries';

import Skeleton from '@components/layout/Skeleton';
import Spinner from '@components/layout/Spinner';
import Modal from '@components/overlays/Modal';
import toast from '@components/toast';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import EventDetailModalTitle from './components/EventDetailModalTitle';
import { InformationalEventContent } from './components/InformationalEventContent';
import { PendingEventContent } from './components/PendingEventContent';

const GetEventLoadingState = () => (
    <Modal.Content className="flex items-center w-[496px] max-w-full [&>span]:w-full">
        <Skeleton className="w-full h-[400px]" />
    </Modal.Content>
);

const EventApprovalLoadingState = () => (
    <Modal.Content className="flex items-center w-[496px] max-w-full [&>span]:w-full">
        <div className="w-full h-[400px] flex flex-col items-center justify-center gap-10">
            <Spinner />
            <Typography variant="body1" className="text-center">
                Approving event...
            </Typography>
        </div>
    </Modal.Content>
);

export const EventDetailModal = ({
    isVisible,
    onClose,
}: {
    isVisible: boolean;
    onClose: () => void;
}) => {
    const { account } = useSparkplugAccount();
    const nav = useEventNavigation();

    const { event, eventIsLoading, eventHasError, refetchEvent } = useGetEventQuery(
        nav.state.detailId,
        {
            enabled: !!nav.state.detailId,
        },
    );

    const waitForQuickApproval =
        event?.requestStatus === 'pending' && !!nav.state.quickApprovalCode;
    const { approveEventAsync, isApprovingEvent, hasApprovalError } = useApproveEventMutation();
    useEffect(() => {
        if (waitForQuickApproval && nav.state.quickApprovalCode === event?.quickApprovalCode) {
            approveEventAsync(event?._id).then(() => {
                refetchEvent();
            });
        }
    }, [event, waitForQuickApproval]);

    useEffect(() => {
        if (eventHasError || hasApprovalError) {
            toast.error('Something went wrong.');
            onClose();
        }
    }, [eventHasError, hasApprovalError]);

    const modalContent = useMemo(() => {
        if (eventIsLoading || !event) {
            return <GetEventLoadingState />;
        }
        if (waitForQuickApproval || isApprovingEvent) {
            return <EventApprovalLoadingState />;
        }

        const eventStatus = getEventDisplayStatus({
            event,
            accountType: account?.type as 'retailer' | 'brand',
        });

        if (eventStatus === 'pending') {
            return (
                <PendingEventContent
                    event={event!}
                    onDecline={() => nav.actions.goToDeclineEventModal(event._id, true)}
                    onApprove={nav.actions.goToScheduledView}
                />
            );
        }

        return <InformationalEventContent event={event} />;
    }, [event, eventIsLoading, waitForQuickApproval, isApprovingEvent, onClose]);

    return (
        <Modal isVisible={isVisible} onClose={onClose}>
            <EventDetailModalTitle event={event} onClose={onClose} />
            <div className="w-[440px] max-w-full">{modalContent}</div>
        </Modal>
    );
};
