import { useCallback, useMemo } from 'react';

import { keyBy } from 'lodash';

import { AccountLink } from '@sparkplug/lib';

import { useQueryParamsState } from '@hooks/QueryParamsHooks';

import { getStateAbbreviationByName } from '@helpers/util';

import { IAccount } from '@app/types/AccountsTypes';
import { IOption } from '@app/types/UITypes';

export const useMarketOptionsFilter = ({ account }: { account?: IAccount }) => {
    const [query, setQuery] = useQueryParamsState<{
        markets: string[];
    }>({ markets: [] });

    const allAccountMarketOptions = useMemo<IOption[]>(
        () =>
            (account?.markets || []).map((label) => ({
                value: getStateAbbreviationByName(label),
                label,
            })),
        [account?.markets],
    );

    const setFilteredMarketOptions = (updatedFilterMarkets: IOption[]) => {
        const updatedQueryValue = updatedFilterMarkets.map(({ value }) => value);
        setQuery({ markets: updatedQueryValue });
    };

    const filteredMarketOptions = useMemo<IOption[]>(() => {
        if (query.markets.length === 0) {
            return allAccountMarketOptions;
        }

        const marketOptionsByValue = keyBy(allAccountMarketOptions, 'value');

        return query.markets.map((value: string) => marketOptionsByValue[value]);
    }, [allAccountMarketOptions, query.markets]);

    const applyMarketsFilter = useCallback(
        (accountLinks: AccountLink[]) => {
            if (!filteredMarketOptions.length) {
                return accountLinks;
            }

            const selectedMarketValues = filteredMarketOptions.map((option) => option.value);

            return accountLinks.filter((accountLink) =>
                accountLink.markets.some((accountMarketAbbr) =>
                    selectedMarketValues.includes(accountMarketAbbr),
                ),
            );
        },
        [filteredMarketOptions],
    );

    const filteredMarketsLabels = useMemo(() => {
        return filteredMarketOptions.map((option) => option.label);
    }, [filteredMarketOptions]);

    return {
        queryMarkets: query.markets,
        allAccountMarketOptions,
        setFilteredMarketOptions,
        filteredMarketOptions,
        filteredMarketsLabels,
        applyMarketsFilter,
    };
};
