import { useMemo } from 'react';

import moment from 'moment';

import { UIEvent } from '@sparkplug/lib';

import {
    getEventDisplayStatus,
    getEventStatusTagConfig,
} from '@features/events/helpers/eventUtils';

import Chip from '@components/chips/Chip';

const EventStatusChip = ({
    event,
    accountType,
    dense = false,
}: {
    event: UIEvent;
    accountType: 'retailer' | 'brand';
    dense?: boolean;
}) => {
    const status = useMemo(
        () => getEventDisplayStatus({ event, accountType }),
        [event, accountType],
    );

    const selectedTag = getEventStatusTagConfig(status, event.startTime);

    return (
        <Chip
            label={selectedTag.label}
            color={selectedTag.color}
            variant={selectedTag.variant}
            dense={dense}
        />
    );
};

export default EventStatusChip;
