import { ChangeEvent, useCallback, useRef, useState } from 'react';

import { Box } from '@mui/material';
import SettingsTitle from '@views/admin/components/SettingsTitle';
import { isEqual } from 'lodash';

import { UserSettings } from '@sparkplug/lib';

import { useSaveUserProfileMutation, useUploadUserAvatar } from '@core/users';

import UserAvatarUpload from '@components/UserAvatarUpload/UserAvatarUpload';
import Form from '@components/form/Form';
import AppView from '@components/layout/AppView';
import Paper from '@components/layout/Paper';

import { useApp } from '@hooks/AppHooks';

import { isEmpty, setObjectProperty } from '@helpers/util';

const AdminUserProfileView = () => {
    const { user, refetchUserAppData, userIsSuperAdmin } = useApp();
    const { firstName, lastName, email, phoneNumber, userSettings } = user || {};

    const [userData, setUserData] = useState<{
        firstName?: string;
        lastName?: string;
        email?: string;
        phoneNumber?: string;
        password?: string;
        pwdCheck?: string;
        userSettings?: UserSettings;
        avatarUrl?: string;
    }>({ firstName, lastName, email, phoneNumber, userSettings });

    const { saveUserProfile } = useSaveUserProfileMutation({});
    const { uploadUserAvatar, isUploadingUserAvatar } = useUploadUserAvatar();
    const [previewImage, setPreviewImage] = useState<File | null>();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const updateUserData = (path: string, value: any) => {
        setUserData((prevUserData) => setObjectProperty(prevUserData, { path, value }));
    };

    const onSave = useCallback(async () => {
        saveUserProfile(
            { userParams: userData, userId: user?._id },
            {
                onSuccess: () => {
                    refetchUserAppData();
                },
            },
        );
    }, [userData]);

    const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setPreviewImage(file);
        }
    };

    const handleSaveAvatar = () => {
        if (previewImage) {
            uploadUserAvatar(
                {
                    userId: user?._id || '',
                    file: previewImage,
                },
                {
                    onSuccess: () => {
                        refetchUserAppData();
                        setPreviewImage(null);
                    },
                },
            );
        }
    };

    return (
        <AppView.Content title={<SettingsTitle label="Profile" />}>
            <Paper className="max-w-[1800px] md:mb-0 mb-[60px]">
                <Form>
                    <div className="flex flex-col gap-8">
                        <div className="flex flex-col md:flex-row md:gap-8">
                            <div className="w-full md:w-1/2">
                                <div className="max-md:hidden">
                                    <Form.SectionTitle>Personal Information</Form.SectionTitle>
                                    <Form.Grid>
                                        <Form.GridItem xs={12} sm={6}>
                                            <Form.TextField
                                                label="First Name"
                                                name="firstName"
                                                defaultValue={userData.firstName || ''}
                                                onChange={(event) => {
                                                    updateUserData('firstName', event.target.value);
                                                }}
                                            />
                                        </Form.GridItem>

                                        <Form.GridItem xs={12} sm={6}>
                                            <Form.TextField
                                                label="Last Name"
                                                name="lastName"
                                                defaultValue={userData.lastName || ''}
                                                onChange={(event) => {
                                                    updateUserData('lastName', event.target.value);
                                                }}
                                            />
                                        </Form.GridItem>

                                        <Form.GridItem xs={12} sm={6}>
                                            <Form.TextField
                                                label="Email"
                                                name="email"
                                                defaultValue={userData.email || ''}
                                                onChange={(event) => {
                                                    updateUserData('email', event.target.value);
                                                }}
                                            />
                                        </Form.GridItem>

                                        <Form.GridItem xs={12} sm={6}>
                                            <Form.PhoneField
                                                label="Phone Number"
                                                name="phoneNumber"
                                                defaultValue={userData.phoneNumber || ''}
                                                disabled
                                                onChange={(event) => {
                                                    updateUserData(
                                                        'phoneNumber',
                                                        event.target.value,
                                                    );
                                                }}
                                            />
                                        </Form.GridItem>
                                    </Form.Grid>
                                </div>

                                <div className="mt-8 max-md:hidden">
                                    <Form.SectionTitle>Reset Password</Form.SectionTitle>
                                    <Form.Grid>
                                        <Form.GridItem xs={12} sm={6}>
                                            <Form.TextField
                                                type="password"
                                                label="New Password"
                                                name="password"
                                                onChange={(event) => {
                                                    updateUserData('password', event.target.value);
                                                }}
                                            />
                                        </Form.GridItem>
                                        <Form.GridItem xs={12} sm={6}>
                                            <Form.TextField
                                                type="password"
                                                label="Confirm Password"
                                                name="pwdCheck"
                                                onChange={(event) => {
                                                    updateUserData('pwdCheck', event.target.value);
                                                }}
                                            />
                                        </Form.GridItem>
                                    </Form.Grid>
                                </div>
                            </div>

                            <div className="w-full md:w-1/2 flex flex-col gap-8">
                                <div>
                                    <Form.SectionTitle>Profile Picture</Form.SectionTitle>
                                    <UserAvatarUpload />
                                </div>

                                <div className="max-md:hidden">
                                    <Form.SectionTitle className="flex justify-between items-center">
                                        <span>Notifications</span>
                                        <span className="text-gray-700 text-sm">Email</span>
                                    </Form.SectionTitle>
                                    <Box className="flex flex-col space-y-4">
                                        <Form.LabeledSwitch
                                            title="Email Notifications"
                                            subtitle="Get notified when there are changes to Sparks and/or Brand Links"
                                            name="emailNotificationsDisabled"
                                            value={
                                                !userData.userSettings?.emailNotificationsDisabled
                                            }
                                            onChange={(event: any) => {
                                                updateUserData(
                                                    'userSettings.emailNotificationsDisabled',
                                                    !event.target.checked,
                                                );
                                            }}
                                        />
                                        <Form.LabeledSwitch
                                            title="Weekly Digest Notifications"
                                            subtitle="Get weekly emails detailing your Spark activity"
                                            name="weeklyDigestEnabled"
                                            value={!!userData.userSettings?.weeklyDigestEnabled}
                                            onChange={(event: any) => {
                                                updateUserData(
                                                    'userSettings.weeklyDigestEnabled',
                                                    event.target.checked,
                                                );
                                            }}
                                        />
                                    </Box>
                                </div>
                            </div>
                        </div>

                        {/* Mobile-only sections */}
                        <div className="md:hidden">
                            <Form.SectionTitle>Personal Information</Form.SectionTitle>
                            <Form.Grid>
                                <Form.GridItem xs={12} sm={6}>
                                    <Form.TextField
                                        label="First Name"
                                        name="firstName"
                                        defaultValue={userData.firstName || ''}
                                        onChange={(event) => {
                                            updateUserData('firstName', event.target.value);
                                        }}
                                    />
                                </Form.GridItem>

                                <Form.GridItem xs={12} sm={6}>
                                    <Form.TextField
                                        label="Last Name"
                                        name="lastName"
                                        defaultValue={userData.lastName || ''}
                                        onChange={(event) => {
                                            updateUserData('lastName', event.target.value);
                                        }}
                                    />
                                </Form.GridItem>

                                <Form.GridItem xs={12} sm={6}>
                                    <Form.TextField
                                        label="Email"
                                        name="email"
                                        defaultValue={userData.email || ''}
                                        onChange={(event) => {
                                            updateUserData('email', event.target.value);
                                        }}
                                    />
                                </Form.GridItem>

                                <Form.GridItem xs={12} sm={6}>
                                    <Form.PhoneField
                                        label="Phone Number"
                                        name="phoneNumber"
                                        defaultValue={userData.phoneNumber || ''}
                                        disabled
                                        onChange={(event) => {
                                            updateUserData('phoneNumber', event.target.value);
                                        }}
                                    />
                                </Form.GridItem>
                            </Form.Grid>
                        </div>

                        <div className="md:hidden">
                            <Form.SectionTitle className="flex justify-between items-center">
                                <span>Notifications</span>
                                <span className="text-gray-700 text-sm">Email</span>
                            </Form.SectionTitle>
                            <Box className="flex flex-col space-y-4">
                                <Form.LabeledSwitch
                                    title="Email Notifications"
                                    subtitle="Get notified when there are changes to Sparks and/or Brand Links"
                                    name="emailNotificationsDisabled"
                                    value={!userData.userSettings?.emailNotificationsDisabled}
                                    onChange={(event: any) => {
                                        updateUserData(
                                            'userSettings.emailNotificationsDisabled',
                                            !event.target.checked,
                                        );
                                    }}
                                />
                                <Form.LabeledSwitch
                                    title="Weekly Digest Notifications"
                                    subtitle="Get weekly emails detailing your Spark activity"
                                    name="weeklyDigestEnabled"
                                    value={!!userData.userSettings?.weeklyDigestEnabled}
                                    onChange={(event: any) => {
                                        updateUserData(
                                            'userSettings.weeklyDigestEnabled',
                                            event.target.checked,
                                        );
                                    }}
                                />
                            </Box>
                        </div>

                        <div className="md:hidden">
                            <Form.SectionTitle>Reset Password</Form.SectionTitle>
                            <Form.Grid>
                                <Form.GridItem xs={12} sm={6}>
                                    <Form.TextField
                                        type="password"
                                        label="New Password"
                                        name="password"
                                        onChange={(event) => {
                                            updateUserData('password', event.target.value);
                                        }}
                                    />
                                </Form.GridItem>
                                <Form.GridItem xs={12} sm={6}>
                                    <Form.TextField
                                        type="password"
                                        label="Confirm Password"
                                        name="pwdCheck"
                                        onChange={(event) => {
                                            updateUserData('pwdCheck', event.target.value);
                                        }}
                                    />
                                </Form.GridItem>
                            </Form.Grid>
                        </div>

                        <section className="mt-8 flex justify-end">
                            <Form.Button
                                color="blue"
                                variant="filled"
                                disabled={
                                    isEmpty(userData) ||
                                    isEqual(userData, {
                                        firstName: user?.firstName,
                                        lastName: user?.lastName,
                                        email: user?.email,
                                        phoneNumber: user?.phoneNumber,
                                        userSettings: user?.userSettings,
                                    })
                                }
                                onClick={onSave}
                            >
                                Update Profile
                            </Form.Button>
                        </section>
                    </div>
                </Form>
            </Paper>
        </AppView.Content>
    );
};

export default AdminUserProfileView;
