import { PagedApiResponseBody } from '..';
import { ACCOUNT_MARKETS_RECORD, AccountMarket, AccountRole, AccountStatus } from '../account';
import { z } from 'zod';

export const UserRoles = [
  'none',
  'brand-admin',
  'retailer-admin',
  'super-admin',
  'webhook',
] as const;
export type UserRole = typeof UserRoles[number];

export const UserSmsStatuses = ['blocked', 'enrolled', 'none', 'pending'] as const;
export type UserSmsStatus = typeof UserSmsStatuses[number];

export interface AccountUserExternalGroup {
  groupId: string;
  groupRole: AccountRole;
  groupName: string;
}

export interface UserSettings {
  emailNotificationsDisabled?: boolean;
  weeklyDigestEnabled?: boolean;
}

export interface UserW9DataPublic {
  fullName: string;
  streetAddress: string;
  city: string;
  state: AccountMarket;
  zip: string;
  ssn: string; // Encrypted SSN stored as Buffer
  createdAt: string;
  updatedAt: string;
}

export interface IPublicUser {
  _id: string;
  avatarUrl: string;
  associatedAccounts?: number;
  accounts?: {
    _id: string;
    name: string;
    status: AccountStatus;
  }[];
  createdAt: string;
  createdBy?: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  posEmployeeProfileIds: string[];
  role: UserRole;
  updatedAt: string;
  updatedBy?: string;
  userSettings: UserSettings;
  smsStatus: UserSmsStatus;
  muteScheduledSms: boolean;
  markets?: string[];
  // Stored in cents
  yearlyEarnings?: number;
  w9Data?: UserW9DataPublic;
  onboardingCompletedAt?: Date;
}

export interface ListUsersQueryParams {
  limit?: number;
  offset?: number;
  pos_employee_profile_id?: string;
  role?: UserRole;
  search?: string;
  smsStatus?: UserSmsStatus;
}

export type ListUsersResponseBody = PagedApiResponseBody<IPublicUser>;

export interface ListUsersByIdRequestBody {
  userIds: string[];
}

export interface ListUsersByIdResponseBody {
  data: IPublicUser[];
}

export interface GetUserPathParams {
  userId: string;
}
export type GetUserResponseBody = IPublicUser;

export const createUserSchema = z.object({
  email: z.string().transform((email) => email?.toLowerCase()),
  password: z.string().optional(),
  firstName: z
    .string()
    .optional()
    .transform((firstName) => firstName?.trim()),
  lastName: z
    .string()
    .optional()
    .transform((lastName) => lastName?.trim()),
  role: z.enum(UserRoles).optional(),
});

createUserSchema.refine(
  (user) => !!user.firstName || !!user.lastName,
  'First name or last name is required when creating a user',
);

export type CreateUserRequestBody = z.infer<typeof createUserSchema>;
export type CreateUserResponseBody = IPublicUser;

export interface UpdateUserPathParams {
  userId: string;
}

export const updateUserSchema = z.object({
  email: z
    .string()
    .optional()
    .transform((email) => email?.toLowerCase()),
  firstName: z
    .string()
    .optional()
    .transform((firstName) => firstName?.trim()),
  lastName: z
    .string()
    .optional()
    .transform((lastName) => lastName?.trim()),
  password: z.string().optional(),
  role: z.enum(UserRoles).optional(),
  muteScheduledSms: z.boolean().optional(),
  userSettings: z
    .object({
      emailNotificationsDisabled: z.boolean().optional(),
      weeklyDigestEnabled: z.boolean().optional(),
    })
    .optional(),
  avatarUrl: z.string().optional(),
});
export type UpdateUserRequestBody = z.infer<typeof updateUserSchema>;

export type UpdateUserResponseBody = IPublicUser;

export const userW9Schema = z.object({
  fullName: z.string(),
  streetAddress: z.string(),
  city: z.string(),
  state: z.enum(Object.keys(ACCOUNT_MARKETS_RECORD) as [string, ...string[]]),
  zip: z.string(),
  ssn: z.string(),
  email: z.string().email(),
});

export const userW9RequestSchema = z.object({
  fullName: z.string(),
  streetAddress: z.string(),
  city: z.string(),
  state: z.enum(Object.keys(ACCOUNT_MARKETS_RECORD) as [string, ...string[]]),
  zip: z
    .string()
    .regex(
      /^\d{4,5}(-\d{4})?$/,
      'ZIP code must be 5 digits, optionally followed by a 4-digit extension',
    ),
  ssn: z.string().regex(/^\d{9}$/, 'SSN must be exactly 9 digits'),
  email: z.string().email('Please enter a valid email address'),
});

export type UpdateW9ResponseBody = z.infer<typeof userW9Schema>;
export type UpdateW9RequestBody = z.infer<typeof userW9RequestSchema>;

export interface DeleteUserPathParams {
  userId: string;
  accountId?: string;
}

export interface UpdatePasswordRequestBody {
  code: string;
  password: string;
  userId: string;
}

export interface InitiatePasswordResetQueryParams {
  email: string;
}

export interface ResetPasswordRequestBody {
  code: string;
  email: string;
  password: string;
}

export interface BatchSmsRequestBody {
  /**
   * The message content to send to the users.
   */
  body: string;
  userIds: string[];
}

export interface SaveAccountUserRequestBody {
  groupId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string | null;
  posEmployeeProfileIds?: string[];
  role?: AccountRole;
  permissions?: {
    manageSparks?: boolean;
    manageEvents?: boolean;
    manageSnaps?: boolean;
    manageCourses?: boolean;
    manageProductTags?: boolean;
    manageLinks?: boolean;
    manageUsers?: boolean;
    accessBilling?: boolean;
    markets?: (AccountMarket | 'all')[];
    locationIds?: string[];
  };
}

export interface SaveAccountUserRoleRequestBody {
  groupId: string;
  email: string;
  role: AccountRole;
  userIdToDelete?: string;
  permissions: {
    manageSparks?: boolean;
    manageEvents?: boolean;
    manageSnaps?: boolean;
    manageCourses?: boolean;
    manageProductTags?: boolean;
    manageLinks?: boolean;
    manageUsers?: boolean;
    accessBilling?: boolean;
    markets?: AccountMarket[];
    locationIds?: string[];
  };
}

export interface BatchSaveAccountUserRequestBody {
  groupId: string;
  users: (Omit<SaveAccountUserRequestBody, 'groupId'> & {
    userId?: string;
  })[];
}

export interface BatchSaveAccountUserResponseBody {
  errors: {
    user: Omit<SaveAccountUserRequestBody, 'groupId'> & {
      userId?: string;
    };
    error: 'conflict' | 'unknown';
    errorMessage?: string;
  }[];
}

export interface CaptureOnboardingDetailsRequestBody {
  firstName: string;
  lastName: string;
  preferredLocationId?: string;
}
