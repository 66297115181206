import {
    ComponentType,
    FC,
    Fragment,
    RefObject,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';

import { SPARK_TYPE_LABELS_RECORD, Spark, SparkType, UIRequestForSpark } from '@sparkplug/lib';

import { getSparkTemplateData } from '@features/spark-wizard/spark-templates';

import { ModalProvider } from '@contexts/ModalContext';
import { SparkProvider } from '@contexts/SparkContext';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import {
    ChevronLeft as ArrowLeftIcon,
    ChevronRight as ArrowRightIcon,
    CommissionSparkIcon,
    GoalSparkIcon,
    LeaderboardSparkIcon,
} from '@components/icons';
import FullscreenNav from '@components/layout/FullscreenNav';
import Skeleton from '@components/layout/Skeleton';
import Modal from '@components/overlays/Modal';
import { useLocation, useParams, useQueryParams } from '@components/router';

import { useApp, useAppBodyClasses } from '@hooks/AppHooks';
import { useModal } from '@hooks/ModalHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';
import { useResize } from '@hooks/UIHooks';

import { ClonedSparkFields } from '@app/types/SparksTypes';

import CommissionCommissionsFormFields from '../../components/CommissionCommissionsFormFields';
import SparkDetailsForm from '../../components/SparkDetailsFormFields';
import { SparkWizardNextButton } from '../../components/SparkModalActionButtons';
import SparkParticipantsSelector from '../../components/SparkParticipantsSelector';
import SparkPrizesFormFields from '../../components/SparkPrizesFormFields';
import SparkProductSelector from '../../components/SparkProductSelector';
import { SparkWizardCancelButton } from '../../components/SparkWizardCancelButton';
import CommissionReview from './CommissionReview';
import GoalReview from './GoalReview';
import LeaderboardReview from './LeaderboardReview';
import { SparkWizardAccountProvider } from './SparkWizardAccountProvider';

import './SparkWizardView.scss';

export type SparkWizardOriginRoute = 'spark-dashboard' | 'spark-details' | 'spark-templates';

const SparkWizardSteps: Record<SparkType, ComponentType[]> = {
    leaderboard: [
        SparkDetailsForm,
        SparkParticipantsSelector,
        SparkProductSelector,
        SparkPrizesFormFields,
        LeaderboardReview,
    ],
    goal: [
        SparkDetailsForm,
        SparkParticipantsSelector,
        SparkProductSelector,
        SparkPrizesFormFields,
        GoalReview,
    ],
    commission: [
        SparkDetailsForm,
        SparkParticipantsSelector,
        SparkProductSelector,
        CommissionCommissionsFormFields,
        CommissionReview,
    ],
};

/**
 * This is used to dynamically set the height of the modal content based on the current stage because
 * the <Table.RenderBody dynamicHeight /> prop does not work without a static modal height.
 */
const useSparkWizardContentDynamicHeight = ({
    currentStage,
    isCreatingMultiRetailerSpark,
    spark,
}: {
    currentStage: number;
    isCreatingMultiRetailerSpark: boolean;
    spark: Spark;
}) => {
    const [height, setHeight] = useState<number>();
    const updateHeight = useCallback(() => {
        const isSingleRetailerProductSelectionStep =
            currentStage === 2 && !isCreatingMultiRetailerSpark;
        const isCommissionStep = currentStage === 3 && spark.type === 'commission';
        if (isSingleRetailerProductSelectionStep || isCommissionStep) {
            // In order to restrict the height of the product selector, we need to set the height of the
            // modal content to the height of the window minus the upper and lower padding of the content
            setHeight(window.innerHeight - 176 - 32);
        } else {
            setHeight(undefined);
        }
    }, [isCreatingMultiRetailerSpark, currentStage, spark.type]);
    useResize(updateHeight);

    useLayoutEffect(() => {
        updateHeight();
    }, [updateHeight]);

    return height;
};

const SparkWizardContent = () => {
    const { account } = useSparkplugAccount();
    const { spark, isCreatingMultiRetailerSpark, sparkIsReady } = useSpark();
    const { currentStage, modalContentRef } = useModal();
    //
    // // Scroll to top of modal content when the stage changes
    // // it was looking like pages were still waiting to load because the content was still at the bottom
    // // from the previous stage
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [currentStage, modalContentRef]);

    const CurrentStepComponent = spark.type ? SparkWizardSteps[spark.type][currentStage] : Fragment;

    const containerHeight = useSparkWizardContentDynamicHeight({
        isCreatingMultiRetailerSpark,
        spark,
        currentStage,
    });

    if (!sparkIsReady || !account) {
        return <Skeleton height="75vh" width="100%" />;
    }

    if (CurrentStepComponent) {
        return (
            <div
                className="wizard-content-container"
                ref={modalContentRef as RefObject<HTMLDivElement>}
                style={{ height: containerHeight }}
            >
                <SparkWizardAccountProvider accountId={spark?.originatorGroupId ?? spark?.groupId}>
                    <CurrentStepComponent />
                </SparkWizardAccountProvider>
            </div>
        );
    }

    return <></>;
};

type SparkWizardAction = 'create' | 'edit';

const SingleRetailerStepperNames = (
    isVendorSponsoredSpark: boolean,
): Record<SparkType, Record<SparkWizardAction, string[]>> => ({
    leaderboard: {
        create: [
            'Spark Details',
            'Participants',
            isVendorSponsoredSpark ? 'Qualifying Products' : 'Objective',
            'Prizes',
            'Review & Launch',
        ],
        edit: [
            'Spark Details',
            'Participants',
            isVendorSponsoredSpark ? 'Qualifying Products' : 'Objective',

            'Prizes',
            'Review & Update',
        ],
    },
    goal: {
        create: [
            'Spark Details',
            'Participants',
            isVendorSponsoredSpark ? 'Qualifying Products' : 'Objective',
            'Goals',
            'Review & Launch',
        ],
        edit: [
            'Spark Details',
            'Participants',
            isVendorSponsoredSpark ? 'Qualifying Products' : 'Objective',
            'Goals',
            'Review & Update',
        ],
    },
    commission: {
        create: [
            'Spark Details',
            'Participants',
            'Qualifying Products',
            'Commissions',
            'Review & Launch',
        ],
        edit: [
            'Spark Details',
            'Participants',
            'Qualifying Products',
            'Commissions',
            'Review & Update',
        ],
    },
});

const MultiRetailerStepperNames: Record<SparkType, Record<SparkWizardAction, string[]>> = {
    leaderboard: {
        create: ['Spark Details', 'Retailers', 'Qualifying Products', 'Prizes', 'Review & Launch'],
        edit: ['Spark Details', 'Retailers', 'Qualifying Products', 'Prizes', 'Review & Update'],
    },
    goal: {
        create: ['Spark Details', 'Retailers', 'Qualifying Products', 'Goals', 'Review & Launch'],
        edit: ['Spark Details', 'Retailers', 'Qualifying Products', 'Goals', 'Review & Update'],
    },
    commission: {
        create: [
            'Spark Details',
            'Retailers',
            'Qualifying Products',
            'Commissions',
            'Review & Launch',
        ],
        edit: [
            'Spark Details',
            'Retailers',
            'Qualifying Products',
            'Commissions',
            'Review & Update',
        ],
    },
};

const SparkWizardStepper = () => {
    const { spark, isCreatingMultiRetailerSpark } = useSpark();

    if (!spark.type) {
        return <></>;
    }

    const stepperNames = isCreatingMultiRetailerSpark
        ? MultiRetailerStepperNames
        : SingleRetailerStepperNames(!!spark?.originatorGroupId);
    const action = spark?._id ? 'edit' : 'create';

    return (
        <Modal.Stepper
            className="spark-wizard-stepper"
            stageNames={stepperNames[spark.type]?.[action]}
        />
    );
};

const SparkWizardBackButton = () => {
    const { currentStage, back } = useModal();

    if (currentStage === 0) {
        return <></>;
    }

    return (
        <Button
            color="white"
            className="spark-wizard-back-button"
            variant="flat"
            startIcon={<ArrowLeftIcon />}
            onClick={back}
        >
            Back
        </Button>
    );
};

const TitleIcons: Record<SparkType, ComponentType> = {
    leaderboard: LeaderboardSparkIcon,
    goal: GoalSparkIcon,
    commission: CommissionSparkIcon,
};

export const SparkWizardTitle: FC<{}> = () => {
    const { spark } = useSpark();

    if (!spark.type) {
        return <></>;
    }

    const Icon = TitleIcons[spark.type] ?? (() => <></>);

    const action = spark._id ? 'Edit' : 'Create';
    const title = `${action} ${SPARK_TYPE_LABELS_RECORD[spark.type]} Spark`;

    return (
        <div className="spark-wizard-title">
            <div className="title-content">
                <span>
                    <Icon />
                </span>
                <span>
                    {title}
                    {spark.name && ':'}
                </span>
            </div>
            {spark.name && <span className="spark-name">{spark.name}</span>}
        </div>
    );
};

/**
 * If we're creating a spark from a request, the requestForSpark is passed in the router's location state.
 * Unfortunately, the router's location state is not persisted when navigating through the Spark Wizard.
 * This hook persists the requestForSpark data in the SparkWizardView component.
 *
 * TODO: Implement a more robust way to persist this data (likely in a Provider pattern)
 * @returns The persisted requestForSpark data
 */
export const usePersistRequestForSpark = () => {
    // TODO move to SparkContext?
    const [wizardRequestForSpark, setWizardRequestForSpark] = useState<UIRequestForSpark>();
    const { state } = useLocation<{
        state: {
            requestForSpark?: UIRequestForSpark;
        };
    }>();

    useEffect(() => {
        if (state?.state?.requestForSpark) {
            setWizardRequestForSpark(state.state.requestForSpark);
        }
    }, [state?.state?.requestForSpark]);

    return wizardRequestForSpark;
};

const getNavDestination = (
    originRoute: SparkWizardOriginRoute,
    accountId: string,
    sparkId?: string,
    clonedSparkId?: string,
): string => {
    switch (originRoute) {
        case 'spark-details':
            if (sparkId || clonedSparkId) {
                return `/${accountId}/sparks/${sparkId || clonedSparkId}`;
            }
            return `/${accountId}/sparks`;
        case 'spark-templates':
            return `/${accountId}/sparks/templates`;
        default:
            return `/${accountId}/sparks`;
    }
};

interface SparkWizardViewProps {}
const SparkWizardView: FC<SparkWizardViewProps> = () => {
    const { account } = useSparkplugAccount();
    const { userIsSuperAdmin, history, appIsMobile } = useApp();
    const { sparkId, sparkType } = useParams<{
        sparkId?: string;
        sparkType?: SparkType;
    }>();
    const { templateId } = useQueryParams();
    const isCreatingNewSpark = !sparkId;
    const isCreatingFromTemplate = !!templateId;
    const requestForSpark = usePersistRequestForSpark();
    const { state } = useLocation<{
        state: {
            clonedSparkId?: string;
            clonedSparkFields?: ClonedSparkFields;
            originRoute: SparkWizardOriginRoute;
        };
    }>();
    const originRouteRef = useRef<SparkWizardOriginRoute>(
        state?.state?.originRoute ?? sparkId ? 'spark-details' : 'spark-dashboard',
    );
    const [navDestination, setNavDestination] = useState<string>(() =>
        getNavDestination(
            originRouteRef.current,
            account?._id ?? '',
            sparkId,
            state?.state?.clonedSparkId,
        ),
    );

    useAppBodyClasses(['app-bgWhite', 'spark-wizard-app-body'], []);

    const { retailerAccountId: initializedRetailerAccountId = '' }: { retailerAccountId?: string } =
        useQueryParams();

    useEffect(() => {
        if (state?.state?.originRoute) {
            originRouteRef.current = state.state.originRoute;
            setNavDestination(
                getNavDestination(
                    state.state.originRoute,
                    account?._id ?? '',
                    sparkId,
                    state?.state?.clonedSparkId,
                ),
            );
        }
    }, [state?.state?.originRoute]);

    const accountIsVendor = account?.type === 'brand';
    const isVendorAdmin = accountIsVendor && !userIsSuperAdmin;

    const initialSparkData: Partial<Spark> = isCreatingNewSpark
        ? {
              groupId: accountIsVendor ? initializedRetailerAccountId : account?._id,
              originatorGroupId: accountIsVendor ? account?._id : undefined,
              type: sparkType,
              metric: accountIsVendor ? 'total_units' : undefined,
              requestState: isVendorAdmin ? 'pending' : 'none',
              internalTracking: {
                  status: 'none',
                  invoiceStatus: 'not-sent',
                  payoutStatus: 'not-paid',
                  notes: '',
              },
              teamType: undefined,
              ...(isCreatingFromTemplate ? getSparkTemplateData(templateId) : {}),
              ...state?.state?.clonedSparkFields,
          }
        : {};

    const onComplete = (resSparkId?: string) => {
        // if this is cloned spark, we will get a new sparkId and we should open new spark details page
        if (
            resSparkId &&
            state?.state?.clonedSparkId &&
            state?.state?.originRoute === 'spark-details' &&
            state?.state?.clonedSparkId !== resSparkId
        ) {
            history.push(`/${account?._id}/sparks/${resSparkId}`);
        } else {
            history.push(navDestination);
        }
    };

    return (
        <ModalProvider isSparkWizard>
            <Form>
                <SparkProvider
                    requestForSpark={requestForSpark}
                    sparkId={sparkId}
                    initialSparkData={initialSparkData}
                >
                    <FullscreenNav
                        className="spark-wizard-view"
                        title={<SparkWizardStepper />}
                        hideBackLink={appIsMobile}
                        fixedHeaderProps={{
                            className: 'spark-wizard-header',
                            title: <SparkWizardTitle />,
                            leftElements: (
                                <SparkWizardCancelButton
                                    destination={
                                        isCreatingFromTemplate ? undefined : navDestination
                                    }
                                />
                            ),
                            rightElements: (
                                <>
                                    <SparkWizardBackButton />
                                    <SparkWizardNextButton
                                        variant="filled"
                                        color="blue"
                                        endIcon={<ArrowRightIcon />}
                                        onComplete={onComplete}
                                    />
                                </>
                            ),
                        }}
                    >
                        <SparkWizardContent />
                    </FullscreenNav>
                </SparkProvider>
            </Form>
        </ModalProvider>
    );
};

export default SparkWizardView;
