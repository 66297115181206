import { ComponentProps } from 'react';

import { MoreVert as MoreIcon } from '@mui/icons-material';

import { DO_NOT_HAVE_PERMISSIONS_MESSAGE, UIEvent } from '@sparkplug/lib';

import Dropdown from '@components/dropdown/Dropdown';
import ClickableArea from '@components/layout/ClickableArea';

import { IAccount } from '@app/types/AccountsTypes';
import { IAuthUser } from '@app/types/UsersTypes';

import { useEventNavigation } from '../../../hooks/useEventNavigation';
import { useApproveEventMutation } from '../../../mutations';
import { addToCalendar } from './addToCalendar';

export const EventTableActionMenu = ({
    event,
    account,
    user,
    showDecline = false,
    showDelete = false,
    showEdit = false,
    showClone = false,
    showApprove = false,
    userIsPermitted = false,
    showOpenInNewTab = true,
}: {
    event: UIEvent;
    account?: IAccount;
    user?: IAuthUser;
    showDecline?: boolean;
    showDelete?: boolean;
    showEdit?: boolean;
    showClone?: boolean;
    showApprove?: boolean;
    userIsPermitted?: boolean;
    showOpenInNewTab?: boolean;
}) => {
    const nav = useEventNavigation();

    const { approveEventAsync } = useApproveEventMutation();
    const handleApproveEvent = async () => {
        await approveEventAsync(event._id);
    };

    const userPermissionTooltip: ComponentProps<typeof Dropdown.MenuItem>['tooltipProps'] =
        !userIsPermitted ? { title: DO_NOT_HAVE_PERMISSIONS_MESSAGE } : undefined;
    return (
        <ClickableArea
            className="more-button w-full h-full flex items-center justify-center"
            onClick={(e) => e.stopPropagation()}
        >
            <Dropdown color="neutral" variant="flat" disabled={false}>
                <Dropdown.IconButton className="!p-[5px]" color="neutral">
                    <MoreIcon />
                </Dropdown.IconButton>

                <Dropdown.Menu>
                    {showApprove && (
                        <Dropdown.MenuItem
                            disabled={!userIsPermitted}
                            tooltipProps={userPermissionTooltip}
                            onClick={handleApproveEvent}
                        >
                            Approve Event
                        </Dropdown.MenuItem>
                    )}
                    {showDecline && (
                        <Dropdown.MenuItem
                            disabled={!userIsPermitted}
                            tooltipProps={userPermissionTooltip}
                            onClick={() => nav.actions.goToDeclineEventModal(event._id, false)}
                        >
                            Decline Event
                        </Dropdown.MenuItem>
                    )}
                    {showEdit && (
                        <Dropdown.MenuItem
                            disabled={!userIsPermitted}
                            tooltipProps={userPermissionTooltip}
                            onClick={() => nav.actions.goToEditEventDrawer(event._id)}
                        >
                            Edit Event
                        </Dropdown.MenuItem>
                    )}
                    {showClone && (
                        <Dropdown.MenuItem
                            disabled={!userIsPermitted}
                            tooltipProps={userPermissionTooltip}
                            onClick={() => nav.actions.goToCloneEventDrawer(event._id)}
                        >
                            Clone Event
                        </Dropdown.MenuItem>
                    )}
                    {showDelete && (
                        <Dropdown.MenuItem
                            disabled={!userIsPermitted}
                            tooltipProps={userPermissionTooltip}
                            onClick={() => nav.actions.goToDeleteEventModal(event._id)}
                        >
                            Delete Event
                        </Dropdown.MenuItem>
                    )}

                    <Dropdown.MenuItem
                        onClick={() =>
                            addToCalendar({ event, account, organizerEmail: user?.email })
                        }
                    >
                        Add to Calendar
                    </Dropdown.MenuItem>
                    {!showApprove && showOpenInNewTab && (
                        <Dropdown.MenuItem
                            onClick={() => nav.actions.goToEventDetailsView(event._id, true)}
                        >
                            Open Event in New Tab
                        </Dropdown.MenuItem>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </ClickableArea>
    );
};
