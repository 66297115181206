import { useAccountLinks } from '@features/account-links/queries';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

export const usePendingVendorLinks = () => {
    const { account } = useSparkplugAccount();

    const {
        accountLinks: pendingVendorLinks = [],
        accountLinksAreReady: pendingVendorLinksAreReady,
        refetchAccountLinks: refetchPendingVendorLinks,
    } = useAccountLinks(account?._id ?? '', !!account && account.type === 'retailer', ['pending']);

    const pendingVendorLinkCount = pendingVendorLinks.length;
    const showPendingVendorLinks = account?.type === 'retailer' && pendingVendorLinkCount > 0;

    return {
        pendingVendorLinks,
        pendingVendorLinkCount,
        pendingVendorLinksAreReady,
        refetchPendingVendorLinks,
        showPendingVendorLinks,
    };
};
