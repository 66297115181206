import { FC } from 'react';

import { MarketsFilter } from '@features/account-links/components/MarketsFilter';

import Toolbar from '@components/toolbar/Toolbar';

import { InputEventOnChange } from '@app/types/UITypes';

import { useAccountLinkTableFilters } from '../../hooks/useAccountLinkTableFilters';

import './AccountLinksTableHeader.scss';

const statusFilterOptions = [
    { label: 'All Statuses', value: 'all' },
    { label: 'Enabled', value: 'enabled' },
    { label: 'Disabled', value: 'disabled' },
];

export interface AccountLinksTableHeaderProps {
    searchFilter?: string;
    onChangeSearchFilter: InputEventOnChange;
    isInboxView?: boolean;
}
export const AccountLinksTableHeader: FC<AccountLinksTableHeaderProps> = ({
    searchFilter,
    onChangeSearchFilter,
    isInboxView = false,
}) => {
    const { statusFilter, setStatusFilter } = useAccountLinkTableFilters();

    return (
        <div className="account-links-header">
            <Toolbar className="account-links-toolbar" scrollOnMobile>
                <Toolbar.Search
                    name="search"
                    defaultValue={searchFilter}
                    onChange={onChangeSearchFilter}
                />
                <Toolbar.Text className="account-markets toolbar-group-start">
                    <MarketsFilter />
                </Toolbar.Text>
                {!isInboxView && (
                    <Toolbar.Dropdown
                        className="status-dropdown"
                        label={null}
                        options={statusFilterOptions}
                        value={statusFilter}
                        onSelect={(value) => setStatusFilter(value)}
                    />
                )}
            </Toolbar>
        </div>
    );
};
