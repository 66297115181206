import { useEffect } from 'react';

import { HelpCenterPage } from '@views/HelpCenterView';
import AccountAdminView from '@views/admin/AccountAdminView';
import DashboardView from '@views/dashboard/DashboardView';
import SparkDetailsView from '@views/sparks/SparkDetailsView';
import SparkManageView from '@views/sparks/SparkManageView';
import { slk } from 'survey-core';

import { AdminAccountLinksView } from '@features/account-links/views';
import AdminBillingSetupView from '@features/billing/views/AdminBIllingSetupView';
import AdminBillingUpdateView from '@features/billing/views/AdminBillingUpdateView';
import AdminCoursesView from '@features/courses/views';
import AdminCoursesCreate from '@features/courses/views/AdminCoursesCreate';
import AdminCoursesDetails from '@features/courses/views/AdminCoursesDetails';
import AdminEventsView from '@features/events/views/AdminEventsView';
import { EventDetailsView } from '@features/events/views/EventDetailsView/EventDetailsView';
import SparkTemplates from '@features/spark-dashboard/views/SparkTemplates/';
import SparksView from '@features/spark-dashboard/views/SparksView';
import SnapAnalytics from '@features/spark-snaps/views/SnapAnalytics';
import SparkSnapsView from '@features/spark-snaps/views/SparkSnapsView';
import TemplatesView from '@features/spark-snaps/views/TemplatesView/TemplatesView';
import { SparkWizardView } from '@features/spark-wizard';

import {
    AdminRoute,
    AuthRoute,
    ConditionalRoute,
    Redirect,
    SuperAdminRoute,
    Switch,
    useParams,
    useRouteMatch,
} from '@components/router';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { isValidObjectId } from '@helpers/util';

slk(
    'NTQyY2I1ZDAtMDcyZC00MzA0LTkwNWYtNzlmYmMwN2I2ODUxOzE9MjAyNS0xMS0xMywyPTIwMjUtMTEtMTMsND0yMDI1LTExLTEz',
);
const AccountRouter = () => {
    const { path } = useRouteMatch();
    const { setIsAdminApp } = useApp();

    const { accountId } = useParams<{
        accountId: string;
    }>();

    const { changeAccountById } = useSparkplugAccount();

    useEffect(() => {
        setIsAdminApp(true);
    }, [path]);

    useEffect(() => {
        if (isValidObjectId(accountId)) {
            changeAccountById(accountId);
        }
    }, [accountId]);

    const { REACT_APP_REQUEST_FOR_SPARK = false } = import.meta.env;

    return (
        <Switch>
            <AdminRoute path={`${path}/help`} component={HelpCenterPage} />
            <AdminRoute
                path={`${path}/sparks/complete`}
                component={() => <SparksView initialSparkStatus="complete" />}
            />
            <AdminRoute
                path={`${path}/sparks/upcoming`}
                component={() => <SparksView initialSparkStatus="upcoming" />}
            />
            <AdminRoute
                path={`${path}/sparks/sent`}
                component={() => <SparksView initialSparkStatus="inbox/sent" />}
            />
            {REACT_APP_REQUEST_FOR_SPARK === 'true' && (
                <AdminRoute
                    path={`${path}/sparks/requests`}
                    component={() => <SparksView initialSparkStatus="inbox/requests" />}
                />
            )}

            <AdminRoute
                path={`${path}/sparks/inbox/:sparkId`}
                component={({ match }: any) => {
                    return (
                        <SparksView
                            initialSparkStatus="inbox/sent"
                            forceSparkApprovalModal={{
                                sparkId: match.params.sparkId,
                            }}
                        />
                    );
                }}
            />
            <AdminRoute
                path={`${path}/sparks/inbox`}
                component={() => <SparksView initialSparkStatus="inbox/sent" />}
            />
            <AdminRoute
                path={`${path}/sparks/create/:sparkType/:stageName`}
                component={SparkWizardView}
            />
            <AdminRoute path={`${path}/sparks/create/:sparkType`} component={SparkWizardView} />
            <AdminRoute
                path={`${path}/sparks/create`}
                component={() => {
                    return <SparksView showPicker />;
                }}
            />
            <AdminRoute path={`${path}/sparks/templates`} component={SparkTemplates} />

            <SuperAdminRoute path={`${path}/sparks/:sparkId/manage`} component={SparkManageView} />
            <AdminRoute
                path={`${path}/sparks/:sparkId/edit/:stageName?`}
                component={SparkWizardView}
            />
            <AuthRoute path={`${path}/sparks/:sparkId`} component={SparkDetailsView} />
            <AuthRoute path={`${path}/sparks`} component={SparksView} />

            <AdminRoute path={`${path}/partners`} component={AdminAccountLinksView} />

            <AdminRoute path={`${path}/events/sent`} component={AdminEventsView} />
            <AdminRoute path={`${path}/events/inbox`} component={AdminEventsView} />
            <AdminRoute path={`${path}/events/:eventId`} component={EventDetailsView} />

            <AdminRoute path={`${path}/events`} component={AdminEventsView} />
            <AdminRoute
                path={`${path}/courses/:learningResourceId/edit`}
                component={AdminCoursesCreate}
            />
            <AdminRoute
                path={`${path}/courses/:learningResourceId`}
                component={AdminCoursesDetails}
            />
            <AdminRoute path={`${path}/courses`} component={AdminCoursesView} />

            <AdminRoute path={`${path}/settings`} component={AccountAdminView} />

            <AdminRoute path={`${path}/snaps/templates`} component={TemplatesView} />

            <AdminRoute path={`${path}/snaps/:snapId`} exact component={SnapAnalytics} />
            <AdminRoute path={`${path}/snaps`} exact component={SparkSnapsView} />

            <ConditionalRoute path={`${path}/update-payment`} component={AdminBillingUpdateView} />
            <ConditionalRoute path={`${path}/setup-payment`} component={AdminBillingSetupView} />

            <AdminRoute path={`${path}/dashboard/market/:market`} component={DashboardView} />
            <AdminRoute path={`${path}/dashboard/:brandRetailerId`} component={DashboardView} />
            <AdminRoute path={`${path}/dashboard`} component={DashboardView} />

            <Redirect from={`${path}/admin`} to={`${path}/settings`} />

            <Redirect from={`${path}`} to={`${path}/dashboard`} />
        </Switch>
    );
};

export default AccountRouter;
