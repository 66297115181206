import { Model } from 'survey-core';

import { formatCurrency } from '@sparkplug/lib';

import Button from '@components/buttons/Button';

import { Badge } from '@app/componentLibrary/Badge';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@app/componentLibrary/Card';
import { Progress } from '@app/componentLibrary/Progress';
import Typography from '@app/componentLibrary/Typography';

type CourseCardProps = {
    resource: {
        learningResourceId: string;
        brandPhoto?: string | null;
        surveyData?: any;
        accountPhoto?: string | null;
        brandName?: string | null;
        accountName?: string | null;
        reward?: number | null;
        name: string;
        description: string;
        budget?: number | null;
        surveyResponse?: { status?: string };
    };
    isCompleted?: boolean;
    onCardClick: (resourceId: string) => void;
};

const CompletionPercentage = ({ resource }: { resource: CourseCardProps['resource'] }) => {
    const survey = new Model(resource.surveyData || {});
    survey.data = resource.surveyResponse || {};
    const totalQuestions = survey.getAllQuestions().filter((question) => {
        const type = question.getType();
        return type !== 'image';
    }).length;
    const completedQuestions = survey
        .getAllQuestions()
        .filter((question) => survey.getValue(question.name) !== undefined).length;
    const incorrectAnswers = survey.getInCorrectAnswerCount();

    // Only count questions that were both attempted AND correct
    const correctlyAnswered = Math.max(0, completedQuestions - incorrectAnswers);
    const completionPercent = (correctlyAnswered / totalQuestions) * 100;

    return (
        <div className="space-y-2 bg-cerulean-0 px-3 py-[10px] rounded-md w-full mb-2">
            <div className="flex flex-row items-center justify-between">
                <Typography variant="sm" className="font-medium text-bluewood-300">
                    {completionPercent.toFixed(0)}% Complete
                </Typography>
                <Typography variant="sm" className="font-medium text-bluewood-300 uppercase">
                    {correctlyAnswered} / {totalQuestions} Correct
                </Typography>
            </div>
            <Progress value={completionPercent} color="cerulean" />
        </div>
    );
};
export const CourseCard = ({ resource, isCompleted, onCardClick }: CourseCardProps) => {
    const getButtonText = () => {
        if (isCompleted) return 'View completed course';
        if (resource.surveyResponse)
            return `Continue Course ${resource.budget ? '(Limited Rewards)' : ''}`;
        if (resource.budget && resource.budget > 0 && resource.reward) {
            return `Earn ${formatCurrency(resource.reward / 100)} (Limited Rewards)`;
        }
        return 'Start Course';
    };
    return (
        <Card className="mb-4" onClick={() => onCardClick(resource.learningResourceId)}>
            <CardHeader className="mb-3">
                <CardTitle className="flex flex-row items-center justify-between">
                    <img
                        src={resource.brandPhoto ?? resource.accountPhoto ?? ''}
                        alt={resource.brandName ?? resource.accountName ?? ''}
                        className="w-10 h-10 rounded-full mr-2"
                    />
                    <div className="flex flex-col space-y-1 items-end">
                        <Badge
                            color="blue"
                            label={resource.brandName ?? resource.accountName}
                            className="w-fit"
                        />
                        <Badge
                            color={isCompleted ? 'dark-gray' : 'green'}
                            className="w-fit"
                            label={`${isCompleted ? 'Earned ' : '+ '}${
                                resource.reward ? formatCurrency(resource.reward / 100) : 0
                            }`}
                        />
                    </div>
                </CardTitle>
            </CardHeader>
            <CardContent>
                <Typography variant="sm" className="font-semibold uppercase mb-1">
                    Course
                </Typography>
                <Typography variant="base" className="text-gray-950 font-bold">
                    {resource.name}
                </Typography>
                <Typography variant="base" className="text-gray-900">
                    {resource.description}
                </Typography>
            </CardContent>
            <CardFooter className="flex-col">
                {resource.surveyResponse ? (
                    <CompletionPercentage resource={resource} />
                ) : (
                    <Button variant="outlined" color="blue" className="w-full">
                        {getButtonText()}
                    </Button>
                )}
            </CardFooter>
        </Card>
    );
};
