import { FC, ReactNode, createContext, useContext, useEffect, useMemo } from 'react';

import { isEqual } from 'lodash';
import moment from 'moment';

import { Spark, SparkDisplayStatus } from '@sparkplug/lib';

import { useGetSparkDateRangeQuery } from '@core/sparks/queries/GetSparkDateRangeQuery';
import { useGetSparksQuery } from '@core/sparks/queries/GetSparksQuery';

import { useSparksFilters } from '@features/spark-dashboard/hooks/useSparkFilters';

import { queryToString, useQueryParams } from '@components/router';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { noop } from '@helpers/util';

import { ISparksContext } from '@app/types/SparksTypes';

export const SparksContext = createContext<ISparksContext>({
    refetchSparks: noop,
    sparks: [],
    meta: {
        current: 0,
        limit: 0,
        offset: 0,
        total: 0,
    },
    sparksAreReady: false,
    sparksIsLoading: false,
    sparkFilters: {
        location: [],
        status: 'active',
        schedule: [],
        training: [],
        markets: [],
        brandIds: [],
        vendorIds: [],
        type: [],
        requestState: undefined,
    },
    sparkDateRange: { startDate: undefined, endDate: undefined },
    updateSparkFilters: (sparks: Spark[]): Spark[] => sparks,
});

export const useSparks = () => useContext(SparksContext);

export const SparksProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { account, accountIsReady } = useSparkplugAccount();

    const { isAdminApp, history } = useApp();
    const { sparkDateRange } = useGetSparkDateRangeQuery({
        account,
        isAdminApp,
    });

    const STORAGE_KEY = 'sparkFiltersContext';

    const {
        p = 0,
        limit = 10,
        orderBy = 'createdAt',
        order = 'desc',
    }: { p?: number; limit?: number; orderBy?: string; order?: string } = useQueryParams();

    const initialSparkStatus = useMemo(() => {
        // this is the last segment of the route
        const statusSegment = window.location.pathname.split('/').pop();
        if (
            statusSegment &&
            ['active', 'upcoming', 'complete', 'sent', 'requests', 'inbox'].includes(statusSegment)
        ) {
            if (statusSegment === 'inbox' || statusSegment === 'sent') return 'inbox/sent';
            if (statusSegment === 'requests') return 'inbox/requests';
            return statusSegment as SparkDisplayStatus;
        }

        // eventually we want to move SparkTemplates under the SparksView or add a wrapper so they are using the same
        return 'active';
    }, [isAdminApp]);

    const defaults: {
        [key: string]: { startDate?: moment.Moment; endDate?: moment.Moment };
    } = {
        complete: {
            startDate: sparkDateRange?.startDate
                ? moment(sparkDateRange?.startDate)
                : moment(account?.createdAt),
            endDate: moment(),
        },
        upcoming: {
            startDate: moment(),
            endDate: sparkDateRange?.endDate ? moment(sparkDateRange?.endDate) : undefined,
        },
        inbox: {
            startDate: undefined,
            endDate: undefined,
        },
        sent: {
            startDate: undefined,
            endDate: undefined,
        },
        default: {
            startDate: moment(),
            endDate: undefined,
        },
    };

    const defaultStartDate = defaults[initialSparkStatus]?.startDate?.format('YYYY-MM-DD');
    const defaultEndDate = defaults[initialSparkStatus]?.endDate?.format('YYYY-MM-DD');

    const { sparkFilters, updateSparkFilters } = useSparksFilters({
        initialFilters: {
            location: [],
            type: [],
            status: initialSparkStatus,
            schedule: [],
            training: [],
            markets: [],
            brandIds: [],
            vendorIds: [],
            search: '',
            startDateRange: defaultStartDate,
            endDateRange: defaultEndDate,
        },
        account,
        sparkDateRange,
    });

    const { p: pageParam = 0, ...rest } = useQueryParams();
    useEffect(() => {
        const storedFilters = localStorage.getItem(STORAGE_KEY) || '{}';

        // Parse both into plain objects for comparison
        const parsedStoredFilters = JSON.parse(storedFilters);
        const plainSparkFilters = JSON.parse(JSON.stringify(sparkFilters));

        if (!isEqual(parsedStoredFilters, plainSparkFilters) && p !== 0) {
            history.push({
                search: `${queryToString({
                    p: 0,
                    ...rest,
                })}`,
            });
            localStorage.setItem(STORAGE_KEY, JSON.stringify(plainSparkFilters));
        }
    }, [sparkFilters]);

    const {
        refetchSparks,
        sparksAreReady,
        sparks = [],
        meta,
        isLoading,
    } = useGetSparksQuery({
        account,
        sparkFilters: {
            status: sparkFilters.status === 'inbox/sent' ? 'inbox' : sparkFilters.status,
            offset: p * limit,
            limit: +limit || 10,
            types: sparkFilters.type.join(','),
            locationId: sparkFilters.location.map((l) => l.value).join(','),
            markets: sparkFilters.markets?.join(','),
            brandIds: sparkFilters.brandIds?.join(','),
            vendorIds: sparkFilters.vendorIds?.join(','),
            schedule: sparkFilters.schedule.join(','),
            training: sparkFilters.training.join(','),
            requestState: sparkFilters.requestState,
            orderBy,
            order,
            search: sparkFilters.search,
            startDateRange: sparkFilters.startDateRange,
            endDateRange: sparkFilters.endDateRange,
        },
        isAdminApp,
        enabled: accountIsReady,
    });

    const value: ISparksContext = useMemo(
        () => ({
            refetchSparks,
            sparksAreReady,
            sparks,
            meta,
            sparkFilters,
            updateSparkFilters,
            sparksIsLoading: isLoading,
            sparkDateRange,
        }),
        [
            sparksAreReady,
            refetchSparks,
            sparks,
            meta,
            updateSparkFilters,
            sparkFilters,
            isLoading,
            sparkDateRange,
        ],
    );

    return <SparksContext.Provider value={value}>{children}</SparksContext.Provider>;
};
