import { useCallback, useMemo, useState } from 'react';

import MoreDataModal, { TMoreDataTitles } from '@views/sparks/SparkDetailsView/MoreDataModal';

import { AccountType, DO_NOT_HAVE_PERMISSIONS_MESSAGE } from '@sparkplug/lib';

import { AddProductsToEventButton } from '@features/events/components/AddProductsToEventButton';
import { EventFormDrawer } from '@features/events/components/EventFormDrawer';
import { EventTableActionMenu } from '@features/events/components/EventTable/useEventsHeadCell/EventTableActionMenu';
import { adminCanEditOrDelete } from '@features/events/components/EventTable/useEventsHeadCell/utils';
import {
    getEventDateString,
    getEventDisplayStatus,
    getEventLocationsString,
    getEventStatusTagConfig,
} from '@features/events/helpers/eventUtils';
import { useEventNavigation } from '@features/events/hooks/useEventNavigation';

import { EntityDetailsCard } from '@components/EntityDetails/EntityDetailsCard';
import { EmptyGraphGraphic } from '@components/graphics';
import { Create as EditIcon } from '@components/icons';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import PageHeader from '@components/layout/PageHeader';
import PanelField from '@components/layout/PanelField';
import Skeleton from '@components/layout/Skeleton';
import { useParams } from '@components/router';
import Toolbar from '@components/toolbar/Toolbar';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { isEmpty } from '@helpers/util';

import { Card, CardContent, CardHeader } from '@app/componentLibrary/Card';
import Typography from '@app/componentLibrary/Typography';

import { useGetEventParticipantsQuery, useGetEventQuery } from '../../queries';

export const EventDetailsView = () => {
    const { eventId } = useParams<{ eventId: string }>();
    const nav = useEventNavigation();
    const { event, eventIsLoading } = useGetEventQuery(eventId, { enabled: !!eventId });
    const { eventParticipants, eventParticipantsAreLoading } =
        useGetEventParticipantsQuery(eventId);
    const participantCount = eventParticipants?.length || 0;
    const { account, userCan } = useSparkplugAccount();
    const hasEventPermissions = userCan('createSpark');
    const { userIsSuperAdmin, user, appIsMobile } = useApp();

    const displayStatus = useMemo(
        () =>
            event
                ? getEventDisplayStatus({
                      event,
                      accountType: account?.type as AccountType,
                  })
                : undefined,
        [event, account?.type],
    );

    const statusConfig = useMemo(
        () =>
            event && displayStatus
                ? getEventStatusTagConfig(displayStatus, event?.startTime || '')
                : null,
        [displayStatus, event?.startTime],
    );

    const eventDateString = useMemo(
        () =>
            event
                ? getEventDateString({
                      startTime: event?.startTime || '',
                      endTime: event?.endTime || '',
                  })
                : '',
        [event?.startTime, event?.endTime],
    );

    const locationsString = useMemo(
        () =>
            event
                ? getEventLocationsString({
                      eventLocations: event?.locations,
                      employeeLocations: account?.locations,
                      totalRetailerLocationCount: Number(event?.totalRetailerLocationCount),
                  })
                : '',
        [event?.locations, account?.locations, event?.totalRetailerLocationCount],
    );

    const [moreDataModalProps, setMoreDataModalProps] = useState<{
        isVisible: boolean;
        data: any;
        title?: TMoreDataTitles;
    }>({ isVisible: false, data: null });

    const closeMoreDataModal = useCallback(
        () => setMoreDataModalProps({ isVisible: false, data: null, title: null }),
        [],
    );

    const onLocationsClick = useCallback(() => {
        const locationLabels = event?.locations.map((l) => l.name);

        setMoreDataModalProps({
            isVisible: !isEmpty(locationLabels),
            data: locationLabels,
            title: 'Locations',
        });
    }, [event?.locations]);

    const onParticipantsClick = useCallback(() => {
        const participants = eventParticipants?.sort((a, b) =>
            a.fullName.localeCompare(b.fullName),
        );
        const participantLabels = participants?.map(({ fullName }) => <>{fullName}</>);

        setMoreDataModalProps({
            isVisible: !isEmpty(participantLabels),
            data: participantLabels,
            title: 'Employees',
        });
    }, [eventParticipants]);

    const titleLabel = useMemo(
        () => (account?.type === 'retailer' ? event?.brand?.name : event?.retailerAccount?.name),
        [account?.type, event?.brand?.name, event?.retailerAccount?.name],
    );

    const metadataGroups = useMemo(
        () => [
            {
                metadata: [
                    {
                        label: 'Start Date',
                        date: event?.startTime,
                    },
                    {
                        label: 'End Date',
                        date: event?.endTime,
                    },
                    {
                        label: 'Time',
                        date: eventDateString,
                    },
                    {
                        label: 'Created By',
                        value: event?.createdByUser?.name,
                    },
                ],
            },
            {
                metadata: [
                    {
                        label: 'Locations',
                        value: (
                            <PanelField
                                value={
                                    Number(event?.locations.length) > 0
                                        ? `${event?.locations.length} Locations`
                                        : '--'
                                }
                                onClick={event?.locations.length ? onLocationsClick : null}
                            />
                        ),
                    },
                    {
                        label: 'Participating Employees',
                        value: (
                            <PanelField
                                value={
                                    participantCount > 0 ? `${participantCount} Employees` : '--'
                                }
                                onClick={participantCount ? onParticipantsClick : null}
                            />
                        ),
                    },
                ],
            },
        ],
        [event, eventDateString, participantCount, onLocationsClick, onParticipantsClick],
    );

    if (eventIsLoading || !event || !statusConfig) {
        return <Skeleton height={500} />;
    }

    return (
        <div>
            {event && !eventIsLoading && (
                <>
                    <PageHeader
                        className="spark-details-header"
                        title={[
                            {
                                url: nav.state.backUrl,
                                title: 'Events',
                            },
                            event.name,
                        ]}
                        metaTitle={`${event.name} | Events`}
                    >
                        <Toolbar collapseOnMobile>
                            <Toolbar.Button
                                startIcon={<EditIcon />}
                                variant="filled"
                                disabled={!hasEventPermissions}
                                color="blue"
                                onClick={() => {
                                    nav.actions.goToEditEventDrawer(event._id);
                                }}
                                tooltip={
                                    !hasEventPermissions ? DO_NOT_HAVE_PERMISSIONS_MESSAGE : ''
                                }
                            >
                                Edit Event
                            </Toolbar.Button>
                        </Toolbar>
                    </PageHeader>

                    <EntityDetailsCard
                        className="[&_.panel-field]:!p-0 [&_.panel-field]:!mt-[2px] [&_.icon-btn]:!text-gray-300 [&_.icon-btn]:!mr-[-16px]"
                        title={event.type}
                        titleBadge={{
                            label: titleLabel ?? '',
                            color: 'blue',
                            tooltip: account?.type === 'retailer' ? event.vendorAccount?.name : '',
                        }}
                        name={event?.name || ''}
                        status={{
                            label: statusConfig.label,
                            color: statusConfig.color,
                        }}
                        imageUrl={event.brand?.photo ?? event.retailerAccount?.photo}
                        imageAlt={event?.name || ''}
                        metadataGroups={metadataGroups}
                        actions={
                            <EventTableActionMenu
                                event={event}
                                account={account}
                                user={user}
                                showApprove={false}
                                showDecline={false}
                                showEdit={
                                    userIsSuperAdmin || adminCanEditOrDelete({ account, event })
                                }
                                showClone={
                                    userIsSuperAdmin || adminCanEditOrDelete({ account, event })
                                }
                                showDelete={
                                    userIsSuperAdmin || adminCanEditOrDelete({ account, event })
                                }
                                showOpenInNewTab={false}
                                userIsPermitted={hasEventPermissions}
                            />
                        }
                        appIsMobile={appIsMobile}
                    />
                    <Card className="mt-4" hasBorder={false}>
                        <CardHeader>
                            <Typography variant="lg" className="font-bold mb-3">
                                Event Description
                            </Typography>
                        </CardHeader>
                        <CardContent>{event.description}</CardContent>
                    </Card>
                    <MoreDataModal {...moreDataModalProps} onClose={closeMoreDataModal} />
                    <Card className="mt-4 py-16" hasBorder={false}>
                        <EmptyStateDisplay
                            contentClassName="pb-4"
                            graphic={<EmptyGraphGraphic />}
                            label="Track Product Sales"
                            smallText={
                                <div className="pb-4">
                                    Select products at{' '}
                                    <span className="font-bold">
                                        {event.retailerAccount?.name} - {locationsString}
                                    </span>{' '}
                                    to view sales for this event time period.
                                </div>
                            }
                            actionButton={<AddProductsToEventButton />}
                        />
                    </Card>
                    <EventFormDrawer
                        isVisible={!!nav.state.editId || !!nav.state.clonedFromId}
                        onClose={() => nav.actions.resetNavParameters()}
                    />
                </>
            )}
        </div>
    );
};
