import { useState } from 'react';

import { DO_NOT_HAVE_PERMISSIONS_MESSAGE } from '@sparkplug/lib';

import SnapsUpgradeConfirmModal from '@features/spark-snaps/components/SnapsUpgradeConfirmModal';

import { Add, PadlockIcon } from '@components/icons';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import { EmptyStateDisplayProps } from '@components/layout/EmptyStateDisplay/EmptyStateDisplay';

import Intercom from '@helpers/Intercom';

import './SnapsEmptyState.scss';

const SnapsEmptyState = ({
    onClick,
    emptyStateProps,
    snapsEnabled = false,
    buttonLabel,
    disabled = false,
}: {
    onClick: () => void;
    emptyStateProps: EmptyStateDisplayProps;
    snapsEnabled: boolean;
    buttonLabel: string;
    disabled?: boolean;
}) => {
    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    return (
        <>
            <EmptyStateDisplay
                layout="horizontal"
                addPaperWrapper
                {...emptyStateProps}
                className="snaps-empty-state"
                actionButton={{
                    label: buttonLabel,
                    onClick: snapsEnabled ? () => onClick() : () => setOpenConfirmModal(true),
                    startIcon:
                        !snapsEnabled || disabled ? (
                            <PadlockIcon className="padlock-icon" />
                        ) : (
                            <Add />
                        ),
                    tooltip: disabled ? DO_NOT_HAVE_PERMISSIONS_MESSAGE : '',
                    disabled: disabled,
                }}
            />
            <SnapsUpgradeConfirmModal
                isVisible={openConfirmModal}
                onClose={() => setOpenConfirmModal(false)}
                onConfirm={() => Intercom.open()}
            />
        </>
    );
};

export default SnapsEmptyState;
