import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { SurveyCreator } from 'survey-creator-react';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { FormState } from '../context/types';
import { useCreateLearningResource } from '../mutations/useCreateLearningResource';
import { useUpdateLearningResource } from '../mutations/useUpdateLearningResource';

interface SaveOperationsProps {
    formState: FormState;
    validateForm: (isDraft?: boolean) => { isValid: boolean };
}

export const useSaveOperations = ({ formState, validateForm }: SaveOperationsProps) => {
    const { account } = useSparkplugAccount();
    const { learningResourceId } = useParams<{ learningResourceId: string }>();
    const { mutateAsync: createLearningResource } = useCreateLearningResource();
    const { mutateAsync: updateLearningResource } = useUpdateLearningResource(learningResourceId);

    const handleSaveCourse = useCallback(
        async (surveyCreator?: SurveyCreator, isDraft?: boolean, isUnpublished?: boolean) => {
            const { isValid } = validateForm(isDraft);
            if (!isValid) {
                toast.error('Please fill in all required fields');
                return false;
            }

            try {
                const surveyData = surveyCreator?.text ? JSON.parse(surveyCreator.text) : undefined;
                const themeData = surveyCreator?.theme
                    ? JSON.parse(JSON.stringify(surveyCreator.theme))
                    : undefined;

                const resourceData = {
                    accountId: account?._id,
                    reward: formState.reward * 100,
                    markets: formState.selectedMarkets,
                    brandId: formState.brandId,
                    name: formState.name,
                    description: formState.description,
                    internalNotes: formState.internalNotes,
                    billingMode: formState.billingMode,
                    ...(surveyData && { surveyData }),
                    ...(themeData && { themeData }),
                    budget: formState.hasBudget ? formState.budget * 100 : -1,
                    ...(isDraft !== undefined && { launchedAt: isDraft ? undefined : new Date() }),
                    ...(isUnpublished !== undefined && {
                        removedAt: !isDraft && isUnpublished ? new Date() : undefined,
                    }),
                };

                if (learningResourceId) {
                    await updateLearningResource(resourceData);
                }
                return true;
            } catch (error) {
                toast.error('Failed to save course');
                return false;
            }
        },
        [
            formState,
            validateForm,
            createLearningResource,
            updateLearningResource,
            learningResourceId,
        ],
    );

    return { handleSaveCourse };
};
