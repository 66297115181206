import { z } from 'zod';

import { AccountMarkets, AccountMarket } from '..';

export const FEES_FRACTION = 0.2;

export interface ILearningResource {
  learningResourceId: string;
  status: LearningResourceStatus;
  accountId: string;
  accountName?: string;
  accountType?: string;
  accountPhoto?: string | null;
  surveyId?: string | null;
  surveyParticipants?: number; // count of survey responses
  employeeDepositsCount?: number; // count of employee deposits
  totalPayout?: number; // total payout
  brandId?: string | null;
  brandName?: string;
  brandPhoto?: string | null;

  markets: AccountMarket[];
  description: string;
  name: string;
  reward: number;
  budget?: number;
  videoUrl?: string | null;
  imageUrl?: string | null;
  createdBy: string;
  createdByFirstName?: string;
  createdByLastName?: string;
  createdAt?: Date | null;
  updatedAt?: Date | null;
  launchedAt?: Date | null;
  removedAt?: Date | null;

  internalNotes?: string | null;
  surveyData?: Record<string, unknown> | null;
  themeData?: Record<string, unknown> | null;
  billingMode?: 'auto' | 'manual';

  surveyParticipantsCount?: number | null;
  currentExpenditure?: number | null;
}
export interface IEmployeeLearningResource extends ILearningResource {
  surveyResponse?: Record<string, unknown>;
}

export const updateLearningResourceSchema = z.object({
  markets: z.array(z.enum(AccountMarkets as [string, ...string[]])).optional(),
  description: z.string().optional(),
  name: z.string().optional(),
  reward: z.number().optional(),
  videoUrl: z.string().optional(),
  imageUrl: z.string().optional(),
  accountId: z.string().optional(),
  brandId: z.string().optional(),
  launchedAt: z
    .string()
    .transform((str) => (str ? new Date(str) : null))
    .optional(),
  removedAt: z
    .string()
    .transform((str) => (str ? new Date(str) : null))
    .optional(),
  surveyData: z.record(z.string(), z.unknown()).optional(),
  themeData: z.record(z.string(), z.unknown()).optional(),
  internalNotes: z.string().optional(),
  billingMode: z.enum(['auto', 'manual']).optional(),
  budget: z.number().optional(),
});

export const createLearningResourceSchema = z.object({
  accountId: z.string(),
  surveyId: z.string().optional(),
  brandId: z.string().optional(),
  markets: z.array(z.enum(AccountMarkets as [string, ...string[]])),
  description: z.string().optional(),
  name: z.string().optional(),
  reward: z.number(),
  videoUrl: z.string().optional(),
  imageUrl: z.string().optional(),
  launchedAt: z
    .string()
    .transform((str) => (str ? new Date(str) : null))
    .optional(),
  surveyData: z.record(z.string(), z.unknown()).optional(),
  themeData: z.record(z.string(), z.unknown()).optional(),
  internalNotes: z.string().optional(),
  billingMode: z.enum(['auto', 'manual']).optional(),
  budget: z.number().optional(),
});

export interface CourseInvoice {
  id: string;
  amount: number;
  status: string | 'unknown';
  created: number;
  paid: boolean;
  hostedInvoiceUrl?: string | null;
}

export interface GetCourseBillingSummaryResponse {
  invoices: CourseInvoice[];
  billedAmount: number;
  billedCount: number;
  unbilledAmount: number;
  unbilledCount: number;
  totalAmount: number;
  totalCount: number;
}

export type CreateLearningResourceRequestBody = z.infer<typeof createLearningResourceSchema>;
export type UpdateLearningResourceRequestBody = z.infer<typeof updateLearningResourceSchema>;
export type CreateLearningResourceResponseBody = ILearningResource;
export type GetLearningResourceResponseBody = ILearningResource;

export interface DeleteLearningResourcePathParams {
  learningResourceId: string;
}

export interface GetLearningResourcePathParams {
  learningResourceId: string;
}

export interface UpdateLearningResourcePathParams {
  learningResourceId: string;
}

export const LearningResourceStatuses = ['published', 'unpublished', 'draft'] as const;
export type LearningResourceStatus = typeof LearningResourceStatuses[number];

export const listLearningResourceRequestParamsSchema = z.object({
  brandId: z.union([z.string(), z.array(z.string())]).optional(),
  accountId: z.union([z.string(), z.array(z.string())]).optional(),
  status: z.enum(LearningResourceStatuses).optional(),
  limit: z.string().optional(),
  billingMode: z.enum(['auto', 'manual']).optional(),
  market: z.enum(AccountMarkets as [string, ...string[]]).optional(),
  offset: z.string().optional(),
  sort: z.string().optional(),
  order: z.enum(['asc', 'desc']).optional(),
  q: z.string().optional(),
});

export const listLearningResourceResponseRequestParamsSchema = z.object({
  limit: z.string().optional(),
  offset: z.string().optional(),
  sort: z.string().optional(),
  order: z.enum(['asc', 'desc']).optional(),
});

export type GetAllLearningResourceQueryParams = z.infer<
  typeof listLearningResourceRequestParamsSchema
>;
