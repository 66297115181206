import { useState } from 'react';
import { toast } from 'react-hot-toast';

import { AccountMarket } from '@sparkplug/lib';

import { FormState } from '../context/types';

export const useFormState = () => {
    const [formState, setFormState] = useState<FormState>(() => ({
        selectedMarkets: [],
        reward: 0,
        brandId: '',
        description: '',
        name: 'Draft Course',
        internalNotes: '',
        billingMode: 'auto' as const,
        budget: 0,
        hasBudget: false,
    }));

    const updateFormFromResource = (newState: Partial<FormState>) => {
        setFormState((prev) => ({ ...prev, ...newState }));
        return newState;
    };

    const setters = {
        setSelectedMarkets: (markets: AccountMarket[]) =>
            setFormState((prev) => ({ ...prev, selectedMarkets: markets })),
        setReward: (value: number | string) => {
            try {
                if (!value) return;
                const numericValue = typeof value === 'string' ? parseFloat(value) : value;
                if (Number.isNaN(numericValue)) return;

                if (numericValue < 0) {
                    throw new Error('Reward cannot be negative');
                }

                setFormState((prev) => ({ ...prev, reward: numericValue }));
            } catch (error) {
                toast.error(error instanceof Error ? error.message : 'Invalid reward value');
            }
        },
        setBrandId: (id: string) => setFormState((prev) => ({ ...prev, brandId: id })),
        setDescription: (value: string) =>
            setFormState((prev) => ({ ...prev, description: value })),
        setName: (value: string) => setFormState((prev) => ({ ...prev, name: value })),
        setInternalNotes: (value: string) =>
            setFormState((prev) => ({ ...prev, internalNotes: value })),
        setBillingMode: (value: 'auto' | 'manual') =>
            setFormState((prev) => ({ ...prev, billingMode: value })),
        setBudget: (value: number) => {
            try {
                if (value < 0) {
                    throw new Error('Budget cannot be negative');
                }
                setFormState((prev) => ({ ...prev, budget: value }));
            } catch (error) {
                toast.error(error instanceof Error ? error.message : 'Invalid budget value');
            }
        },
        setHasBudget: (value: boolean) => setFormState((prev) => ({ ...prev, hasBudget: value })),
    };

    return { formState, setFormState, updateFormFromResource, ...setters };
};
