import Button from '@components/buttons/Button/Button';
import { CustomArrowRightIcon } from '@components/icons';
import AppHeaderMobile from '@components/layout/AppHeaderMobile';

import Typography from '@app/componentLibrary/Typography';

interface SignFormProps {
    setReadyToSign: (ready: boolean) => void;
    handleNext: () => void;
}

export const SignForm = ({ setReadyToSign, handleNext }: SignFormProps) => {
    return (
        <div>
            <AppHeaderMobile
                hideAppFooter
                back={{
                    onClick: () => {
                        setReadyToSign(false);
                    },
                    component: 'Back',
                }}
            />
            <div className="bg-white max-w-[600px] mx-auto absolute top-[60px] left-0 right-0 bottom-0 overflow-y-auto p-4 pb-48">
                <Typography variant="2xl" className="mb-2 mt-6 font-bold">
                    Sign your W-9 Form
                </Typography>
                <Typography variant="base" className="mb-2 mt-6">
                    Under penalties of perjury, I certify that:
                </Typography>
                <ol className="list-decimal pl-6 space-y-4">
                    <li>
                        <Typography variant="base">
                            The number shown on this form is my correct taxpayer identification
                            number (or I am waiting for a number to be issued to me); and
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="base">
                            I am not subject to backup withholding because:
                            <ul className="list-[lower-alpha] pl-6 mt-2">
                                <li>I am exempt from backup withholding, or</li>
                                <li>
                                    I have not been notified by the Internal Revenue Service (IRS)
                                    that I am subject to backup withholding as a result of a failure
                                    to report all interest or dividends, or
                                </li>
                                <li>
                                    The IRS has notified me that I am no longer subject to backup
                                    withholding; and
                                </li>
                            </ul>
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="base">
                            I am a US citizen or other US person; and
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="base">
                            The FATCA code(s) entered on this form (if any) indicating that I am
                            exempt from FATCA reporting is correct.
                        </Typography>
                    </li>
                </ol>
                <Typography variant="base" className="mb-2 mt-6">
                    The Internal Revenue Service does not require your consent to any provision of
                    this document other than the certifications required to avoid backup
                    withholding.
                </Typography>
            </div>
            <div className="fixed bottom-0 left-0 right-0 p-6 bg-white border-t shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)]">
                <div className="max-w-[600px] mx-auto">
                    <Typography variant="base" className="mb-3 text-center">
                        By selecting “Confirm & Submit”, I understand that this constitutes a legal
                        signature provided electronically.
                    </Typography>
                    <Button
                        type="button"
                        className="w-full [&>span>svg>path]:fill-cerulean-0 h-12"
                        variant="filled"
                        color="blue"
                        onClick={handleNext}
                    >
                        Confirm & Submit
                    </Button>
                </div>
            </div>
        </div>
    );
};
