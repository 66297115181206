export const FORM_DEFAULTS = {
    INITIAL_NAME: 'Draft Course',
    INITIAL_REWARD: 0,
    INITIAL_BUDGET: 0,
    BILLING_MODE: {
        AUTO: 'auto',
        MANUAL: 'manual',
    } as const,
    DEBOUNCE_DELAY: 500,
    AUTO_SAVE_INTERVAL: 30000,
} as const;
