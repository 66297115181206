import { default as DownloadIcon } from './DownloadIcon.svg?react';
import { FC, useState } from 'react';
import { createPortal } from 'react-dom';

import AccountsAPI from '@api/AccountsAPI';
import moment from 'moment';

import { ExtendedSparksViewFilters } from '@features/spark-dashboard/hooks/useSparkFilters';
import { SparkDashboardTableRowData } from '@features/spark-dashboard/types';

import IconButton from '@components/buttons/IconButton';
import Tooltip from '@components/layout/Tooltip';
import toast from '@components/toast';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { THeadCell } from '@app/types/TableTypes';

import ExportSparksModal from '../ExportSparksModal';

import './ExportSparkDashboardButton.scss';

interface ExportSparkDashboardButtonProps {
    stickyLeftCells: THeadCell<SparkDashboardTableRowData>[];
    baseCells: THeadCell<SparkDashboardTableRowData>[];
    data: SparkDashboardTableRowData[];
    sparkFilters?: ExtendedSparksViewFilters;
}
export const ExportSparkDashboardButton: FC<ExportSparkDashboardButtonProps> = ({
    sparkFilters,
}) => {
    const { account } = useSparkplugAccount();
    const [showAllSparksReportModal, setShowAllSparksReportModal] = useState(false);

    const filters = {
        ...(account?.type === 'retailer' && { group_id: account?._id }),
        ...(account?.type === 'brand' && { originator_group_id: account?._id }),
        status: sparkFilters?.status === 'inbox/sent' ? 'inbox' : sparkFilters?.status,
        types: sparkFilters?.type.join(','),
        locationId: sparkFilters?.location.map((l) => l.value).join(','),
        markets: sparkFilters?.markets?.join(','),
        brandIds: sparkFilters?.brandIds?.join(','),
        vendorIds: sparkFilters?.vendorIds?.join(','),
        schedule: sparkFilters?.schedule.join(','),
        training: sparkFilters?.training.join(','),
        requestState: sparkFilters?.requestState,
        search: sparkFilters?.search,
        userTimezone: moment.tz.guess(),
        startDateRange: sparkFilters?.startDateRange
            ? moment(sparkFilters?.startDateRange).format('YYYY-MM-DD')
            : undefined,
        endDateRange: sparkFilters?.endDateRange
            ? moment(sparkFilters?.endDateRange).format('YYYY-MM-DD')
            : undefined,
    };

    return (
        <>
            <Tooltip title="Export to CSV">
                <span className="export-to-csv">
                    <IconButton
                        onClick={() => setShowAllSparksReportModal(true)}
                        variant="flat"
                        color="blue"
                    >
                        <DownloadIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <ExportSparksModal
                isVisible={showAllSparksReportModal}
                onConfirm={() => {
                    AccountsAPI.requestSparksReport(account?._id || '', filters)
                        .then(() => {
                            toast.success('Export started');
                        })
                        .catch((error) => {
                            console.log('error', error);
                            toast.error(`Export initialization failed`);
                        });
                }}
                onClose={() => setShowAllSparksReportModal(false)}
                initialStatus={sparkFilters?.status || 'active'}
            />
        </>
    );
};

export default (props: ExportSparkDashboardButtonProps) => {
    const sparksViewToolbarPlaceholder = document.querySelector('.export-to-csv-placeholder');

    if (!sparksViewToolbarPlaceholder) {
        return <></>;
    }

    return createPortal(<ExportSparkDashboardButton {...props} />, sparksViewToolbarPlaceholder);
};
