import { useCallback, useMemo } from 'react';

import { groupBy } from 'lodash';
import moment from 'moment';

import { UIEvent } from '@sparkplug/lib';

import { EventDetails } from '@features/events/components/EventDetails';
import { useEmployeeEvents } from '@features/events/queries/EmployeeEventQueries';

import { EmptyCalendarGraphic } from '@components/graphics';
import Skeleton from '@components/layout/Skeleton';

import { useApp } from '@hooks/AppHooks';
import { useEmployeeLocationsQuery } from '@hooks/QueryHooks/EmployeeDataQueries';

import Typography from '@app/componentLibrary/Typography';

const PreLaunchEvents = () => {
    return (
        <div className="flex flex-col items-center justify-center p-4">
            <div className="text-xl font-bold mb-2">Events are coming soon!</div>
            <div className="text-base text-gray-600">
                Get ready for exclusive events through SparkPlug.
            </div>
        </div>
    );
};

const Events = () => {
    const { history } = useApp();
    const { events, eventsAreReady } = useEmployeeEvents();
    const { employeeLocations, employeeLocationsAreLoading } = useEmployeeLocationsQuery();

    // Memoize time calculations since they're used in multiple places
    const timeRanges = useMemo(
        () => ({
            now: moment(),
            startOfToday: moment().startOf('day'),
            endOfToday: moment().endOf('day'),
        }),
        [],
    ); // Empty dependency array since we want these fixed for component lifetime

    // Memoize filtered events since they depend on both events and time calculations
    const { activeEvents, upcomingEvents } = useMemo(
        () => ({
            activeEvents: events.filter(
                (event) =>
                    (moment(event.startTime).isBefore(timeRanges.now) &&
                        moment(event.endTime).isAfter(timeRanges.now)) ||
                    moment(event.startTime).isBetween(
                        timeRanges.startOfToday,
                        timeRanges.endOfToday,
                        'day',
                        '[]',
                    ),
            ),
            upcomingEvents: events.filter((event) =>
                moment(event.startTime).isAfter(timeRanges.endOfToday),
            ),
        }),
        [events, timeRanges],
    );

    // Memoize grouped events since it depends on upcomingEvents
    const eventsByDate = useMemo(
        () => groupBy(upcomingEvents, (event) => moment(event.startTime).format('YYYY-MM-DD')),
        [upcomingEvents],
    );

    // Memoize the event handler to prevent unnecessary re-renders of child components
    const onEventSelected = useCallback(
        (event: Pick<UIEvent, '_id'>) => {
            history.push(`/user/events/${event._id}`, {
                prevUrl: document.location.pathname,
                prevTitle: `Events`,
            });
        },
        [history],
    );

    if (!eventsAreReady && employeeLocationsAreLoading) {
        return <Skeleton height={500} />;
    }

    return (
        <>
            {events.length > 0 && eventsAreReady ? (
                <>
                    {activeEvents.length > 0 && (
                        <div className="events-by-day flex flex-col gap-3 mb-4">
                            <div className="flex flex-row items-center gap-3">
                                <Typography variant="lg" className="text-gray-950 font-semibold">
                                    {moment(activeEvents[0].startTime).format('MMM D')}
                                </Typography>
                                <Typography variant="base" className="text-gray-900">
                                    Today
                                </Typography>
                            </div>
                            {activeEvents.map((event) => (
                                <EventDetails
                                    key={event._id}
                                    event={event}
                                    version="compact"
                                    onSelected={() => onEventSelected(event)}
                                    employeeLocations={employeeLocations}
                                    accountType="retailer"
                                    capturePassiveView
                                />
                            ))}
                        </div>
                    )}
                    {Object.keys(eventsByDate).length > 0 && (
                        <>
                            {Object.keys(eventsByDate)
                                .sort((a, b) => moment(a).diff(moment(b)))
                                .map((date) => (
                                    <div
                                        key={date}
                                        className="events-by-day flex flex-col gap-3 mb-4"
                                    >
                                        <div className="flex flex-row items-center gap-3">
                                            <Typography
                                                variant="lg"
                                                className="text-gray-950 font-semibold"
                                            >
                                                {moment(date).format('MMM D')}
                                            </Typography>
                                            <Typography variant="base" className="text-gray-900">
                                                {moment(date).format('dddd')}
                                            </Typography>
                                        </div>
                                        {eventsByDate[date].map((event) => (
                                            <EventDetails
                                                key={event._id}
                                                event={event}
                                                version="compact"
                                                onSelected={() => onEventSelected(event)}
                                                employeeLocations={employeeLocations}
                                                accountType="retailer"
                                                capturePassiveView
                                            />
                                        ))}
                                    </div>
                                ))}
                        </>
                    )}
                </>
            ) : (
                <div className="text-center text-gray-600 text-lg font-semibold mt-12">
                    <EmptyCalendarGraphic />
                    <span className="text-nowrap">No Upcoming Events</span>
                </div>
            )}
        </>
    );
};

const EventsTab = () => {
    const isPreLaunch = import.meta.env.REACT_APP_EVENTS !== 'true';

    return isPreLaunch ? <PreLaunchEvents /> : <Events />;
};

export default EventsTab;
