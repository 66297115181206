import { useEffect } from 'react';

import clsx from 'clsx';

import { AccountMarket, formatCurrency } from '@sparkplug/lib';

import { useGetAccountReachQuery } from '@features/account-links/queries/useGetAccountReach';
import { getAccountMarketOptions } from '@features/spark-brands/views/CCSparkBrandsView/CreateEditSparkBrandsModal/CreateEditSparkBrandsModal';

import Form from '@components/form/Form';
import CalloutMessage from '@components/layout/CalloutMessage';
import Spinner from '@components/layout/Spinner';

import useVendorBrands from '@hooks/BrandHooks/useVendorBrands';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import Typography from '@app/componentLibrary/Typography';

import { useLearningResource } from '../../context/LearningResourceContext';

const LearningResourcesForm = () => {
    const [
        {
            selectedMarkets,
            description,
            marketOptions,
            reward,
            brandId,
            name,
            errors,
            budget,
            hasBudget,
        },
        {
            setSelectedMarkets,
            setDescription,
            setReward,
            setBrandId,
            setName,
            setBudget,
            setHasBudget,
        },
    ] = useLearningResource();

    const MarketOptions = getAccountMarketOptions(marketOptions);
    const { account } = useSparkplugAccount();
    const { data: vendorBrands, isLoading: isLoadingVendorBrands } = useVendorBrands(account);
    const { data: reachData, isLoadingReach } = useGetAccountReachQuery({
        accountId: account?._id,
        markets: selectedMarkets,
    });

    useEffect(() => {
        if (vendorBrands?.length === 1 && !brandId && !isLoadingVendorBrands) {
            setBrandId(vendorBrands[0]._id);
        }
    }, [vendorBrands, brandId, isLoadingVendorBrands]);

    return (
        <div className="p-4 flex flex-col gap-4">
            <div>
                <Form.SearchSelect
                    name="brandId"
                    label="Brand "
                    required
                    value={brandId}
                    error={!!errors.brandId && brandId.length === 0}
                    options={
                        vendorBrands
                            ?.map((brand) => ({
                                value: brand._id,
                                label: brand.name,
                            }))
                            .sort((a, b) => a.label.localeCompare(b.label)) ?? []
                    }
                    onChange={(option) => setBrandId(option.value)}
                    className="mb-4 w-full"
                    disabled={isLoadingVendorBrands}
                />
            </div>
            <div>
                <Form.TextField
                    error={!!errors.name && name.length === 0}
                    name="name"
                    data-testid="name-input"
                    label="Name"
                    value={name}
                    required
                    maxLength={32}
                    helperText={`${name?.length} / 32`}
                    className="w-full"
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div>
                <Form.TextField
                    name="description"
                    error={!!errors.description && description.length === 0}
                    data-testid="description-input"
                    label="Description"
                    value={description}
                    required
                    className="w-full [&_.MuiInputBase-root]:!p-0"
                    maxLength={90}
                    helperText={`${description?.length} / 90`}
                    multiline
                    rows={3}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </div>
            <div>
                <Form.TreeSelect
                    label="Audience"
                    error={!!errors.markets && selectedMarkets.length === 0}
                    controlled
                    className={clsx([
                        'hide-toggles hide-search w-full',
                        selectedMarkets.length >= 1 && 'all-markets',
                    ])}
                    selected={selectedMarkets}
                    onChange={(selected) => {
                        if (selected.length > 0) {
                            if (selected.includes('all')) {
                                setSelectedMarkets(marketOptions);
                            } else {
                                setSelectedMarkets(selected as AccountMarket[]);
                            }
                        }
                    }}
                    required
                    options={MarketOptions}
                />
            </div>

            {selectedMarkets.length > 0 && (
                <CalloutMessage
                    color="light-blue"
                    overrideIcon={isLoadingReach ? <Spinner size={22} /> : undefined}
                    message={
                        isLoadingReach ? (
                            <div>Estimating costs...</div>
                        ) : (
                            <div>
                                This course will reach{' '}
                                <span className="font-bold">
                                    {new Intl.NumberFormat().format(
                                        reachData?.groupMembershipCount || 0,
                                    )}{' '}
                                    employees{' '}
                                </span>{' '}
                                at{' '}
                                {new Intl.NumberFormat().format(reachData?.accountLinkCount || 0)}{' '}
                                retailers.
                            </div>
                        )
                    }
                />
            )}

            <div>
                <Form.TextField
                    name="reward"
                    data-testid="reward-input"
                    required
                    error={!!errors.reward && reward < 1}
                    isMoneyField
                    moneyFieldIsActive
                    type="number"
                    label="Reward Amount"
                    min={1}
                    value={reward}
                    onChange={(e) => setReward(Number(e.target.value))}
                    className="mb-4 w-full"
                />
                {errors.reward && reward < 1 && (
                    <Typography variant="sm" className="text-sienna-500 italic mt-1">
                        {errors.reward}
                    </Typography>
                )}
                {!hasBudget && reward && (reachData?.groupMembershipCount || 0) >= 0 ? (
                    <Typography variant="sm" className="text-gray-80 italic mt-1">
                        Maximum estimated payout:{' '}
                        <span className="font-bold">
                            {formatCurrency(
                                reward * (reachData?.groupMembershipCount || 0) * 1.2,
                                true,
                            )}
                        </span>{' '}
                        (including platform fees)
                    </Typography>
                ) : (
                    <></>
                )}
            </div>
            <div>
                <Form.Checkbox
                    label="Set a course budget"
                    value={hasBudget}
                    onChange={(e: { target: { checked: boolean } }) => {
                        setHasBudget(e.target.checked);
                    }}
                />
            </div>
            {hasBudget && (
                <div>
                    <Form.TextField
                        name="budget"
                        data-testid="budget-input"
                        required={hasBudget}
                        error={!!errors.budget && budget === 0}
                        isMoneyField
                        moneyFieldIsActive
                        moneyFieldErrorMessage="Budget must be greater than 0 and reward amount"
                        type="number"
                        label="Course Budget"
                        value={budget}
                        onChange={(e) => {
                            const newValue = Number(e.target.value);
                            if (!Number.isNaN(newValue)) {
                                setBudget(Number(e.target.value));
                            }
                        }}
                        className="mb-4 w-full"
                    />
                    {errors.budget && (
                        <span className="text-sm text-sienna-500 italic block mt-1">
                            {errors.budget}
                        </span>
                    )}
                    <span className="text-sm text-gray-80 italic block mt-1">
                        This course will be automatically unpublished once your total budget is
                        reached. You can always increase your budget and republish.
                    </span>
                </div>
            )}
        </div>
    );
};

export default LearningResourcesForm;
