import { FC, ReactElement, ReactNode, cloneElement } from 'react';
import { NavLink } from 'react-router-dom';

import clsx from 'clsx';

import Typography from '@app/componentLibrary/Typography';

import { cn } from '../utils';

export interface NavigationItem {
    name?: string;
    href?: string;
    current?: boolean;
    icon?: ReactElement;
    count?: number;
    badgeCount?: number;
    type?: 'hr' | 'header';
    isHidden?: boolean;
    onClick?: () => void;
}

const CountStyleWrapper: FC<{ className?: string; children: ReactNode }> = ({
    className,
    children,
}) => (
    <span className={cn('ml-auto w-5 h-5 flex justify-center items-center', className)}>
        {children}
    </span>
);

export default function Menu({
    navigation = [],
    menuDirection = 'vertical',
}: {
    navigation: NavigationItem[];
    menuDirection?: 'horizontal' | 'vertical';
}) {
    function renderHeader(item: NavigationItem) {
        return (
            <div className="pt-3 pb-2 text-gray-900 text-xs font-semibold uppercase leading-none tracking-wide">
                {item.name}
            </div>
        );
    }

    function renderDivider() {
        return <div role="separator" className="border-t border-gray-200 border-solid my-2" />;
    }

    function renderLink(item: NavigationItem) {
        const children = (
            <>
                {item.icon && (
                    <div className="flex items-center">
                        {cloneElement(item.icon, {
                            className: cn('w-5 h-5 text-cerulean-600', item.icon.props.className),
                        })}
                    </div>
                )}
                <Typography
                    variant="base"
                    className={clsx(
                        item.current ? 'text-gray-950' : 'text-gray-800',
                        'font-medium hover:text-gray-950',
                    )}
                >
                    {item.name}
                </Typography>
                {!!item.count && (
                    <CountStyleWrapper>
                        <Typography variant="sm" className="text-gray-800">
                            {item.count}
                        </Typography>
                    </CountStyleWrapper>
                )}
                {!!item.badgeCount && (
                    <CountStyleWrapper className="bg-cerulean-500 rounded">
                        <Typography variant="sm" className="text-white">
                            {item.badgeCount}
                        </Typography>
                    </CountStyleWrapper>
                )}
            </>
        );

        const navClasses =
            'group flex gap-x-[8px] rounded-md px-3 py-[10px] items-center h-10 hover:bg-bluewood-0';

        if (item.onClick) {
            return (
                <button
                    onClick={item.onClick}
                    type="button"
                    className={clsx(
                        navClasses,
                        'cursor-pointer outline-none border-none w-full bg-transparent',
                        item.current ? '!bg-gray-50 cursor-default' : '',
                    )}
                >
                    {children}
                </button>
            );
        }
        return (
            <NavLink
                to={item.href || '#'}
                className={clsx(item.current ? 'bg-gray-50 cursor-default' : '', navClasses)}
            >
                {children}
            </NavLink>
        );
    }

    return (
        <nav className="flex flex-1 flex-col max-sm:flex-row max-sm:w-full">
            <ul
                className={cn(
                    'flex flex-1 flex-col max-sm:flex-row max-sm:overflow-x-auto',
                    menuDirection === 'horizontal' && '!flex-row',
                )}
            >
                {navigation
                    .filter((item) => !item.isHidden)
                    .map((item, index) => (
                        <li key={index} className="menu-item max-sm:text-nowrap">
                            {(() => {
                                switch (item.type) {
                                    case 'header':
                                        return renderHeader(item);
                                    case 'hr':
                                        return renderDivider();
                                    default:
                                        return renderLink(item);
                                }
                            })()}
                        </li>
                    ))}
            </ul>
        </nav>
    );
}
