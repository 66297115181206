import { useCreateLearningResource } from '@features/courses/mutations/useCreateLearningResource';

import Button from '@components/buttons/Button';
import { Add as AddIcon } from '@components/icons';
import toast from '@components/toast';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

export const CreateCourseButton = () => {
    const { history } = useApp();
    const { account } = useSparkplugAccount();
    const basePath = `/${account?._id}`;
    const { mutateAsync: createLearningResource, isLoading: isCreating } =
        useCreateLearningResource();
    const handleCreateCourse = async () => {
        try {
            const resourceData = {
                accountId: account?._id || '',
                markets: [],
                reward: 0,
                name: 'Draft Course',
                description: '',
                budget: 0,
            };
            const result = await createLearningResource(resourceData);
            const newResourceId = result?.learningResourceId; // Assuming the result contains the new resource ID
            if (newResourceId) {
                history.push(`${basePath}/courses/${newResourceId}/edit`);
            }
        } catch (error) {
            toast.error('Error creating learning resource');
        }
    };

    return (
        <Button
            variant="filled"
            color="blue"
            startIcon={<AddIcon />}
            onClick={handleCreateCourse}
            disabled={isCreating}
        >
            Create Course
        </Button>
    );
};
