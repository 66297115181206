import { useState } from 'react';

import { DO_NOT_HAVE_PERMISSIONS_MESSAGE } from '@sparkplug/lib';

import { useEventNavigation } from '@features/events/hooks/useEventNavigation';

import Button from '@components/buttons/Button';
import { Add as AddIcon, Lock as LockIcon } from '@components/icons';
import UpgradeModalV2 from '@components/overlays/UpgradeModal/UpgradeModalV2';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

export const AddProductsToEventButton = () => {
    const { account, userCan } = useSparkplugAccount();
    const { userIsSuperAdmin } = useApp();
    const nav = useEventNavigation();
    const accountCanCreateEvent =
        account?.metaData?.subscriptionType === 'paid' || userIsSuperAdmin;
    const [upgradeModalIsVisible, setUpgradeModalIsVisible] = useState(false);

    const userIsPermitted = userCan('createSpark');

    const onClick = () => {
        setUpgradeModalIsVisible(true);
    };

    const onCloseUpgradeModal = () => setUpgradeModalIsVisible(false);

    const userPermissionTooltip = !userIsPermitted ? DO_NOT_HAVE_PERMISSIONS_MESSAGE : undefined;

    return (
        <>
            <Button
                variant="filled"
                tooltip={userPermissionTooltip}
                disabled={!userIsPermitted}
                color="blue"
                startIcon={
                    accountCanCreateEvent ? (
                        <AddIcon className="w-5 h-5" />
                    ) : (
                        <LockIcon className="w-5 h-5" />
                    )
                }
                onClick={onClick}
            >
                Select Products
            </Button>

            <UpgradeModalV2
                title="Track product sales related to an Event"
                content={
                    <div className="leading-5 flex flex-col">
                        See how your pop-up, promo, or training event impacts sales by tracking key
                        metrics like total units sold and revenue. Analyze trends, measure
                        effectiveness, and refine your strategy with real-time insights.
                        <span className="pt-3 text-gray-950 font-semibold">
                            {`Unlock deeper analytics and maximize your event's impact—upgrade your plan today.`}
                        </span>
                    </div>
                }
                image={
                    <img
                        src="/static/images/events-product-tracking.png"
                        alt="Update to use events"
                    />
                }
                isVisible={upgradeModalIsVisible}
                onClose={onCloseUpgradeModal}
                onConfirm={() => {
                    onCloseUpgradeModal();
                    window.open('https://sparkplugapp.typeform.com/eventsanalytics', '_blank');
                }}
            />
        </>
    );
};
