import { Model } from 'survey-core';

import { ISurveyResponse } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import Table from '@components/table/Table';

import { capitalize } from '@helpers/util';

import { TDefaultTableOptions, THeadCell } from '@app/types/TableTypes';

interface ProcessedResponse {
    answer: any;
    otherAnswer?: string;
    updatedAt: string;
    userName: string;
    retailerName: string;
    locationName: string;
    [key: string]: any; // for other spread properties
}

interface ProcessedQuestion {
    name: string;
    title: string;
    type: string;
    key: string;
    orderNumber: number;
    responses: ProcessedResponse[];
    [key: string]: any; // for other jsonObj properties
}

export const processQuestions = (
    survey: Model | null,
    surveyResponses: ISurveyResponse[] = [],
): ProcessedQuestion[] => {
    if (!survey) return [];
    const excludedTypes = ['image', 'html']; // Add more types as needed
    const allQuestions = survey
        .getAllQuestions()
        .filter((question: any) => !excludedTypes.includes(question.jsonObj.type))
        .map((question: any, correctIndex: number) => {
            const { jsonObj } = question;
            return {
                ...jsonObj,
                key: jsonObj.name,
                orderNumber: correctIndex + 1, // Starting from 1 is more user-friendly
                responses: surveyResponses?.map((response: any) => {
                    const answer = response.data?.[jsonObj.name];
                    const otherAnswer =
                        jsonObj.type === 'checkbox' && answer?.includes('other')
                            ? response.data?.[`${jsonObj.name}-Comment`]
                            : undefined;

                    return {
                        answer,
                        otherAnswer,
                        updatedAt: response.updatedAt,
                        userName: `${response.firstName || ''} ${response.lastName || ''}`.trim(),
                        retailerName: response.retailerName,
                        locationName: response.locationName,
                    };
                }),
            };
        });

    return allQuestions;
};

export const courseResponsesTableHeadCells: THeadCell<any>[] = [
    {
        id: 'question',
        label: 'Course Question',
        render: (row: any) => (
            <Table.Cell>
                {row.orderNumber}. {row.title}
            </Table.Cell>
        ),
    },

    {
        id: 'responses',
        label: 'Responses',
        render: (row: any) => (
            <Table.Cell>
                {row.responses?.filter((r: any) =>
                    row.type === 'boolean' ? r.answer !== undefined : r.answer,
                )?.length || 0}
            </Table.Cell>
        ),
    },
    {
        id: 'view-button',
        label: '',
        render: (row: any) => (
            <Table.Cell>
                <Button variant="flat" dense>
                    View
                </Button>
            </Table.Cell>
        ),
    },
];

export const tableDefaultOptions: TDefaultTableOptions = {
    rowsPerPage: -1,
    order: 'asc',
    orderBy: 'question',
};

export const questionTypeLabels: Record<string, string> = {
    radiogroup: 'Multiple Choice',
    radio: 'Single Choice',
    rating: 'Rating',
    text: 'Text Input',
    comment: 'Long Text Input',
    checkbox: 'Checkbox',
    boolean: 'Yes/No',
    matrix: 'Matrix',
    // Add any other question types you need
};

export const getQuestionTypeLabel = (type: string): string => {
    return capitalize(questionTypeLabels[type] || type);
};
