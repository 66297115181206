import { FC, ReactElement, RefObject } from 'react';

import clsx from 'clsx';

import Button from '@components/buttons/Button';
import { ChevronLeft as BackIcon } from '@components/icons';

import { useApp, useAppBodyClasses } from '@hooks/AppHooks';

import { cn } from '@app/componentLibrary/utils';

import './AppHeaderMobile.scss';

interface AppHeaderMobileProps {
    innerRef?: RefObject<HTMLDivElement>;
    back?: {
        to?: string;
        component?: string;
        onClick?: () => void;
    };
    title?: string;
    subNav?: ReactElement;
    hideAppFooter?: boolean;
}

const AppHeaderMobile: FC<AppHeaderMobileProps> = ({
    innerRef,
    back,
    title,
    subNav,
    hideAppFooter = false,
}) => {
    const { history } = useApp();

    const classNamesAppended = clsx('app-header', 'app-header-mobile', { 'has-subnav': subNav });

    useAppBodyClasses(
        [
            'has-app-header-mobile',
            subNav ? 'app-header-mobile-has-subnav' : '',
            hideAppFooter ? 'hide-app-footer' : '',
        ],
        [],
    );

    return (
        <div ref={innerRef} className={cn(classNamesAppended, 'z-20')}>
            <div className="app-header-mobile_upper">
                {back && (
                    <Button
                        className="app-header-mobile_back"
                        variant="flat"
                        color="neutral"
                        startIcon={<BackIcon />}
                        onClick={() => {
                            if (back.onClick) {
                                back.onClick();
                            } else {
                                history.push(back?.to || document.referrer);
                            }
                        }}
                    >
                        {back?.component}
                    </Button>
                )}
                {title && <h1 className="text-2xl font-bold text-gray-950">{title}</h1>}
            </div>
            {subNav && <div className="app-header-mobile_subnav">{subNav}</div>}
        </div>
    );
};

export default AppHeaderMobile;
