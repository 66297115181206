import { DateTime } from 'luxon';

import { UIEvent } from '@sparkplug/lib';

import { RightArrow } from '@components/icons';
import { useHistory } from '@components/router';

import { cn } from '@app/componentLibrary/utils';
import { IAccount } from '@app/types/AccountsTypes';
import { ITableRow } from '@app/types/TableTypes';

import { EventDetails } from '../EventDetails';
import { EventTableRowData } from './types';

interface EventAdminMobileCardProps {
    event: EventTableRowData;
    onClick?: (event: UIEvent) => void;
    account: IAccount;
}

const EventAdminMobileCard = ({ event, onClick, account }: EventAdminMobileCardProps) => {
    const startDate = DateTime.fromISO(event.startTime);
    const endDate = DateTime.fromISO(event.endTime);
    const isAllDayEvent = endDate.diff(startDate, 'hours').hours >= 23;
    const eventTime = isAllDayEvent
        ? 'All day'
        : `${startDate.toFormat('h:mm a')} - ${endDate.toFormat('h:mm a')}`;

    return (
        <EventDetails
            event={event}
            isEmployee={false}
            accountType={account.type}
            onSelected={() => onClick?.(event)}
            isAdminCard
        />
    );
};

interface EventAdminMobileCardsProps {
    account: IAccount;
    eventRows: ITableRow<EventTableRowData>[];
    isLoading: boolean;
    onEventClick?: (event: UIEvent) => void;
    total?: number;
    page?: number;
    limit?: number;
}

export const EventAdminMobileCards = ({
    eventRows,
    isLoading,
    onEventClick,
    account,
    total = 1,
    page = 0,
    limit = 10,
}: EventAdminMobileCardsProps) => {
    const history = useHistory();

    const totalPages = Math.ceil(total / limit);

    if (isLoading) {
        return null;
    }

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col">
                {eventRows.map((event) => (
                    <EventAdminMobileCard
                        key={event._id}
                        event={event}
                        onClick={onEventClick}
                        account={account}
                    />
                ))}
            </div>

            {totalPages > 1 && (
                <div className="flex justify-between items-center mt-4 px-4">
                    <button
                        type="button"
                        onClick={() => {
                            history.push({
                                search: `p=${page - 1}`,
                            });
                        }}
                        disabled={page === 0}
                        className={cn(
                            'h-11 w-11 rounded-full bg-cerulean-500 hover:bg-cerulean-400 border-none cursor-pointer flex items-center justify-center',
                            page === 0 && 'opacity-50 cursor-not-allowed',
                        )}
                    >
                        <div className="flex items-center justify-center">
                            <RightArrow className="h-4 w-4 rotate-180 [&_>path]:stroke-white" />
                            <span className="sr-only">Previous page</span>
                        </div>
                    </button>

                    <span className="text-gray-700">
                        Page {page + 1} of {totalPages}
                    </span>

                    <button
                        type="button"
                        onClick={() => {
                            history.push({
                                search: `p=${page + 1}`,
                            });
                        }}
                        disabled={page >= totalPages - 1}
                        className={cn(
                            'h-11 w-11 rounded-full bg-cerulean-500 hover:bg-cerulean-400 border-none cursor-pointer flex items-center justify-center',
                            page >= totalPages - 1 && 'opacity-50 cursor-not-allowed',
                        )}
                    >
                        <div className="flex items-center justify-center">
                            <RightArrow className="h-4 w-4 [&_>path]:stroke-white" />
                            <span className="sr-only">Next page</span>
                        </div>
                    </button>
                </div>
            )}
        </div>
    );
};
