/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { SurveyCreator } from 'survey-creator-react';

import moment from 'moment-timezone';
import { Action, Question } from 'survey-core';
import { ElementGetActionsEvent, SurveyCreatorModel, UploadFileEvent } from 'survey-creator-core';

import { AdminCourseReview } from '@features/courses/components/AdminCourseReview';
import AdminCoursesForm from '@features/courses/components/CoursesForm/CoursesForm';
import { CourseStatus } from '@features/courses/types';
import { useGetSurvey } from '@features/learn-and-earn/queries/useGetSurvey';

import Button from '@components/buttons/Button';
import coursesGraphic from '@components/icons/CoursesGraphic.svg';
import FullscreenNav from '@components/layout/FullscreenNav';
import ConfirmModal from '@components/overlays/ConfirmModal';
import { useParams, useQueryParams } from '@components/router';
import toast from '@components/toast';

import { useApp, useAppBodyClasses } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { Badge } from '@app/componentLibrary/Badge';
import Typography from '@app/componentLibrary/Typography';

import CourseAssetsModal from '../../components/CourseAssetsModal';
import {
    LearningResourceProvider,
    useLearningResource,
} from '../../context/LearningResourceContext';
import { useUploadCourseAsset } from '../../mutations/useUploadCourseAsset';
import { useGetCourse } from '../../queries/useGetCourses';
import defaultSurveyData from './defaultSurveyData.json';
import { getCourseActionButtons, validateFile } from './utils';

import './styles.css';

export const creatorOptions = {
    showLogicTab: false,
    showJSONEditorTab: false,
    // mandatory for auto save
    isAutoSave: true,
    saveSurveyFunc: () => {},
    showSurveyTitle: false,
    showThemeTab: true,
    showDesignerTab: true,
    previewShowResults: false,
    showInvisibleElementsInPreviewTab: false,
    questionTypes: [
        'boolean',
        'checkbox',
        'comment',
        'dropdown',
        // tagbox is a multi-select dropdown
        'tagbox',
        'panel',
        'radiogroup',
        'rating',
        'ranking',
        'text',
        'image',
        'html',
    ],
};
type ConfirmationType = 'unsavedChanges' | 'unpublish';

const AdminCoursesCreate = () => {
    const [showGalleryModal, setShowGalleryModal] = useState(false);
    const { account } = useSparkplugAccount();
    const { learningResourceId } = useParams<{ learningResourceId: string }>();
    const { enableGallery, cloneFrom } = useQueryParams();
    const { learningResource: existingResource } = useGetCourse(learningResourceId);
    const { survey } = useGetSurvey(cloneFrom || existingResource?.surveyId || '');
    const { mutateAsync: uploadCourseAsset } = useUploadCourseAsset(account?._id || '');
    const [
        { name: courseName, hasUnsavedChanges, isReviewing, isSaving, lastSaved },
        {
            handleSaveAsDraft,
            handleSaveAsPublished,
            handleSaveAsUnpublished,
            handleReviewCourse,
            handleSaveCourse,
            handleAutoSave,
            handleModalClose,
        },
    ] = useLearningResource();
    const { history } = useApp();
    const [confirmationType, setConfirmationType] = useState<ConfirmationType | null>(null);
    const [currentTime, setCurrentTime] = useState(Date.now());

    const creatorRef = useRef<SurveyCreator | null>(null);

    if (!creatorRef.current) {
        creatorRef.current = new SurveyCreator({
            ...creatorOptions,
            previewOrientation: 'portrait',
        });
    }

    const creator = creatorRef.current;
    useAppBodyClasses(['sjs-creator-container']);

    useEffect(() => {
        if (existingResource && survey) {
            creator.text = JSON.stringify(survey.surveyData || {});
            if (survey.themeData) {
                creator.theme = survey.themeData;
            }
        }
        if (!existingResource) {
            creator.text = JSON.stringify(defaultSurveyData);
        }
    }, [existingResource, survey, creator]);

    const createGalleryAction = (question: Question) => {
        return new Action({
            id: 'gallery',
            title: 'Gallery',
            action: (context: any) => {
                setShowGalleryModal(true);
            },
            visibleIndex: 9,
        });
    };

    useEffect(() => {
        if (!creator) return () => {};
        creator.saveSurveyFunc = (
            saveNo: number,
            callback: (saveNo: number, success: boolean) => void,
        ) => {
            if (existingResource?.launchedAt || existingResource?.removedAt) {
                callback(saveNo, false);
                return;
            }
            callback(saveNo, true);
            handleAutoSave(creator);
        };
        const onShowingPropertyHandler = (_: any, options: any) => {
            const questionTypes = [
                'boolean',
                'checkbox',
                'comment',
                'dropdown',
                'tagbox',
                'panel',
                'radiogroup',
                'rating',
                'ranking',
                'text',
            ];
            const blackList = ['choicesByUrl', 'saveMmaskaskedValue', 'maskType', 'maskSettings'];

            if (questionTypes.includes(options.obj.getType())) {
                options.canShow = blackList.indexOf(options.property.name) === -1;
            }
        };

        const onSurveyInstanceCreatedHandler = (
            _: any,
            { area, obj, survey: surveyInstance }: any,
        ) => {
            if (area === 'property-grid') {
                // Note there is more show/hide logic in the css file ./styles.css. Especially to show/hide things in the survey level.

                const choicesFromWebService = surveyInstance?.getPanelByName('choicesByUrl');
                const validationPanel = surveyInstance?.getPanelByName('validation');
                const navigationPanel = surveyInstance?.getPanelByName('navigation');
                const layoutPanel = surveyInstance?.getPanelByName('layout');
                const dataPanel = surveyInstance?.getPanelByName('data');
                // conditions panel.
                const conditionsPanel = surveyInstance?.getPanelByName('logic');
                const panels = surveyInstance?.getAllPanels();
                if (choicesFromWebService) {
                    choicesFromWebService.visible = false;
                }

                if (dataPanel) {
                    dataPanel.title = 'Answers';
                }
                if (validationPanel) {
                    validationPanel.visible = false;
                }
                if (layoutPanel) {
                    layoutPanel.visible = false;
                }
                if (conditionsPanel) {
                    conditionsPanel.visible = false;
                }
            }
        };

        const onQuestionAddedHandler = (sender: any, options: any) => {
            options.question.isRequired = true;
        };
        const onDefineElementMenuItems = (
            _: SurveyCreatorModel,
            options: ElementGetActionsEvent,
        ) => {
            if (!enableGallery) return;

            const question = options.obj;

            const questionType = question.getType();
            if (questionType === 'image') {
                const galleryAdorner = createGalleryAction(question as Question);
                options.items.push(galleryAdorner);
            }
        };
        const onActiveTabChangedHandler = (sender: any, options: any) => {
            if (options.tabName === 'test') {
                const previewTab = sender.getPlugin('test');
                previewTab.setDevice('iPhoneSE');
            }
        };

        creator.onDefineElementMenuItems.add(onDefineElementMenuItems);
        creator.onShowingProperty.add(onShowingPropertyHandler);
        creator.onSurveyInstanceCreated.add(onSurveyInstanceCreatedHandler);
        creator.onQuestionAdded.add(onQuestionAddedHandler);
        creator.onActiveTabChanged.add(onActiveTabChangedHandler);
        creator.onNotify.add(() => {});

        return () => {
            creator.onShowingProperty.remove(onShowingPropertyHandler);
            creator.onSurveyInstanceCreated.remove(onSurveyInstanceCreatedHandler);
            creator.onQuestionAdded.remove(onQuestionAddedHandler);
            creator.onDefineElementMenuItems.remove(onDefineElementMenuItems);
            creator.onActiveTabChanged.remove(onActiveTabChangedHandler);
        };
    }, [creator, handleAutoSave]);

    useEffect(() => {
        if (!creator) return () => {};

        const onUploadFile = (_: any, options: UploadFileEvent) => {
            if (!account?._id) {
                toast.error('Please select an account before uploading a file');
                options.callback('error');

                return;
            }
            const requiredFileType = options.elementType === 'theme' ? 'image/jpeg' : undefined;
            const validation = validateFile(options.files[0], requiredFileType);
            if (!validation.valid) {
                toast.error(validation.error || 'Invalid file');
                options.callback('error', 'Invalid file');
                return;
            }

            uploadCourseAsset(options.files[0])
                .then((result) => {
                    options.callback('success', result.cacheUrl);
                })
                .catch((error) => {
                    options.callback('error');
                });
        };

        creator.toolbox.getItemByName('radiogroup').title = 'Single Choice';
        creator.toolbox.getItemByName('image').title = 'Image/Video';

        creator.onUploadFile.add(onUploadFile);
        return () => {
            creator.onUploadFile.remove(onUploadFile);
        };
    }, [account?._id, creator]);

    const unblockRef = useRef<(() => void) | null>(null);

    useEffect(() => {
        const handlePopState = () => {
            if (isSaving) return;
            if (hasUnsavedChanges || creator.state === 'modified') {
                setConfirmationType('unsavedChanges');
            }
        };

        // Set up single history block
        unblockRef.current = history.block(() => {
            if (isSaving) return true;
            if (hasUnsavedChanges || creator.state === 'modified') {
                setConfirmationType('unsavedChanges');
                return false;
            }
            return true;
        });

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [hasUnsavedChanges, creator.state, history, isSaving]);
    // Add a function to unblock history when needed
    const removeHistoryBlock = async () => {
        if (unblockRef.current) {
            await unblockRef.current();
            unblockRef.current = null;
        }
    };
    const handleExitClick = () => {
        if (hasUnsavedChanges || creator.state === 'modified') {
            setConfirmationType('unsavedChanges');
        } else {
            handleModalClose();
        }
    };
    const confirmModalConfig = {
        unsavedChanges: {
            title: 'Unsaved Changes',
            message: (
                <Typography variant="base">
                    <span className="font-bold">Are you sure you wish to exit this Course?</span>{' '}
                    Your changes will be not be saved.
                </Typography>
            ),
            confirmText: 'Yes, Cancel Changes',
            cancelText: 'No, Go Back',
            confirmBtnColor: 'red' as const,
            onConfirm: () => {
                if (unblockRef.current) {
                    unblockRef.current();
                }
                setConfirmationType(null);
                history.goBack();
            },
        },
        unpublish: {
            title: 'Unpublish Course',
            message: (
                <Typography variant="base">
                    <span className="font-bold">
                        Are you sure you wish to unpublish this Course?
                    </span>{' '}
                    It will no longer be accessible to your selected audience. You can always
                    republish in the future.
                </Typography>
            ),
            confirmText: 'Unpublish',
            cancelText: 'Back',
            confirmBtnColor: 'blue' as const,
            onConfirm: async () => {
                setConfirmationType(null);
                await handleSaveAsUnpublished(creator);
            },
        },
    };

    // Update handleSaveAsUnpublished in the context to use this confirmation
    const handleUnpublishClick = async () => {
        setConfirmationType('unpublish');
    };
    const resourceState: CourseStatus = existingResource?.status || 'draft';
    const activeConfig = confirmationType
        ? confirmModalConfig[confirmationType]
        : confirmModalConfig.unsavedChanges;

    useEffect(() => {
        const handleKeyPress = async (e: KeyboardEvent) => {
            if ((e.metaKey || e.ctrlKey) && e.key === 's') {
                e.preventDefault();
                if (isReviewing || (!hasUnsavedChanges && creator.state !== 'modified')) return;

                const result = await handleSaveCourse(creator);
                if (result && typeof result === 'object' && 'learningResourceId' in result) {
                    history.push(`/${account?._id}/courses/${result.learningResourceId}/edit`);
                }
            }
        };

        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [
        creator,
        resourceState,
        isReviewing,
        hasUnsavedChanges,
        handleSaveAsDraft,
        handleSaveAsPublished,
        history,
        account?._id,
    ]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(Date.now());
        }, 60000); // 60000 ms = 1 minute

        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <FullscreenNav
                className="*:!relative *:!m-0"
                fixedHeaderProps={{
                    title: (
                        <div className="flex items-center ">
                            <img src={coursesGraphic} alt="person at whiteboard" className="w-12" />

                            <div className="mr-1">Course: {courseName || ''}</div>

                            {existingResource?.status === 'unpublished' && (
                                <Badge label="Unpublished" color="neutral" />
                            )}
                            {existingResource?.status === 'published' && (
                                <Badge label="Published" color="green" />
                            )}
                            {existingResource?.status === 'draft' && (
                                <Badge label="Draft" color="yellow" />
                            )}
                        </div>
                    ),
                    leftElements: (
                        <div className="flex items-center gap-3">
                            <Button variant="outlined" color="white" onClick={handleExitClick}>
                                Exit
                            </Button>
                            {lastSaved && (
                                <div className="text-sm text-gray-400 font-semibold">
                                    saved {moment(lastSaved).fromNow(true)}
                                </div>
                            )}
                        </div>
                    ),
                    rightElements: (
                        <div className="flex items-center gap-2">
                            {getCourseActionButtons({
                                state: resourceState,
                                isReviewing,
                                handlers: {
                                    handleSaveAsDraft: async () => {
                                        await removeHistoryBlock();
                                        await handleSaveAsDraft(creator).then(() => {
                                            removeHistoryBlock();
                                        });
                                    },
                                    handleUnpublishClick: async () => {
                                        await removeHistoryBlock();
                                        await handleUnpublishClick().then(() => {
                                            removeHistoryBlock();
                                        });
                                    },
                                    handleSaveAsPublished: async () => {
                                        await removeHistoryBlock();
                                        await handleSaveAsPublished(creator).then(() => {
                                            removeHistoryBlock();
                                        });
                                    },
                                    handleReviewCourse,
                                },
                                creator,
                            }).map((button) => (
                                <Button
                                    key={button.label}
                                    variant={button.variant}
                                    color={button.color}
                                    onClick={() => button.onClick(creator)}
                                    tooltip={button.tooltip}
                                >
                                    {button.label}
                                </Button>
                            ))}
                        </div>
                    ),
                    className: 'relative m-0',
                }}
                disableAppNavbar
            >
                {isReviewing ? (
                    <AdminCourseReview creator={creator} />
                ) : (
                    <AdminCoursesForm creator={creator} />
                )}
            </FullscreenNav>

            <ConfirmModal
                isVisible={!!confirmationType}
                onClose={() => setConfirmationType(null)}
                onConfirm={activeConfig.onConfirm}
                title={activeConfig.title}
                confirmText={activeConfig.confirmText}
                confirmBtnColor={activeConfig.confirmBtnColor}
                confirmBtnVariant="smooth"
                cancelText={activeConfig.cancelText}
                message={activeConfig.message}
            />

            <CourseAssetsModal
                isVisible={showGalleryModal && !!enableGallery}
                onCloseHandler={() => setShowGalleryModal(false)}
            />
        </>
    );
};

export default () => {
    return (
        <LearningResourceProvider>
            <AdminCoursesCreate />
        </LearningResourceProvider>
    );
};
