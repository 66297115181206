export interface GetPresignedUrlPathParams {
  groupId: string;
  folder: string;
}
export interface GetPresignedAvatarUrlPathParams {
  userId: string;
  folder: string;
}
export interface GetPresignedUrlQueryParams {
  filename?: string;
}

export interface GetPresignedUrlResponseBody {
  fields: {
    [key: string]: string;
  };
  key: string;
  url: string;
  cacheUrl: string;
}

export enum UPLOAD_FOLDERS {
  ACCOUNT = 'account-photos',
  SPARK_BRANDS = 'spark-brands',
  USER = 'user-photos',
  COURSE_ASSETS = 'course-assets',
}
