import axios from 'axios';
import { isNil, omitBy } from 'lodash';

import {
    AccountVendorRetailerRequestPathParams,
    GetAccountVendorRetailerPosDataResponseBody,
    SparksViewFilters,
} from '@sparkplug/lib';

import { ListSparksQueryParams, SparkListQueryParams } from '../../../lib/spark/spark.types';

export default class AccountsAPI {
    static async getVendorRetailerPosData({
        groupId,
        retailerAccountId,
    }: AccountVendorRetailerRequestPathParams) {
        return (
            await axios.get<GetAccountVendorRetailerPosDataResponseBody>(
                `/api/v1/accounts/${groupId}/vendor-retailers/${retailerAccountId}/pos-data`,
            )
        ).data;
    }

    static async requestAllSparksReport(accountId: string) {
        return (
            await axios.post<void>(`/api/v1/accounts/all-sparks-report`, {
                accountId,
            })
        ).data;
    }

    static async requestSparksReport(accountId: string, filters?: ListSparksQueryParams) {
        const source = axios.CancelToken.source();
        return (
            await axios.post<void>(
                `/api/v1/accounts/sparks-report`,
                {
                    locationId: filters?.locationId?.split(',').filter(Boolean) ?? [],
                    markets: filters?.markets?.split(',').filter(Boolean) ?? [],
                    brandIds: filters?.brandIds?.split(',').filter(Boolean) ?? [],
                    vendorIds: filters?.vendorIds?.split(',').filter(Boolean) ?? [],
                },
                {
                    params: {
                        ...omitBy(
                            {
                                accountId,
                                ...filters,
                                locationId: undefined,
                                offset: filters?.offset ?? 0,
                                limit: filters?.limit !== undefined ? filters?.limit : 10,
                            },
                            isNil,
                        ),
                    },
                    cancelToken: source.token,
                },
            )
        ).data;
    }
}
