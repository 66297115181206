import { useEffect, useMemo } from 'react';

import moment from 'moment';

import { UIEvent } from '@sparkplug/lib';

import { getEventDateString, getEventLocationsString } from '@features/events/helpers/eventUtils';

import Chip from '@components/chips/Chip';
import { SandTimerIcon } from '@components/icons';
import Paper from '@components/layout/Paper';

import { Card, CardContent } from '@app/componentLibrary/Card';
import Typography from '@app/componentLibrary/Typography';
import { cn } from '@app/componentLibrary/utils';

import {
    useCaptureActiveEventView,
    useCapturePassiveEventView,
} from '../../mutations/EmployeeEventMutations';
import EventStatusChip from '../EventStatusChip';

const formatEventType = (eventType?: UIEvent['type']) => {
    if (eventType === 'popup') {
        return 'POP-UP';
    }
    return eventType?.toUpperCase() ?? '';
};

interface EventDetailsProps {
    className?: string;
    event: UIEvent;
    isFullScreen?: boolean;
    version?: 'full' | 'compact';
    onSelected?: () => void;
    accountType: 'retailer' | 'brand';
    employeeLocations?: { _id: string; name: string }[];
    captureActiveView?: boolean;
    capturePassiveView?: boolean;
    isEmployee?: boolean;
    isAdminCard?: boolean;
}

export const EventDetails = ({
    className,
    event,
    isFullScreen = false,
    version = 'full',
    accountType,
    onSelected,
    employeeLocations,
    captureActiveView = false,
    capturePassiveView = false,
    isEmployee = false,
    isAdminCard = false,
}: EventDetailsProps) => {
    const eventChip = <EventStatusChip dense event={event} accountType={accountType} />;

    const startDate = moment(event.startTime);
    const endDate = moment(event.endTime);

    const isMultiDayEvent = !startDate.isSame(endDate, 'day');
    const eventStartTime = moment(event.startTime).format('h:mm A');
    const eventEndTime = moment(event.endTime).format('h:mm A');

    const eventDate = getEventDateString({ startTime: event.startTime, endTime: event.endTime });

    const locations = getEventLocationsString({
        eventLocations: event.locations,
        employeeLocations,
        totalRetailerLocationCount: Number(event.totalRetailerLocationCount),
    });

    const { captureActiveViewAsync } = useCaptureActiveEventView();
    useEffect(() => {
        if (captureActiveView) {
            captureActiveViewAsync(event._id);
        }
    }, [captureActiveView]);

    const { capturePassiveViewAsync } = useCapturePassiveEventView();
    useEffect(() => {
        if (capturePassiveView) {
            capturePassiveViewAsync(event._id);
        }
    }, [capturePassiveView]);

    return !isAdminCard ? (
        <div
            className={cn(
                'event-details',
                isFullScreen &&
                    'bg-white shadow-[0_2px_4px_-0px_rgba(98,103,110,0.24),0_4px_4px_-2px_rgba(131,142,158,0.24)] -mx-4 -mt-4 pt-4 pb-6 px-5',
                className,
            )}
        >
            {version === 'full' ? (
                <>
                    {event.requestStatus === 'pending' && isFullScreen && (
                        <div className="-mx-[20px] -mt-4 mb-[16px] rounded-b-none p-[10px] bg-buttercup-50 flex flex-row gap-2 justify-center items-center">
                            <SandTimerIcon className="[&_path]:fill-buttercup-700" />
                            <Typography variant="base" className="text-buttercup-950 font-semibold">
                                Awaiting manager approval!
                            </Typography>
                        </div>
                    )}
                    <div className="event-details__header">
                        <div className="flex flex-row justify-between">
                            <img
                                className={cn(
                                    'h-[64px] w-[64px]',
                                    isEmployee ? 'rounded-full' : 'rounded',
                                )}
                                src={event.brand?.photo ?? event.retailerAccount?.photo}
                                alt={event.brand?.name ?? event.retailerAccount?.name}
                            />
                            <div className="flex flex-col gap-1 items-end">
                                <Chip
                                    label={event.brand?.name ?? event.retailerAccount?.name}
                                    color="blue"
                                    dense
                                />
                                {eventChip}
                            </div>
                        </div>
                        <div className="pt-3 flex flex-col gap-1">
                            <Typography variant="sm" className="text-gray-700 font-semibold">
                                {formatEventType(event.type)}
                            </Typography>
                            <Typography variant="xl" className="text-gray-950 font-bold">
                                {event.name}
                            </Typography>
                            <Typography variant="base" className="text-gray-900">
                                {eventDate}
                            </Typography>
                            {!isMultiDayEvent && (
                                <Typography variant="base" className="text-gray-900">
                                    {eventStartTime} - {eventEndTime}
                                </Typography>
                            )}

                            <Typography variant="base" className="text-gray-900 pb-2">
                                {event.retailerAccount?.name} - {locations}
                            </Typography>
                            <Typography variant="lg" className="text-gray-950 font-semibold py-2">
                                {isEmployee ? 'Event Description' : 'Event Details'}
                            </Typography>
                            <Typography variant="base" className="text-gray-900">
                                {event.description}
                            </Typography>
                        </div>
                    </div>
                </>
            ) : (
                <Paper onClick={onSelected} data-testid="paper-component">
                    {event.requestStatus === 'pending' && (
                        <div className="-mx-[16px] -mt-[16px] mb-[14px] rounded-b-none w-[calc(100%+32px)] p-[10px] bg-buttercup-50 flex flex-row gap-2 justify-center items-center">
                            <SandTimerIcon className="[&_path]:fill-buttercup-700" />
                            <Typography variant="base" className="text-buttercup-950 font-semibold">
                                Awaiting manager approval!
                            </Typography>
                        </div>
                    )}
                    <div className="event-details__compact grid grid-cols-[1fr_64px] items-center">
                        <div className="flex flex-col gap-1">
                            <div className="flex flex-row gap-2 items-center">
                                <Chip
                                    label={event.brand?.name ?? event.retailerAccount?.name}
                                    color="blue"
                                    dense
                                />
                                <Typography variant="sm" className="text-gray-700 font-semibold">
                                    {formatEventType(event.type)}
                                </Typography>
                            </div>
                            <Typography variant="lg" className="text-gray-950 font-semibold">
                                {event.name}
                            </Typography>
                            {!isMultiDayEvent ? (
                                <Typography variant="base" className="text-gray-900">
                                    {eventStartTime} - {eventEndTime}
                                </Typography>
                            ) : (
                                <Typography variant="base" className="text-gray-900">
                                    {eventDate}
                                </Typography>
                            )}
                            <Typography variant="base" className="text-gray-900">
                                {locations}
                            </Typography>
                        </div>
                        <img
                            className="h-[64px] w-[64px] rounded-full"
                            src={event.brand?.photo ?? event.retailerAccount?.photo}
                            alt={event.brand?.name ?? event.retailerAccount?.name}
                        />
                    </div>
                </Paper>
            )}
        </div>
    ) : (
        <Card onClick={onSelected} className="mb-3 p-3" hasBorder={false}>
            <CardContent>
                <div className="flex flex-row gap-2 mb-2">
                    <div className="flex flex-row gap-2 items-center flex-1">
                        <img
                            className={cn('h-[32px] w-[32px] rounded')}
                            src={
                                accountType === 'retailer'
                                    ? event.brand?.photo ?? event.retailerAccount?.photo
                                    : event.retailerAccount?.photo
                            }
                            alt={
                                accountType === 'retailer'
                                    ? event.brand?.name ?? event.retailerAccount?.name
                                    : event.retailerAccount?.name
                            }
                        />
                        <Typography variant="base" className="text-gray-950 font-bold">
                            {accountType === 'retailer'
                                ? event.brand?.name ?? event.retailerAccount?.name
                                : event.retailerAccount?.name}
                        </Typography>
                    </div>
                    {eventChip}
                </div>
                <Typography variant="xs" className="text-gray-700 font-semibold">
                    {formatEventType(event.type)}
                </Typography>
                <div className="flex flex-row gap-2">
                    <Typography variant="sm" className="text-gray-900">
                        {eventDate}
                    </Typography>
                    {!isMultiDayEvent && (
                        <Typography variant="sm" className="text-gray-900">
                            {eventStartTime} - {eventEndTime}
                        </Typography>
                    )}
                </div>
                <Typography variant="sm" className="text-gray-700 mb-2">
                    {locations}
                </Typography>
                <Typography variant="base" className="text-gray-950 font-semibold">
                    {event.name}
                </Typography>
                <Typography variant="sm" className="text-gray-900">
                    {event.description}
                </Typography>
            </CardContent>
        </Card>
    );
};
