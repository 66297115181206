import { useState } from 'react';

import { useGetEmployeeLearningResources } from '@features/learn-and-earn/queries/useGetEmployeeLearningResources';

import Button from '@components/buttons/Button';
import { NoCoursesGraphic } from '@components/graphics/EmptyStates';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';

import { useApp } from '@hooks/AppHooks';

import Typography from '@app/componentLibrary/Typography';

import SparkStatusIcon from '../../../../components/sparks/SparkStatusIcon/SparkStatusIcon';
import { CourseCard } from '../CourseCard';

const EmptyState = ({ variant }: { variant: 'new' | 'complete' }) => {
    return (
        <EmptyStateDisplay
            graphic={<NoCoursesGraphic />}
            label={variant === 'new' ? 'No New Courses' : 'No Completed Courses'}
            className="pt-10"
        />
    );
};

const LearnAndEarn = () => {
    const { history } = useApp();
    const { learningResources } = useGetEmployeeLearningResources();
    const [activeTab, setActiveTab] = useState('new');

    const incompleteResources = learningResources.filter(
        (resource) => resource.surveyResponse?.status !== 'completed',
    );
    const completeCourses = learningResources.filter(
        (resource) => resource.surveyResponse?.status === 'completed',
    );

    const handleCardClick = (resourceId: string) => {
        history.push(`/user/learn-and-earn/${resourceId}`);
    };

    const tabOptions = [
        { value: 'new', label: 'New!', startIcon: <SparkStatusIcon status="active" /> },
        { value: 'complete', label: 'Complete', startIcon: <SparkStatusIcon status="completed" /> },
    ] as const;
    return (
        <>
            <div className="flex gap-2 mb-4">
                {tabOptions.map((option) => (
                    <Button
                        key={option.value}
                        color="neutral"
                        startIcon={option.startIcon}
                        variant="smooth"
                        className={`h-10 w-full spark-status-button !hover:bg-bluewood-0 ${
                            activeTab === option.value ? '!bg-gray-50' : ''
                        }`}
                        onClick={() => setActiveTab(option.value)}
                    >
                        <Typography variant="base" className="font-medium">
                            {option.label}
                        </Typography>
                    </Button>
                ))}
            </div>

            {activeTab === 'new' &&
                (incompleteResources.length > 0 ? (
                    incompleteResources.map((resource) => (
                        <CourseCard
                            key={resource.learningResourceId}
                            resource={resource}
                            onCardClick={handleCardClick}
                        />
                    ))
                ) : (
                    <EmptyState variant="new" />
                ))}

            {activeTab === 'complete' &&
                (completeCourses.length > 0 ? (
                    completeCourses.map((resource) => (
                        <CourseCard
                            key={resource.learningResourceId}
                            resource={resource}
                            isCompleted
                            onCardClick={handleCardClick}
                        />
                    ))
                ) : (
                    <EmptyState variant="complete" />
                ))}
        </>
    );
};

const LearnAndEarnTab = () => {
    return <LearnAndEarn />;
};

export default LearnAndEarnTab;
