import { ReactNode } from 'react';

import moment from 'moment';

import Tooltip from '@components/layout/Tooltip';

import { Badge, BadgeColor } from '@app/componentLibrary/Badge';
import { Card, CardContent } from '@app/componentLibrary/Card';
import Typography from '@app/componentLibrary/Typography';

import { MetadataItem } from './MetaDataItem';

interface BaseEntityMetadata {
    label: string;
}

interface ValueMetadata extends BaseEntityMetadata {
    value: string | ReactNode | null | undefined;
    date?: never;
    className?: string;
}

interface DateMetadata extends BaseEntityMetadata {
    value?: never;
    date: Date | string | null | undefined;
}

type EntityMetadata = ValueMetadata | DateMetadata;

interface MetadataGroup {
    metadata: EntityMetadata[];
}

interface EntityDetailsCardProps {
    title: string;
    titleBadge?: {
        label: string;
        color: BadgeColor;
        tooltip?: string;
    };
    secondaryTitleBadge?: {
        label: string;
        color: BadgeColor;
        tooltip?: string;
    };
    name: string;
    description?: string;
    status?: {
        label: string;
        color: BadgeColor;
        tooltip?: string;
    };
    image?: ReactNode;
    imageUrl?: string;
    imageAlt?: string;
    metadataGroups: MetadataGroup[];
    actions?: ReactNode;
    className?: string;
    testId?: string;
    appIsMobile: boolean;
}

export const EntityDetailsCard = (props: EntityDetailsCardProps) => {
    const {
        title,
        titleBadge,
        name,
        description,
        status,
        image,
        imageUrl,
        imageAlt,
        metadataGroups,
        actions,
        className,
        testId,
        secondaryTitleBadge,
    } = props;

    const formatDate = (date: string | Date) => (date ? moment(date).format('MMM D, YYYY') : '--');

    return (
        <Card className={className} data-testid={testId} hasBorder={false}>
            <CardContent className="p-2">
                <div className="flex max-sm:flex-col w-full">
                    {/* Header Section */}
                    <div className="flex-1 sm:border-r sm:pr-7 border-solid border-gray-100 max-sm:border-b max-sm:pb-4">
                        {/* Image + Status Section */}
                        <div className="flex w-full items-start sm:items-center">
                            <div className="flex items-center">
                                {imageUrl && (
                                    <img
                                        src={imageUrl}
                                        alt={imageAlt || ''}
                                        className="w-16 h-16 rounded mr-3"
                                    />
                                )}
                                {image && image}
                            </div>

                            {/* Title and Description - Desktop */}
                            <div className="max-sm:hidden sm:block flex-1 mr-10">
                                <div className="flex items-center gap-2 mb-2">
                                    <Typography
                                        variant="xs"
                                        className="uppercase font-semibold text-gray-700 !mb-0"
                                    >
                                        {title}
                                    </Typography>
                                    {secondaryTitleBadge && (
                                        <span>
                                            <Badge
                                                label={secondaryTitleBadge.label}
                                                color={secondaryTitleBadge.color}
                                                size="sm"
                                            />
                                        </span>
                                    )}
                                    {titleBadge?.label && (
                                        <Tooltip
                                            title={titleBadge.tooltip}
                                            disabled={!titleBadge.tooltip}
                                            placement="right-end"
                                        >
                                            <span>
                                                <Badge
                                                    className="text-nowrap"
                                                    label={titleBadge.label}
                                                    color={titleBadge.color}
                                                    size="sm"
                                                />
                                            </span>
                                        </Tooltip>
                                    )}
                                </div>
                                <Typography
                                    variant={name.length > 20 ? 'xl' : '2xl'}
                                    className="font-bold break-words text-[24px] !mr-2"
                                >
                                    {name}
                                </Typography>
                                {description && (
                                    <Typography variant="base" className="text-gray-700">
                                        {description}
                                    </Typography>
                                )}
                            </div>

                            {/* Status Badge */}
                            {status && (
                                <div className="sm:flex sm:items-center">
                                    <Tooltip
                                        title={status.tooltip}
                                        disabled={!status.tooltip}
                                        placement="right-end"
                                    >
                                        <span>
                                            <Badge
                                                className="text-nowrap"
                                                label={status.label}
                                                color={status.color}
                                                size="lg"
                                            />
                                        </span>
                                    </Tooltip>
                                </div>
                            )}

                            {/* Mobile Actions */}
                            <div className="sm:hidden ml-auto">{actions}</div>
                        </div>

                        {/* Title and Description - Mobile */}
                        <div className="sm:hidden mt-4">
                            <div className="flex items-center gap-2 mb-2">
                                <Typography
                                    variant="xs"
                                    className="uppercase font-semibold text-gray-700 !mb-0"
                                >
                                    {title}
                                </Typography>
                                {secondaryTitleBadge && (
                                    <span>
                                        <Badge
                                            label={secondaryTitleBadge.label}
                                            color={secondaryTitleBadge.color}
                                            size="sm"
                                        />
                                    </span>
                                )}
                                {titleBadge?.label && (
                                    <Tooltip
                                        title={titleBadge.tooltip}
                                        disabled={!titleBadge.tooltip}
                                        placement="right-end"
                                    >
                                        <span>
                                            <Badge
                                                className="text-nowrap"
                                                label={titleBadge.label}
                                                color={titleBadge.color}
                                                size="sm"
                                            />
                                        </span>
                                    </Tooltip>
                                )}
                            </div>
                            <Typography
                                variant="xl"
                                className="font-bold break-words text-xl !mr-2"
                            >
                                {name}
                            </Typography>
                            {description && (
                                <Typography variant="base" className="text-gray-700">
                                    {description}
                                </Typography>
                            )}
                        </div>
                    </div>
                    <div className="sm:flex sm:items-center">
                        {metadataGroups.map((group, groupIndex) => (
                            <div
                                key={groupIndex}
                                className={`
                                    grid gap-x-8 gap-y-4
                                    max-sm:grid-cols-2 max-sm:mt-4
                                    sm:grid-flow-col sm:grid-cols-1 sm:grid-rows-2 sm:px-4
                                    ${
                                        groupIndex < metadataGroups.length - 1
                                            ? 'max-sm:border-b max-sm:pb-4 sm:border-r border-solid border-gray-100'
                                            : ''
                                    }
                                `}
                            >
                                {group.metadata.map((item, index) => (
                                    <MetadataItem
                                        key={index}
                                        label={item.label}
                                        value={item.date ? formatDate(item.date) : item.value}
                                    />
                                ))}
                            </div>
                        ))}
                        {/* Desktop Actions */}
                        <div className="max-sm:hidden sm:flex sm:items-center sm:justify-center">
                            {actions}
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};
