import React, { ReactNode } from 'react';

import Typography from '@app/componentLibrary/Typography';

interface MetadataItemProps {
    label: string;
    value: string | ReactNode;
    className?: string;
}

export const MetadataItem = ({ label, value, className }: MetadataItemProps) => {
    return (
        <div className={className}>
            <Typography variant="xs" className="label font-medium text-gray-400">
                {label}
            </Typography>
            {typeof value === 'string' ? (
                <Typography variant="sm" className="value text-gray-700 font-medium">
                    {value}
                </Typography>
            ) : (
                value
            )}
        </div>
    );
};
