import { useRef } from 'react';

import PointOfSaleAPI from '@api/PointOfSaleAPI';
import UploadsAPI from '@api/UploadsAPI';
import { UserFriendlyErrorMessages } from '@constants/ErrorConstants';
import axios from 'axios';
import { has, isUndefined } from 'lodash';

import {
    AccountRole,
    BatchSaveAccountUserRequestBody,
    BatchSaveAccountUserResponseBody,
    BatchSmsRequestBody,
    CreateUserRequestBody,
    CreateUserResponseBody,
    DeleteUserPathParams,
    IPublicUser,
    InitiatePasswordResetQueryParams,
    ResetPasswordRequestBody,
    SaveAccountUserRequestBody,
    SaveAccountUserRoleRequestBody,
    UPLOAD_FOLDERS,
    UpdatePasswordRequestBody,
    UpdateUserPathParams,
    UpdateUserRequestBody,
    UpdateUserResponseBody,
    UpdateW9RequestBody,
    UserRole,
} from '@sparkplug/lib';

import { getAllAccounts, removeMember } from '@core/accounts';

import toast from '@components/toast';

import { useAdvancedMutation } from '@hooks/QueryHooks';

import { isObjectDataNull } from '@helpers/ui';

import { IAccount } from '@app/types/AccountsTypes';
import { IAccountUser, IUserData } from '@app/types/UsersTypes';

import { getAccountUsersQueryKey, getSparkplugUsersQueryKey } from '../queries';
import { isNewPasswordValid } from '../utils';

const API = {
    createUser: async (body: CreateUserRequestBody) => {
        return (await axios.post<CreateUserResponseBody>('/api/v1/users', body)).data;
    },
    updateUser: async (
        { userId }: UpdateUserPathParams,
        body: UpdateUserRequestBody,
    ): Promise<UpdateUserResponseBody> => {
        return axios.put(`/api/v1/users/${userId}`, body);
    },
    deleteUser: async ({ userId, accountId = '' }: DeleteUserPathParams): Promise<void> => {
        return axios.delete(`/api/v1/users/${userId}/${accountId}`);
    },
    sendUsersSMS: async (body: BatchSmsRequestBody): Promise<void> => {
        return axios.post('/api/v1/users/multi/sms', body);
    },
    sendBulkEnrollment: async ({ groupId, userIds }: { groupId: string; userIds: string[] }) => {
        return (
            await axios.post<any>(`/api/v1/accounts/${groupId}/bulk-enroll`, {
                groupId,
                userIds,
            })
        ).data;
    },
    updatePassword: async (body: UpdatePasswordRequestBody): Promise<void> => {
        return axios.post('/api/v1/users/password/set', body);
    },
    createAccountUser: async (payload: SaveAccountUserRequestBody) => {
        return axios.post<void>(`/api/v1/sparkplug/users/`, payload);
    },
    updateAccountUser: async (userId: string, payload: SaveAccountUserRequestBody) => {
        return axios.put<void>(`/api/v1/sparkplug/users/${userId}`, payload);
    },
    updateAccountUserRole: async (payload: SaveAccountUserRoleRequestBody) => {
        return axios.post<void>(`/api/v1/sparkplug/users/group-role`, payload);
    },
    updateAccountUserMulti: async (payload: BatchSaveAccountUserRequestBody) => {
        return axios.post<BatchSaveAccountUserResponseBody>(
            `/api/v1/sparkplug/users/multi`,
            payload,
        );
    },
    updateUsersMulti: async (
        groupId: string,
        posEmployeeProfilesToEnroll: any[],
        usersToAdd: any[],
        usersToEdit: any[],
    ) => {
        return (
            await axios.post<any>(`/api/v1/sparkplug/users/multi`, {
                groupId,
                posEmployeeProfilesToEnroll,
                usersToAdd,
                usersToEdit,
            })
        ).data;
    },
    initiatePasswordReset: async (params: InitiatePasswordResetQueryParams) => {
        return axios.get<void>('/api/v1/users/password/reset', { params });
    },
    resetPassword: async (body: ResetPasswordRequestBody) => {
        return axios.post<void>('/api/v1/users/password/reset', body);
    },
    sendResetPasswordRequest: async (userId: string, email: boolean, phoneNumber: boolean) => {
        const url = `/api/v1/sparkplug/users/send-password-reset`;
        const query = [`user_id=${userId}`];

        if (email != null) {
            query.push(`email=${email}`);
        }

        if (phoneNumber != null) {
            query.push(`phone_number=${phoneNumber}`);
        }

        return (await axios.get<any>(`${url}?${query.join('&')}`)).data;
    },
    textInterfaceEnrollUser: async (groupId: string, userId: string) => {
        return (await axios.post<any>(`/api/v1/sparkplug/ti/enroll`, { userId, groupId })).data;
    },
    textInterfaceGetUserContext: async (userId: string) => {
        return (await axios.get<any>(`/api/v1/sparkplug/ti/context?user_id=${userId}`)).data;
    },
    textInterfaceGetUserContextsMulti: async (userIds: string[]) => {
        return (await axios.post<any>(`/api/v1/sparkplug/ti/context/multi/get`, { userIds })).data;
    },
    textInterfaceDeleteUserContext: async (contextId: string) => {
        return (await axios.delete<any>(`/api/v1/sparkplug/ti/context/${contextId}`)).data;
    },
    sendOneTimePasscode: async (payload: { email: string }) => {
        return axios.post<void>(`/api/v1/users/send-otp`, payload);
    },
    saveW9Form: async ({ userId }: UpdateUserPathParams, body: UpdateW9RequestBody) => {
        return axios.post<void>(`/api/v1/users/${userId}/w9`, body);
    },
    uploadUserAvatar: async ({ userId, file }: { userId: string; file: any }) => {
        const data = await UploadsAPI.getPresignedAvatarUploadUrl({
            userId,
            folder: UPLOAD_FOLDERS.USER,
        });

        return UploadsAPI.upload(data, file);
    },
};

const saveUserProfile = async (userParams: IUserData, userId?: string): Promise<IPublicUser> => {
    if (isObjectDataNull(userParams)) {
        throw new Error('No user properties provided');
    }

    const { password, pwdCheck, role, ...userProps } = userParams;

    (userProps as IUserData).role = role as UserRole;

    if (password && isNewPasswordValid(password, pwdCheck)) {
        (userProps as IUserData).password = password;
    }

    if (userId) {
        return API.updateUser({ userId }, userProps);
    }

    return API.createUser({
        email: userProps.email!,
        firstName: userProps.firstName,
        lastName: userProps.lastName,
        role: role as UserRole,
    });
};

/**
 * @deprecated
 *
 * We shooooould have different mutations for `isCurrentUser: true` and
 * `isCurrentUser: false` but for the sake of the code refactor, we will keep just one
 *
 * This mutation is used in the UI where a user profile is edited outside of
 * the context of an account, such as a user editing their own profile or creating
 * a new super-admin
 */
export const useSaveUserProfileMutation = ({
    isCurrentUser,
}: {
    /**
     * @deprecated
     */
    isCurrentUser?: boolean;
}) => {
    const { mutate, isLoading: isSavingUserProfile } = useAdvancedMutation(
        async (payload: { userParams: IUserData; userId?: string }) => {
            const { userParams, userId } = payload;
            return saveUserProfile(userParams, userId);
        },
        {
            updateQuery: { queryKey: getSparkplugUsersQueryKey() },
            toastOptions: {
                loading: 'Saving...',
                success: () => {
                    return isCurrentUser ? 'Profile saved' : `User saved`;
                },
                error: (err) => {
                    if (err?.response?.data?.details) {
                        return err.response.data.details;
                    } else if (err?.response?.status === 409) {
                        return 'Email or phone number already exists';
                    } else if (err.message) {
                        return err.message;
                    }

                    return 'Could not save user information';
                },
            },
        },
    );

    return { isSavingUserProfile, saveUserProfile: mutate };
};

export const saveAccountUser = (
    groupId: string,
    userData: Omit<SaveAccountUserRequestBody, 'groupId' | 'userId'>,
    userId?: string,
) => {
    return userId
        ? API.updateAccountUser(userId, {
              groupId,
              ...userData,
          })
        : API.createAccountUser({
              groupId,
              ...userData,
          });
};

export const useSaveAccountUserMutation = (account: IAccount) => {
    const { mutate, isLoading: isSavingUserProfile } = useAdvancedMutation(
        (payload: {
            userData: Omit<SaveAccountUserRequestBody, 'groupId' | 'userId'>;
            userId?: string;
        }) => {
            const { userData, userId } = payload;

            if (has(userData, 'phoneNumber') && isUndefined(userData.phoneNumber)) {
                userData.phoneNumber = null; // "undefined" will be stripped in the api, so removing a phoneNumber will fail unless we use "null" specifically
            }

            const toastId = toast.loading('Saving...', {
                // In case the request takes longer than 3 seconds, we don't want the toast to disappear
                duration: 10000,
            });

            const promise = saveAccountUser(account._id, userData, userId);

            promise
                .then(() => {
                    toast.success(`User saved`, {
                        id: toastId,
                        // Once the request is successful, we want the toast to disappear after 3 seconds instead of taking the full 10 seconds
                        duration: 3000,
                    });
                })
                .catch((err) => {
                    const isConflict = err?.response?.status === 409;
                    const isAdmin = userData.role === 'group-admin';
                    const isEmailUpdate = !!userData.email;

                    if (isConflict && isAdmin && isEmailUpdate) {
                        setTimeout(() => {
                            toast.dismiss(toastId);
                        }, 500);
                        return;
                    }

                    if (err.message) {
                        toast.error(
                            UserFriendlyErrorMessages[
                                err?.response?.status as keyof typeof UserFriendlyErrorMessages
                            ] || err.message,
                            {
                                id: toastId,
                            },
                        );
                        return;
                    }

                    toast.error('Could not save user information', {
                        id: toastId,
                    });
                });

            return promise;
        },
        {
            updateQuery: { queryKey: getAccountUsersQueryKey(account._id) },
        },
    );

    return { isSavingUserProfile, saveAccountUser: mutate };
};

export interface BulkAccountUserData {
    flexibleEmployeeId: string;
    userId: string;
    email: string;
    phoneNumber: string | null;
    posEmployeeProfileIds: string[];
    role: AccountRole;
    firstName: string;
    lastName: string;
}

export class SaveAccountUsersMultiError extends Error {
    data: { unsavedUserData: Partial<BulkAccountUserData>[] } = { unsavedUserData: [] };

    constructor(message = '', unsavedUserData: Partial<BulkAccountUserData>[]) {
        super(message);
        this.data = { unsavedUserData };
    }
}

/**
 * `saveAccountUsersMulti` handles all the logic for bulk updating users
 * Do NOT update this function unless a related API endpoint has changes.
 * All logic/conditionals within this function pass the expected data to the
 * API and the API will handle additional logic
 */
const saveAccountUsersMulti = async (
    groupId: string,
    bulkUserData: Partial<BulkAccountUserData>[],
): Promise<void> => {
    const users = bulkUserData.map(
        ({ userId, email, phoneNumber, posEmployeeProfileIds, role, firstName, lastName }) => ({
            userId,
            firstName,
            lastName,
            email,
            phoneNumber,
            posEmployeeProfileIds,
            role,
        }),
    );

    const { errors } = (await API.updateAccountUserMulti({ groupId, users })).data;

    if (errors.length) {
        const error = new SaveAccountUsersMultiError(
            `Could not save ${errors.length} ${errors.length > 1 ? 'users' : 'user'}`,
            errors.map(({ user }) => ({
                ...user,
                flexibleEmployeeId: user.userId ?? user.posEmployeeProfileIds?.[0],
            })),
        );

        return Promise.reject(error);
    }

    return Promise.resolve();
};

export const useSaveUsersMultiMutation = (account: IAccount) => {
    const bulkDataValuesRef = useRef<Partial<BulkAccountUserData>[]>([]);

    const { mutate, isLoading: isSavingUsersMulti } = useAdvancedMutation(
        (payload: { bulkUserData: Partial<BulkAccountUserData>[] }) => {
            const { bulkUserData } = payload;

            if (account._id) {
                return saveAccountUsersMulti(account._id, bulkUserData);
            }

            throw new Error(
                'Bulk edit of `users` directly is not supported. Bulk editing must be done within an account',
            );
        },
        {
            updateQuery: { queryKey: getAccountUsersQueryKey(account._id) },
            toastOptions: {
                loading: `Saving ${bulkDataValuesRef.current.length} users...`,
                success: () => {
                    return `Saved ${bulkDataValuesRef.current.length} users`;
                },
                error: (err) => {
                    return err.message ?? `Could not save users`;
                },
            },
        },
    );

    return { isSavingUsersMulti, saveUsersMulti: mutate };
};

export const useRemoveAccountMemberMutation = (account: IAccount) => {
    const { mutate, isLoading: isRemovingAccountMember } = useAdvancedMutation(
        async (payload: { userId: string }) => {
            const { userId } = payload;

            await removeMember(account._id, userId);

            const userGroups = await getAllAccounts(userId);

            if (!userGroups.length) {
                await API.updateUser(
                    { userId },
                    {
                        role: 'none',
                    },
                );
            }
        },
        {
            updateQuery: { queryKey: getAccountUsersQueryKey(account._id) },
        },
    );

    return { isRemovingAccountMember, removeAccountMember: mutate };
};

const purgeUser = async (userId: string) => {
    const userGroups = await getAllAccounts(userId);

    await Promise.all(
        userGroups.map((group) => {
            return removeMember(group?._id, userId);
        }),
    );

    await API.deleteUser({ userId });

    return {};
};

export const usePurgeUserMutation = () => {
    const { mutate, isLoading: isPurgingUser } = useAdvancedMutation(
        (payload: { userId: string }) => {
            const { userId } = payload;

            return purgeUser(userId);
        },
    );

    return { isPurgingUser, purgeUser: mutate };
};

const removeAndDeleteAccountMember = async (groupId: string, userId: string) => {
    if (groupId) {
        await removeMember(groupId, userId);
    }

    const userGroups = await getAllAccounts(userId);
    // If the above `getAllGroups` call is done on a secondary read instance of Mongo, it's possible the deletion of this user's groupmembership has not yet propagated to that instance.  In that case, we need to check that the user either has no groups, or that the only group they belong to is the one we just "deleted".  If either is true, we are safe to delete the user.
    const hasOtherGroupMemberships = userGroups?.filter(({ _id }) => _id !== groupId)?.length;

    if (!hasOtherGroupMemberships) {
        await API.deleteUser({ userId });
    }

    return {};
};

export const useRemoveAndDeleteAccountMemberMutation = (account: IAccount) => {
    const { mutate, isLoading: isRemovingAndDeletingAccountMember } = useAdvancedMutation(
        (payload: { userId: string }) => {
            const { userId } = payload;
            return API.deleteUser({ userId, accountId: account._id });
        },
        {
            updateQuery: { queryKey: getAccountUsersQueryKey(account._id) },
            toastOptions: {
                loading: 'Deleting member...',
                success: 'Member deleted',
                error: (err) =>
                    err.response?.status === 400 && err.response?.data?.details
                        ? err.response?.data?.details
                        : 'There was an error deleting this member - please try again',
            },
        },
    );

    return { isRemovingAndDeletingAccountMember, removeAndDeleteAccountMember: mutate };
};

/**
 * This is the simplest example to take the existing `UsersUI.tsx` functionality
 * and using the toast messages and wrapping it all in a `useAdvancedMutation`
 */
export const useSetActiveMutation = (accountId: string) => {
    const { mutate, isLoading: isSettingActive } = useAdvancedMutation(
        (payload: { userId: string; role: AccountRole }) => {
            const { userId, role } = payload;

            return saveAccountUser(
                accountId,
                {
                    role,
                },
                userId,
            );
        },
        {
            updateQuery: {
                queryKey: getAccountUsersQueryKey(accountId),
            },
            toastOptions: {
                loading: 'Reactivating member...',
                success: 'Member reactivated',
                error: (err) => err.message ?? 'Could not reactivate member',
            },
        },
    );
    return { isSettingActive, setActive: mutate };
};

export const useUnenrollAndSetInactiveMutation = (accountId: string) => {
    const { mutate, isLoading: isUnenrollingAndSettingInactive } = useAdvancedMutation(
        ({
            userId,
            userData,
        }: {
            userId: string | undefined;
            userData: {
                firstName: string;
                lastName: string;
                posEmployeeProfileIds: string[];
            };
        }) => {
            return saveAccountUser(
                accountId,
                {
                    ...userData,
                    role: 'none',
                    // We want to empty their permissions
                    permissions: {},
                },
                userId,
            );
        },
        {
            updateQuery: {
                queryKey: getAccountUsersQueryKey(accountId),
            },

            toastOptions: {
                loading: 'Setting member inactive...',
                success: 'Member set inactive',
                error: 'There was an error updating the member',
            },
        },
    );

    return { isUnenrollingAndSettingInactive, unenrollAndSetInactive: mutate };
};

export const useUnenrollAndSetInactiveMultiMutation = (accountId: string) => {
    const { mutate, isLoading: isUnenrollingAndSettingInactiveMulti } = useAdvancedMutation(
        ({
            users = [],
        }: {
            users: Pick<
                IAccountUser,
                'userId' | 'posEmployeeProfileIds' | 'firstName' | 'lastName'
            >[];
        }) => {
            const inactiveUsersPayload = users.map(
                ({ posEmployeeProfileIds, userId, firstName, lastName }) => ({
                    userId,
                    posEmployeeProfileIds,
                    role: 'none' as AccountRole,
                    firstName,
                    lastName,
                }),
            );

            return saveAccountUsersMulti(accountId, inactiveUsersPayload);
        },
        {
            updateQuery: {
                queryKey: getAccountUsersQueryKey(accountId),
            },
            toastOptions: {
                loading: 'Setting users inactive...',
                success: 'Users updated',
                error: 'There was an error updating the users',
            },
        },
    );

    return { isUnenrollingAndSettingInactiveMulti, unenrollAndSetInactiveMulti: mutate };
};

const deleteUsersMulti = async (groupId: string | null, userIds: string[]) => {
    const promises: any[] = [];
    const undeletedUserIds: string[] = [];

    if (groupId != null) {
        userIds.forEach((userId) => {
            promises.push({
                userId,
                promise: removeAndDeleteAccountMember(groupId, userId),
            });
        });
    } else {
        userIds.forEach((userId) => {
            promises.push({
                userId,
                promise: purgeUser(userId),
            });
        });
    }

    for (let i = 0; i < promises.length; i += 1) {
        const { userId, promise } = promises[i];

        try {
            await promise;
        } catch (err) {
            undeletedUserIds.push(userId);
        }
    }

    if (undeletedUserIds.length > 0) {
        const err: any = new Error(
            `Could not delete ${undeletedUserIds.length} ${
                undeletedUserIds.length > 1 ? 'users' : 'user'
            }`,
        );
        err.data = { undeletedUserIds };
        return err;
    }

    return [];
};

export const useDeleteUsersMultiMutation = () => {
    const { mutate, isLoading: isDeletingUsersMulti } = useAdvancedMutation(
        ({ groupId, userIds }: { groupId: string | null; userIds: string[] }) => {
            return deleteUsersMulti(groupId, userIds);
        },
    );

    return { isDeletingUsersMulti, deleteUsersMulti: mutate };
};

export const useUpdateUserPasswordUnauthMutation = () => {
    const { mutate, isLoading: isUpdatingUserPasswordUnauth } = useAdvancedMutation(
        ({
            userId,
            pwd,
            pwdCheck,
            code,
        }: {
            userId: string;
            pwd: string | undefined;
            pwdCheck: string | undefined;
            code: string;
        }) => {
            return new Promise((res, rej) => {
                if (isNewPasswordValid(pwd, pwdCheck)) {
                    (async () => {
                        try {
                            const updateUserResponse = await API.updatePassword({
                                userId,
                                code,
                                password: pwd as string,
                            });
                            return res(updateUserResponse);
                        } catch (err) {
                            if (axios.isAxiosError(err) && err?.response?.status === 403) {
                                if (err?.response?.data?.details === 'missing verification code') {
                                    return rej(new Error('Your verification code has expired.'));
                                }
                            }
                            return rej(err);
                        }
                    })();
                }
            });
        },
    );

    return { isUpdatingUserPasswordUnauth, updateUserPasswordUnauth: mutate };
};

export const useSendSMSMessageByUserIdsMutation = () => {
    const { mutate, isLoading: isSendingSMSMessageByUserIds } = useAdvancedMutation(
        (payload: { message: string; userIds: string[] }) => {
            const { message, userIds } = payload;
            return API.sendUsersSMS({ body: message, userIds });
        },
        {
            toastOptions: {
                loading: 'Sending...',
                success: 'Messages sent',
                error: 'Messages were not sent',
            },
        },
    );

    return { isSendingSMSMessageByUserIds, sendSMSMessageByUserIds: mutate };
};

export const useSendBulkEnrollSMSByUserIdsMutation = () => {
    const { mutate, isLoading: isSendingBulkEnrollmentSMSByUserIds } = useAdvancedMutation(
        (payload: { groupId: string; userIds: string[] }) => {
            const { groupId, userIds } = payload;
            return API.sendBulkEnrollment({ groupId, userIds });
        },
        {
            toastOptions: {
                loading: 'Sending...',
                success: 'Messages sent',
                error: 'Messages were not sent',
            },
        },
    );

    return { isSendingBulkEnrollmentSMSByUserIds, sendBulkEnrollmentSMSByUserIds: mutate };
};
export const useOneTimePasscode = () => {
    const { mutate, isLoading: isSendingOTP } = useAdvancedMutation((email: string) => {
        return API.sendOneTimePasscode({ email });
    });

    return { isSendingOTP, sendOneTimePasscode: mutate };
};
export const useResetPasswordRequestMutation = () => {
    const { mutate, isLoading: isResettingPasswordRequest } = useAdvancedMutation(
        (email: string) => {
            return API.initiatePasswordReset({ email });
        },
    );

    return { isResettingPasswordRequest, resetPasswordRequest: mutate };
};

export const useResetPasswordSetMutation = () => {
    const { mutate, isLoading: isResettingPasswordSet } = useAdvancedMutation(
        (payload: { email: string; code: string; pwd: string; pwdCheck: string }) => {
            const { email, code, pwd, pwdCheck } = payload;

            if (pwd === pwdCheck) {
                return API.resetPassword({ email, code, password: pwd });
            }
            throw Error('Passwords did not match');
        },
    );

    return { isResettingPasswordSet, resetPasswordSet: mutate };
};

export const useSaveAccountUserRoleMutation = () => {
    const { mutate, isLoading: isSavingAccountUserRole } = useAdvancedMutation(
        (payload: SaveAccountUserRoleRequestBody) => {
            return API.updateAccountUserRole(payload);
        },
        {
            toastOptions: {
                loading: 'Saving',
                success: 'User saved.',
                error: 'Something went wrong',
            },
        },
    );

    return { isSavingAccountUserRole, saveAccountUserRole: mutate };
};

export const useSendResetPasswordEmailRequest = () => {
    const { mutate, isLoading: isSendingResetPasswordEmailRequest } = useAdvancedMutation(
        (userId: string) => {
            return API.sendResetPasswordRequest(userId, true, false);
        },
        {
            toastOptions: {
                loading: 'Sending...',
                success: () => {
                    return `Password reset email sent`;
                },
                error: () => {
                    return `Could not send password reset email`;
                },
            },
        },
    );

    return {
        sendResetPasswordEmailRequest: mutate,
        isSendingResetPasswordEmailRequest,
    };
};

export const useSendResetPasswordTextRequest = () => {
    const { mutate, isLoading: isSendingResetPasswordTextRequest } = useAdvancedMutation(
        (userId: string) => {
            return API.sendResetPasswordRequest(userId, false, true);
        },
        {
            updateQuery: { queryKey: getSparkplugUsersQueryKey() },
            toastOptions: {
                loading: 'Sending...',
                success: () => {
                    return `Password reset text sent`;
                },
                error: () => {
                    return `Could not send password reset text`;
                },
            },
        },
    );

    return {
        sendResetPasswordTextRequest: mutate,
        isSendingResetPasswordTextRequest,
    };
};

type TSMSMessageGroup = {
    message: string;
    posEmployeeProfileIds: string[];
};

const sendBulksSMSMessagesByPosEmployeeProfileIds = async (messageGroups: TSMSMessageGroup[]) => {
    let unsentPosEmployeeProfileIds: string[] = [];
    const unsentMessageGroups: TSMSMessageGroup[] = [];

    const promises = messageGroups.map(({ message, posEmployeeProfileIds }) => {
        return PointOfSaleAPI.sendPosEmployeeProfilesSMS({
            body: message,
            posEmployeeProfileIds,
        }).catch(() => {
            unsentPosEmployeeProfileIds = unsentPosEmployeeProfileIds.concat(posEmployeeProfileIds);
            unsentMessageGroups.push({
                message,
                posEmployeeProfileIds,
            });
        });
    });

    await Promise.any(promises);

    if (unsentMessageGroups.length > 0) {
        const errorData = {
            data: {
                unsentMessageGroups,
                unsentPosEmployeeProfileIds,
            },
        };

        return Promise.reject(errorData);
    }

    return Promise.resolve();
};

export const useSendBulksSMSMessagesByPosEmployeeProfileIdsMutation = () => {
    const totalMessages = useRef<number>();

    const { mutate, isLoading: isSendingBulksSMSMessagesByPosEmployeeProfileIds } =
        useAdvancedMutation(
            (messageGroups: TSMSMessageGroup[]) => {
                totalMessages.current = messageGroups.flatMap(
                    ({ posEmployeeProfileIds }) => posEmployeeProfileIds,
                ).length;
                return sendBulksSMSMessagesByPosEmployeeProfileIds(messageGroups);
            },
            {
                toastOptions: {
                    loading: 'Sending...',
                    success: () => {
                        const total = totalMessages.current;

                        return total === 1 ? `${total} Message Sent` : `${total} Messages Sent`;
                    },
                    error: (err) => {
                        const unsentPosEmployeeProfileIds =
                            err?.data?.unsentPosEmployeeProfileIds || [];

                        return unsentPosEmployeeProfileIds.length === 1
                            ? `${unsentPosEmployeeProfileIds.length} message did not send.`
                            : `${unsentPosEmployeeProfileIds.length} messages did not send.`;
                    },
                },
            },
        );

    return {
        isSendingBulksSMSMessagesByPosEmployeeProfileIds,
        sendBulksSMSMessagesByPosEmployeeProfileIds: mutate,
    };
};

export const useEnrollUser = () => {
    const { mutate, isLoading: isEnrollingUser } = useAdvancedMutation(
        (payload: { accountId?: string; userId?: string }) => {
            const { accountId, userId } = payload;

            if (accountId && userId) {
                return API.textInterfaceEnrollUser(accountId, userId);
            }

            return Promise.resolve();
        },
        {
            updateQuery: { queryKey: getSparkplugUsersQueryKey() },
            toastOptions: {
                loading: 'Sending...',
                success: () => {
                    return 'Enrollment text sent';
                },
                error: () => {
                    return 'Could not send enrollment text';
                },
            },
        },
    );

    return { enrollUser: mutate, isEnrollingUser };
};

const unenrollUser = async (groupId: string, userId?: string) => {
    if (!userId) return null;
    const userData: SaveAccountUserRequestBody = {
        phoneNumber: null,
        groupId,
    };

    return saveAccountUser(groupId, userData, userId);
};

export const useUnenrollUser = (user: IAccountUser) => {
    const { mutate, isLoading: isUnenrollingUser } = useAdvancedMutation(
        async (payload: { accountId: string; userId: string }) => {
            const { accountId, userId } = payload;

            return unenrollUser(accountId, userId);
        },
        {
            updateQuery: { queryKey: getSparkplugUsersQueryKey() },
            toastOptions: {
                loading: 'Unenrolling...',
                success: () => {
                    return `Unenrolled ${user.firstName}`;
                },
                error: () => {
                    return `Could not unenroll ${user.firstName}`;
                },
            },
        },
    );

    return { unenrollUser: mutate, isUnenrollingUser };
};

/**
 * @deprecated all API-accessing functions should be wrapped in a `useAdvancedQuery` or `useAdvancedMutation`
 */
export const textInterfaceGetUserContext = (userId: string) => {
    return API.textInterfaceGetUserContext(userId);
};

export const useSaveW9Form = () => {
    const { mutate, isLoading: isSavingW9Form } = useAdvancedMutation(
        (payload: { userId: string; body: UpdateW9RequestBody }) => {
            return API.saveW9Form({ userId: payload.userId }, payload.body);
        },
    );
    return { saveW9Form: mutate, isSavingW9Form };
};

const updateUserWithAvatar = async (userId: string, file: any) => {
    if (!file) {
        throw new Error('Image is required');
    }

    const { key } = await API.uploadUserAvatar({
        userId,
        file,
    });
    await API.updateUser(
        {
            userId,
        },
        {
            avatarUrl: key,
        },
    );
};

export const useUploadUserAvatar = () => {
    const { mutate, isLoading: isUploadingUserAvatar } = useAdvancedMutation(
        (payload: { userId: string; file: any }) => {
            return updateUserWithAvatar(payload.userId, payload.file);
        },
        {
            toastOptions: {
                loading: 'Uploading...',
                success: 'Photo uploaded',
                error: 'Failed to upload photo. Please logout and try again. If the issue persists, contact support.',
            },
        },
    );

    return { uploadUserAvatar: mutate, isUploadingUserAvatar };
};
