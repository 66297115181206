import { useState } from 'react';

import moment from 'moment';

import { formatCurrency } from '@sparkplug/lib';

import { useGetCourseBillingSummary } from '@features/courses/queries/useGetCourseBillingSummary';

import Button from '@components/buttons/Button';
import { ChevronRight } from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import Drawer from '@components/overlays/Drawer';
import Table from '@components/table/Table';
import TableBody from '@components/table/TableRenderBody';
import TableHeader from '@components/table/TableRenderHead/';

import Typography from '@app/componentLibrary/Typography';
import { cn } from '@app/componentLibrary/utils';

const BillingSummaryDrawer = ({ learningResourceId }: { learningResourceId: string }) => {
    const [isVisible, setIsVisible] = useState(false);
    const {
        billingSummary,
        billingSummaryAreLoading,
        billingSummaryAreReady,
        billingSummaryHaveError,
    } = useGetCourseBillingSummary(learningResourceId);
    const commonTypographyClasses = 'text-gray-950 font-semibold';
    const billingRowClasses = 'flex justify-between';

    return (
        <>
            <Button endIcon={<ChevronRight />} onClick={() => setIsVisible(true)}>
                View Invoices
            </Button>

            <Drawer
                title="Payout Summary"
                variant="right"
                isVisible={isVisible}
                onCloseHandler={() => setIsVisible(false)}
            >
                <CalloutMessage
                    message="View current total payout and related invoices for this Course."
                    className="mb-6"
                />
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <Typography variant="base" className="text-gray-800 mb-2">
                            Billing Breakdown
                        </Typography>
                        <div className="flex flex-col gap-2 shadow-gray-4dp p-4 rounded mb-4">
                            <div
                                className={cn(
                                    billingRowClasses,
                                    'pb-4 border-b border-gray-200 border-solid mb-4',
                                )}
                            >
                                <Typography variant="lg" className={commonTypographyClasses}>
                                    Current Total Payout
                                </Typography>
                                <Typography variant="lg" className={commonTypographyClasses}>
                                    {formatCurrency((billingSummary?.totalAmount || 0) / 100)}
                                </Typography>
                            </div>
                            <div className={cn(billingRowClasses, 'mb-3')}>
                                <Typography variant="lg" className={commonTypographyClasses}>
                                    Billed-to-Date
                                </Typography>
                                <Typography variant="lg" className={commonTypographyClasses}>
                                    {formatCurrency((billingSummary?.billedAmount || 0) / 100)}
                                </Typography>
                            </div>
                            <div className={billingRowClasses}>
                                <Typography variant="lg" className={commonTypographyClasses}>
                                    Upcoming Billing
                                </Typography>
                                <Typography variant="lg" className={commonTypographyClasses}>
                                    {formatCurrency((billingSummary?.unbilledAmount || 0) / 100)}
                                </Typography>
                            </div>
                        </div>
                        <Typography variant="base" className="text-gray-800 mb-2">
                            Related Invoices
                        </Typography>
                        {billingSummaryAreLoading && <div>Loading invoices...</div>}
                        {billingSummaryHaveError && (
                            <CalloutMessage message="Failed to load invoices" />
                        )}
                        {billingSummaryAreReady && (
                            <Table
                                variant="raised"
                                rows={(billingSummary?.invoices || []).map((invoice) => ({
                                    ...invoice,
                                    key: invoice.id,
                                }))}
                                headCells={[
                                    {
                                        id: 'created',
                                        label: 'Invoice Date',
                                        render: (row: any) => (
                                            <Table.Cell>
                                                {moment.unix(row.created).format('MM/DD/YYYY')}
                                            </Table.Cell>
                                        ),
                                    },
                                    {
                                        id: 'amount',
                                        label: 'Total',
                                        render: (row: any) => (
                                            <Table.Cell>
                                                {formatCurrency((row.amount || 0) / 100)}
                                            </Table.Cell>
                                        ),
                                    },

                                    {
                                        id: 'hostedInvoiceUrl',
                                        label: '',
                                        render: (row: any) => (
                                            <Table.Cell>
                                                {row.hostedInvoiceUrl && (
                                                    <a
                                                        href={row.hostedInvoiceUrl}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        View
                                                    </a>
                                                )}
                                            </Table.Cell>
                                        ),
                                    },
                                ]}
                                showPagination={false}
                                useExternalProvider={false}
                            >
                                <TableHeader />
                                <TableBody />
                            </Table>
                        )}
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default BillingSummaryDrawer;
