import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { SurveyCreator } from 'survey-creator-react';

import { debounce } from 'lodash';

import { AccountMarket } from '@sparkplug/lib';

import { useApp } from '@hooks/AppHooks';
import { useQueryClient } from '@hooks/QueryHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { FORM_DEFAULTS } from '../constants';
import { useFormState } from '../hooks/useFormState';
import { useFormValidation } from '../hooks/useFormValidation';
import { useSaveOperations } from '../hooks/useSaveOperations';
import { useUnsavedChanges } from '../hooks/useUnsavedChanges';
import { useCreateLearningResource } from '../mutations/useCreateLearningResource';
import { useUpdateLearningResource } from '../mutations/useUpdateLearningResource';
import { useGetCourse } from '../queries/useGetCourses';
import { LearningResourceContextType } from './types';

const LearningResourceContext = createContext<LearningResourceContextType | undefined>(undefined);

export const LearningResourceProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const [marketOptions, setMarketOptions] = useState<AccountMarket[]>([]);
    const [isReviewing, setIsReviewing] = useState(false);
    const [lastSaved, setLastSaved] = useState<Date | null>(null);
    const queryClient = useQueryClient();

    const { account } = useSparkplugAccount();
    const { formState, updateFormFromResource, ...formSetters } = useFormState();
    const { validateForm, errors } = useFormValidation(formState);
    const { history } = useApp();
    const { learningResourceId } = useParams<{ learningResourceId: string }>();

    const { learningResource: existingResource, isLoadingLearningResource } =
        useGetCourse(learningResourceId);
    const { isLoading: isCreating } = useCreateLearningResource();
    const { isLoading: isUpdating } = useUpdateLearningResource(learningResourceId);

    const { handleSaveCourse } = useSaveOperations({
        formState,
        validateForm,
    });

    // Track initial values for comparison
    const [initialState, setInitialState] = useState(formState);
    const hasUnsavedChanges = useUnsavedChanges(initialState, formState);
    const handleModalClose = () => {
        queryClient.invalidateQueries({
            queryKey: ['learning-resource'],
        });
        queryClient.invalidateQueries({
            queryKey: ['survey'],
        });
        history.push(`/${account?._id}/courses`);
    };
    const handleAutoSave = useMemo(() => {
        // will save the course when called only if the course has not been launched
        const debouncedSave = debounce(async (surveyCreator?: SurveyCreator) => {
            if (!existingResource?.launchedAt) {
                await handleSaveCourse(surveyCreator, true).then(() => {
                    setLastSaved(new Date());
                    setInitialState(formState);
                });
            }
        }, FORM_DEFAULTS.DEBOUNCE_DELAY);

        return (surveyCreator?: SurveyCreator): Promise<void> => {
            return new Promise((resolve) => {
                debouncedSave(surveyCreator);
                resolve();
            });
        };
    }, [formState, existingResource?.launchedAt, handleSaveCourse]);

    useEffect(() => {
        // A mechanism to auto save the course every 30 seconds
        const intervalId = setInterval(() => {
            if (hasUnsavedChanges) {
                handleAutoSave();
            }
        }, FORM_DEFAULTS.AUTO_SAVE_INTERVAL);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [hasUnsavedChanges, handleAutoSave]);

    const handleReviewCourse = async (value: boolean) => {
        const { isValid } = validateForm();
        if (!isValid) {
            toast.error('Please fill in all required fields');
            return false;
        }
        setIsReviewing(value);
        return true;
    };

    const handleSaveAsDraft = async (surveyCreator: SurveyCreator) => {
        await handleSaveCourse(surveyCreator, true).then(() => {
            toast.success('Draft saved');
            handleModalClose();
        });
    };

    const handleSaveAsPublished = async (surveyCreator: SurveyCreator) => {
        await handleSaveCourse(surveyCreator, false).then(() => {
            toast.success('Course published');
            handleModalClose();
        });
    };

    const handleSaveAsUnpublished = async (surveyCreator: SurveyCreator) => {
        await handleSaveCourse(surveyCreator, false, true).then(() => {
            toast.success('Course unpublished');
            handleModalClose();
        });
    };

    useEffect(() => {
        setMarketOptions(account?.metaData?.markets || []);
    }, [account]);

    useEffect(() => {
        if (existingResource) {
            const newState = {
                selectedMarkets: existingResource.markets,
                reward: existingResource.reward ? existingResource.reward / 100 : 0,
                brandId: existingResource.brandId || '',
                description: existingResource.description || '',
                name: existingResource.name || '',
                internalNotes: existingResource.internalNotes || '',
                billingMode: existingResource.billingMode || 'auto',
                hasBudget: Boolean(existingResource.budget && existingResource.budget > 0),
                budget: existingResource.budget ? existingResource.budget / 100 : 0,
            };
            setInitialState(newState);
            updateFormFromResource(newState);
        }
    }, [existingResource, account, isLoadingLearningResource]);

    const state = useMemo(
        () => ({
            ...formState,
            marketOptions,
            errors,
            isSaving: isCreating || isUpdating,
            hasUnsavedChanges,
            isReviewing,
            lastSaved,
        }),
        [
            formState,
            marketOptions,
            errors,
            isCreating,
            isUpdating,
            hasUnsavedChanges,
            isReviewing,
            lastSaved,
        ],
    );

    const actions = useMemo(
        () => ({
            ...formSetters,
            handleSaveCourse,
            handleSaveAsDraft,
            handleSaveAsPublished,
            handleSaveAsUnpublished,
            handleReviewCourse,
            handleAutoSave,
            handleModalClose,
        }),
        [
            formSetters,
            handleSaveCourse,
            handleSaveAsDraft,
            handleSaveAsPublished,
            handleSaveAsUnpublished,
            handleAutoSave,
            handleModalClose,
        ],
    );

    const value: LearningResourceContextType = [state, actions];

    return (
        <LearningResourceContext.Provider value={value}>
            {children}
        </LearningResourceContext.Provider>
    );
};

export const useLearningResource = () => {
    const context = useContext(LearningResourceContext);
    if (context === undefined) {
        throw new Error('useLearningResource must be used within a LearningResourceProvider');
    }
    return context;
};
