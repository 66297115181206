import { FC } from 'react';

import CommissionCommissionsFormFields from '@features/spark-wizard/components/CommissionCommissionsFormFields';

import { SparkProvider } from '@contexts/SparkContext';

import Form from '@components/form/Form';
import PageLoading from '@components/layout/PageLoading';
import Modal from '@components/overlays/Modal';

import { useModal } from '@hooks/ModalHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';

interface MissingCommissionModalProps {
    sparkId: string;
    onClose: (shouldRefresh: boolean) => void;
    isVisible: boolean;
    isVendorSparkAndCanEdit?: boolean;
}

const MissingCommissionModal: FC<MissingCommissionModalProps> = ({ onClose }) => {
    const { stageValidationFn } = useModal();
    const { saveSpark, sparkIsReady } = useSpark();

    const handleSave = async () => {
        const valid = stageValidationFn();
        if (valid) {
            stageValidationFn(async (updatedSparkProperties) => {
                await saveSpark('edit', updatedSparkProperties);
                onClose(true);
            });
        }
    };

    return sparkIsReady ? (
        <>
            <Modal.Title onClose={() => onClose(false)}>Edit Commission Spark</Modal.Title>
            <Modal.Content>
                <CommissionCommissionsFormFields isVendorEditExperience />
            </Modal.Content>
            <Modal.Actions>
                <Form.Button color="blue" onClick={onClose}>
                    Cancel
                </Form.Button>
                <Form.Button color="blue" onClick={handleSave}>
                    Save
                </Form.Button>
            </Modal.Actions>
        </>
    ) : (
        <PageLoading label="Loading Spark..." />
    );
};

export default ({ sparkId, isVisible, onClose }: MissingCommissionModalProps) => {
    return (
        <Modal
            isVisible={isVisible}
            className="spark-loading-modal"
            onClose={onClose}
            maxWidth={2000}
        >
            <SparkProvider sparkId={sparkId}>
                <Form>
                    <MissingCommissionModal
                        isVisible={isVisible}
                        onClose={onClose}
                        sparkId={sparkId}
                    />
                </Form>
            </SparkProvider>
        </Modal>
    );
};
