import { ReactElement } from 'react';

import { ESparkStatusColors } from '@constants/SparkConstants';
import { Emoji } from 'emoji-mart';
import moment from 'moment-timezone';

import { DetailedSparkType, Spark } from '@sparkplug/lib';

import { EntityDetailsCard } from '@components/EntityDetails/EntityDetailsCard';
import { RecurringSparkIcon } from '@components/icons';
import PanelField from '@components/layout/PanelField';
import Tooltip from '@components/layout/Tooltip';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import {
    formatSparkInfo,
    getDetailedSparkTypeDisplayName,
    getMinThresholdLabel,
    getOtherGroupName,
    getSelectedLocationsFromSparkSubGroups,
    getSelectedParticipantsFromSparkSubGroups,
    getSparkTypeDisplayName,
} from '@helpers/sparks';
import { formatRecurringTooltip } from '@helpers/ui';

import { BadgeColor } from '@app/componentLibrary/Badge';
import { ISparkPosData, ISparkSubGroup } from '@app/types/SparksTypes';

interface SharedSparkDetailsPanelProps {
    spark: Spark;
    detailedSparkType?: DetailedSparkType;
    onLocationsClick?: () => void;
    onProductClick?: () => void;
    onParticipantsClick?: () => void;
    moreButton?: ReactElement;
}
interface SparkDetailsPanelProps extends SharedSparkDetailsPanelProps {
    otherGroupName?: string;
    vendorGroupName?: string;
    locationCount?: number;
    participantCount?: number;
    productCount?: number;
    sparkPosDataIsReady?: boolean;
    accountType?: 'retailer' | 'brand';
    appIsMobile?: boolean;
}

/**
 * This is a controlled version of the SparkDetailsPanel component where we can manually
 * pass in the location, participant, and product counts as well as the other group name
 * and the vendor name.
 */
export const SparkDetailsPanel = ({
    spark,
    detailedSparkType,
    otherGroupName,
    vendorGroupName,
    locationCount = 0,
    participantCount = 0,
    productCount = 0,
    onLocationsClick,
    onProductClick,
    onParticipantsClick,
    moreButton,
    sparkPosDataIsReady = false,
    accountType,
    appIsMobile = false,
}: SparkDetailsPanelProps) => {
    const {
        name,
        description,
        type: sparkType,
        emoji,
        startDate,
        endDate,
        recurringSchedule,
        sparkBrand,
        paymentMethod,
        reminderSentAt,
        createdAt,
    } = spark;

    const {
        formattedStartDate,
        formattedEndDate,
        totalDays: duration,
        status,
    } = formatSparkInfo(startDate, endDate, spark.requestState, recurringSchedule);

    const participantType = detailedSparkType === 'goalManager' ? 'Managers' : 'Employees';
    const minThresholdLabel = getMinThresholdLabel(spark);

    const isManagerGoal = detailedSparkType === 'goalManager';
    const isPaymentMethodSpark = paymentMethod;

    const statusChipTooltip =
        spark.requestState === 'pending'
            ? spark.reminderSentAt
                ? `Pending Spark reminder sent on ${moment(reminderSentAt).format('LL')}`
                : `Spark sent on ${moment(createdAt).format('LL')}`
            : '';

    const sparkStatus = getSparkTypeDisplayName(sparkType);
    const statusColor = ESparkStatusColors[status];
    const statusLabel =
        sparkStatus === 'Rejected' ? 'Declined' : status !== 'Complete' ? status : 'Complete';

    let productText = '--';
    if (sparkPosDataIsReady) {
        productText = productCount > 0 ? `${productCount} Products` : '0 Products';
    }

    const metaDataGroups = [
        {
            metadata: [
                {
                    label: 'Start Date',
                    value: formattedStartDate,
                },
                {
                    label: 'Duration',
                    value: `${duration} days`,
                },
                {
                    label: 'End Date',
                    value: formattedEndDate,
                },
                {
                    label:
                        minThresholdLabel && sparkType === 'commission' ? 'Minimum Payout' : 'Type',
                    value:
                        minThresholdLabel && sparkType === 'commission'
                            ? minThresholdLabel
                            : getDetailedSparkTypeDisplayName(
                                  detailedSparkType as DetailedSparkType,
                              ),
                },
            ],
        },
        {
            metadata: [
                {
                    label: 'Participating Locations',
                    value: (
                        <PanelField
                            value={locationCount > 0 ? `${locationCount} Locations` : '--'}
                            onClick={locationCount ? onLocationsClick : null}
                        />
                    ),
                    isCustomValue: true,
                },
                {
                    label: 'Qualifying Products',
                    value: (
                        <PanelField
                            value={productText}
                            onClick={productCount ? onProductClick : null}
                        />
                    ),
                },
                {
                    label: `Participating ${participantType}`,
                    value: (
                        <PanelField
                            value={
                                participantCount > 0
                                    ? `${participantCount} ${participantType}`
                                    : '--'
                            }
                            onClick={participantCount ? onParticipantsClick : null}
                        />
                    ),
                },
            ],
        },
    ];

    const sparkImage = spark.sparkBrand ? (
        <div className="relative mr-5">
            <img
                className="w-16 h-16 mr-3 rounded"
                src={sparkBrand?.photo}
                alt={sparkBrand?.name}
            />
            {!!recurringSchedule && (
                <Tooltip title={formatRecurringTooltip(recurringSchedule)}>
                    <span className="absolute bottom-0 right-0 translate-x-1/4 translate-y-1/4 rounded-full p-1 bg-white flex items-center justify-center shadow-sm">
                        <RecurringSparkIcon className="w-6 h-6" />
                    </span>
                </Tooltip>
            )}
        </div>
    ) : (
        <div className="emoji-wrapper relative mr-5">
            <Emoji emoji={emoji} set="apple" size={40} />
            {!!recurringSchedule && (
                <Tooltip title={formatRecurringTooltip(recurringSchedule)}>
                    <span
                        id="recurring-icon-container"
                        className="absolute bottom-0 right-0 translate-x-1/4 translate-y-1/4 rounded-full p-1 bg-white flex items-center justify-center shadow-sm"
                    >
                        <RecurringSparkIcon className="w-6 h-6" />
                    </span>
                </Tooltip>
            )}
        </div>
    );

    const getSecondaryBadge = () => {
        if (isManagerGoal) return { label: 'M', color: 'yellow' as BadgeColor };
        if (isPaymentMethodSpark) return { label: '$', color: 'green' as BadgeColor };
        return undefined;
    };

    return (
        <EntityDetailsCard
            appIsMobile={appIsMobile}
            className="[&_.panel-field]:!p-0 [&_.panel-field]:!mt-[2px] [&_p]:!mb-0 [&_.text-xs]:text-nowrap [&_.text-sm]:text-nowrap mb-2"
            title={sparkStatus}
            titleBadge={{
                label: otherGroupName ?? '',
                color: 'blue',
                tooltip: accountType === 'retailer' ? vendorGroupName : '',
            }}
            secondaryTitleBadge={getSecondaryBadge()}
            name={name}
            image={sparkImage}
            description={description}
            status={{
                label: statusLabel,
                color: statusColor,
                tooltip: statusChipTooltip,
            }}
            metadataGroups={metaDataGroups}
            actions={<div className="[&_.icon-btn]:mr-[-16px] text-gray-300"> {moreButton}</div>}
        />
    );
};

/**
 * This is a wrapped version of the SparkDetailsPanel for when we want to use the
 * sparkPosData and sparkSubGroups to calculate the location, participant, and product counts
 * as well as the other group name and vendor group name.
 */
export default ({
    spark,
    sparkPosData,
    sparkPosDataIsReady,
    sparkSubGroups,
    detailedSparkType,
    ...sharedProps
}: SharedSparkDetailsPanelProps & {
    sparkPosData: ISparkPosData;
    sparkPosDataIsReady: boolean;
    sparkSubGroups: ISparkSubGroup[];
}) => {
    const { account } = useSparkplugAccount();
    const { appIsMobile } = useApp();

    const { associatedProducts, locations, participants } = sparkPosData;

    const otherGroupName = getOtherGroupName(spark, account);
    const vendorGroupName = account?.type !== 'brand' ? spark.sparkVendor?.name : '';
    const subGroupsLocations = getSelectedLocationsFromSparkSubGroups(sparkSubGroups);
    const subGroupsParticipants = getSelectedParticipantsFromSparkSubGroups(sparkSubGroups);

    const productCount = associatedProducts?.length;

    const locationCount =
        detailedSparkType === 'leaderboardMulti' ? subGroupsLocations.length : locations?.length;

    const participantCount =
        detailedSparkType === 'leaderboardMulti'
            ? subGroupsParticipants.length
            : participants?.length;

    return (
        <>
            <SparkDetailsPanel
                spark={spark}
                detailedSparkType={detailedSparkType}
                otherGroupName={otherGroupName}
                vendorGroupName={vendorGroupName}
                locationCount={locationCount}
                participantCount={participantCount}
                sparkPosDataIsReady={sparkPosDataIsReady}
                productCount={productCount}
                {...sharedProps}
                appIsMobile={appIsMobile}
                accountType={account?.type}
            />
        </>
    );
};
