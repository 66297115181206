import UploadsAPI from '@api/UploadsAPI';

import { UPLOAD_FOLDERS } from '@sparkplug/lib';

import { useAdvancedMutation, useQueryClient } from '@hooks/QueryHooks';

import { coursesCacheKey } from '../queries/useGetAllCourses';

const uploadAsset = async ({ accountId, file }: { accountId: string; file: any }) => {
    const data = await UploadsAPI.getPresignedUploadUrl({
        groupId: accountId,
        folder: UPLOAD_FOLDERS.COURSE_ASSETS,
        filename: file.name,
    });

    return UploadsAPI.upload(data, file);
};

export const useUploadCourseAsset = (accountId: string) => {
    const queryClient = useQueryClient();

    return useAdvancedMutation(
        (payload: File) => {
            return uploadAsset({ accountId, file: payload });
        },
        {
            customOptions: {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: [coursesCacheKey] });
                },
            },
            toastOptions: {
                loading: 'Uploading course asset...',
                success: 'Course asset uploaded successfully!',
                error: 'Failed to upload course asset.',
            },
        },
    );
};
