import SparksAPI from '@api/SparksAPI';

import { useAdvancedQuery } from '@hooks/QueryHooks';

import { IAccount } from '@app/types/AccountsTypes';

export const useGetSparkDateRangeQuery = ({
    account,
    isAdminApp,
}: {
    account?: IAccount;
    isAdminApp: boolean;
}) => {
    const { isLoading, data, refetch, isFetched } = useAdvancedQuery(
        `spark-date-range-${account?._id ?? ''}`,
        () =>
            account?._id ? SparksAPI.getAllTimeSparkDateRange(account?._id) : Promise.resolve(0),
        {
            enabled: isAdminApp,
        },
    );

    return { sparkDateRangeIsReady: !isLoading, sparkDateRange: data, refetch, isFetched };
};
