import * as React from 'react';

import ClickableArea from '@components/layout/ClickableArea';

import { cn } from '../utils';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
    hasBorder?: boolean;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
    ({ className, hasBorder = true, onClick, ...props }, ref) => {
        const classes = cn(
            'rounded-md border bg-white shadow-gray-4dp p-4',
            hasBorder ? 'border-solid border border-gray-300' : '',
            className,
        );
        return onClick ? (
            <ClickableArea onClick={onClick} className={classes} {...props} />
        ) : (
            <div ref={ref} className={classes} {...props} />
        );
    },
);
Card.displayName = 'Card';

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => (
        <div ref={ref} className={cn('flex flex-col mb-1', className)} {...props} />
    ),
);
CardHeader.displayName = 'CardHeader';

const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
    ({ className, children, ...props }, ref) => (
        <h3 ref={ref} className={cn(className)} {...props}>
            {children}
        </h3>
    ),
);
CardTitle.displayName = 'CardTitle';

const CardDescription = React.forwardRef<
    HTMLParagraphElement,
    React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => <p ref={ref} className={cn(className)} {...props} />);
CardDescription.displayName = 'CardDescription';

const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => <div ref={ref} className={cn(className)} {...props} />,
);
CardContent.displayName = 'CardContent';

const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => (
        <div ref={ref} className={cn('flex items-center pt-4', className)} {...props} />
    ),
);
CardFooter.displayName = 'CardFooter';

/**
 * Card Image Props
 */
export interface CardImageProps extends React.HTMLAttributes<HTMLDivElement> {
    image: React.ReactElement;
    backgroundColor?: string;
}

/**
 * Card Image Component
 */
const CardImage = React.forwardRef<HTMLDivElement, CardImageProps>(
    ({ className, image, backgroundColor, ...props }, ref) => (
        <div
            ref={ref}
            className={cn(
                'w-full rounded-md relative flex justify-center items-center mb-3',
                className,
            )}
            style={{ background: backgroundColor }}
            {...props}
        >
            {image}
        </div>
    ),
);
CardImage.displayName = 'CardImage';

/**
 * Card Header Graphic Props
 */
export interface CardHeaderGraphicProps extends React.HTMLAttributes<HTMLDivElement> {
    graphic: React.ReactElement;
}

/**
 * Card Header Graphic Component
 */
const CardHeaderGraphic = React.forwardRef<HTMLDivElement, CardHeaderGraphicProps>(
    ({ className, graphic, ...props }, ref) => (
        <div
            ref={ref}
            className={cn(
                'w-10 h-10 rounded-md flex justify-center items-center mr-2 border-0',
                className,
            )}
            {...props}
        >
            {React.cloneElement(graphic, {
                className: cn('x-6 y-6', graphic.props.className),
            })}
        </div>
    ),
);
CardHeaderGraphic.displayName = 'CardHeaderGraphic';

export {
    Card,
    CardHeader,
    CardFooter,
    CardTitle,
    CardDescription,
    CardContent,
    CardImage,
    CardHeaderGraphic,
};
