import { useMemo } from 'react';

import { BrandLinkStatusOptions } from '@constants/AccountConstants';
import moment from 'moment';

import { AccountLink } from '@sparkplug/lib';

import AccountLinkLabel from '@components/labels/AccountLinkLabel';
import Table from '@components/table/Table';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { THeadCell } from '@app/types/TableTypes';

import { useAccountLinkTableFilters } from '../../hooks/useAccountLinkTableFilters';
import AccountLinkMappedBrands from '../AccountLinkMappedBrands';
import AccountStatusChip from '../AccountStatusChip';
import TagStatus from '../TagStatus';
import { AccountLinksTableActionCell } from './AccountLinksTableActionCell';

import './AccountLinksTable.scss';

export interface AccountLinksTableProps {
    accountLinksAreReady: boolean;
    accountTagCountsAreReady: boolean;
    searchableAccountLinks: AccountLink[];
    applySearch: (list: AccountLink[]) => AccountLink[];
    isInboxView?: boolean;
}
export const AccountLinksTable = ({
    accountLinksAreReady,
    accountTagCountsAreReady,
    searchableAccountLinks,
    applySearch,
    isInboxView,
}: AccountLinksTableProps) => {
    const { account } = useSparkplugAccount();
    const { appIsMobile } = useApp();
    const { applyStatusFilter, genAccountLinkStatus } = useAccountLinkTableFilters();

    const headCells: THeadCell<
        AccountLink & {
            key: string;
            accountLinkStatus?: BrandLinkStatusOptions;
        }
    >[] = useMemo(() => {
        return [
            {
                id: 'accountName',
                sortType: 'string',
                label: account?.type === 'retailer' ? 'Vendor' : 'Retailer',
                render: (row) => (
                    <Table.Cell align="left" className="account-cell">
                        <AccountLinkLabel accountLink={row} />
                    </Table.Cell>
                ),
            },
            {
                id: 'mappedNamesStr',
                sortType: 'string',
                label: 'Mapped brands',
                render: (row) => (
                    <Table.Cell align="left">
                        <AccountLinkMappedBrands accountLink={row} />
                    </Table.Cell>
                ),
                isHidden: appIsMobile,
            },
            {
                id: 'accountLinkStatus.label',
                sortType: 'string',
                label: 'Status',
                isHidden: !isInboxView,
                render: (row) => (
                    <Table.Cell align="left">
                        <AccountStatusChip accountLink={row} accountType={account?.type} />
                    </Table.Cell>
                ),
            },
            {
                id: 'createdAt',
                sortType: 'date',
                label: `Date ${isInboxView ? 'Requested' : 'Added'}`,
                render: (row) => (
                    <Table.Cell align="left">
                        {row.createdAt ? moment(row.createdAt).format('MMM D, YYYY') : ''}
                    </Table.Cell>
                ),
            },
            {
                id: 'tagStatus',
                sortType: 'string',
                label: 'Products',
                isHidden: account?.type !== 'brand' || appIsMobile,
                render: (row) => (
                    <Table.Cell align="left">
                        <TagStatus
                            accountTagCountsAreReady={accountTagCountsAreReady}
                            row={row}
                            tagsEnabled={account?.rulesBasedSparksEnabled}
                        />
                    </Table.Cell>
                ),
            },
            {
                id: 'actions',
                isHidden: account?.type === 'brand' && appIsMobile,
                render: (row) => <AccountLinksTableActionCell accountLink={row} />,
            },
        ];
    }, [accountTagCountsAreReady, account, appIsMobile, isInboxView]);

    return (
        <Table
            className="account-links-table"
            isLoading={!accountLinksAreReady}
            variant="raised"
            rows={searchableAccountLinks.map((accountLink) => ({
                ...accountLink,
                accountLinkStatus: genAccountLinkStatus(accountLink),
                key: [accountLink.accountId, accountLink.status, accountTagCountsAreReady].join(
                    '-',
                ),
            }))}
            headCells={headCells}
            defaultOptions={{}}
            filters={[applySearch, applyStatusFilter]}
            enableQueryParams
        >
            <Table.RenderHead />
            <Table.RenderBody />
        </Table>
    );
};
