import { useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';

import { Menu, SidebarLayout } from '@componentLibrary';
import UnauthorizedView from '@views/auth/UnauthorizedView';

import { AccountLink } from '@sparkplug/lib';

import { AutoLinkingInfoCallout } from '@features/account-links/components/AutoLinkingInfoCallout';

import { Inbox as InboxIcon, People as PeopleIcon, Search as SearchIcon } from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import PageHeader from '@components/layout/PageHeader';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import AdminAccountLinksLinkedView from './LinkedAccountsTab';
import { PartnersHubToolbar } from './PartnersHubToolbar';
import AdminAccountLinksSuggestedView from './SuggestedLinkTab';
import { useMappedLinks } from './useMappedLinks';
import { usePendingVendorLinks } from './usePendingVendorLinks';

import './AccountLinksView.scss';

interface LinksViewProps {
    links: AccountLink[];
    linksAreReady: boolean;
}
const MappedLinksView = ({ links, linksAreReady }: LinksViewProps) => (
    <AdminAccountLinksLinkedView accountLinks={links} accountLinksAreReady={linksAreReady} />
);
const PendingVendorLinksView = ({ links, linksAreReady }: LinksViewProps) => (
    <AdminAccountLinksLinkedView
        isInboxView
        accountLinks={links}
        accountLinksAreReady={linksAreReady}
    />
);

const AccountLinksView = () => {
    const { account, connectEnabled, refetchAccount } = useSparkplugAccount();
    const { appIsMobile, history, userId, userIsSuperAdmin } = useApp();

    const userIsAccountOwner =
        account?.assignedOwnerUserId && account.assignedOwnerUserId === userId;
    const userCanEnableAutoLinking = userIsAccountOwner || userIsSuperAdmin;

    const { mappedLinks, mappedLinkCount, mappedLinksAreReady, refetchMappedLinks } =
        useMappedLinks();

    const {
        pendingVendorLinks,
        pendingVendorLinkCount,
        pendingVendorLinksAreReady,
        refetchPendingVendorLinks,
        showPendingVendorLinks,
    } = usePendingVendorLinks();

    useEffect(() => {
        // There's a lot of action that can affect data in this view, we want to make sure we're always displaying the latest information
        refetchAccount();
        refetchMappedLinks();
        refetchPendingVendorLinks();
    }, [pendingVendorLinkCount, mappedLinkCount]);

    const { pathname } = useLocation();
    const path = `/${account?._id}/partners`;
    const currentView: 'mapped' | 'inbox' | 'available' = useMemo(() => {
        if (pathname.includes('available')) {
            return 'available';
        }
        if (pathname.includes('inbox')) {
            return 'inbox';
        }
        return 'mapped';
    }, [pathname]);

    return (
        <SidebarLayout
            header={
                connectEnabled ? (
                    <PageHeader className="m-0" title={account?.name || ''} heading="Partners">
                        <PartnersHubToolbar
                            condensed={appIsMobile}
                            account={account}
                            onClick={() => history.push(`/${account?._id}/settings/credits/manage`)}
                        />
                    </PageHeader>
                ) : undefined
            }
            sidebar={
                <Menu
                    navigation={[
                        {
                            name: 'Inbox',
                            href: `/${account?._id}/partners/inbox`,
                            icon: <InboxIcon />,
                            current: currentView === 'inbox',
                            isHidden: !showPendingVendorLinks,
                            badgeCount: pendingVendorLinkCount,
                        },
                        {
                            type: 'hr',
                            isHidden: !showPendingVendorLinks,
                        },
                        {
                            name: account?.type === 'retailer' ? 'Vendor Links' : 'Retailer Links',
                            href: `/${account?._id}/partners`,
                            icon: <PeopleIcon />,
                            current: currentView === 'mapped',
                            count: mappedLinkCount,
                        },
                        {
                            name: `Available ${
                                account?.type === 'retailer' ? 'Vendors' : 'Retailers'
                            }`,
                            href: `/${account?._id}/partners/available`,
                            icon: <SearchIcon />,
                            current: currentView === 'available',
                        },
                    ]}
                />
            }
            content={
                <>
                    {appIsMobile && account?.type === 'brand' && (
                        <CalloutMessage
                            className="desktop-callout"
                            customIcon="desktop"
                            message="Go to desktop to manage Linked Retailers"
                        />
                    )}

                    {!!account && (
                        <AutoLinkingInfoCallout
                            account={account}
                            className="mb-4"
                            currentView={currentView}
                            userCanEnableAutoLinking={userCanEnableAutoLinking}
                            onEnableAutoLinkingClick={() =>
                                history.push(`/${account?._id}/settings/data-privacy`)
                            }
                        />
                    )}

                    <Switch>
                        <Route
                            path={`${path}/available`}
                            component={
                                connectEnabled ? AdminAccountLinksSuggestedView : UnauthorizedView
                            }
                        />
                        <Route
                            path={`${path}/inbox`}
                            render={() => {
                                const noPendingLinks =
                                    pendingVendorLinksAreReady && !pendingVendorLinkCount;
                                if (account?.type === 'brand' || noPendingLinks) {
                                    return <Redirect to={path} />;
                                }
                                return connectEnabled ? (
                                    <PendingVendorLinksView
                                        links={pendingVendorLinks}
                                        linksAreReady={pendingVendorLinksAreReady}
                                    />
                                ) : (
                                    <UnauthorizedView />
                                );
                            }}
                        />
                        <Route
                            path={path}
                            render={() => {
                                return connectEnabled ? (
                                    <MappedLinksView
                                        links={mappedLinks}
                                        linksAreReady={mappedLinksAreReady}
                                    />
                                ) : (
                                    <UnauthorizedView />
                                );
                            }}
                        />
                    </Switch>
                </>
            }
        />
    );
};

export default AccountLinksView;
