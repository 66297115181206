import { useCallback } from 'react';

import axios from 'axios';

import { ListEmployeeStandingsResponseBody, Spark, UISparkStanding } from '@sparkplug/lib';

import { useApp } from '@hooks/AppHooks';
import { useAdvancedQuery, useQueryClient } from '@hooks/QueryHooks';

const API = {
    fetchEmployeeStandings: async (sparkId: string) => {
        return (
            (
                await axios.get<ListEmployeeStandingsResponseBody>(
                    `/api/v1/employee-data/standings/${sparkId}`,
                )
            ).data.data ?? ([] as Spark[])
        );
    },
};

export const getEmployeeSparkStandingsCacheKey = (sparkId: string, userId: string) => [
    'employee-standings',
    'spark',
    sparkId,
    'user',
    userId,
];
export const useEmployeeSparkStandings = (
    sparkId: string,
    options?: { autoFetch?: boolean; enabled?: boolean },
) => {
    const { userId = '' } = useApp();

    const { data, isFetched: sparkStandingsAreReady } = useAdvancedQuery(
        getEmployeeSparkStandingsCacheKey(sparkId, userId),
        () => API.fetchEmployeeStandings(sparkId),
        {
            enabled: !!userId && !!sparkId && (options?.enabled ?? options?.autoFetch ?? true),
        },
    );

    const queryClient = useQueryClient();
    const prefetchSparkStandings = useCallback(async () => {
        queryClient.prefetchQuery(getEmployeeSparkStandingsCacheKey(sparkId, userId), () =>
            API.fetchEmployeeStandings(sparkId),
        );
    }, [queryClient, sparkId]);

    return {
        sparkStandings: data?.standings ?? [],
        currentUserStanding: data?.currentUserStanding,
        minutesSinceLastCache: data?.minutesSinceLastCache,
        sparkStandingsAreReady,
        prefetchSparkStandings,
    };
};

export const useMultipleEmployeeSparkStandings = (
    sparkIds: string[],
    options?: { enabled?: boolean },
) => {
    const { userId = '' } = useApp();

    const results = sparkIds.map((sparkId) => {
        const { data, isFetched } = useAdvancedQuery(
            getEmployeeSparkStandingsCacheKey(sparkId, userId),
            () => API.fetchEmployeeStandings(sparkId),
            {
                enabled: !!userId && !!sparkId && (options?.enabled ?? true),
            },
        );
        return { sparkId, data, isFetched };
    });

    const queryClient = useQueryClient();
    const prefetchMultipleSparkStandings = useCallback(async () => {
        sparkIds.forEach((sparkId) => {
            queryClient.prefetchQuery(getEmployeeSparkStandingsCacheKey(sparkId, userId), () =>
                API.fetchEmployeeStandings(sparkId),
            );
        });
    }, [queryClient, sparkIds, userId]);

    const standingsBySparkId = results.reduce(
        (acc, { sparkId, data }) => {
            acc[sparkId] = {
                standings: data?.standings ?? [],
                currentUserStanding: data?.currentUserStanding,
                minutesSinceLastCache: data?.minutesSinceLastCache,
            };
            return acc;
        },
        {} as Record<
            string,
            {
                standings: UISparkStanding[] | undefined;
                currentUserStanding: UISparkStanding | undefined;
                minutesSinceLastCache: number | undefined;
            }
        >,
    );

    const allFetched = results.every((result) => result.isFetched);

    return {
        standingsBySparkId,
        allStandingsAreReady: allFetched,
        prefetchMultipleSparkStandings,
    };
};
