import { FC, ReactElement } from 'react';

import moment from 'moment';

import { Spark } from '@sparkplug/lib';

import { SparkAccountDisplay } from '@features/spark-dashboard/components/SparkAccountDisplay';
import { SparkOwnerDisplay } from '@features/spark-dashboard/components/SparkOwnerDisplay';
import { SparkDashboardTableRowData } from '@features/spark-dashboard/types';

import { RightArrow as ArrowRightIcon } from '@components/icons';
import Paper from '@components/layout/Paper';
import SparkStatusChip from '@components/sparks/SparkStatusChip';

import './SparkAdminMobileCard.scss';

export interface SparkMobileCardProps {
    row: SparkDashboardTableRowData;
    onSparkSelected: (spark: Spark) => void;
}
export const SparkAdminMobileCard: FC<SparkMobileCardProps> = ({ row, onSparkSelected }) => {
    const adminCardView = (label: string, value: string | ReactElement) => {
        return (
            <div className="display-view">
                <span>{label}</span>
                {value}
            </div>
        );
    };

    const onRowClick = () => {
        onSparkSelected(row.spark);
    };

    const showStatus =
        row.sparkStatus === 'Pending' ||
        row.sparkStatus === 'Expired' ||
        row.sparkStatus === 'Rejected';

    return (
        <Paper className="spark-mobile-card">
            <button type="button" className="row top-row" onClick={onRowClick}>
                <SparkAccountDisplay
                    spark={row.spark}
                    name={row.pinnedSparkData.name}
                    photo={row.pinnedSparkData.photo}
                    isMobile
                />
                {showStatus && (
                    <span>
                        <SparkStatusChip status={row.sparkStatus} />
                    </span>
                )}
                <ArrowRightIcon className="arrow-right-icon" />
            </button>
            <div className="row grid-row">
                {adminCardView('Spark Name', row.spark.name)}
                {adminCardView(
                    'Owner',
                    <SparkOwnerDisplay
                        firstName={row.spark.sparkCreator?.firstName ?? ''}
                        lastName={row.spark.sparkCreator?.lastName ?? ''}
                        avatarUrl={row.spark.sparkCreator?.avatarUrl ?? ''}
                    />,
                )}
            </div>
            <div className="row grid-row">
                {adminCardView('Start Date', moment(row.spark.startDate).format('MMM D, YYYY'))}
                {adminCardView('End Date', moment(row.spark.endDate).format('MMM D, YYYY'))}
            </div>
        </Paper>
    );
};
