import ChartsAPI from '@api/ChartsAPI';
import { ChartMetricLabelMap } from '@constants/ChartConstants';
import { DatumValue } from '@nivo/core';
import { keyBy } from 'lodash';
import moment, { MomentInput } from 'moment';

import {
    CalculatorFrequency,
    FullChartResponseBody,
    GetChartResponseBody,
    PercentIncreaseData,
    SparkMetric,
} from '@sparkplug/lib';

import { numberFormatterFactory } from '@helpers/chartFormatters';

import { IBucketFactory, IChartFactory } from '@app/types/CalculatorTypes';
import {
    ChartLeader,
    IChartDataSettings,
    IDataArrayItem,
    TChartDataPrecision,
    TChartType,
} from '@app/types/ChartDataTypes';
import { IPosLocation, IPosProduct } from '@app/types/PosTypes';
import { IAccountUser } from '@app/types/UsersTypes';

import DayBucketFactory from '../visualization/buckets/DayBucketFactory';
import MonthBucketFactory from '../visualization/buckets/MonthBucketFactory';
import WeekBucketFactory from '../visualization/buckets/WeekBucketFactory';
import YearBucketFactory from '../visualization/buckets/YearBucketFactory';
import NivoBarChartFactory from '../visualization/nivo/NivoBarChartFactory';
import NivoLineChartFactory from '../visualization/nivo/NivoLineChartFactory';
import { transformCloudChartData } from './transformCloudChartData';

const bucketFactoryMap = {
    day: DayBucketFactory,
    week: WeekBucketFactory,
    month: MonthBucketFactory,
    year: YearBucketFactory,
};

const chartFactoryMap: Record<TChartType, any> = {
    line: NivoLineChartFactory,
    bar: NivoBarChartFactory,
    // We don't actually use a Chart Factory for Tables.
    // This is just so that the code doesn't crash
    table: class MockTableChartFactory {
        generateChartData() {}
    },
};

export const frequencyMap: Record<TChartDataPrecision, CalculatorFrequency> = {
    day: 'daily',
    week: 'weekly',
    month: 'monthly',
    year: 'yearly',
};

export const getDefaultPrecisionByDateRange = (
    dateStart: MomentInput,
    dateEnd: MomentInput,
): IChartDataSettings['precision'] => {
    const duration = moment(dateEnd).diff(moment(dateStart), 'days');

    if (duration <= 31) {
        return 'day';
    } else if (duration > 31 && duration <= 91) {
        return 'week';
    } else if (duration > 91) {
        return 'month';
    }

    return 'month';
};

export const addUserDataToChartDataLeaders = (
    chartDataLeaders: ChartLeader[],
    accountUsers?: IAccountUser[],
) => {
    if (!chartDataLeaders?.length || !accountUsers?.length) {
        return chartDataLeaders;
    }

    const accountUserMap = keyBy(accountUsers, 'flexibleEmployeeId');
    const posEmployeeMap = accountUsers.reduce((acc, user) => {
        if (user.posEmployeeProfileIds?.length) {
            user.posEmployeeProfileIds.forEach((id) => {
                acc[id] = user;
            });
        }
        return acc;
    }, {} as Record<string, IAccountUser>);

    return chartDataLeaders.map((leader) => {
        const { flexibleEmployeeId = '' } = leader;
        const {
            smsStatus,
            role,
            firstTransactionDateByLocation,
            lastTransactionDaysAgoStr,
            avatarUrl,
        } = accountUserMap[flexibleEmployeeId] || posEmployeeMap[flexibleEmployeeId] || {};
        return {
            ...leader,
            smsStatus,
            role,
            firstTransactionDateByLocation,
            lastTransactionDaysAgoStr,
            avatarUrl,
        };
    });
};

export const formatAxisLeftValue =
    (formatter?: (value: DatumValue) => string) => (value: DatumValue) => {
        return formatter != null ? formatter(value) : String(value);
    };

export const convertChartDataToTeamChartData = (
    chartDataValue: number,
    locations: IPosLocation[],
    type: TChartType,
    isCalculatingChartData: boolean,
): ChartLeader[] => {
    if (isCalculatingChartData) {
        return [];
    }

    const locationLabel = locations.length === 1 ? locations[0].name : 'All Locations';

    const locationChartData: Omit<ChartLeader, 'chartData'> = {
        flexibleEmployeeId: locationLabel,
        locations: '',
        name: locationLabel,
        value: chartDataValue,
    };

    return [locationChartData];
};

export function calculatePercentIncrease(
    currentValue: number,
    prevValue: number,
): [number, string] {
    const increase = currentValue - prevValue;

    const valueFormatted = numberFormatterFactory(1)((increase / prevValue) * 100);
    const value = parseFloat(valueFormatted);

    return [value, `${valueFormatted}%`];
}

export const getChartMetricLabel = (metric?: string, percentIncreaseData?: PercentIncreaseData) => {
    if (metric === 'percent_increase') {
        const prefix = 'Percentage Increase of';
        switch (percentIncreaseData?.metric) {
            case 'order_average':
                return `${prefix} Order Average`;
            case 'total_units':
                return `${prefix} Total Units`;
            case 'total_sales':
                return `${prefix} Total Sales`;
            default:
                return '';
        }
    }
    return ChartMetricLabelMap[metric as Exclude<SparkMetric, 'percent_increase'>] ?? '';
};

const filterFutureBuckets = (allBuckets: Map<string, IDataArrayItem>) => {
    const filteredBuckets = new Map();
    const today = moment();

    const allBucketsAreValidDates = [...allBuckets].every(([bucketName]) => {
        const date = moment(bucketName, 'YYYY-MM-DD', true);
        return date.isValid();
    });

    if (!allBucketsAreValidDates) {
        return allBuckets;
    }

    allBuckets.forEach((bucket, bucketName) => {
        if (moment(bucketName).isSameOrBefore(today, 'date')) {
            filteredBuckets.set(bucketName, bucket);
        }
    });

    return filteredBuckets;
};

export const emptyBucketsFactory = (allBuckets: Map<string, IDataArrayItem>, type: TChartType) => {
    const filteredBuckets = type === 'line' ? filterFutureBuckets(allBuckets) : allBuckets;
    return () => new Map<string, IDataArrayItem>(JSON.parse(JSON.stringify([...filteredBuckets])));
};

export const transformCloudChartDataToResult = (
    rawData: GetChartResponseBody,
    settings: IChartDataSettings,
    bucketFactory: IBucketFactory,
    chartFactory: IChartFactory,
    posData: {
        accountPosLocations: IPosLocation[];
        accountPosProducts: IPosProduct[];
    },
) => {
    const { dateStart, dateEnd } = settings;
    const buckets = bucketFactory.generateAllBucketsAsMap(dateStart, dateEnd);
    const chartDataBuckets = [...buckets.keys()].map((bucketName) => {
        return {
            name: bucketName,
            nameFormatted: bucketFactory.getShortName(bucketName),
        };
    });

    const _data = transformCloudChartData(rawData, settings, bucketFactory, chartFactory, posData);

    return {
        chartDataValue: (rawData as FullChartResponseBody)?.total ?? 0,
        chartDataBuckets,
        maximumIndividualLeaderValue: rawData?.employeeTotals
            ? Math.max(...Object.values<number>(rawData.employeeTotals))
            : 0,
        chartDataDatePercentage: (rawData as FullChartResponseBody)?.datePercentage ?? 0,
        ..._data,
    };
};

export const getCloudChartData = ChartsAPI.fetchChart;
export const getCloudChartDataVendor = ChartsAPI.fetchChartVendor;

export const getChartFactories = ({
    settings,
}: {
    settings: Pick<IChartDataSettings, 'precision' | 'type'>;
}) => {
    const { precision, type } = settings;

    const bucketFactory = new bucketFactoryMap[precision]();
    const chartFactory = new chartFactoryMap[type]();

    return {
        bucketFactory,
        chartFactory,
    };
};
