import { FC, PropsWithChildren, useMemo } from 'react';
import {
    MutationFunction,
    QueryClient,
    QueryClientProvider,
    QueryKey,
    UseMutationOptions,
    UseQueryOptions, // eslint-disable-next-line no-restricted-imports
    useMutation as _useMutation, // eslint-disable-next-line no-restricted-imports
    useQuery as _useQuery, // eslint-disable-next-line no-restricted-imports
    useQueryClient,
} from 'react-query';

import toast, { TToastPromiseMessageParameters } from '@components/toast';

export function useAdvancedQuery<IResponse, IError>(
    queryKey: QueryKey,
    queryFn: () => Promise<IResponse>,
    customOptions: UseQueryOptions<IResponse, IError> = {},
) {
    const isDisabled = useMemo(() => {
        return Array.isArray(queryKey) ? queryKey.some((dep) => dep == null) : queryKey == null;
    }, [queryKey]);

    const query = _useQuery<IResponse, IError>(queryKey, queryFn, {
        staleTime: 60 * 60 * 1000, // 1hr
        enabled: !isDisabled,

        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        ...customOptions,
    });

    return query;
}

interface IMutationOptions<TQueryObjectType, TPayload, TResponse, TError, TContext> {
    updateQuery?: {
        queryKey: QueryKey;
        useOptimistic?: boolean;
    };
    updateListQuery?: {
        queryKey: QueryKey;
        findFn: (item: TQueryObjectType) => boolean;
        useOptimistic?: boolean;
    };
    toastOptions?: TToastPromiseMessageParameters;
    customOptions?: UseMutationOptions<TResponse, TError, TPayload, TContext>;
}

const buildMutationCallbackFnStack = <TResponse, TPayload>(
    key: string,
    obj: any,
    newFn: (payload: TPayload) => TResponse,
) => {
    const fns: ((payload: TPayload) => TResponse)[] = [newFn];

    if (obj?.[key]) {
        fns.push(obj[key]);
    }

    return (payload: TPayload) => {
        return fns.reduce((res, fn) => {
            return {
                ...res,
                ...(fn(payload) || {}),
            };
        }, {}) as TResponse;
    };
};

function applyToast<TResponse, TPayload>(
    mutationFn: MutationFunction<TResponse, TPayload>,
    toastOptions: TToastPromiseMessageParameters,
): MutationFunction<TResponse, TPayload> {
    return (variables: TPayload): Promise<TResponse> => {
        const promise = mutationFn(variables);
        toast.promise(promise, toastOptions);
        return promise;
    };
}

export function useAdvancedMutation<TQueryObjectType, TPayload, TResponse, TError, TContext>(
    mutationFn: MutationFunction<TResponse, TPayload>,
    options: IMutationOptions<TQueryObjectType, TPayload, TResponse, TError, TContext> = {},
) {
    const queryClient = useQueryClient();

    const { updateQuery, updateListQuery, toastOptions, customOptions = {} } = options;

    /**
     * Using `updateQuery.queryKey`, will invalidate the item in specified query.
     * If `useOptimistic` is set, the existing query data is updated with the payload
     *
     * TODO: Abstract into generic fn (?) since very similar to `updateListQuery.queryKey`
     */
    if (updateQuery?.queryKey) {
        const { queryKey, useOptimistic } = updateQuery;

        if (useOptimistic) {
            let prevValue: TQueryObjectType | undefined;

            customOptions.onMutate = buildMutationCallbackFnStack<TContext, TPayload>(
                'onMutate',
                customOptions,
                (payload) => {
                    prevValue = queryClient.getQueryData<TQueryObjectType>(queryKey);

                    queryClient.setQueryData(queryKey, {
                        ...prevValue,
                        ...payload,
                    });

                    return {} as TContext;
                },
            );

            customOptions.onError = buildMutationCallbackFnStack<void, TError>(
                'onError',
                customOptions,
                () => {
                    queryClient.setQueryData(queryKey, prevValue);
                },
            );
        }

        customOptions.onSettled = buildMutationCallbackFnStack('onSuccess', customOptions, () => {
            queryClient.invalidateQueries(queryKey);
        });
    }

    /**
     * Using `updateListQuery.queryKey`, will invalidate the item in specified list query.
     * If `useOptimistic` is set, the `findFn` will find the item in the list query
     * and update the object with the payload
     *
     * TODO: Abstract into generic fn (?) since very similar to `updateQuery.queryKey`
     */
    if (updateListQuery?.queryKey) {
        const { queryKey, findFn, useOptimistic } = updateListQuery;

        if (useOptimistic) {
            let prevValue: TQueryObjectType[] | undefined;

            customOptions.onMutate = buildMutationCallbackFnStack<TContext, TPayload>(
                'onMutate',
                customOptions,
                (payload) => {
                    prevValue = queryClient.getQueryData<TQueryObjectType[]>(queryKey);

                    if (prevValue) {
                        const i = prevValue.findIndex(findFn);

                        if (i > -1) {
                            prevValue[i] = {
                                ...prevValue[i],
                                ...payload,
                            };

                            queryClient.setQueryData(queryKey, prevValue);
                        }
                    }

                    return {} as TContext;
                },
            );

            customOptions.onError = buildMutationCallbackFnStack<void, TError>(
                'onError',
                customOptions,
                () => {
                    queryClient.setQueryData(queryKey, prevValue);
                },
            );
        }

        customOptions.onSettled = buildMutationCallbackFnStack('onSuccess', customOptions, () => {
            queryClient.invalidateQueries(queryKey);
        });
    }

    return _useMutation<TResponse, TError, TPayload, TContext>(
        toastOptions ? applyToast(mutationFn, toastOptions) : mutationFn,
        customOptions,
    );
}

// Unit testing utility
export const createReactQueryTestWrapper = (): FC<PropsWithChildren> => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                // ✅ turns retries off
                retry: false,
            },
        },
    });

    return ({ children }) => (
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    );
};
