import { ComponentProps, ReactNode, useState } from 'react';

import clsx from 'clsx';
import moment from 'moment-timezone';

import { UIRequestForSpark } from '@sparkplug/lib';

import CalloutMessage from '@components/layout/CalloutMessage';

import './RequestDetailsCallout.scss';

export interface RequestDetailsCalloutProps {
    requestForSpark: Pick<
        UIRequestForSpark,
        'sourceAccountName' | 'createdAt' | 'message' | 'status'
    >;
    actions?: ReactNode;
    sx?: ComponentProps<typeof CalloutMessage>['sx'];
}
export const RequestDetailsCallout = ({
    requestForSpark: { sourceAccountName, createdAt, message = '', status },
    actions,
    sx,
}: RequestDetailsCalloutProps) => {
    const [isHidden, setIsHidden] = useState(false);

    const handleClose = () => {
        setIsHidden(true);
    };

    return (
        <CalloutMessage
            sx={{ width: '100%', ...sx }}
            className={clsx('request-details-callout', isHidden && 'hidden')}
            title="Request Details"
            message={
                <div className="flex flex-col gap-4">
                    <div>
                        <strong>{sourceAccountName}</strong> requested a Spark on{' '}
                        {moment(createdAt).format('MMM D, YYYY')}{' '}
                        {message?.length ? `: "${message.trim()}"` : ''}
                    </div>
                    {actions || null}
                </div>
            }
            onClose={actions ? handleClose : undefined}
        />
    );
};
