import { Redirect, Switch } from 'react-router';

import { Menu, SidebarLayout } from '@componentLibrary';
import UnauthorizedView from '@views/auth/UnauthorizedView';

import { CreateEventButton } from '@features/events/components/CreateEventButton';
import { DeclineEventModal } from '@features/events/components/DeclineEventModal/DeclineEventModal';
import { DeleteEventModal } from '@features/events/components/DeleteEventModal';
import { EventDetailModal } from '@features/events/components/EventDetailModal';
import { EventFormDrawer } from '@features/events/components/EventFormDrawer';
import { useEventNavigation } from '@features/events/hooks/useEventNavigation';
import { useGetEventCountQuery } from '@features/events/queries';

import Button from '@components/buttons/Button';
import {
    CalendarIcon,
    Inbox as InboxIcon,
    PhoneAndroid,
    Send as SendIcon,
} from '@components/icons';
import PageHeader from '@components/layout/PageHeader';
import { AdminRoute } from '@components/router';
import Toolbar from '@components/toolbar/Toolbar';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import useEmployeePreview from '@hooks/useEmployeePreview';

import { IAccount } from '@app/types/AccountsTypes';

import { EventDetailsView } from '../EventDetailsView/EventDetailsView';
import { EventsInbox } from './routes/EventsInbox';
import { EventsOutbox } from './routes/EventsOutbox';
import { RetailerScheduledEvents } from './routes/ScheduledEvents/RetailerScheduledEvents';
import { VendorScheduledEvents } from './routes/ScheduledEvents/VendorScheduledEvents';

const AdminEventsView = ({
    account,
    connectEnabled,
}: {
    account: IAccount;
    connectEnabled: boolean;
}) => {
    const nav = useEventNavigation();
    const baseEventsPath = `/${account._id}/events`;
    const inboxPath = `${baseEventsPath}/inbox`;
    const sentPath = `${baseEventsPath}/sent`;
    const eventDetailsPath = `${baseEventsPath}/:eventId`;

    const showEventFormDrawer: boolean =
        !!nav.state.editId || nav.state.create === 'true' || !!nav.state.clonedFromId;
    const showEventDetailModal: boolean = !!nav.state.detailId;
    const showDeleteEventModal: boolean = !!nav.state.deleteId;
    const showDeclineEventModal: boolean = !!nav.state.declineId;

    const { data: pendingEventCount = 0 } = useGetEventCountQuery({
        accountId: account._id,
        variant: 'inbox',
    });

    const { showPreviewModal, previewModal, isEmployeePreviewEnabled } = useEmployeePreview({
        page: 'events',
    });

    return (
        <>
            <SidebarLayout
                header={
                    <PageHeader title={account?.name ?? ''} heading="Events">
                        <Toolbar collapseOnMobile>
                            <Toolbar.Group className="toolbar-group-end">
                                {isEmployeePreviewEnabled && (
                                    <Button
                                        variant="flat"
                                        onClick={showPreviewModal}
                                        className="!mr-2 hover:!bg-transparent hover:!shadow-none"
                                    >
                                        <span className="flex items-center gap-2 text-gray-800 hover:text-gray-900">
                                            <PhoneAndroid />
                                            Preview
                                        </span>
                                    </Button>
                                )}
                                <CreateEventButton />
                            </Toolbar.Group>
                        </Toolbar>
                    </PageHeader>
                }
                sidebar={
                    <Menu
                        navigation={[
                            {
                                name: 'Inbox',
                                href: `${inboxPath}?requestStatus=pending`,
                                icon: <InboxIcon />,
                                isHidden: account.type === 'brand' || !connectEnabled,
                                current: nav.state.currentView === 'inbox',
                                badgeCount: pendingEventCount ?? undefined,
                            },
                            {
                                name: 'Sent',
                                href: `${sentPath}?requestStatus=pending`,
                                icon: <SendIcon />,
                                isHidden: account.type === 'retailer' || !connectEnabled,
                                current: nav.state.currentView === 'sent',
                                badgeCount: pendingEventCount ?? undefined,
                            },
                            {
                                type: 'hr',
                                isHidden: !connectEnabled,
                            },
                            {
                                name: 'Scheduled Events',
                                href: baseEventsPath,
                                icon: <CalendarIcon />,
                                current: nav.state.currentView === 'scheduled',
                            },
                        ]}
                    />
                }
                contentClassName="overflow-x-auto"
                content={
                    <Switch>
                        <AdminRoute
                            path={inboxPath}
                            render={() => {
                                if (!connectEnabled) {
                                    return <UnauthorizedView />;
                                }
                                if (account.type !== 'retailer') {
                                    return <Redirect to={baseEventsPath} />;
                                }
                                return <EventsInbox />;
                            }}
                        />
                        <AdminRoute
                            path={sentPath}
                            render={() => {
                                if (!connectEnabled) {
                                    return <UnauthorizedView />;
                                }
                                if (account.type !== 'brand') {
                                    return <Redirect to={baseEventsPath} />;
                                }
                                return <EventsOutbox />;
                            }}
                        />
                        <AdminRoute
                            path={eventDetailsPath}
                            render={() => {
                                return <EventDetailsView />;
                            }}
                        />
                        <AdminRoute
                            path={baseEventsPath}
                            render={() => {
                                if (account.type === 'retailer') {
                                    return <RetailerScheduledEvents />;
                                }

                                return <VendorScheduledEvents />;
                            }}
                        />
                    </Switch>
                }
            />

            {showEventFormDrawer && (
                <EventFormDrawer
                    isVisible={showEventFormDrawer}
                    onClose={nav.actions.resetNavParameters}
                />
            )}

            {showEventDetailModal && (
                <EventDetailModal
                    isVisible={showEventDetailModal}
                    onClose={nav.actions.resetNavParameters}
                />
            )}

            {showDeleteEventModal && (
                <DeleteEventModal
                    account={account}
                    isVisible={showDeleteEventModal}
                    onClose={nav.actions.resetNavParameters}
                />
            )}

            {showDeclineEventModal && (
                <DeclineEventModal
                    isVisible={showDeclineEventModal}
                    onClose={nav.actions.resetNavParameters}
                />
            )}
            {previewModal}
        </>
    );
};

export default () => {
    const { account, connectEnabled } = useSparkplugAccount();
    if (!account) {
        return <></>;
    }

    // TODO: Delete this once the events feature is fully released
    const eventsFeatureEnabled = import.meta.env.REACT_APP_EVENTS === 'true';
    if (!eventsFeatureEnabled) {
        return <div className="text-xl font-bold mb-2">Events coming soon!</div>;
    }

    return <AdminEventsView account={account} connectEnabled={connectEnabled} />;
};
