import axios from 'axios';

import { CaptureOnboardingDetailsRequestBody } from '@sparkplug/lib';

import { useAdvancedMutation } from '@hooks/QueryHooks/QueryHooks';

const API = {
    async onboardUser(userId: string, onboardingDetails: CaptureOnboardingDetailsRequestBody) {
        return (await axios.post(`/api/v1/users/${userId}/onboarding-details`, onboardingDetails))
            .status;
    },
};

export const useOnboardUserMutation = () => {
    const { mutateAsync, isLoading } = useAdvancedMutation(
        ({
            userId,
            onboardingDetails,
        }: {
            userId: string;
            onboardingDetails: CaptureOnboardingDetailsRequestBody;
        }) => API.onboardUser(userId, onboardingDetails),
    );

    return {
        isOnboarding: isLoading,
        onboardUserAsync: mutateAsync,
    };
};
