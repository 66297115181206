import { DetailedSparkType, Spark } from '@sparkplug/lib';

import Grid from '@components/layout/Grid';
import Paper from '@components/layout/Paper';

import {
    formatSparkInfo,
    getDetailedSparkType,
    getDetailedSparkTypeDisplayName,
    getMinThresholdLabel,
} from '@helpers/sparks';

import './SparkDetailsContent.scss';

const TypeValues = [
    {
        label: 'Total Sales',
        value: 'total_sales',
    },
    {
        label: 'Total Units',
        value: 'total_units',
    },
    {
        label: 'Order Average',
        value: 'order_average',
    },
    {
        label: 'Transactions',
        value: 'transaction_count',
    },
    {
        label: 'Units Per Transaction',
        value: 'units_per_transaction',
    },
];

export const getTypeLabel = (value: string) => {
    for (let i = 0; i < TypeValues.length; i += 1) {
        if (TypeValues[i].value === value) {
            return TypeValues[i].label;
        }
    }
    return value;
};

const SparkDetailGroup = ({ title, content }: { title: string; content: string }) => {
    return (
        <div className="spark-detail-group">
            <h3>{title}</h3>
            <span>{content}</span>
        </div>
    );
};

const getMinTransactionLabel = (spark: Spark): string | undefined => {
    const { minimumTransactionsToQualify, detailedSparkType } = spark;
    if (!minimumTransactionsToQualify) {
        return undefined;
    }

    if (detailedSparkType === 'leaderboardLocation') {
        return `${minimumTransactionsToQualify} per Location`;
    }

    return String(minimumTransactionsToQualify);
};

const SparkDetailsContent = ({ spark }: { spark: Spark }) => {
    const detailedSparkType = getDetailedSparkType(spark) || ('' as DetailedSparkType);
    const { formattedStartDate, formattedEndDate, totalDays, schedule, formattedPaymentMethod } =
        formatSparkInfo(
            spark.startDate,
            spark.endDate,
            spark.requestState,
            spark.recurringSchedule,
            spark.paymentMethod,
        );

    const minThresholdLabel = getMinThresholdLabel(spark);
    const minTransactionLabel = getMinTransactionLabel(spark);

    return (
        <Paper className="spark-details-content" variant="smooth">
            <Grid>
                <Grid.Item sm={3}>
                    <div className="spark-details-content_row flex-direction-column">
                        <SparkDetailGroup title="Name" content={spark.name} />
                        <SparkDetailGroup title="Description" content={spark.description} />
                        <SparkDetailGroup
                            title="Type"
                            content={getDetailedSparkTypeDisplayName(detailedSparkType)}
                        />
                    </div>
                </Grid.Item>
                <Grid.Item sm={4}>
                    <div className="spark-details-content_row flex-direction-row">
                        <SparkDetailGroup title="Start Date" content={formattedStartDate} />
                        <SparkDetailGroup title="End Date" content={formattedEndDate} />
                    </div>
                    <div className="spark-details-content_row flex-direction-row">
                        <SparkDetailGroup title="Duration" content={`${totalDays} Days`} />
                        <SparkDetailGroup title="Schedule" content={schedule} />
                    </div>

                    {(minThresholdLabel || minTransactionLabel) && (
                        <div className="spark-details-content_row flex-direction-row">
                            {minThresholdLabel && (
                                <SparkDetailGroup
                                    title={
                                        spark?.type === 'commission'
                                            ? 'Minimum Commission'
                                            : 'Minimum Threshold'
                                    }
                                    content={minThresholdLabel}
                                />
                            )}
                            {minTransactionLabel && (
                                <SparkDetailGroup
                                    title="Minimum Transactions"
                                    content={minTransactionLabel}
                                />
                            )}
                        </div>
                    )}

                    {spark.paymentMethod && formattedPaymentMethod && (
                        <div className="spark-details-content_row flex-direction-row">
                            <SparkDetailGroup
                                title="Payment Method"
                                content={formattedPaymentMethod}
                            />
                        </div>
                    )}
                </Grid.Item>
            </Grid>
        </Paper>
    );
};

export default SparkDetailsContent;
