import { parseCurrencyToNumber } from './text';

export const formatCurrency = (num: string | number, showCents = false): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: showCents ? 2 : 0,
  });
  if (typeof num === 'string') {
    return formatter.format(parseCurrencyToNumber(num));
  }

  return formatter.format(num);
};

export const formatShortenedCurrency = (value: number) => {
  const hasDecimals = value % 1 !== 0;

  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: hasDecimals ? 2 : 0,
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
};

export const formatFloat = (n: number, digits = 2): string => n.toFixed(digits);

export const formatLargeNumber = (value: number) => {
  const hasDecimals = value % 1 !== 0;

  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: hasDecimals ? 1 : 0,
    maximumFractionDigits: 1,
  }).format(value);
};
