import { ReactElement, cloneElement } from 'react';

import { Box, SxProps } from '@mui/material';
import { clsx } from 'clsx';

import {
    AlertIcon,
    CloseRounded,
    DesktopIcon,
    Info as InfoIcon,
    PadlockIcon,
    SparklesIcon,
} from '@components/icons';

import './CalloutMessage.scss';

type TCalloutMessageVariant = 'default' | 'outlined' | 'filled';
type TCalloutMessageColor = 'blue' | 'green' | 'red' | 'neutral' | 'yellow' | 'light-blue' | 'gray';
type CalloutMessageIcon = 'info' | 'padlock' | 'alert' | 'sparkles' | 'desktop';
export interface CalloutMessageProps {
    className?: string;
    title?: string | ReactElement;
    message: string | ReactElement;
    variant?: TCalloutMessageVariant;
    color?: TCalloutMessageColor;
    hideIcon?: boolean;
    customIcon?: CalloutMessageIcon;
    overrideIcon?: ReactElement;
    sx?: SxProps;
    onClose?: () => void;
}
function CalloutMessage({
    className,
    title,
    message,
    variant = 'default',
    color = 'neutral',
    hideIcon = false,
    customIcon = 'info',
    overrideIcon,
    sx,
    onClose,
}: CalloutMessageProps) {
    const iconClassName = `callout-message_icon icon-color-${color}`;

    let icon;
    switch (customIcon) {
        case 'padlock':
            icon = <PadlockIcon className={iconClassName} />;
            break;
        case 'alert':
            icon = <AlertIcon className={iconClassName} />;
            break;
        case 'sparkles':
            icon = <SparklesIcon className={iconClassName} />;
            break;
        case 'desktop':
            icon = <DesktopIcon className={iconClassName} />;
            break;
        default:
            icon = <InfoIcon className={iconClassName} />;
            break;
    }

    if (overrideIcon) {
        icon = cloneElement(overrideIcon, {
            className: clsx([iconClassName, overrideIcon.props.className]),
        });
    }

    return (
        <Box
            sx={sx}
            className={clsx([
                className,
                'callout-message_container',
                `callout-message-variant-${variant}`,
                `callout-message-color-${color}`,
                title != null && 'has-title',
                'relative',
            ])}
        >
            {onClose && (
                <CloseRounded
                    onClick={onClose}
                    className="absolute top-3 right-3 cursor-pointer w-4 h-4"
                />
            )}
            {!hideIcon && icon}
            <div className="callout-message">
                {title != null && <h4 className="callout-message_title">{title}</h4>}
                <div className="callout-message_message">{message}</div>
            </div>
        </Box>
    );
}

export default CalloutMessage;
