import { z } from 'zod';

export interface SubmitSurveyResponsePathParams {
  learningResourceId: string;
}

export interface GetAllLearningResourceSurveyResponsePathParams {
  learningResourceId: string;
}

export const submitSurveyResponseSchema = z.object({
  surveyId: z.string(),
  data: z.record(z.unknown()),
  learningResourceId: z.string(),
  status: z.enum(['completed', 'in_progress']),
  markets: z.array(z.string()).optional(),
  retailerId: z.string().optional(),
  locationName: z.string().optional(),
  locationId: z.string().optional(),
});

export type SubmitSurveyResponseRequestBody = z.infer<typeof submitSurveyResponseSchema>;

export interface ISurveyResponse {
  learningResourceId: string;
  surveyId: string;
  userId: string;
  accountId: string;
  createdAt: Date;
  updatedAt: Date;
  data: Record<string, unknown>;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  employeeDeposit: {
    amount: number;
    status: string;
    createdAt: Date;
  };
  markets?: string[];
  retailerId?: string;
  retailerName?: string;
  locationName?: string;
  locationAddress?: Record<string, unknown>;
}
