import { UIEvent } from '@sparkplug/lib';

import { EventDisplayStatus, getEventDisplayStatus } from '@features/events/helpers/eventUtils';

import { IAccount } from '@app/types/AccountsTypes';

import { EventTableRowData } from '../types';

export const formatEventType = (event: UIEvent) => {
    if (event.type === 'popup') return 'Pop-up';
    if (event.type === 'training') return 'Training';
    if (event.type === 'promo') return 'Promo';
    return 'Other';
};

type EventWithStatus = EventTableRowData | UIEvent;

export const adminCanEditOrDelete = ({
    account,
    event,
}: {
    account?: IAccount;
    event: EventWithStatus;
}) => {
    const displayStatus =
        'displayStatus' in event
            ? event.displayStatus
            : getEventDisplayStatus({ event, accountType: account?.type });

    const uneditableStatuses: EventDisplayStatus[] = ['complete', 'expired', 'declined'];
    if (uneditableStatuses.includes(displayStatus) || !account) {
        return false;
    }

    // Vendors and Retailers can only edit/delete their own events
    if (account.type === 'retailer') {
        return account._id === event.retailerAccount._id && !event.vendorAccount?._id;
    }
    return account._id === event.vendorAccount?._id;
};
