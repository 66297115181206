import { AccountLinkStatus } from '@sparkplug/lib';

import { useAccountLinks } from '@features/account-links/queries';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

const MAPPED_LINKS_STATUSES: AccountLinkStatus[] = ['enabled', 'disabled'];

export const useMappedLinks = () => {
    const { account } = useSparkplugAccount();

    const {
        accountLinks: mappedLinks = [],
        accountLinksAreReady: mappedLinksAreReady,
        refetchAccountLinks: refetchMappedLinks,
    } = useAccountLinks(account?._id ?? '', !!account, MAPPED_LINKS_STATUSES);

    return {
        mappedLinks,
        mappedLinkCount: mappedLinks.length,
        mappedLinksAreReady,
        refetchMappedLinks,
    };
};
