import { AccountLink, AccountLinkStatuses } from '../brandlinks';
import { PagedApiResponseBody } from '../http';
import {
  CondensedHydratedBrandProduct,
  HydratedBrandProduct,
  PosConfigType,
  PosLocation,
} from '../pos';
import { z } from 'zod';
import { courseTypes } from '../training-course';
import { subscriptionLineItemSchema } from '../billing';
import { SubscriptionConfig } from '../subscription-config';

export const AccountTypes = ['retailer', 'brand'] as const;
export type AccountType = typeof AccountTypes[number];
export type HubspotAccountType = Capitalize<AccountType>;

export const AccountStatuses = [
  'created',
  'integrated',
  'onboarding',
  'active',
  'inactive',
  'churned',
] as const;
export type AccountStatus = typeof AccountStatuses[number];
export type HubspotAccountStatus = Capitalize<AccountStatus>;

export const Industries = [
  'cannabis',
  'food service',
  'apparel',
  'cosmetics',
  'nutritional supplements',
  'outdoor gear',
] as const;
export type Industry = typeof Industries[number];

export const AccountPosImportStatuses = [
  'pending',
  'failing',
  'caution',
  'working',
  'inactive',
] as const;
export type AccountPosImportStatus = typeof AccountPosImportStatuses[number];
export type AccountSparkCount = {
  active: number;
  upcoming: number;
  completed: number;
  pending: number;
  expired: number;
};

export type AdminUserLoginDetails = { [adminUserId: string]: Date | undefined };
export type AccountAdminMetrics = {
  userLoginDetails: AdminUserLoginDetails;
  lastLogin?: Date;
};
export type AccountEnrollmentMetrics = {
  pendingCount: number;
  enrolledCount: number;
  blockedCount: number;
  activeCount: number;
  enrolledPercent: number;
};
export type AccountUserMetrics = {
  admin: AccountAdminMetrics;
  enrollmentMetrics: AccountEnrollmentMetrics;
};

export type AccountSparkBrands = {
  [sparkBrandId: string]: AccountSparkBrand;
};

export type BrandTagState = {
  state: 'all-tagged' | 'missing-tags' | 'none-mapped';
  missingTagCount?: number;
};

export type AccountSparkBrand = {
  _id: string;
  createdAt: string;
  updatedAt: string;
  photo: string;
  name: string;
  isEnabled: boolean;
  tagState?: BrandTagState;
  productsAvailableAtRetailer?: boolean;
  productsAvailableByRetailer?: { [p: string]: boolean };
  aliases?: string[];
  excludedMarkets?: AccountMarket[];
};

export type AccountSparkBrandMap = {
  [brandId: string]: AccountSparkBrand;
};

export const AccountRoles = ['group-member', 'group-admin', 'unmatched', 'none'] as const;
export type AccountRole = typeof AccountRoles[number];

export const SubscriptionTypes = ['none', 'paid'] as const;
export type SubscriptionType = typeof SubscriptionTypes[number];

/**
 * Credentials for working with the storifyme SDK and API
 * - accountId: The accountId in storifyme associated to this particular account
 * - apiKey: The apiKey for interacting with their server-side API (should never be sent to the UI)
 * - sdkKey: The sdkKey for interacting with their Web SDK
 */
export interface StorifymeCredentials {
  accountId: string;
  apiKey: string;
  sdkKey: string;
}

export const ACCOUNT_MARKETS_RECORD = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
} as const;

export const AccountMarkets = Object.keys(ACCOUNT_MARKETS_RECORD) as AccountMarket[];
export type AccountMarket = keyof typeof ACCOUNT_MARKETS_RECORD;
export type AccountMarketLabel = typeof ACCOUNT_MARKETS_RECORD[AccountMarket];

export interface AccountMetaData {
  subscriptionType?: SubscriptionType;
  billingDisabled?: boolean;
  markets?: AccountMarket[];
  hubspotStateOrRegion?: string;
}

export interface AccountRequestPathParams {
  groupId: string;
}

export interface AccountLinkPathParams {
  groupId: string;
  vendorAccountId: string;
}

export const createAccountLinkSchema = z.object({});

export type CreateAccountLinkRequestBody = z.infer<typeof createAccountLinkSchema>;

export interface CreateAccountLinkResponseBody {
  data: AccountLink;
}

export type DeleteAccountLinkPathParams = AccountLinkPathParams;
export interface AccountPaymentHealth {
  isInGoodHealth: boolean;
  latestChargeStatus?: 'succeeded' | 'failed' | 'pending';
  hasPaymentMethods: boolean;
}
export interface IPublicAccount {
  _id: string;
  connectDisabled?: boolean;
  createdAt: string;
  deletedAt: string;
  hubspotId: string;
  intercomId?: string;
  assignedSuperAdminUserId?: string;
  assignedOwnerUserId?: string;
  industries: Industry[];
  metaData?: AccountMetaData;
  members?: any[];
  name: string;
  onboardingUrl: string;
  parentId: string;
  photo?: string | null;
  stripeCustomerId: string;
  type: AccountType;
  updatedAt: string;
  posImportStatus?: AccountPosImportStatus;
  posImportStatusDetails?: string;
  sparkCount: AccountSparkCount;
  userMetrics: AccountUserMetrics;
  referralUrl?: string;
  monthlySpend?: number; // In pennies
  companyUrl?: string;
  status: AccountStatus;
  sparkBrands?: AccountSparkBrands;
  paymentHealth?: AccountPaymentHealth;

  // Account Specific Settings
  zoltrainEnabled?: boolean;
  seedTalentEnabled?: boolean;
  tagAssistantMessageDismissed?: boolean;
  tagAssistantEnabled?: boolean;
  tagAssistantEnabledAt?: string;
  managerSparksEnabled: boolean;
  snapsEnabled?: boolean;
  subscriptionConfig?: SubscriptionConfig;
  autoLinkingEnabled?: boolean;
  inventoryEnabled?: boolean;
  // Note: only applies to vendor accounts
  rulesBasedSparksEnabled?: boolean;
  posTypes?: PosConfigType[];
}

export interface ListAccountsQueryParams {
  user_id?: string;
  name?: string;
  offset?: number;
  limit?: number;
}

export type ListAccountsResponse = PagedApiResponseBody<IPublicAccount>;
export interface ListByIdAccountResponse {
  data: IPublicAccount[];
}

export type GetAccountResponseBody = IPublicAccount;
export type CreateAccountResponseBody = IPublicAccount;

export const createAccountSchema = z.object({
  name: z.string().min(1),
  hubspotId: z.string().optional(),
  type: z.enum(AccountTypes),

  referralUrl: z.string().optional(),
  assignedSuperAdminUserId: z.string().optional(),
  assignedOwnerUserId: z.string().optional(),
  stripeCustomerId: z.string().optional(),
  stripeData: z
    .object({
      email: z.string().email().min(1),
      invoicePrefix: z.string().min(1),
      subscriptionLineItems: z.array(subscriptionLineItemSchema).optional(),
    })
    .optional(),
  trainingEnabled: z.enum(courseTypes).optional(),
  connectDisabled: z.boolean().optional(),
  managerSparksEnabled: z.boolean().optional(),
  autoLinkingEnabled: z.boolean().optional(),
  inventoryEnabled: z.boolean().optional(),
  productTagsEnabled: z.boolean().optional(),
  snapsEnabled: z.boolean().optional(),
  rulesBasedSparksEnabled: z.boolean().optional(),
  photo: z.string().optional(),
  parentId: z.string().optional(),
  metaData: z.any().optional(),
  industries: z.array(z.enum(Industries)).optional(),
  monthlySpend: z.number().optional(),
  companyUrl: z.string().optional(),
  status: z.string().optional(),
  isActive: z.boolean().optional(),
});

export type CreateAccountRequestBody = z.infer<typeof createAccountSchema>;

export const updateAccountSchema = createAccountSchema.partial();
export type UpdateAccountRequestBody = z.infer<typeof updateAccountSchema>;

export const updateAccountLinkSchema = z.object({
  state: z
    .enum(AccountLinkStatuses, {
      required_error: 'state is required for updating account links',
      invalid_type_error: 'Must be a supported state type',
    })
    .optional(),
  shareSalesData: z.boolean().optional(),
  shareInventoryData: z.boolean().optional(),
});
export type UpdateAccountLinkRequestBody = z.infer<typeof updateAccountLinkSchema>;

export interface DeleteAccountMemberRequestBody {
  userId: string;
}

export interface StripeLinkResponseBody {
  url: string;
}

export type ListAccountSparkBrandsResponseBody = AccountSparkBrand[];

export const createAccountSparkBrandSchema = z.object({
  name: z.string().min(1),
  photo: z.string().min(1),
  isEnabled: z.boolean(),
  aliases: z.array(z.string()).optional(),
  excludedMarkets: z.array(z.enum(AccountMarkets as [string, ...string[]])).optional(),
});
export type CreateAccountSparkBrandRequestBody = z.infer<typeof createAccountSparkBrandSchema>;

export interface AccountSparkBrandUpdateDeleteRequestPathParams {
  groupId: string;
  sparkBrandId: string;
}

export const updateAccountSparkBrandSchema = z.object({
  name: z.string().optional(),
  photo: z.string().optional(),
  isEnabled: z.boolean().optional(),
  aliases: z.array(z.string()).optional(),
});
export type UpdateAccountSparkBrandRequestBody = z.infer<typeof updateAccountSparkBrandSchema>;

export interface CreateSparkBrandAndUploadPhotoParams
  extends Omit<CreateAccountSparkBrandRequestBody, 'photo'> {
  photo: File;
}

export type UpdateSparkBrandAndUploadPhotoParams = Partial<CreateSparkBrandAndUploadPhotoParams>;

export interface AccountVendorRetailerRequestPathParams {
  groupId: string;
  retailerAccountId: string;
}

export interface GetAccountVendorRetailerResponseBody extends IPublicAccount {
  allLocations: PosLocation[];
  activeLocations: PosLocation[];
}
export interface PaymentHealthResponseBody extends AccountPaymentHealth {
  accountId: string;
  stripeCustomerId?: string;
}
export interface ProductsVendorBrand {
  products: HydratedBrandProduct[];
  excludedProducts: HydratedBrandProduct[];
}
export interface ProductsVendorBrandMap {
  [brandId: string]: ProductsVendorBrand;
}

export interface CondensedProductsVendorBrand {
  products: CondensedHydratedBrandProduct[];
  excludedProducts: CondensedHydratedBrandProduct[];
}
export interface CondensedProductsVendorBrandMap {
  [brandId: string]: CondensedProductsVendorBrand;
}

export const bulkEnrollUsersRequestBodySchema = z.object({
  userIds: z.array(z.string().min(1)).min(1),
  groupId: z.string().min(1),
});

export type BulkEnrollUsersRequestBody = z.infer<typeof bulkEnrollUsersRequestBodySchema>;

export type GetAccountVendorRetailerPosDataResponseBody = {
  brands: { _id: string; name: string; createdAt: string }[];
  categories: { _id: string; name: string; createdAt: string }[];
  productsVendorBrandMap: CondensedProductsVendorBrandMap;
};

export const getBulkAccountVendorRetailerPosDataSchema = z.object({
  retailerAccountIds: z.array(z.string().min(1)).min(1),
});

export type GetBulkAccountVendorRetailerPosDataRequestBody = z.infer<
  typeof getBulkAccountVendorRetailerPosDataSchema
>;
export type GetBulkAccountVendorRetailerPosDataResponseBody = Record<
  string,
  GetAccountVendorRetailerPosDataResponseBody
>;

export type AccountBrandLinkMetrics = {
  enabled: number;
  pending: number;
  disabled: number;
};

export const respondToAccountLinkSchema = z.object({
  vendorAccountId: z.string().min(1),
  accepted: z.boolean(),
});
export type RespondToAccountLinkRequestBody = z.infer<typeof respondToAccountLinkSchema>;
export type RespondToAccountLinkRequestPathParams = Pick<AccountLinkPathParams, 'groupId'>;

export const requestAccountLinkAsVendorSchema = z.object({
  retailerAccountId: z.string().min(1),
});
export type RequestAccountLinkAsVendorBody = z.infer<typeof requestAccountLinkAsVendorSchema>;
export type RequestAccountLinkPathParams = Pick<AccountLinkPathParams, 'groupId'>;

export interface AccountReachResponse {
  brandId: string;
  brandName: string;
  accountLinkCount: number;
  groupMembershipCount: number;
}
