import { useState } from 'react';

import { FormErrors, FormState } from '../context/types';

export const useFormValidation = (formState: FormState) => {
    const [errors, setErrors] = useState<FormErrors>({
        brandId: '',
        markets: '',
        reward: '',
        name: '',
        description: '',
        budget: '',
    });
    const validateForm = (isDraft?: boolean): { isValid: boolean } => {
        const newErrors: FormErrors = {
            brandId: '',
            markets: '',
            reward: '',
            name: '',
            description: '',
            budget: '',
        };

        if (!formState.name.trim()) {
            newErrors.name = 'Name is required';
        }

        if (!isDraft) {
            if (!formState.brandId) {
                newErrors.brandId = 'Brand ID is required';
            }
            if (formState.selectedMarkets.length === 0) {
                newErrors.markets = 'At least one market must be selected';
            }
            if (Number(formState.reward) < 1) {
                newErrors.reward = 'Reward must be at least $1';
            }
            if (!formState.description) {
                newErrors.description = 'Description is required';
            }
            if (
                formState.hasBudget &&
                (formState.budget === 0 || formState.budget < formState.reward)
            ) {
                newErrors.budget = 'Budget must be greater than 0 and reward amount';
            }
        }
        setErrors(newErrors);
        return {
            isValid: Object.values(newErrors).every((error) => error === ''),
        };
    };

    return { validateForm, errors, setErrors };
};
