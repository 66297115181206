import axios from 'axios';

import { GetCourseBillingSummaryResponse } from '@sparkplug/lib';

import { useAdvancedQuery } from '@hooks/QueryHooks';

export class CourseBillingSummaryAPI {
    static async getCourseBillingSummary(
        learningResourceId: string,
    ): Promise<GetCourseBillingSummaryResponse> {
        const response = await axios.get<GetCourseBillingSummaryResponse>(
            `/api/v1/learning-resource/${learningResourceId}/invoices`,
        );
        return response.data;
    }
}

export const getCourseBillingSummaryQueryKey = (courseId: string) => [
    'course-billing-summary',
    courseId,
];

export const useGetCourseBillingSummary = (courseId: string) => {
    const {
        data: billingSummary,
        isLoading: billingSummaryAreLoading,
        isFetched: billingSummaryAreReady,
        refetch: refetchBillingSummary,
        isError: billingSummaryHaveError,
    } = useAdvancedQuery(
        getCourseBillingSummaryQueryKey(courseId),
        () => CourseBillingSummaryAPI.getCourseBillingSummary(courseId),
        {
            enabled: !!courseId,
        },
    );

    return {
        billingSummary,
        billingSummaryAreLoading,
        billingSummaryAreReady,
        refetchBillingSummary,
        billingSummaryHaveError,
    };
};
