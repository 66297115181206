import { ILearningResource, formatCurrency } from '@sparkplug/lib';

import { Badge } from '@app/componentLibrary/Badge';
import { Card, CardContent, CardHeader } from '@app/componentLibrary/Card';
import Typography from '@app/componentLibrary/Typography/Typography';

import BillingSummaryDrawer from '../BillingSummaryDrawer';

interface RewardStatProps {
    label: string;
    value: string | number;
    showBudgetReached?: boolean;
}

const RewardStat = ({ label, value, showBudgetReached }: RewardStatProps) => (
    <div className="flex flex-col text-center px-8 max-sm:px-4 max-sm:py-4 border-r border-gray-200 border-solid last:border-r-0 max-sm:border-r-0 max-sm:border-b max-sm:last:border-b-0">
        <Typography variant="sm" className="font-bold text-gray-600">
            {label}
        </Typography>
        <Typography variant="2xl" className="font-bold text-gray-800">
            {value}
        </Typography>
        {showBudgetReached && <Badge label="BUDGET REACHED!" color="yellow" className="mt-1" />}
    </div>
);

export const CourseSummaryCard = ({
    learningResource,
}: {
    learningResource?: ILearningResource;
}) => {
    return (
        <Card className="mb-4" hasBorder={false}>
            <CardHeader className="flex flex-row justify-between items-center max-sm:flex-col max-sm:items-start">
                <Typography variant="lg" className="font-bold mb-3">
                    Payout Summary
                </Typography>
                {learningResource?.billingMode === 'auto' ? (
                    <BillingSummaryDrawer
                        learningResourceId={learningResource?.learningResourceId}
                    />
                ) : (
                    <></>
                )}
            </CardHeader>
            <CardContent>
                <div className="flex flex-row max-sm:flex-col justify-center items-center mb-2">
                    <RewardStat
                        label="Reward Amount"
                        value={formatCurrency((learningResource?.reward ?? 0) / 100, true)}
                    />
                    <RewardStat
                        label="Course Completions"
                        value={learningResource?.employeeDepositsCount || '0'}
                    />
                    <RewardStat
                        label="Total Payout"
                        value={formatCurrency((learningResource?.totalPayout ?? 0) / 100, true)}
                        showBudgetReached={
                            !!learningResource?.budget &&
                            !!learningResource.currentExpenditure &&
                            learningResource.currentExpenditure >= learningResource.budget
                        }
                    />
                </div>
            </CardContent>
        </Card>
    );
};
