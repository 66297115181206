import { useState } from 'react';

import Button from '@components/buttons/Button';
import LottieGraphic from '@components/graphics/LottieGraphic';
import EmployeeOnboarding1JSON from '@components/graphics/lottie/Employee-onboarding-1.json';
import EmployeeOnboarding2JSON from '@components/graphics/lottie/Employee-onboarding-2.json';
import EmployeeOnboarding3JSON from '@components/graphics/lottie/Employee-onboarding-3.json';

import { useApp } from '@hooks/AppHooks';
import { useEmployeeLocationsQuery } from '@hooks/QueryHooks/EmployeeDataQueries';

import Typography from '@app/componentLibrary/Typography/Typography';

const onboardingCarousel: {
    id: number;
    title: string;
    description: string;
    src: any;
}[] = [
    {
        id: 0,
        title: 'Welcome to SparkPlug',
        description:
            'Where you can participate in sales incentives and training programs to earn more cash!',
        src: EmployeeOnboarding1JSON,
    },
    {
        id: 1,
        title: 'Join the community',
        description:
            'Of 75,000 frontline employees, 3,000 stores, and the best consumer brands in the world.',
        src: EmployeeOnboarding2JSON,
    },
    {
        id: 2,
        title: 'Earn extra on the job!',
        description: 'Over $20 million in cash prizes have been rewarded through SparkPlug.',
        src: EmployeeOnboarding3JSON,
    },
];

const OnboardingCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { history } = useApp();
    useEmployeeLocationsQuery();

    const handleContinue = () => {
        if (currentIndex === onboardingCarousel.length - 1) {
            history.push('/user/onboarding/details');
            return;
        }

        setCurrentIndex((i) => i + 1);
    };

    return (
        <div className="flex flex-col gap-2 absolute top-0 left-0 right-0 px-4 py-6 overflow-x-hidden items-center md:pt-16">
            <div className="flex flex-col gap-2 w-full h-full relative max-w-[650px]">
                <Typography variant="2xl" className="text-white font-bold">
                    {onboardingCarousel[currentIndex].title}
                </Typography>
                <Typography variant="lg" className="text-white font-medium">
                    {onboardingCarousel[currentIndex].description}
                </Typography>
            </div>
            <div className="flex flex-col h-[70vh] justify-center">
                <LottieGraphic
                    json={onboardingCarousel[currentIndex].src}
                    startPlay
                    key={currentIndex}
                />
            </div>
            <div className="flex flex-col gap-4 py-4 fixed bottom-0 left-0 ring-0 w-full shadow-inner p-4">
                <Button
                    variant="smooth"
                    className="w-full !text-cerulean-600"
                    onClick={handleContinue}
                >
                    Continue
                </Button>
            </div>
        </div>
    );
};

export default OnboardingCarousel;
