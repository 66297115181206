import { useCallback, useEffect, useState } from 'react';

import { UIEvent } from '@sparkplug/lib';

import { EventFormFields } from '@features/events/types';

const intialEventFormFields: EventFormFields = {
    _id: undefined,
    type: undefined,
    locationIds: [],
    startTime: undefined,
    endTime: undefined,
    name: undefined,
    description: undefined,
    brandId: undefined,
    retailerAccountId: undefined,
    requestNote: undefined,
    clonedFromId: undefined,
};

export const useEventFormState = (initialEvent?: Partial<UIEvent>) => {
    const [form, setForm] = useState<EventFormFields>({
        ...intialEventFormFields,
        startTime: initialEvent?.startTime,
        endTime: initialEvent?.endTime,
    });

    useEffect(() => {
        if (initialEvent) {
            setForm({
                _id: initialEvent?._id,
                type: initialEvent?.type,
                locationIds: initialEvent?.locations?.map((location) => location._id) ?? [],
                startTime: initialEvent?.startTime,
                endTime: initialEvent?.endTime,
                name: initialEvent?.name,
                description: initialEvent?.description,
                brandId: initialEvent?.brand?._id,
                retailerAccountId: initialEvent?.retailerAccount?._id,
                requestNote: initialEvent?.requestNote,
                clonedFromId: initialEvent?.clonedFromId,
            });
        }
    }, [initialEvent]);

    const updateForm = useCallback((updates: Partial<EventFormFields>) => {
        setForm((currentForm) => ({
            ...currentForm,
            ...updates,
        }));
    }, []);

    const resetForm = useCallback(() => {
        setForm(intialEventFormFields);
    }, []);

    return {
        form,
        updateForm,
        resetForm,
    };
};
