import { MouseEvent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { PhoneAndroid } from '@mui/icons-material';
import { Box } from '@mui/material';
import { capitalize, debounce } from 'lodash';
import moment from 'moment-timezone';

import {
    REQUEST_FOR_SPARK_STATUSES,
    RequestForSparkStatus,
    Spark,
    accountCanCreateSpark,
} from '@sparkplug/lib';

import { useGetPendingSparksCountQuery } from '@core/sparks/queries/GetPendingSparksQuery';
import { useGetTotalSparksCountQuery } from '@core/sparks/queries/GetTotalSparksQuery';

import { useGetRequestForSparksTotal } from '@features/request-for-spark';
import { RequestSparkButton } from '@features/request-for-spark/components/RequestSparkButton';
import RequestsForSparksTable from '@features/request-for-spark/components/RequestsForSparksTable';
import { SparkDashboardContent } from '@features/spark-dashboard/components/SparkDashboardContent';
import SparkStats from '@features/spark-dashboard/components/SparkStats/SparkStats';
import { SPARK_STATS_DATE_RANGE_OPTIONS } from '@features/spark-dashboard/components/SparkStats/constants';
import { SparkWizardOriginRoute } from '@features/spark-wizard/views/SparkWizardView/SparkWizardView';
import { useGetSparkStatsQuery } from '@features/stats/queries/SparkStatsQueries';

import {
    useSparkManagementModal,
    wrapWithSparkManagementModal,
} from '@contexts/SparkManagementModalContext';
import { SparksProvider, useSparks } from '@contexts/SparksContext';

import Button from '@components/buttons/Button';
import { NoInboxSparksGraphic, NoSparksGraphic } from '@components/graphics';
import AppContentWithSidebar from '@components/layout/AppContentWithSidebar';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import PageHeader from '@components/layout/PageHeader';
import Skeleton from '@components/layout/Skeleton';
import UpgradeModal from '@components/overlays/UpgradeModal';
import { queryToString, useLocation, useQueryParams } from '@components/router';
import CreateSparkButton from '@components/sparks/CreateSparkButton';
import {
    FromTemplateButton,
    StartFromBlankButton,
} from '@components/sparks/CreateSparkButton/CreateSparkButton';
import Toolbar from '@components/toolbar/Toolbar';

import { useApp, useAppBodyClasses, withMobileView } from '@hooks/AppHooks';
import { useQueryClient } from '@hooks/QueryHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import {
    useSparkBrandsQuery,
    useSparkMarketsQuery,
    useSparkRetailersQuery,
    useSparkVendorsQuery,
} from '@hooks/SparksHooks/SparksHooks';
import useEmployeePreview from '@hooks/useEmployeePreview';

import { getStateNameByAbbreviation } from '@helpers/util';

import { IPosLocation } from '@app/types/PosTypes';
import { IOption, IOptionTree } from '@app/types/UITypes';

import HomeView from '../../../user-home/views/HomeView';
import {
    SPARK_REQUEST_STATE_OPTIONS,
    SPARK_SCHEDULE_OPTIONS,
    SPARK_TRAINING_OPTIONS,
    SPARK_TYPE_OPTIONS,
} from '../../SparksView.constants';
import SparkStatusButtons from '../../components/SparkStatusButtons';
import SparkPickerModal from './SparkPickerModal';

import './SparksView.scss';

interface ILocationOptions {
    optionTree: IOptionTree<{}>[];
    allLocations: IOption<{ key: string }>[];
}

const getLocationOptionsForRetailers = (
    accountLocations: IOption<IPosLocation>[] = [],
): ILocationOptions => {
    return {
        optionTree: accountLocations,
        allLocations: accountLocations.map((location) => ({ ...location, key: location._id })),
    };
};

const requestStatusOptions: IOption[] = REQUEST_FOR_SPARK_STATUSES.map((status) => ({
    label: `${capitalize(status)} Requests`,
    value: status,
}));

type SparksViewProps = {
    showPicker?: boolean;
    sparkPickerType?: 'leaderboard' | 'goal' | 'commission' | null;
    forceSparkApprovalModal?: {
        sparkId: string;
    };
};

export const SparksViewWithManagementModal = wrapWithSparkManagementModal(
    ({ showPicker = false, forceSparkApprovalModal }: SparksViewProps) => {
        const { history, userIsAdmin, user, isAdminApp, userIsSuperAdmin } = useApp();

        const { sparks, meta, refetchSparks, sparkFilters, updateSparkFilters, sparkDateRange } =
            useSparks();
        const { openCreateModal, openApprovalModal } = useSparkManagementModal();
        const location = useLocation<{
            state: {
                originRoute: SparkWizardOriginRoute;
            };
        }>();
        const { state } = location;
        const userTimezone = moment.tz.guess();

        const { account, connectEnabled, userCan, accountIsReady } = useSparkplugAccount();

        const queryParams = useQueryParams();

        const isSparkStatsEnabled = import.meta.env.REACT_APP_SPARK_STATS === 'true';

        const { sparkStats, sparkStatsIsLoading } = useGetSparkStatsQuery({
            account,
            sparkFilters: {
                ...(account?.type === 'retailer' && { group_id: account?._id }),
                ...(account?.type === 'brand' && { originator_group_id: account?._id }),
                status: sparkFilters.status === 'inbox/sent' ? 'inbox' : sparkFilters.status,
                types: sparkFilters.type.join(','),
                locationId: sparkFilters.location.map((l) => l.value).join(','),
                markets: sparkFilters.markets?.join(','),
                brandIds: sparkFilters.brandIds?.join(','),
                vendorIds: sparkFilters.vendorIds?.join(','),
                schedule: sparkFilters.schedule.join(','),
                training: sparkFilters.training.join(','),
                requestState: sparkFilters.requestState,
                search: sparkFilters.search,
                userTimezone,
                startDateRange: sparkFilters.startDateRange
                    ? moment(sparkFilters.startDateRange).format('YYYY-MM-DD')
                    : undefined,
                endDateRange: sparkFilters.endDateRange
                    ? moment(sparkFilters.endDateRange).format('YYYY-MM-DD')
                    : undefined,
            },
            isAdminApp,
            enabled: isSparkStatsEnabled && sparkFilters?.status === 'complete',
        });

        const userCanCreateSpark = userCan('createSpark');
        const searchRef = useRef<HTMLInputElement>(null);
        useEffect(() => {
            if (searchRef.current) {
                searchRef.current.focus();
            }
        }, [queryParams]);

        const { count: openRequestCount } = useGetRequestForSparksTotal({ account });
        const { totalSparksCount = 0 } = useGetTotalSparksCountQuery({
            account,
            isAdminApp,
        });

        const { showPreviewModal, previewModal, isEmployeePreviewEnabled } = useEmployeePreview({
            page: 'sparks',
        });

        useAppBodyClasses(['dashboard-app-content'], []);

        const handleSearchChange = (value: string) => {
            updateSparkFilters({ search: value });
        };

        const debouncedHandleSearchChange = useCallback(debounce(handleSearchChange, 500), [
            updateSparkFilters,
        ]);

        const [showSparkPickerModal, setShowSparkPickerModal] = useState(showPicker);
        const [upgradeModalIsVisible, setUpgradeModalIsVisible] = useState(false);

        const { count: pendingSparkCount = 0 } = useGetPendingSparksCountQuery({
            account,
            isAdminApp: userIsAdmin,
        });

        const { sparkRetailers = [], sparkRetailersAreReady } = useSparkRetailersQuery(
            account?._id ?? '',
            sparkFilters.status,
            // Only enable for brand accounts
            account?.type === 'brand',
        );

        const { sparkMarkets = [], sparkMarketsAreReady } = useSparkMarketsQuery(
            account?._id ?? '',
            sparkFilters.status,
        );

        const { sparkBrands = [], sparkBrandsAreReady } = useSparkBrandsQuery(
            account?._id ?? '',
            sparkFilters.status,
        );

        const { sparkVendors = [], sparkVendorsAreReady } = useSparkVendorsQuery(
            account?._id ?? '',
            sparkFilters.status,
            account?.type === 'retailer',
        );

        const locationOptions: ILocationOptions = useMemo(() => {
            if (!account) {
                return {
                    optionTree: [],
                    allLocations: [],
                };
            }

            // For retailers, use existing logic
            if (account.type === 'retailer') {
                return getLocationOptionsForRetailers(
                    sparkFilters.status === 'complete' ? account.allLocations : account.locations,
                );
            }

            // For brands, use API results
            if (!sparkRetailersAreReady) {
                return {
                    optionTree: [],
                    allLocations: [],
                };
            }

            const allLocations = sparkRetailers.flatMap((retailer) =>
                // eslint-disable-next-line @typescript-eslint/no-shadow
                (retailer.children ?? []).map((location) => ({
                    ...location,
                    key: location.value,
                })),
            );

            return {
                optionTree: sparkRetailers,
                allLocations,
            };
        }, [
            account?.type,
            account?.locations,
            account?.allLocations,
            sparkFilters.status,
            // Only include brand-specific dependencies when needed
            ...(account?.type === 'brand' ? [sparkRetailers, sparkRetailersAreReady] : []),
        ]);

        const marketOptions = useMemo(() => {
            return sparkMarkets.map((market) => ({
                label: getStateNameByAbbreviation(market),
                value: market,
            }));
        }, [sparkMarkets]);

        const onClickCreateSparkButton = () => setShowSparkPickerModal(true);

        const onSparkCardSelected = useCallback(
            (spark: Pick<Spark, '_id' | 'requestState'>, event?: MouseEvent<HTMLElement>) => {
                if (spark?.requestState === 'pending' && account?.type !== 'brand') {
                    openApprovalModal(spark._id);
                    return;
                }

                const sparkUrl = `/${account?._id}/sparks/${spark._id}`;
                if (event?.metaKey || event?.ctrlKey) {
                    window.open(`${window.location.origin}${sparkUrl}`, '_blank');
                } else {
                    history.push(sparkUrl, { prevUrl: document.location.pathname });
                }
            },
            [account, openApprovalModal],
        );

        const queryClient = useQueryClient();
        useEffect(() => {
            refetchSparks();
            // Currently, query invalidations from SparkContext.saveSpark aren't working as expected.
            queryClient.invalidateQueries('requestForSpark');
        }, []);

        useEffect(() => {
            if (forceSparkApprovalModal?.sparkId) {
                openApprovalModal(forceSparkApprovalModal?.sparkId);
            }
        }, [forceSparkApprovalModal]);

        const updateRequestForSparkStatus = (requestStatus?: RequestForSparkStatus) => {
            const { status, ...rest } = queryParams;
            history.push(
                `?${queryToString({
                    ...rest,
                    ...(requestStatus && { status: requestStatus }),
                })}`,
            );
        };

        useEffect(() => {
            if (showPicker && user && account) {
                const accountHasSparkEntitlement =
                    userIsSuperAdmin || accountCanCreateSpark(account);

                if (!accountHasSparkEntitlement) {
                    setShowSparkPickerModal(false);
                    setUpgradeModalIsVisible(true);
                }
            }
        }, [showPicker, account, user]);

        const handleCloseSparkPickerModal = () => {
            setShowSparkPickerModal(false);
            history.goBack();
        };

        let emptyStateComponent: ReactElement | null = null;
        const isInboxOrSent = sparkFilters.status === 'inbox/sent';
        const showRequestsForSparks =
            sparkFilters.status === 'inbox/requests' &&
            account?.type === 'brand' &&
            import.meta.env.REACT_APP_REQUEST_FOR_SPARK === 'true';

        if (isInboxOrSent && account?.type === 'retailer') {
            emptyStateComponent = (
                <EmptyStateDisplay graphic={<NoInboxSparksGraphic />} label="no new spark offers" />
            );
        } else if (showRequestsForSparks) {
            emptyStateComponent = (
                <EmptyStateDisplay
                    graphic={<NoInboxSparksGraphic />}
                    label="no new spark requests"
                />
            );
        } else if (totalSparksCount === 0) {
            emptyStateComponent = (
                <EmptyStateDisplay
                    layout="horizontal"
                    addPaperWrapper
                    label={
                        account?.type === 'brand'
                            ? 'Incentivize Retailers to sell your products!'
                            : 'Enhance Sales and Team Engagement with Sparks'
                    }
                    smallText={
                        account?.type === 'brand'
                            ? 'Create incentives to motivate employees, boost sales, and enhance product visibility. Monitor performance with detailed analytics.'
                            : 'Create Spark incentives to motivate employees with dynamic rewards and prizes, boost sales, and track performance.'
                    }
                    graphic={
                        <img src="/static/images/no-sparks-placeholder.jpg" alt="placeholder" />
                    }
                    actionButton={<CreateSparkButton onClick={onClickCreateSparkButton} />}
                />
            );
        } else {
            emptyStateComponent = (
                <EmptyStateDisplay
                    contentClassName="pb-4"
                    graphic={<NoSparksGraphic />}
                    label={
                        isInboxOrSent ? 'no sparks found' : `no ${sparkFilters.status} sparks found`
                    }
                    actionButton={
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '8px',
                                marginTop: '8px',
                                width: '335px',
                            }}
                        >
                            {userCanCreateSpark && (
                                <>
                                    <StartFromBlankButton className="w-full" />
                                    <FromTemplateButton className="w-full" />
                                </>
                            )}
                            <RequestSparkButton />
                        </Box>
                    }
                />
            );
        }

        const { appIsMobile } = useApp();
        const showSparkStats =
            !appIsMobile &&
            isSparkStatsEnabled &&
            accountIsReady &&
            account?.type === 'brand' &&
            sparkFilters?.status === 'complete';

        // Add local state for search input
        const [searchValue, setSearchValue] = useState(sparkFilters.search || '');

        return (
            <div className="sparks-view">
                <PageHeader
                    className="m-4 sm:m-0"
                    title={`${account?.name || ''}`.trim()}
                    heading="Sparks"
                >
                    <Toolbar collapseOnMobile>
                        <Toolbar.Group className="toolbar-group-end max-sm:[&_.toolbar-group-inner]:flex-col max-sm:[&_.toolbar-group-inner]:flex">
                            {isEmployeePreviewEnabled && (
                                <Button variant="flat" onClick={showPreviewModal} className="!mr-2">
                                    <span className="flex items-center gap-2 text-gray-800 hover:text-gray-900">
                                        <PhoneAndroid />
                                        Preview
                                    </span>
                                </Button>
                            )}
                            <RequestSparkButton sx={{ marginRight: '1rem' }} />
                            <CreateSparkButton onClick={onClickCreateSparkButton} />
                        </Toolbar.Group>
                    </Toolbar>
                </PageHeader>

                <Toolbar className="spark-view__status-toolbar" scrollOnMobile>
                    <SparkStatusButtons
                        value={sparkFilters.status}
                        onSelect={({ value, to }) => {
                            updateSparkFilters({ status: value });
                            history.push(`/${account?._id}${to}`);
                        }}
                        isBrand={account?.type === 'brand'}
                        inboxCount={pendingSparkCount}
                        requestCount={openRequestCount}
                        isAdmin={userIsAdmin}
                        connectEnabled={connectEnabled}
                    />
                </Toolbar>

                <AppContentWithSidebar
                    sidebar={
                        <SparkStatusButtons
                            value={sparkFilters.status}
                            onSelect={({ value, to }) => {
                                updateSparkFilters({ status: value });
                                history.push(`/${account?._id}${to}`);
                            }}
                            isBrand={account?.type === 'brand'}
                            inboxCount={pendingSparkCount}
                            requestCount={openRequestCount}
                            isAdmin={userIsAdmin}
                            connectEnabled={connectEnabled}
                        />
                    }
                    content={
                        <div className="flex flex-col">
                            {/* Only show spark stats for completed sparks for now. remove this once we have stats for all spark statuses */}
                            {showSparkStats && (
                                <Toolbar
                                    scrollOnMobile
                                    className="flex [&_.toolbar]:justify-start items-center w-full bg-bluewood-0 rounded-md px-4 pt-4 mb-[20px] [&_.toolbar-group]:p-0 [&_.toolbar-group]:m-0"
                                >
                                    <Toolbar.DateRangeSelector
                                        id="spark-stats-date-range"
                                        label="Date Range"
                                        alwaysShowDates
                                        dateStart={moment(sparkFilters.startDateRange)}
                                        dateEnd={moment(sparkFilters.endDateRange)}
                                        onApply={(dateStart, dateEnd) => {
                                            updateSparkFilters({
                                                startDateRange: dateStart
                                                    ? moment(dateStart).format('YYYY-MM-DD')
                                                    : undefined,
                                                endDateRange: dateEnd
                                                    ? moment(dateEnd).format('YYYY-MM-DD')
                                                    : undefined,
                                            });
                                        }}
                                        additionalRangeOptions={SPARK_STATS_DATE_RANGE_OPTIONS(
                                            moment(sparkDateRange?.startDate || account?.createdAt),
                                        )}
                                        infoText="Sparks started and completed within this date range will display below."
                                        className="[&_.MuiSvgIcon-root]:flex [&_.MuiSvgIcon-root]:items-center"
                                    />
                                </Toolbar>
                            )}

                            {showRequestsForSparks ? (
                                <Toolbar className="spark-view__filter-toolbar" scrollOnMobile>
                                    <Toolbar.Search
                                        name="sparks-search"
                                        defaultValue={queryParams.search || ''}
                                        inputRef={searchRef}
                                        onChange={debounce(({ target }) => {
                                            const { search, ...rest } = queryParams;
                                            history.push(
                                                `?${queryToString({
                                                    ...rest,
                                                    ...(target.value && { search: target.value }),
                                                })}`,
                                            );
                                        }, 250)}
                                    />
                                    <Toolbar.Dropdown
                                        label={null}
                                        titlePlaceholder="All Requests"
                                        value={queryParams.status || undefined}
                                        onSelect={(status) => {
                                            updateRequestForSparkStatus(status);
                                        }}
                                        clear={{
                                            active: !!queryParams.status,
                                            onClear: () => {
                                                updateRequestForSparkStatus(undefined);
                                            },
                                        }}
                                        options={requestStatusOptions}
                                    />
                                </Toolbar>
                            ) : (
                                <>
                                    {totalSparksCount > 0 && (
                                        <>
                                            {sparkBrandsAreReady &&
                                            sparkMarketsAreReady &&
                                            ((account?.type === 'brand' &&
                                                sparkRetailersAreReady) ||
                                                (account?.type === 'retailer' &&
                                                    sparkVendorsAreReady)) ? (
                                                <Toolbar
                                                    className="spark-view__filter-toolbar"
                                                    scrollOnMobile
                                                >
                                                    <Toolbar.Search
                                                        name="sparks-search"
                                                        defaultValue={searchValue}
                                                        inputRef={searchRef}
                                                        onChange={({ target }) => {
                                                            const value = target.value;
                                                            setSearchValue(value);
                                                            debouncedHandleSearchChange(value);
                                                        }}
                                                    />

                                                    {sparkFilters.status === 'inbox/sent' && (
                                                        <Toolbar.Dropdown
                                                            label={null}
                                                            titlePlaceholder="Spark Status"
                                                            options={SPARK_REQUEST_STATE_OPTIONS}
                                                            value={sparkFilters.requestState}
                                                            onSelect={(value) =>
                                                                updateSparkFilters({
                                                                    requestState: value,
                                                                })
                                                            }
                                                            clear={{
                                                                active: !!sparkFilters.requestState,
                                                                onClear: () =>
                                                                    updateSparkFilters({
                                                                        requestState: undefined,
                                                                    }),
                                                            }}
                                                        />
                                                    )}

                                                    <Toolbar.DropdownListSelector
                                                        label={
                                                            account?.type === 'brand'
                                                                ? 'Retailer Locations'
                                                                : 'Locations'
                                                        }
                                                        selected={
                                                            sparkFilters.location.length > 0
                                                                ? sparkFilters.location
                                                                : locationOptions.allLocations
                                                        }
                                                        options={locationOptions.optionTree}
                                                        onApply={(selectedLocations) => {
                                                            // If all locations are selected or no locations are selected,
                                                            // set to empty array to represent "all locations"
                                                            const isAllLocationsSelected =
                                                                selectedLocations.length ===
                                                                locationOptions.allLocations.length;

                                                            updateSparkFilters({
                                                                location: isAllLocationsSelected
                                                                    ? []
                                                                    : selectedLocations,
                                                            });
                                                        }}
                                                        clear={{
                                                            active:
                                                                sparkFilters.location.length > 0,
                                                            onClear: () => {
                                                                updateSparkFilters({
                                                                    location: [],
                                                                });
                                                            },
                                                        }}
                                                    />
                                                    {sparkBrandsAreReady &&
                                                        sparkBrands.length > 1 && (
                                                            <Toolbar.MultiSelectDropdown
                                                                label="Brands"
                                                                options={sparkBrands}
                                                                selected={sparkFilters.brandIds}
                                                                applyOnClose
                                                                onApply={(value) =>
                                                                    updateSparkFilters({
                                                                        brandIds: value,
                                                                    })
                                                                }
                                                            />
                                                        )}

                                                    {account?.type === 'retailer' &&
                                                        sparkVendorsAreReady &&
                                                        sparkVendors.length > 1 && (
                                                            <Toolbar.MultiSelectDropdown
                                                                label="Vendors"
                                                                options={sparkVendors}
                                                                selected={sparkFilters.vendorIds}
                                                                applyOnClose
                                                                onApply={(value) =>
                                                                    updateSparkFilters({
                                                                        vendorIds: value,
                                                                    })
                                                                }
                                                            />
                                                        )}

                                                    {account?.type === 'brand' &&
                                                        sparkMarketsAreReady &&
                                                        sparkMarkets.length > 1 && (
                                                            <Toolbar.MultiSelectDropdown
                                                                label="Markets"
                                                                options={marketOptions || []}
                                                                selected={sparkFilters.markets}
                                                                applyOnClose
                                                                onApply={(value) =>
                                                                    updateSparkFilters({
                                                                        markets: value,
                                                                    })
                                                                }
                                                            />
                                                        )}

                                                    <Toolbar.MultiSelectDropdown
                                                        label="Types"
                                                        options={SPARK_TYPE_OPTIONS}
                                                        selected={sparkFilters.type}
                                                        applyOnClose
                                                        onApply={(value) =>
                                                            updateSparkFilters({ type: value })
                                                        }
                                                    />

                                                    <Toolbar.MultiSelectDropdown
                                                        label="Schedules"
                                                        options={SPARK_SCHEDULE_OPTIONS}
                                                        selected={sparkFilters.schedule}
                                                        applyOnClose
                                                        onApply={(value) =>
                                                            updateSparkFilters({
                                                                schedule: value,
                                                            })
                                                        }
                                                    />

                                                    {(account?.zoltrainEnabled ||
                                                        account?.seedTalentEnabled) && (
                                                        <Toolbar.MultiSelectDropdown
                                                            label="Training"
                                                            dataTestId="training-dropdown"
                                                            options={SPARK_TRAINING_OPTIONS}
                                                            selected={sparkFilters.training}
                                                            applyOnClose
                                                            onApply={(value) =>
                                                                updateSparkFilters({
                                                                    training: value,
                                                                })
                                                            }
                                                        />
                                                    )}

                                                    <div className="column-widget-placeholder" />
                                                    <div className="export-to-csv-placeholder" />
                                                </Toolbar>
                                            ) : (
                                                <Skeleton height={43} className="mb-4" />
                                            )}
                                        </>
                                    )}
                                    {showSparkStats && (
                                        <SparkStats
                                            sparkStats={sparkStats}
                                            sparkStatsAreReady={!sparkStatsIsLoading}
                                        />
                                    )}
                                </>
                            )}
                            {showRequestsForSparks ? (
                                <RequestsForSparksTable
                                    account={account}
                                    enableQueryParams={!showSparkPickerModal}
                                />
                            ) : (
                                <SparkDashboardContent
                                    sparkFilters={sparkFilters}
                                    emptyStateComponent={emptyStateComponent}
                                    sparks={sparks}
                                    meta={meta}
                                    onSparkSelected={(spark, event) =>
                                        onSparkCardSelected(spark, event)
                                    }
                                    enableQueryParams={!showSparkPickerModal}
                                />
                            )}
                        </div>
                    }
                />

                <SparkPickerModal
                    isVisible={showSparkPickerModal}
                    onClose={handleCloseSparkPickerModal}
                    onNewSpark={(selected) => {
                        setShowSparkPickerModal(false);
                        openCreateModal(selected, state?.state?.originRoute || 'spark-dashboard');
                    }}
                />

                <UpgradeModal
                    isVisible={upgradeModalIsVisible}
                    onClose={() => setUpgradeModalIsVisible(false)}
                />

                {previewModal}
            </div>
        );
    },
);
export const SparksView = (sparksViewProps: SparksViewProps) => {
    return (
        <SparksProvider>
            <SparksViewWithManagementModal {...sparksViewProps} />
        </SparksProvider>
    );
};
export default withMobileView(SparksView, HomeView, ({ userIsAdmin }) => {
    return !userIsAdmin;
});
