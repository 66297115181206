import { useMemo } from 'react';

import AllUnitedStates from '@data/UnitedStates.json';

import Button from '@components/buttons/Button/Button';
import Form from '@components/form/Form';
import { CustomArrowRightIcon } from '@components/icons';
import AppHeaderMobile from '@components/layout/AppHeaderMobile';
import CalloutMessage from '@components/layout/CalloutMessage';
import toast from '@components/toast';

import InputGroup from '@app/componentLibrary/Input/InputGroup';
import Typography from '@app/componentLibrary/Typography';

import { W9FormData, W9InputErrors } from './W9Form.types';

interface W9InputFormProps {
    formData: W9FormData;
    formErrors: W9InputErrors;
    updateForm: (updates: Partial<W9FormData>) => void;
    updateFormErrors: (updates: Partial<W9InputErrors>) => void;
    handleNext: () => void;
}

export const W9InputForm = ({
    formData,
    formErrors,
    updateForm,
    updateFormErrors,
    handleNext,
}: W9InputFormProps) => {
    const stateOptions = useMemo(() => {
        const options = Object.entries(AllUnitedStates).map(([abbr, name]) => ({
            value: abbr,
            label: name,
        }));

        return [
            { value: '', label: 'Select' },
            ...options.sort((a, b) => a.label.localeCompare(b.label)),
        ];
    }, []);

    const validateForm = () => {
        const newErrors = {
            fullName: !formData.fullName?.trim(),
            streetAddress: !formData.streetAddress?.trim(),
            city: !formData.city?.trim(),
            state: !formData.state?.trim(),
            zip: !formData.zip?.trim() || !/^\d{4,5}(-\d{4})?$/.test(formData.zip),
            ssn: !formData.ssn?.trim() || !/^\d{3}-\d{2}-\d{4}$/.test(formData.ssn),
            email: !formData.email?.trim() || !/^\S+@\S+\.\S+$/.test(formData.email),
        };

        updateFormErrors(newErrors);

        // Check if there are any errors
        if (Object.values(newErrors).some((error) => error)) {
            toast.error('Please complete required fields');
            return;
        }

        handleNext();
    };

    const formatSSN = (value: string) => {
        const cleaned = value.replace(/\D+/g, ''); // Remove any non-numeric characters
        let formatted = cleaned;
        if (cleaned.length > 3 && cleaned.length <= 5) {
            formatted = `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
        } else if (cleaned.length > 5) {
            formatted = `${cleaned.slice(0, 3)}-${cleaned.slice(3, 5)}-${cleaned.slice(5, 9)}`;
        }
        return formatted;
    };

    return (
        <div>
            <AppHeaderMobile hideAppFooter back={{ to: '/user/wallet', component: 'Back' }} />
            <div className="bg-white max-w-[600px] mx-auto absolute top-[60px] left-0 right-0 bottom-0 overflow-y-auto p-4 pb-32">
                <Typography variant="2xl" className="mb-2 mt-2 font-bold">
                    W-9 Form
                </Typography>

                <CalloutMessage
                    className="mb-4"
                    customIcon="padlock"
                    variant="filled"
                    color="gray"
                    message={
                        <>
                            This form is encrypted for your security. For instructions and latest
                            information on how to fill out this W-9, please go to{' '}
                            <a
                                href="https://www.irs.gov/FormW9"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-cerulean-500 hover:text-cerulean-600 underline"
                            >
                                www.irs.gov/FormW9
                            </a>
                            .
                        </>
                    }
                />

                <div className="space-y-4">
                    <InputGroup label="Full Name*" error={formErrors.fullName}>
                        <Form.TextField
                            data-testid="fullName"
                            name="fullName"
                            value={formData.fullName ?? ''}
                            onChange={(e) => updateForm({ fullName: e.target.value })}
                            required
                            className="w-full"
                            error={formErrors.fullName}
                        />
                        <div
                            className={
                                formErrors.fullName
                                    ? 'text-[12px] text-[#EA6940] pt-1'
                                    : 'text-[12px] text-gray-800 pt-1'
                            }
                        >
                            Enter your name as shown on your tax return.
                        </div>
                    </InputGroup>

                    <InputGroup label="Email*" error={formErrors.email}>
                        <Form.TextField
                            data-testid="email"
                            name="email"
                            value={formData.email ?? ''}
                            onChange={(e) => updateForm({ email: e.target.value })}
                            required
                            className="w-full"
                            error={formErrors.email}
                        />
                    </InputGroup>

                    <InputGroup label="Street Address*" error={formErrors.streetAddress}>
                        <Form.TextField
                            data-testid="streetAddress"
                            name="streetAddress"
                            value={formData.streetAddress ?? ''}
                            onChange={(e) => updateForm({ streetAddress: e.target.value })}
                            required
                            className="w-full"
                            error={formErrors.streetAddress}
                        />
                    </InputGroup>

                    <InputGroup label="City*" error={formErrors.city}>
                        <Form.TextField
                            data-testid="city"
                            name="city"
                            value={formData.city ?? ''}
                            onChange={(e) => updateForm({ city: e.target.value })}
                            required
                            className="w-full"
                            error={formErrors.city}
                        />
                    </InputGroup>

                    <div className="grid grid-cols-2 gap-4">
                        <InputGroup label="State*" data-testid="state" error={formErrors.state}>
                            <Form.Select
                                name="state"
                                required
                                className="w-full"
                                options={stateOptions}
                                value={formData.state ?? ''}
                                onChange={(e) => updateForm({ state: e.target.value })}
                                error={formErrors.state}
                                color={formErrors.state ? 'red' : 'neutral'}
                            />
                        </InputGroup>

                        <InputGroup label="Zip*" error={formErrors.zip}>
                            <Form.TextField
                                data-testid="zip"
                                name="zip"
                                required
                                type="string"
                                maxLength={10}
                                value={formData.zip ?? ''}
                                onChange={(e) => updateForm({ zip: e.target.value })}
                                className="w-full"
                                error={formErrors.zip}
                            />
                        </InputGroup>
                    </div>

                    <InputGroup label="Social Security Number*" error={formErrors.ssn}>
                        <Form.TextField
                            data-testid="ssn"
                            type="string"
                            name="ssn"
                            required
                            maxLength={11} // // Increased to account for separators (XXX-XX-XXXX)
                            placeholder="XXX-XX-XXXX"
                            value={formData.ssn ?? ''}
                            onChange={(e) => updateForm({ ssn: formatSSN(e.target.value) })}
                            className="w-full"
                            error={formErrors.ssn}
                        />
                    </InputGroup>
                </div>
            </div>
            <div className="fixed bottom-0 left-0 right-0 p-6 bg-white border-t shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)]">
                <div className="max-w-[600px] mx-auto">
                    <Button
                        data-testid="nextButton"
                        type="button"
                        className="w-full [&>span>svg>path]:fill-cerulean-0 h-12"
                        variant="filled"
                        color="blue"
                        onClick={validateForm}
                        endIcon={<CustomArrowRightIcon />}
                    >
                        Next: Sign Form
                    </Button>
                </div>
            </div>
        </div>
    );
};
