import React, { ReactNode } from 'react';

import {
    Carousel,
    CarouselApi,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from '@app/componentLibrary/Carousel/carousel';
import { cn } from '@app/componentLibrary/utils';

type ImageCarouselProps = {
    items: ReactNode[];
    setCarouselApi?: (api: CarouselApi) => void;
    className?: string;
    showButtons?: boolean;
    watchDrag?: boolean;
};

export default ({
    items,
    setCarouselApi: setCarouselApiProp,
    className = '',
    showButtons = true,
    watchDrag = true,
}: ImageCarouselProps) => {
    const [carouselApi, setCarouselApi] = React.useState<CarouselApi>();
    const [current, setCurrent] = React.useState(0);

    React.useEffect(() => {
        if (!carouselApi) {
            return;
        }

        setCurrent(carouselApi.selectedScrollSnap() + 1);

        carouselApi.on('select', () => {
            setCurrent(carouselApi.selectedScrollSnap() + 1);
        });
    }, [carouselApi]);

    React.useEffect(() => {
        if (!carouselApi || !setCarouselApiProp) {
            return;
        }

        setCarouselApiProp(carouselApi);
    }, [carouselApi, setCarouselApiProp]);

    return (
        <div
            className={cn(
                'flex flex-col gap-4 h-full bg-cerulean-600 items-center max-sm:w-full max-sm:mt-[64px]',
                className,
            )}
        >
            <Carousel
                setCarouselApi={setCarouselApi}
                className="flex flex-col gap-8 h-full sm:w-9/12"
                watchDrag={watchDrag}
                // plugins={[Fade()]}
            >
                <div className="h-full [&_>div]:h-full [&_>div]:flex">
                    <CarouselContent
                        className="flex-grow"
                        style={{ width: `${Math.round(100 / items.length)}%` }}
                    >
                        {items.map((item, index) => (
                            <CarouselItem key={index} className="flex items-center justify-center">
                                {item}
                            </CarouselItem>
                        ))}
                    </CarouselContent>
                </div>
                {showButtons && (
                    <>
                        <CarouselPrevious className="max-sm:hidden" />
                        <CarouselNext className="max-sm:hidden" />
                    </>
                )}
                <div className="flex gap-1 w-full justify-center absolute bottom-12">
                    {Array.from({ length: items.length }).map((_, index) => (
                        // eslint-disable-next-line jsx-a11y/control-has-associated-label
                        <button
                            key={index}
                            type="button"
                            className={cn(
                                'w-2 h-2 rounded-full bg-cerulean-300 cursor-pointer border-none p-0 hover:bg-cerulean-400',
                                index + 1 === current && 'bg-white',
                            )}
                            onClick={() => carouselApi?.scrollTo(index)}
                        />
                    ))}
                </div>
            </Carousel>
        </div>
    );
};
