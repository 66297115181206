import { ComponentProps } from 'react';

import { Avatar as MuiAvatar, SxProps } from '@mui/material';

interface IAvatarProps {
    src?: string;
    firstName?: string;
    sx?: SxProps;
    avatarUrl?: string;
}

const Avatar = ({ src, firstName, sx, avatarUrl }: IAvatarProps) => {
    const props: ComponentProps<typeof MuiAvatar> = {
        className: 'avatar',
        src,
        sx,
    };

    if (src == null && firstName != null) {
        props.children = firstName.charAt(0).toUpperCase();
    }

    return (
        <>
            {avatarUrl ? (
                <Avatar firstName={firstName} src={avatarUrl} sx={{ width: 25, height: 25 }} />
            ) : (
                <MuiAvatar {...props} />
            )}
        </>
    );
};

export default Avatar;
