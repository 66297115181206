import React, { ReactElement, useMemo } from 'react';

import clsx from 'clsx';

import { TableProvider } from '@contexts/TableContext';

import Table from '@components/table/Table';
import { ITableExternalProvider, ITableInternalProvider } from '@components/table/Table/Table';

import { useTableContext } from '@hooks/TableHooks';

import { THeadCell } from '@app/types/TableTypes';

import TableCell from '../TableCell/TableCell';
import { TableRow } from '../TableRenderRow/TableRenderRow';
import ExpandableRow from './ExpandableRow';

export type ExpandableTableProps<T> = Omit<
    ITableInternalProvider<T> | ITableExternalProvider<T>,
    'children'
> & {
    collapse: (row: T & { key: string; selectionDisabled?: boolean }) => ReactElement;
    children?: React.ReactNode;
    emptyStateText?: string | ReactElement;
    page?: number;
    rowsPerPage?: number;
    totalCount?: number;
    onPageChange?: (newPage: number) => void;
    onRowsPerPageChange?: (newRowsPerPage: number) => void;
};

const ExpandableTableContent = <T extends {}>({
    headCells,
    expandableHeadCells,
    rows,
    filters,
    collapse,
    emptyStateText,
    className,
    useInfiniteScrollStyling,
    ...otherProps
}: Omit<ExpandableTableProps<T>, 'headCells'> & {
    headCells: THeadCell<T>[];
    expandableHeadCells: THeadCell<T>[];
    rows: any[];
}) => {
    const { tablePage, tableRowsPerPage, tableFilteredRows } = useTableContext<T>();

    const filteredRows = useMemo(() => {
        if (!filters || filters.length === 0) return tableFilteredRows;
        return filters.reduce((acc, filter) => filter(acc), tableFilteredRows);
    }, [tableFilteredRows, filters]);

    const paginatedRows = useMemo(() => {
        const start = tablePage * tableRowsPerPage;
        return filteredRows.slice(start, start + tableRowsPerPage);
    }, [filteredRows, tablePage, tableRowsPerPage]);

    const { useExternalProvider, ...tableProps } = otherProps;

    return (
        <Table
            className={clsx('expandable-table rounded', className)}
            useExternalProvider
            useInfiniteScrollStyling={useInfiniteScrollStyling}
            {...tableProps}
        >
            <Table.RenderHead />
            <Table.Body>
                {paginatedRows?.length > 0 ? (
                    <>
                        {paginatedRows.map((row) => (
                            <ExpandableRow
                                key={row.key}
                                tableHeadData={headCells}
                                row={row as T & { key: string; selectionDisabled?: boolean }}
                                collapse={collapse}
                            />
                        ))}
                    </>
                ) : (
                    <TableRow>
                        <TableCell
                            className="empty-state"
                            align="center"
                            colSpan={expandableHeadCells.length}
                        >
                            {emptyStateText}
                        </TableCell>
                    </TableRow>
                )}
            </Table.Body>
        </Table>
    );
};

const ExpandableTable = <T extends {}>({
    headCells = [],
    rows = [],
    isLoading,
    className,
    enableQueryParams,
    showPagination,
    disableFrontendFiltering,
    rowCountOverride,
    defaultOptions,
    filters,
    collapse,
    emptyStateText,
    useInfiniteScrollStyling,
    totalCount,
    ...otherProps
}: ExpandableTableProps<T>) => {
    const expandableHeadCells = useMemo(
        () => [{ id: 'expandButton', label: '', sortable: false }, ...headCells],
        [headCells],
    );

    return (
        <TableProvider
            rows={rows}
            headCells={expandableHeadCells}
            isLoading={isLoading}
            filters={filters}
            showPagination={showPagination}
            defaultOptions={{
                orderBy: defaultOptions?.orderBy ?? 'name',
                order: defaultOptions?.order ?? 'asc',
                rowsPerPage: defaultOptions?.rowsPerPage ?? 10,
            }}
            rowCountOverride={totalCount}
            enableQueryParams={enableQueryParams}
            disableFrontendFiltering={disableFrontendFiltering}
        >
            <ExpandableTableContent
                headCells={headCells}
                expandableHeadCells={expandableHeadCells}
                rows={rows}
                filters={filters}
                collapse={collapse}
                emptyStateText={emptyStateText}
                className={className}
                useInfiniteScrollStyling={useInfiniteScrollStyling}
                {...otherProps}
            />
        </TableProvider>
    );
};

export default ExpandableTable;
