import { useMemo } from 'react';

import { FormState } from '../context/types';

export const useUnsavedChanges = (initialState: FormState, currentState: FormState) => {
    return useMemo(() => {
        return (
            JSON.stringify(initialState.selectedMarkets) !==
                JSON.stringify(currentState.selectedMarkets) ||
            initialState.reward !== currentState.reward ||
            initialState.brandId !== currentState.brandId ||
            initialState.description !== currentState.description ||
            initialState.name !== currentState.name ||
            initialState.internalNotes !== currentState.internalNotes ||
            initialState.billingMode !== currentState.billingMode ||
            initialState.hasBudget !== currentState.hasBudget ||
            initialState.budget !== currentState.budget
        );
    }, [initialState, currentState]);
};
