import { useMemo, useState } from 'react';

import { DATE_DISPLAY_FORMAT } from '@constants/AppConstants';
import moment from 'moment';

import { TableProvider } from '@contexts/TableContext';

import { TrainingCompleteIcon, TrainingIcon } from '@components/icons';
import Avatar from '@components/layout/Avatar';
import Table from '@components/table/Table';
import Toolbar from '@components/toolbar/Toolbar';

import { useModal } from '@hooks/ModalHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';
import { useSearch } from '@hooks/UIHooks';

import { getSelectedParticipantsFromSparkSubGroups } from '@helpers/sparks';

import { SparkParticipant } from '@app/types/SparksTypes';
import { THeadCell } from '@app/types/TableTypes';

import './TrainingStatusTable.scss';

type ParticipantCourseCompletionStatus = 'complete' | 'incomplete';
interface SparkParticipantRow extends SparkParticipant {
    key: string;
    status: ParticipantCourseCompletionStatus;
    completionDate?: Date;
}

const headCells: THeadCell<SparkParticipantRow>[] = [
    {
        id: 'fullName',
        sortType: 'string',
        label: 'Employee',
        render: ({ fullName, avatarUrl, firstName }) => (
            <Table.Cell>
                <div className="flex flex-row items-center gap-2">
                    <Avatar
                        firstName={firstName}
                        avatarUrl={avatarUrl}
                        sx={{ width: 25, height: 25, fontSize: '14px' }}
                    />
                    {fullName}
                </div>
            </Table.Cell>
        ),
    },
    {
        id: 'status',
        sortType: 'string',
        label: 'Status',
        render: ({ completionDate }) => {
            return (
                <Table.Cell>
                    {completionDate ? (
                        <TrainingCompleteIcon fill="#38AC89" />
                    ) : (
                        <TrainingIcon fill="#CCD3DE" />
                    )}
                </Table.Cell>
            );
        },
    },
    {
        id: 'completionDate',
        sortType: 'date',
        label: 'Completion Date',
        render: ({ completionDate }) => (
            <Table.Cell>
                {completionDate ? moment(completionDate).format(DATE_DISPLAY_FORMAT) : '--'}
            </Table.Cell>
        ),
    },
];

const statusFilterOptions = [
    {
        value: '',
        label: 'All Statuses',
        onApply: () => true,
    },
    {
        value: 'complete',
        label: 'Complete',
        onApply: (status: string) => status === 'complete',
    },
    {
        value: 'incomplete',
        label: 'Incomplete',
        onApply: (status: string) => status === 'incomplete',
    },
];

export const TrainingStatusTable = ({ paginated = false }: { paginated?: boolean }) => {
    const {
        spark: { detailedSparkType, courseData },
        sparkPosData,
        sparkSubGroups,
    } = useSpark();
    const { modalContentRef } = useModal();
    const { applySearch, onChangeSearchFilter } = useSearch(['fullName']);

    const [filters, setFilters] = useState<{ status: ParticipantCourseCompletionStatus | '' }>({
        status: '',
    });

    const participants =
        detailedSparkType === 'leaderboardMulti'
            ? getSelectedParticipantsFromSparkSubGroups(sparkSubGroups)
            : sparkPosData.participants;

    const participantRows = useMemo<SparkParticipantRow[]>(() => {
        return participants.map((participant: SparkParticipant) => {
            const completionDate = participant.flexibleEmployeeId
                ? courseData?.completedUsersByCourseId?.[courseData?.courseId]?.[
                      participant.flexibleEmployeeId
                  ]
                : undefined;
            const status = completionDate ? 'complete' : 'incomplete';

            return {
                key: participant.flexibleEmployeeId,
                status,
                completionDate,
                ...participant,
            };
        });
    }, [participants]);

    const applyStatusFilter = (rows: SparkParticipantRow[]) => {
        if (!filters.status) {
            return rows;
        }

        return rows.filter(({ status }) => filters.status.toLowerCase() === status.toLowerCase());
    };

    return (
        <TableProvider
            showPagination={paginated}
            headCells={headCells}
            rows={participantRows}
            filters={[applySearch, applyStatusFilter]}
            defaultOptions={{ orderBy: 'fullName' }}
        >
            <Toolbar justifyContentStart>
                <Toolbar.Search
                    name="training-participant_search"
                    onChange={onChangeSearchFilter}
                />
                <Toolbar.Dropdown
                    label=""
                    value={filters.status}
                    options={statusFilterOptions}
                    onSelect={(value) =>
                        setFilters((prevValue: any) => {
                            return {
                                ...prevValue,
                                status: value,
                            };
                        })
                    }
                    clear={{
                        active: filters.status !== '',
                        onClear: () =>
                            setFilters((prevValue) => ({
                                ...prevValue,
                                status: '',
                            })),
                    }}
                />
            </Toolbar>
            <Table className="training-status-table" useExternalProvider variant="smooth">
                <Table.RenderHead />
                <Table.RenderBody
                    dynamicHeight={{
                        infiniteScroll: false,
                        containerRef: modalContentRef,
                    }}
                />
            </Table>
        </TableProvider>
    );
};
