import React, { ChangeEvent, FC, useRef, useState } from 'react';

import { useUploadUserAvatar } from '@core/users';

import { useApp } from '@hooks/AppHooks';

import Button from '../buttons/Button';
import IconButton from '../buttons/IconButton';
import { Delete, Edit, Photo } from '../icons';

interface UserAvatarProps {
    className?: string;
}

const UserAvatarUpload: FC<UserAvatarProps> = ({ className = '' }) => {
    const { user, refetchUserAppData } = useApp();
    const { uploadUserAvatar, isUploadingUserAvatar } = useUploadUserAvatar();
    const [previewImage, setPreviewImage] = useState<File | null>();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setPreviewImage(file);
        }
    };

    const handleSaveAvatar = () => {
        if (previewImage) {
            uploadUserAvatar(
                {
                    userId: user?._id || '',
                    file: previewImage,
                },
                {
                    onSuccess: () => {
                        refetchUserAppData();
                        setPreviewImage(null);
                    },
                },
            );
        }
    };

    return (
        <div className={`flex flex-col items-center gap-4 ${className}`}>
            <div className="relative">
                <div className="w-32 h-32 rounded-full bg-gray-200 overflow-hidden flex items-center justify-center relative">
                    {previewImage || user?.avatarUrl ? (
                        <img
                            src={previewImage ? URL.createObjectURL(previewImage) : user?.avatarUrl}
                            alt="Profile"
                            className="w-32 h-32 rounded-full object-cover"
                        />
                    ) : (
                        <Photo className="text-gray-400" sx={{ width: '80%', height: '80%' }} />
                    )}
                </div>
                {previewImage && (
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            backgroundColor: 'white',
                            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
                        }}
                        onClick={() => {
                            setPreviewImage(null);
                            if (fileInputRef.current) {
                                fileInputRef.current.value = '';
                            }
                        }}
                    >
                        <Delete />
                    </IconButton>
                )}
                {!previewImage && (
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            backgroundColor: 'white',
                            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
                        }}
                        onClick={() => fileInputRef.current?.click()}
                    >
                        <Edit />
                    </IconButton>
                )}
            </div>
            <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                accept="image/*"
                onChange={handleFileSelect}
            />
            <div className="flex gap-2 w-full items-center justify-center">
                {previewImage && (
                    <Button
                        variant="filled"
                        color="blue"
                        className="w-1/2"
                        onClick={handleSaveAvatar}
                        disabled={isUploadingUserAvatar}
                    >
                        Save Picture
                    </Button>
                )}
            </div>
        </div>
    );
};

export default UserAvatarUpload;
