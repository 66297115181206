import { FC, PropsWithChildren, RefObject, createContext, useRef, useState } from 'react';

import { Spark, SparkType } from '@sparkplug/lib';

import { useParams } from '@components/router';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

export type UpdateCallback = (updatedSparkValues: Partial<Spark>) => void;

export interface IModalContext {
    currentStage: number;
    modalContentRef: RefObject<HTMLElement>;
    stageValidationFn: (callback?: UpdateCallback) => boolean;
    updateValidationFn: (fn: (callback?: UpdateCallback) => boolean) => void;
    updateBackFn: (fn: (callback?: UpdateCallback) => boolean) => void;
    modalDirectionIsForward: boolean;
    modalDirectionIsBack: boolean;
    next: () => void;
    back: () => void;
}

export const ModalContext = createContext<IModalContext>({} as IModalContext);

const GenericSparkWizardStageNames: string[] = [
    'details',
    'participants',
    'objective',
    'prizes',
    'review',
];

interface ModalProviderProps {
    initialStage?: number;
    isSparkWizard?: boolean;
}
export const ModalProvider: FC<PropsWithChildren<ModalProviderProps>> = ({
    initialStage = 0,
    isSparkWizard,
    children,
}) => {
    const stageValidationFn = useRef<(callback?: UpdateCallback) => boolean>(() => true);
    const backFn = useRef<(callback?: UpdateCallback) => boolean>(() => true);
    const modalDirection = useRef<'forward' | 'back'>();
    const modalContentRef = useRef<HTMLElement>(null);
    const [stage, setStage] = useState<number>(initialStage);
    const { history } = useApp();
    const { isFullScreenWizard } = useSparkplugAccount();
    const { accountId, sparkType, stageName, sparkId } =
        useParams<{
            accountId: string;
            sparkId?: string;
            sparkType?: SparkType;
            stageName?: string;
        }>?.() || {}; // conditionally called just so we don't have to mock this in a bunch of tests where it's irrelevant

    if (
        isSparkWizard &&
        isFullScreenWizard &&
        stageName &&
        stage !== GenericSparkWizardStageNames.indexOf(stageName)
    ) {
        const stageIndex = GenericSparkWizardStageNames.indexOf(stageName);
        if (stageIndex > -1) {
            setStage(stageIndex);
        }
    }

    const updateValidationFn = (fn: (callback?: UpdateCallback) => boolean) => {
        stageValidationFn.current = fn;
    };
    const updateBackFn = (fn: (callback?: UpdateCallback) => boolean) => {
        backFn.current = fn;
    };
    const onBack = () => {
        modalDirection.current = 'back';
        if (backFn.current()) {
            updateBackFn(() => true);
        }
        requestAnimationFrame(() => {
            setStage((prevStage) => {
                const returnStage = Math.max(prevStage - 1, 0);
                if (
                    isSparkWizard &&
                    isFullScreenWizard &&
                    GenericSparkWizardStageNames[returnStage]
                ) {
                    // if there is a sparkId in the url we are editing an existing spark
                    if (sparkId) {
                        history.push(
                            `/${accountId}/sparks/${sparkId}/edit/${GenericSparkWizardStageNames[returnStage]}`,
                        );
                    } else {
                        history.push(
                            `/${accountId}/sparks/create/${sparkType}/${GenericSparkWizardStageNames[returnStage]}`,
                        );
                    }
                }
                return returnStage;
            });
        });
    };

    const onContinue = () => {
        if (stageValidationFn.current()) {
            modalDirection.current = 'forward';
            updateValidationFn(() => true);
            requestAnimationFrame(() => {
                setStage((prevStage) => {
                    const nextStage = prevStage + 1;
                    if (
                        isSparkWizard &&
                        isFullScreenWizard &&
                        GenericSparkWizardStageNames[nextStage]
                    ) {
                        if (sparkId) {
                            history.push(
                                `/${accountId}/sparks/${sparkId}/edit/${GenericSparkWizardStageNames[nextStage]}`,
                                history.location.state,
                            );
                        } else {
                            history.push(
                                `/${accountId}/sparks/create/${sparkType}/${GenericSparkWizardStageNames[nextStage]}`,
                                history.location.state,
                            );
                        }
                    }
                    return nextStage;
                });
            });
        }
    };

    return (
        <ModalContext.Provider
            value={{
                currentStage: stage,
                stageValidationFn: (callback?: UpdateCallback) =>
                    stageValidationFn.current(callback),
                updateValidationFn,
                updateBackFn,
                modalContentRef,
                modalDirectionIsForward: modalDirection.current === 'forward',
                modalDirectionIsBack: modalDirection.current === 'back',
                next: onContinue,
                back: onBack,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};
