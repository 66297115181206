import React, { useMemo, useState, useTransition } from 'react';
import toast from 'react-hot-toast';

import { debounce, uniqBy } from 'lodash';
import moment from 'moment-timezone';

import {
    GetAllLearningResourceQueryParams,
    ILearningResource,
    LearningResourceStatus,
    formatCurrency,
} from '@sparkplug/lib';

import { useCreateLearningResource } from '@features/courses/mutations/useCreateLearningResource';
import { useDeleteLearningResource } from '@features/courses/mutations/useDeleteLearningResource';
import { useGetAllAccountCourses } from '@features/courses/queries/useGetAllCourses';
import { useSparkBrandsQuery } from '@features/spark-brands';

import TruncatedMarketsList from '@components/TruncatedMarketsList';
import Chip from '@components/chips/Chip';
import Dropdown from '@components/dropdown/Dropdown';
import { MoreVert } from '@components/icons';
import PageLoading from '@components/layout/PageLoading';
import ConfirmModal from '@components/overlays/ConfirmModal';
import { queryToString, useQueryParams } from '@components/router';
import Table from '@components/table/Table';
import Toolbar from '@components/toolbar/Toolbar';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { AlertIcon } from '@app/componentLibrary/Icon/Alert';
import Typography from '@app/componentLibrary/Typography/Typography';
import { THeadCell } from '@app/types/TableTypes';

import CoursesEmptyState from '../CoursesEmptyState';

const CoursesList = () => {
    const [isPending, startTransition] = useTransition();
    const { account } = useSparkplugAccount();
    const { history } = useApp();
    const { sparkBrandsAreReady, sparkBrands } = useSparkBrandsQuery(account?._id || '');
    const [activeResourceToDelete, setActiveResourceToDelete] = useState<string>();
    const [searchTerm, setSearchTerm] = useState('');

    const basePath = `/${account?._id}`;
    const { mutateAsync: createLearningResource, isLoading: isCreating } =
        useCreateLearningResource();
    const handleCloneCourse = async (row: ILearningResource) => {
        try {
            const resourceData = {
                accountId: account?._id || '',
                markets: row.markets,
                reward: row.reward,
                name: `${row.name || ''} (copy)`.trim(),
                description: row.description,
                budget: row.budget,
                brandId: row.brandId || '',
            };
            const result = await createLearningResource(resourceData);
            const newResourceId = result?.learningResourceId; // Assuming the result contains the new resource ID
            if (newResourceId) {
                history.push(`${basePath}/courses/${newResourceId}/edit?cloneFrom=${row.surveyId}`);
            }
        } catch (error) {
            toast.error('Error creating learning resource');
        }
    };

    const queryParams: {
        p?: number;
        brandId?: string;
        rid?: string;
        status?: LearningResourceStatus;
        q?: string;
        orderBy?: string;
        order?: 'asc' | 'desc';
    } & GetAllLearningResourceQueryParams = useQueryParams();

    const { deleteLearningResource } = useDeleteLearningResource();

    const columns: THeadCell<ILearningResource>[] = [
        ...(account?.type === 'retailer'
            ? [
                  {
                      id: 'creator',
                      label: 'Creator',
                      render: (row: ILearningResource) => (
                          <Table.Cell>
                              <div className="flex items-center gap-2">
                                  {(row.accountPhoto || row.brandPhoto) && (
                                      <img
                                          src={row.accountPhoto || row.brandPhoto || ''}
                                          alt="brand"
                                          className="h-10 w-10 rounded"
                                      />
                                  )}
                                  {row.accountName}
                                  {row.status === 'published' || row.status === 'unpublished' ? (
                                      <Chip
                                          color={row.status === 'published' ? 'green' : 'neutral'}
                                          label={row.status}
                                      />
                                  ) : (
                                      <Chip color="yellow" label="Draft" />
                                  )}
                              </div>
                          </Table.Cell>
                      ),
                  },
              ]
            : []),
        ...(account?.type === 'brand'
            ? [
                  {
                      id: 'brandName',
                      label: 'Brand',
                      sortType: 'string',
                      render: (row: ILearningResource) => (
                          <Table.Cell>
                              <div className="flex items-center gap-2">
                                  {row.brandPhoto && (
                                      <img
                                          src={row.brandPhoto}
                                          alt="brand"
                                          className="h-10 w-10 rounded"
                                      />
                                  )}
                                  {row.brandName}
                              </div>
                          </Table.Cell>
                      ),
                  } as THeadCell<ILearningResource>,
              ]
            : []),
        {
            id: 'status',
            label: 'Status',
            sortType: 'string',
            render: (row) => (
                <Table.Cell>
                    {row.status === 'published' || row.status === 'unpublished' ? (
                        <Chip
                            color={row.status === 'published' ? 'green' : 'neutral'}
                            label={row.status}
                        />
                    ) : (
                        <Chip color="yellow" label="Draft" />
                    )}{' '}
                </Table.Cell>
            ),
        },
        { id: 'name', label: 'Course Name', sortType: 'string' },
        {
            id: 'reward',
            label: 'Reward',
            sortType: 'numeric',
            render: (row) => (
                <Table.Cell data-testid="reward-amount">
                    {formatCurrency(row.reward / 100, true)}
                </Table.Cell>
            ),
        },
        {
            id: 'updatedAt',
            label: 'Last Edited',
            sortType: 'date',
            render: (row) => (
                <Table.Cell title={moment(row.updatedAt).format('MM/DD/YYYY hh:mm a')}>
                    {moment(row.updatedAt).format('MMM D, YYYY')}
                </Table.Cell>
            ),
        },
        {
            id: 'audience',
            label: 'Audience',
            render: (row) => (
                <Table.Cell>
                    {account?.type === 'retailer' ? (
                        'All Locations'
                    ) : (
                        <TruncatedMarketsList
                            markets={row.markets}
                            limit={3}
                            totalMarkets={account?.metaData?.markets?.length}
                        />
                    )}
                </Table.Cell>
            ),
        },
        {
            id: 'employeeDepositsCount',
            label: 'Completed',
            sortType: 'numeric',
        },
        {
            id: 'totalPayout',
            label: 'Payout',
            sortType: 'numeric',
            info: 'Based on course completions, this is the current total payout.',
            render: (row) => {
                if (account?.type === 'retailer' && row.accountId !== account?._id) {
                    return <Table.Cell data-testid="total-payout">N/A</Table.Cell>;
                }
                const totalPayout = row.totalPayout ?? 0;
                const willExceedBudget = row.budget && totalPayout >= row.budget;
                return (
                    <Table.Cell className="text-right " data-testid="total-payout">
                        <div className="flex items-center gap-2">
                            {formatCurrency(totalPayout / 100, true)}
                            {willExceedBudget && <AlertIcon className=" text-buttercup-600" />}
                        </div>
                    </Table.Cell>
                );
            },
        },
        {
            id: 'actions',
            label: '',
            render: (row) => (
                <Table.Cell onClick={(e) => e.stopPropagation()}>
                    <Dropdown color="neutral" variant="flat" disabled={false}>
                        <Dropdown.IconButton
                            data-testid={`more-vert-button-${row.learningResourceId}`}
                        >
                            <MoreVert />
                        </Dropdown.IconButton>
                        <Dropdown.Menu>
                            <Dropdown.MenuItem
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    history.push(
                                        `/${account?._id}/courses/${row.learningResourceId}/edit`,
                                    );
                                }}
                            >
                                Edit Course
                            </Dropdown.MenuItem>

                            {row.status !== 'draft' && (
                                <Dropdown.MenuItem
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleCloneCourse(row);
                                    }}
                                >
                                    Clone Course
                                </Dropdown.MenuItem>
                            )}
                            {row.status === 'draft' && (
                                <Dropdown.MenuItem
                                    color="red"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setActiveResourceToDelete(row.learningResourceId);
                                    }}
                                >
                                    Delete Draft
                                </Dropdown.MenuItem>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Table.Cell>
            ),
        },
    ];

    const { courses, meta, isLoadingCourses } = useGetAllAccountCourses({
        accountId: account?._id,
        limit: String(queryParams.limit),
        offset: String(queryParams.p ?? 0),
        order: queryParams.order,
        sort: queryParams.orderBy,
        status: queryParams.status,
        brandId: queryParams.brandId,
        q: searchTerm,
    });
    const brandOptions = useMemo(() => {
        if (!sparkBrandsAreReady) return [];
        return uniqBy(
            sparkBrands?.map((brand) => ({
                value: brand._id ?? '',
                label: brand.name ?? '',
            })) ?? [],
            'value',
        ).sort((a, b) => a.label.localeCompare(b.label));
    }, [courses]);
    const noFiltersSelected = !queryParams.brandId && !queryParams.status;

    // Modify these conditions to be more precise
    const isInitialLoading = isLoadingCourses && !courses;
    const showEmptyState =
        !isLoadingCourses && courses?.length === 0 && noFiltersSelected && !searchTerm;

    // Use startTransition when changing filters
    const handleBrandSelect = (option: string) => {
        startTransition(() => {
            history.push({
                search: queryToString({
                    ...queryParams,
                    brandId: option,
                }),
            });
        });
    };

    const handleStatusSelect = (option: string) => {
        startTransition(() => {
            history.push({
                search: queryToString({
                    ...queryParams,
                    status: option,
                }),
            });
        });
    };

    const handleSearch = debounce((value: string) => {
        setSearchTerm(value);
    }, 300);

    if (isInitialLoading) {
        return <PageLoading label="Loading Courses..." />;
    }

    if (showEmptyState) {
        return <CoursesEmptyState accountType={account?.type || 'retailer'} />;
    }

    return (
        <div>
            <Toolbar justifyContentStart>
                <Toolbar.Search
                    name="q"
                    defaultValue={searchTerm}
                    onChange={(e) => handleSearch(e.target.value)}
                />
                {account?.type === 'brand' && brandOptions && (
                    <Toolbar.Dropdown
                        label={null}
                        value={queryParams.brandId}
                        titlePlaceholder="Brand"
                        options={brandOptions || []}
                        onSelect={handleBrandSelect}
                        clear={{
                            active: !!queryParams.brandId,
                            onClear: () => {
                                const { brandId, ...restQueryParams } = queryParams;
                                history.push({
                                    search: queryToString(restQueryParams),
                                });
                            },
                        }}
                    />
                )}
                <Toolbar.Dropdown
                    label={null}
                    value={queryParams.status}
                    titlePlaceholder="Course Status"
                    options={[
                        { label: 'Draft', value: 'draft' },
                        { label: 'Unpublished', value: 'unpublished' },
                        { label: 'Published', value: 'published' },
                    ]}
                    onSelect={handleStatusSelect}
                    clear={{
                        active: !!queryParams.status,
                        onClear: () => {
                            const { status, ...restQueryParams } = queryParams;
                            history.push({
                                search: queryToString(restQueryParams),
                            });
                        },
                    }}
                />
            </Toolbar>

            <Table
                enableQueryParams
                rowCountOverride={meta?.total}
                disableFrontendFiltering
                headCells={columns}
                isLoading={isLoadingCourses}
                rows={courses?.map((course) => ({
                    ...course,
                    key: course.learningResourceId,
                }))}
                defaultOptions={{
                    rowsPerPage: Number(queryParams.limit),
                    order: queryParams.order === 'asc' ? 'asc' : 'desc',
                    orderBy: queryParams.sort ?? 'createdAt',
                }}
                variant="raised"
                showPagination
                className="w-full rounded"
                key={`${queryParams.brandId}-${queryParams.status}`}
            >
                <Table.RenderHead />
                <Table.RenderBody
                    onRowClick={(row) => {
                        history.push(`/${account?._id}/courses/${row.learningResourceId}`);
                    }}
                    emptyStateText="No courses found with these filters."
                />
            </Table>
            <ConfirmModal
                isVisible={!!activeResourceToDelete}
                onClose={() => setActiveResourceToDelete(undefined)}
                onConfirm={() => {
                    if (activeResourceToDelete) {
                        deleteLearningResource(activeResourceToDelete);
                        setActiveResourceToDelete(undefined);
                    }
                }}
                confirmBtnColor="red"
                title="Delete Draft"
                message={
                    <Typography variant="base">
                        <span className="font-bold">
                            Are you sure you wish to delete this Course?
                        </span>{' '}
                        The edits you&apos;ve made will be deleted.
                    </Typography>
                }
            />
        </div>
    );
};

export default CoursesList;
