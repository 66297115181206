import { useCallback, useMemo } from 'react';

import axios from 'axios';

import {
    AccountMarket,
    GetMyPermissionsPathParams,
    GetMyPermissionsResponseBody,
    Policy,
    PublicPermission,
    getPoliciesByPermissions,
} from '@sparkplug/lib';

import { useAdvancedQuery } from '@hooks/QueryHooks';

import { IAccount } from '@app/types/AccountsTypes';
import { IAuthUser } from '@app/types/UsersTypes';

const UI_PERMISSIONS = [
    'createSpark',
    'updateSpark',
    'deleteSpark',
    'manageEvents',
    'manageSnaps',
    'manageCourses',
    'manageProductTags',
    'manageLinks',
    'manageUsers',
    'accessBillingPortal',
] as const;
export type UIPermission = (typeof UI_PERMISSIONS)[number];

const sharedValidationFn = ({
    user,
}: {
    user: IAuthUser;
    account: IAccount;
}): boolean | undefined => {
    if (user.role === 'super-admin') {
        return true;
    }

    return undefined;
};

const createSparkValidationFn: PermissionValidationFunction = ({ user, account, permissions }) => {
    return (
        sharedValidationFn({ user, account }) ??
        permissions.some(({ resource, action }) => resource === 'spark' && action === 'create')
    );
};

const updateSparkValidationFn: PermissionValidationFunction = ({ user, account, permissions }) => {
    return (
        sharedValidationFn({ user, account }) ??
        permissions.some(({ resource, action }) => resource === 'spark' && action === 'update')
    );
};

const deleteSparkValidationFn: PermissionValidationFunction = ({ user, account, permissions }) => {
    return (
        sharedValidationFn({ user, account }) ??
        permissions.some(({ resource, action }) => resource === 'spark' && action === 'destroy')
    );
};

const manageProductTagsValidationFn: PermissionValidationFunction = ({
    user,
    account,
    policies,
}) => {
    return sharedValidationFn({ user, account }) ?? policies.includes('manageProductTags');
};

const manageEventsValidationFn: PermissionValidationFunction = ({ user, account, policies }) => {
    return sharedValidationFn({ user, account }) ?? policies.includes('manageEvents');
};

const manageSnapsValidationFn: PermissionValidationFunction = ({ user, account, policies }) => {
    return sharedValidationFn({ user, account }) ?? policies.includes('manageSnaps');
};

const manageCoursesValidationFn: PermissionValidationFunction = ({ user, account, policies }) => {
    return sharedValidationFn({ user, account }) ?? policies.includes('manageCourses');
};

const manageLinksValidationFn: PermissionValidationFunction = ({ user, account, policies }) => {
    return sharedValidationFn({ user, account }) ?? policies.includes('manageLinks');
};

const manageUsersValidationFn: PermissionValidationFunction = ({ user, account, policies }) => {
    return sharedValidationFn({ user, account }) ?? policies.includes('manageUsers');
};

const accessBillingPortalValidationFn: PermissionValidationFunction = ({
    user,
    account,
    policies,
}) => {
    return (
        (sharedValidationFn({ user, account }) ?? policies.includes('accessBilling')) &&
        !account?.metaData?.billingDisabled
    );
};

type PermissionValidationFunction = (params: {
    user: IAuthUser;
    account: IAccount;
    permissions: PublicPermission[];
    policies: Policy[];
}) => boolean;
const UI_PERMISSIONS_MAP: Record<UIPermission, { validationFn: PermissionValidationFunction }> = {
    createSpark: { validationFn: createSparkValidationFn },
    updateSpark: { validationFn: updateSparkValidationFn },
    deleteSpark: { validationFn: deleteSparkValidationFn },
    manageEvents: { validationFn: manageEventsValidationFn },
    manageSnaps: { validationFn: manageSnapsValidationFn },
    manageCourses: { validationFn: manageCoursesValidationFn },
    manageProductTags: { validationFn: manageProductTagsValidationFn },
    manageLinks: { validationFn: manageLinksValidationFn },
    manageUsers: { validationFn: manageUsersValidationFn },
    accessBillingPortal: { validationFn: accessBillingPortalValidationFn },
};

const fetchMyPermissions = async ({ accountId }: GetMyPermissionsPathParams) => {
    return (
        await axios.get<GetMyPermissionsResponseBody>(`/api/v1/users/my-permissions/${accountId}`)
    ).data.data;
};

const useCurrentUserPermissionsQuery = ({
    userId,
    accountId,
    isEnabled = true,
}: {
    userId?: string;
    accountId?: string;
    isEnabled?: boolean;
}) => {
    const {
        isFetched: currentUserPermissionsAreReady,
        data: currentUserPermissions = { permissions: [] },
    } = useAdvancedQuery(
        ['currentUser', userId, 'permissions', accountId],
        () =>
            accountId ? fetchMyPermissions({ accountId }) : Promise.resolve({ permissions: [] }),
        { enabled: isEnabled && !!userId && !!accountId },
    );

    return {
        currentUserPermissionsAreReady,
        currentUserPermissions,
    };
};

export const useUserAccountPermissions = ({
    user,
    account,
}: {
    user: IAuthUser;
    account: IAccount;
}): {
    currentUserPermissionsAreReady: boolean;
    userCan: (permission: UIPermission) => boolean;
    userMarkets: AccountMarket[];
} => {
    const { currentUserPermissionsAreReady, currentUserPermissions } =
        useCurrentUserPermissionsQuery({
            userId: user._id,
            accountId: account._id,
        });

    const userCan = useCallback(
        (permission: UIPermission): boolean => {
            const permissions = currentUserPermissions.permissions ?? [];

            return UI_PERMISSIONS_MAP[permission].validationFn({
                user,
                account,
                permissions,
                policies: getPoliciesByPermissions(permissions),
            });
        },
        [user, account, currentUserPermissions],
    );

    const userMarkets = useMemo(() => {
        return currentUserPermissions.markets ?? [];
    }, [currentUserPermissions.markets ?? []]);

    return { currentUserPermissionsAreReady, userCan, userMarkets };
};
