import moment from 'moment-timezone';

import { SparkStatsDateRangeTypes } from '@sparkplug/lib';

import { RangeOption } from '@components/toolbar/ToolbarDateRangeSelector';

export const SPARK_STATS_DATE_RANGE_OPTIONS: (sparkStartDate: moment.Moment) => RangeOption[] = (
    sparkStartDate,
) => [
    {
        value: SparkStatsDateRangeTypes.thisYear,
        label: 'This Year',
        onSelect: (onApply) => {
            const startDate = moment().startOf('year');
            const endDate = moment().endOf('year');
            onApply(startDate, endDate);
        },
        isCurrentSelection: (dateStart, dateEnd) => {
            const startDate = moment().startOf('year');
            const endDate = moment().endOf('year');
            return startDate.isSame(dateStart, 'date') && endDate.isSame(dateEnd, 'date');
        },
    },
    {
        value: SparkStatsDateRangeTypes.lastYear,
        label: 'Last Year',
        onSelect: (onApply) => {
            const startDate = moment().subtract(1, 'year').startOf('year');
            const endDate = moment().subtract(1, 'year').endOf('year');
            onApply(startDate, endDate);
        },
        isCurrentSelection: (dateStart, dateEnd) => {
            const startDate = moment().subtract(1, 'year').startOf('year');
            const endDate = moment().subtract(1, 'year').endOf('year');
            return startDate.isSame(dateStart, 'date') && endDate.isSame(dateEnd, 'date');
        },
    },
    {
        value: SparkStatsDateRangeTypes.allTime,
        label: 'All Time',
        onSelect: (onApply) => {
            onApply(moment(sparkStartDate), moment());
        },
        isCurrentSelection: (startDate, endDate) => {
            return (
                moment(sparkStartDate).isSame(startDate, 'date') && moment().isSame(endDate, 'date')
            );
        },
    },
];
