import { useEffect, useMemo, useState } from 'react';

import { DateTime } from 'luxon';

import { AccountMarketLabel } from '@sparkplug/lib';

import { EventDateTimeOnChange } from '@features/events/types';

import Checkbox from '@app/componentLibrary/Input/Checkbox';
import InputGroup from '@app/componentLibrary/Input/InputGroup';

import { EventDatePicker } from './components/EventDatePicker';
import { EventTimePicker } from './components/EventTimePicker';
import {
    checkIsAllDayEvent,
    formatTimezone,
    mergeDateAndTime,
    pullTimeFromDateTime,
} from './dateTimeUtils';

interface EventFormDateTimeSectionProps {
    error: boolean;
    timeZone: string;
    state: AccountMarketLabel;
    startDateTime: string;
    endDateTime: string;
    onDateTimeChange: EventDateTimeOnChange;
}
export const EventFormDateTimeSection = ({
    error,
    timeZone,
    state,
    startDateTime,
    endDateTime,
    onDateTimeChange,
}: EventFormDateTimeSectionProps) => {
    const [startTime, setStartTime] = useState<string>(pullTimeFromDateTime(startDateTime));
    const [endTime, setEndTime] = useState<string>(pullTimeFromDateTime(endDateTime));
    const [isAllDayEvent, setIsAllDayEvent] = useState<boolean>(
        checkIsAllDayEvent({ startDateTime, endDateTime }),
    );

    const timeZoneLabel = useMemo(() => formatTimezone(timeZone, state), [timeZone, state]);

    const handleDateChange: EventDateTimeOnChange = (value) => {
        onDateTimeChange({
            startDateTime: value.startDateTime,
            endDateTime: value.endDateTime,
        });
    };

    const handleStartTimeChange = (timeString: string = '12:00:00') => {
        const mergedStartDateTime = mergeDateAndTime({ dateString: startDateTime, timeString });
        if (mergedStartDateTime) {
            onDateTimeChange({
                startDateTime: mergedStartDateTime,
                endDateTime,
            });
        }
    };

    const handleEndTimeChange = (timeString: string = '13:00:00') => {
        const mergedEndDateTime = mergeDateAndTime({ dateString: endDateTime, timeString });
        if (mergedEndDateTime) {
            onDateTimeChange({
                startDateTime,
                endDateTime: mergedEndDateTime,
            });
        }
    };

    const handleAllDayToggle = (isAllDay: boolean) => {
        if (!startDateTime || !DateTime.fromISO(startDateTime).isValid) {
            return;
        }

        // When toggling from all-day, set the start time to 12pm and the end time to 1pm
        if (!isAllDay) {
            const selectedDayTime = startDateTime;
            // 12 pm in the local time zone
            const defaultStartDateTime = DateTime.fromISO(selectedDayTime)
                .set({ hour: 12, minute: 0, second: 0 })
                .toISO()!;
            // 1 pm in the local time zone
            const defaultEndDateTime = DateTime.fromISO(selectedDayTime)
                .set({ hour: 13, minute: 0, second: 0 })
                .toISO()!;
            onDateTimeChange({
                startDateTime: defaultStartDateTime,
                endDateTime: defaultEndDateTime,
            });
        }

        if (isAllDay) {
            const startDateTimeValue = startDateTime;
            // 1 day after the startDateTime in the local time zone
            const defaultEndDateTimeValue = DateTime.fromISO(startDateTimeValue).toISO()!;
            onDateTimeChange({
                startDateTime: startDateTimeValue,
                endDateTime: defaultEndDateTimeValue,
            });
        }

        setIsAllDayEvent(isAllDay);
    };

    // If the start/end dateTime props change, update the startTime/endTime state
    useEffect(() => {
        setStartTime(pullTimeFromDateTime(startDateTime));
        setEndTime(pullTimeFromDateTime(endDateTime));
    }, [startDateTime, endDateTime]);

    const showTimePicker = !isAllDayEvent && startDateTime;

    return (
        <>
            <InputGroup label="Event Date*" error={error}>
                <InputGroup>
                    <EventDatePicker
                        startDateTime={startDateTime}
                        endDateTime={endDateTime}
                        variant={isAllDayEvent ? 'multi-day' : 'single-day'}
                        onChange={handleDateChange}
                    />
                    <Checkbox
                        id="all-day-event-checkbox"
                        name="all-day-event-checkbox"
                        checked={isAllDayEvent}
                        label="All-day event"
                        onChange={(e) => handleAllDayToggle(e.target.checked)}
                    />
                </InputGroup>
            </InputGroup>
            {showTimePicker && (
                <InputGroup>
                    <EventTimePicker
                        startTime={startTime}
                        onStartTimeChange={handleStartTimeChange}
                        endTime={endTime}
                        onEndTimeChange={handleEndTimeChange}
                        timeZoneLabel={timeZoneLabel}
                    />
                </InputGroup>
            )}
        </>
    );
};
