import { FC } from 'react';

import Button from '@components/buttons/Button/Button';
import CalloutMessage from '@components/layout/CalloutMessage';

interface W9FormWarningProps {
    fillOutW9FormClicked: () => void;
}

export const W9FormWarning: FC<W9FormWarningProps> = ({ fillOutW9FormClicked }) => {
    return (
        <div className="shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)] p-6">
            <CalloutMessage
                className="text-start mb-4"
                customIcon="info"
                variant="filled"
                color="yellow"
                title="We need some additional info from you"
                message={
                    <>
                        SparkPlug handles all tax reporting obligations for cash earnings on the
                        platform. If required, we will file a 1099 with the IRS for you.{' '}
                        <strong>
                            Before cashing out, please complete the electronic W-9 form.
                        </strong>
                    </>
                }
            />

            <Button
                variant="filled"
                color="blue"
                className="w-full mt-4 h-12"
                onClick={fillOutW9FormClicked}
            >
                Fill out W-9 Form
            </Button>
        </div>
    );
};
