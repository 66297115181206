import { DateTime } from 'luxon';

/**
 * 12pm in HH:mm:ss format
 */
export const DEFAULT_EVENT_TIME = '12:00:00';

/**
 * If the start and endTime are greater than 23 hours apart, it is an all-day event
 */
export const checkIsAllDayEvent = ({
    startDateTime,
    endDateTime,
}: {
    startDateTime: string;
    endDateTime: string;
}): boolean => {
    if (!startDateTime || !endDateTime) {
        return false;
    }
    const start = DateTime.fromISO(startDateTime);
    const end = DateTime.fromISO(endDateTime);
    return end.diff(start, 'hours').hours >= 23;
};

/**
 * Merges the date and time into a single ISO date string
 */
export const mergeDateAndTime = ({
    dateString,
    timeString,
}: {
    dateString: string;
    timeString: string;
}): string | undefined => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return (
        DateTime.fromISO(dateString || '')
            .set({ hour: hours, minute: minutes, second: seconds })
            .toISO() || undefined
    );
};

/**
 * Pulls the time from an ISO date string in HH:mm:ss format
 * @returns HH:mm:ss from dateTime or '00:00:00' if dateTime is undefined or not a date
 * dateTime should always be a valid ISO date string.
 */
export const pullTimeFromDateTime = (dateTime?: string) => {
    if (!dateTime) {
        return DEFAULT_EVENT_TIME;
    }

    const isoDateTime = DateTime.fromISO(dateTime);
    if (!DateTime.fromISO(dateTime).isValid) {
        return DEFAULT_EVENT_TIME;
    }

    return isoDateTime.toFormat('HH:mm:ss');
};

export const formatTimezone = (timezone: string, state: string): string => {
    const now = DateTime.now().setZone(timezone);
    const offset = now.toFormat('ZZ'); // Gets offset like '+04:00' or '-04:00'
    const abbr = now.toFormat('ZZZZ'); // Gets abbreviation like 'MST' instead of full zone name

    let timeZoneLabel = `(GMT${offset} ${abbr})`;
    if (state) {
        timeZoneLabel = `${state} ${timeZoneLabel}`;
    }
    return timeZoneLabel;
};
