import { useMemo } from 'react';

import { omit } from 'lodash';

import { queryToString, useLocation, useQueryParams } from '@components/router';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { EVENT_NAVIGATION_QUERY_PARAMS, EventSearchParams } from '../types';

export const useEventNavigation = () => {
    const { history } = useApp();
    const { account } = useSparkplugAccount();
    const queryParams = useQueryParams<EventSearchParams>();

    const { pathname } = useLocation();

    const currentView: 'inbox' | 'sent' | 'scheduled' = useMemo(() => {
        if (pathname.includes('inbox')) {
            return 'inbox';
        }
        if (pathname.includes('sent')) {
            return 'sent';
        }
        return 'scheduled';
    }, [pathname]);

    // Query params without navigation query params
    const nonNavQueryParams = useMemo(() => {
        const navSearchParams = Object.values(EVENT_NAVIGATION_QUERY_PARAMS);
        return queryToString(omit(queryParams, navSearchParams));
    }, [queryParams]);

    const backUrl = useMemo(() => {
        const baseUrl = history.location.state?.prevUrl || `/${account?._id}/events`;
        const search = history.location.state?.prevSearch || nonNavQueryParams;
        return search ? `${baseUrl}?${search}` : baseUrl;
    }, [
        history.location.state?.prevUrl,
        history.location.state?.prevSearch,
        account?._id,
        nonNavQueryParams,
    ]);

    return useMemo(
        () => ({
            state: {
                currentView,
                backUrl,
                editId: queryParams.editId,
                clonedFromId: queryParams.clonedFromId,
                create: queryParams.create,
                detailId: queryParams.detailId,
                deleteId: queryParams.deleteId,
                declineId: queryParams.declineId,
                quickApprovalCode: queryParams.quickApprovalCode,
                fromDetailsModal: queryParams.fromDetailsModal,
            },
            actions: {
                // Event view level navigation
                goToInboxView: () => {
                    history.push({
                        pathname: `/${account?._id}/events/inbox`,
                        search: nonNavQueryParams,
                    });
                },
                goToSentView: () => {
                    history.push({
                        pathname: `/${account?._id}/events/sent`,
                        search: nonNavQueryParams,
                    });
                },
                goToScheduledView: () => {
                    history.push({
                        pathname: `/${account?._id}/events`,
                        search: nonNavQueryParams,
                    });
                },
                goToEventDetailsView: (eventId: string, openInNewTab?: boolean) => {
                    const url = `/${account?._id}/events/${eventId}`;

                    if (openInNewTab) {
                        window.open(url, '_blank');
                    } else {
                        history.push({
                            pathname: url,
                            search: '',
                            state: {
                                prevUrl: pathname,
                                prevSearch: nonNavQueryParams,
                            },
                        });
                    }
                },
                // Event drawer/modal level navigation
                goToCreateEventDrawer: () => {
                    history.push({ search: queryToString({ ...queryParams, create: 'true' }) });
                },
                goToEditEventDrawer: (eventId: string) => {
                    history.push({
                        search: queryToString({
                            ...queryParams,
                            editId: eventId,
                        }),
                    });
                },
                goToCloneEventDrawer: (eventId: string) => {
                    history.push({
                        search: queryToString({
                            ...queryParams,
                            clonedFromId: eventId,
                        }),
                    });
                },
                goToDeleteEventModal: (eventId: string) => {
                    history.push({
                        search: queryToString({
                            ...queryParams,
                            deleteId: eventId,
                        }),
                    });
                },
                goToDeclineEventModal: (eventId: string, fromDetailsModal: boolean = false) => {
                    const { detailId, ...updatedQueryParams } = queryParams;
                    history.push({
                        search: queryToString({
                            ...updatedQueryParams,
                            declineId: eventId,
                            fromDetailsModal,
                        }),
                    });
                },
                goToEventDetailModal: (eventId: string) => {
                    history.push({
                        search: queryToString({
                            ...queryParams,
                            detailId: eventId,
                        }),
                    });
                },
                resetNavParameters: () => {
                    history.push({
                        search: nonNavQueryParams,
                    });
                },
                backToEventDetailModal: (eventId: string) => {
                    const { declineId, fromDetailsModal, ...updatedQueryParams } = queryParams;
                    history.push({
                        search: queryToString({
                            ...updatedQueryParams,
                            detailId: eventId,
                        }),
                    });
                },
            },
        }),
        [queryParams, history, currentView, account?._id, pathname],
    );
};
