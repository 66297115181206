import { useState } from 'react';
import { toast } from 'react-hot-toast';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import Spinner from '@components/layout/Spinner';

import { useApp } from '@hooks/AppHooks';
import { useEmployeeLocationsQuery } from '@hooks/QueryHooks/EmployeeDataQueries';

import { InputGroup } from '@app/componentLibrary/Input/InputGroup/InputGroup';
import Typography from '@app/componentLibrary/Typography';

import { useOnboardUserMutation } from '../mutations/OnboadingMutations';

const OnboardingDetails = () => {
    const { employeeLocations } = useEmployeeLocationsQuery();
    const { user, refetchUserAppData, history } = useApp();
    const { onboardUserAsync } = useOnboardUserMutation();
    const [isOnboarding, setIsOnboarding] = useState(false);

    const [formData, setFormData] = useState({
        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
        retailerLocation:
            employeeLocations.find((label) => label.isLatest)?._id ??
            employeeLocations[0]?._id ??
            '',
        agreeToTnc: false,
    });

    const [errors, setErrors] = useState({
        firstName: false,
        lastName: false,
        retailerLocation: false,
        agreeToTnc: false,
    });

    const validateForm = async () => {
        try {
            setIsOnboarding(true);
            const newErrors = {
                firstName: !formData.firstName?.trim(),
                lastName: !formData.lastName?.trim(),
                retailerLocation:
                    !formData.retailerLocation?.trim() && employeeLocations.length > 0,
                agreeToTnc: !formData.agreeToTnc,
            };

            setErrors(newErrors);

            if (Object.values(newErrors).some((error) => error)) {
                toast.error('Please complete required fields');
                return;
            }

            if (!formData.agreeToTnc) {
                toast.error('Please agree to the terms and conditions');
                return;
            }

            await onboardUserAsync({
                userId: user?._id!,
                onboardingDetails: {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    ...(formData.retailerLocation && {
                        preferredLocationId: formData.retailerLocation,
                    }),
                },
            });

            await refetchUserAppData();

            history.push('/user/sparks');
        } finally {
            setIsOnboarding(false);
        }
    };

    return (
        <div className="flex flex-col w-full relative bg-white p-4 max-w-[650px] mx-auto">
            <div className="flex flex-col gap-6 absolute top-0 left-0 right-0 md:pt-16">
                <Typography variant="2xl" className="font-bold">
                    Last step - please confirm your profile info.
                </Typography>

                <Form>
                    <div className="space-y-4">
                        <InputGroup label="First Name*" error={errors.firstName}>
                            <Form.TextField
                                data-testid="firstName"
                                name="firstName"
                                value={formData.firstName ?? ''}
                                onChange={(e) =>
                                    setFormData((data) => ({ ...data, firstName: e.target.value }))
                                }
                                required
                                className="w-full"
                            />
                        </InputGroup>

                        <InputGroup label="Last Name*" error={errors.lastName}>
                            <Form.TextField
                                data-testid="lastName"
                                name="lastName"
                                value={formData.lastName ?? ''}
                                onChange={(e) =>
                                    setFormData((data) => ({ ...data, lastName: e.target.value }))
                                }
                                required
                                className="w-full"
                            />
                        </InputGroup>

                        <InputGroup label="Phone">
                            <Form.PhoneField
                                data-testid="phoneNumber"
                                disabled
                                name="phoneNumber"
                                defaultValue={user?.phoneNumber ?? ''}
                                required
                                className="w-full"
                            />
                        </InputGroup>

                        {employeeLocations.length > 0 ? (
                            <InputGroup
                                label="Retailer Location*"
                                data-testid="retailerLocation"
                                error={errors.retailerLocation}
                            >
                                <Form.Select
                                    name="retailerLocation"
                                    required
                                    className="w-full"
                                    options={employeeLocations.map((label) => ({
                                        label: label.name,
                                        value: label._id,
                                    }))}
                                    value={formData.retailerLocation ?? ''}
                                    onChange={(e) =>
                                        setFormData((data) => ({
                                            ...data,
                                            retailerLocation: e.target.value,
                                        }))
                                    }
                                />
                                <span className="text-sm text-gray-800">
                                    Please select the location where you work most often.
                                </span>
                            </InputGroup>
                        ) : null}

                        <div className="flex gap-2 pt-2">
                            <Form.Checkbox
                                name="agreeToTnc"
                                variant="smooth"
                                value={formData.agreeToTnc}
                                onChange={(e) =>
                                    setFormData((data) => ({
                                        ...data,
                                        agreeToTnc: e.target.checked,
                                    }))
                                }
                                className="[&.checkbox>span]:p-0"
                            />
                            <Typography className="text-gray-900 font-bold">
                                I have read and agree to SparkPlug’s{' '}
                                <a
                                    href="https://sparkplug.app/privacy-policy"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    Terms of Service
                                </a>{' '}
                                and{' '}
                                <a
                                    href="https://sparkplug.app/privacy-policy"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    Privacy Policy
                                </a>
                                .
                            </Typography>
                        </div>
                    </div>
                </Form>
            </div>

            <div className="flex flex-col gap-4 py-4 fixed bottom-0 left-0 ring-0 w-full shadow-inner p-4">
                <Button
                    className="w-full"
                    variant="filled"
                    color="blue"
                    onClick={validateForm}
                    disabled={!formData.agreeToTnc}
                >
                    {isOnboarding ? (
                        <Spinner className="w-4 h-4 [&>svg]:text-white" />
                    ) : (
                        'Launch SparkPlug'
                    )}
                </Button>
            </div>
        </div>
    );
};

export default OnboardingDetails;
