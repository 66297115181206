import { z } from 'zod';
import {
  AccountMarket,
  AccountSparkBrand,
  AccountType,
  Industry,
  PagedApiResponseBody,
  PosBrand,
  PosLocation,
} from '..';

export const BrandLinkStates = ['none', 'pending', 'disabled', 'rejected', 'enabled'] as const;
/**
 * @deprecated  We'll be using the AccountLinkStatus moving forward,
 * as part of the Brand Links v2 initiative
 */
export type BrandLinkState = typeof BrandLinkStates[number];

export const AccountLinkStatuses = BrandLinkStates;
export type AccountLinkStatus = typeof AccountLinkStatuses[number];

export interface BrandLink {
  _id: string;
  /**
   * The ID of the VENDOR account that this brand link is connected to.
   *
   * @deprecated  We'll be using the brandId moving forward, as part of
   * the Brand Links v2 initiative.
   */
  vendorAccountId: string;
  /**
   * The IDs of the full set of POSBrands that are linked the specified Brand.
   */
  posBrandIds: string[];
  /**
   * The IDs of the POSProducts that, while otherwise present through the POSBrand,
   * are to be explicitly excluded from the Brand through this link.
   */
  excludedProductIds?: string[];
  predictedExcludedProductIds?: string[];
  /**
   * The ID of the retailer account for which this brand link pertains.
   */
  retailerAccountId: string;
  /**
   * The state of a given brand link.
   * @see BrandLinkState for more information.
   * @deprecated We'll be using the AccountLink.status moving forward,
   * as part of the Brand Links v2 initiative
   */
  state: BrandLinkState;
  createdAt?: Date;
  /**
   * The date at which a reminder email was sent to the retailer.
   */
  reminderSentAt?: Date;

  /**
   * The last transaction date for the products associated with this brand link.
   */
  lastTransactionDate?: Date | null;
}

/**
 * @deprecated Removed in favor for `PublicBrandLinkV2` in Brand Links v2
 */
export interface PublicBrandLink extends BrandLink {
  posBrands: PosBrand[];
  label: string;
  name: string;
  accountType: AccountType;
  locations?: any[];
  markets: AccountMarket[];
  mappedNamesStr: string;
}

export interface BrandLinkCounts {
  totalProductCount?: number;
}
export interface BrandLinkCountsMap {
  [brandLinkId: string]: BrandLinkCounts;
}

export interface PublicBrandLinkV2
  extends Omit<BrandLink, 'vendorAccountId' | 'retailerAccountId' | '_id' | 'state' | 'createdAt'> {
  _id?: string;
  name: string;
  photo: string;
  brandId: string;
  posBrands: PosBrand[];
}

export interface AccountLink extends Pick<BrandLink, 'reminderSentAt' | 'createdAt'> {
  accountId: string;
  accountType: AccountType;
  accountName: string;
  markets: AccountMarket[];
  sharedMarkets: string[];
  photo: string;
  brandLinks: PublicBrandLinkV2[];
  locations?: PosLocation[];
  disabledCurrentLocations?: PosLocation[];
  status: AccountLinkStatus;
  shareSalesData?: boolean;
  shareInventoryData?: boolean;
}

export interface ProspectiveBrandLink {
  connectDisabled: boolean;
  groupId: string;
  industries: Industry[];
  locationCount: number;
  name: string;
  photo: string;
  sharedMarkets: AccountMarket[];
  type: AccountType;
  brands: AccountSparkBrand[];
}

export interface ListBrandLinksQueryParams {
  brand_group_id?: string;
  limit?: number;
  offset?: number;
  retailer_group_id?: string;
}

export type ListBrandLinksResponseBody = PagedApiResponseBody<BrandLink>;

export interface ListAccountBrandLinksRequestPathParams {
  groupId: string;
}
export type ListAccountBrandLinksResponseBody = PublicBrandLink[];
export type ListAccountLinksResponseBody = AccountLink[];

export const listProspectiveBrandLinksSchema = z.object({
  groupId: z.string().min(1),
});
export type ListProspectiveBrandLinksRequestBody = z.infer<typeof listProspectiveBrandLinksSchema>;

export type ListProspectiveBrandLinksResponseBody = ProspectiveBrandLink[];

export interface GetBrandLinkPathParams {
  brandLinkId: string;
}

export interface GetBrandLinkResponseBody {
  data: BrandLink;
}

export type UpdateBrandLinkPathParams = GetBrandLinkPathParams;

export const updateBrandLinkV2Schema = z.object({
  posBrandIds: z.array(z.string()).optional(),
});
export type UpdateBrandLinkV2RequestBody = z.infer<typeof updateBrandLinkV2Schema>;

export const brandLinksCountDataRequestSchema = z.object({
  brandLinkIds: z.array(z.string()),
});
export type BrandLinksCountDataRequest = z.infer<typeof brandLinksCountDataRequestSchema>;
export interface BrandLinksCountDataResponse {
  data: Record<string, BrandLinkCounts>;
}

export const updateBrandLinkSchema = z.object({
  state: z.enum(BrandLinkStates),
  brandGroupId: z.string().optional(),
  posBrandIds: z.array(z.string()).optional(),
  retailerGroupId: z.string().optional(),
});
export type UpdateBrandLinkRequestBody = z.infer<typeof updateBrandLinkSchema>;

export type DeleteBrandLinkPathParams = GetBrandLinkPathParams;

export const createBrandLinkSchema = z.object({
  brandGroupId: z.string().min(1),
  /**
   * The group ID of the RETAILER creating the brand link
   */
  groupId: z.string().min(1),
  posBrandIds: z.array(z.string()).min(1),
});

export const createBrandLinkV2Schema = z.object({
  brandId: z.string().min(1),
  /**
   * The group ID of the RETAILER creating the brand link
   */
  retailerAccountId: z.string().min(1),
  posBrandIds: z.array(z.string()).min(1),
});

export type CreateBrandLinkRequestBody = z.infer<typeof createBrandLinkSchema>;

export type CreateBrandLinkV2RequestBody = z.infer<typeof createBrandLinkV2Schema>;

export interface CreateBrandLinkResponseBody {
  data: BrandLink;
}

export interface CreateBrandLinkV2ResponseBody {
  data: PublicBrandLinkV2;
}

export const requestBrandLinkSchema = z.object({
  /**
   * The group ID of the BRAND requesting the brand link
   */
  groupId: z.string().min(1),
  retailerGroupId: z.string().min(1),
});
export type RequestBrandLinkRequestBody = z.infer<typeof requestBrandLinkSchema>;

export const respondToBrandLinkSchema = z.object({
  accepted: z.boolean(),
  brandLinkId: z.string().min(1),
  /**
   * The group id of the RETAILER responding to the brand link request
   */
  groupId: z.string().min(1),
  posBrandIds: z.array(z.string()),
});
export type RespondToBrandLinkRequestBody = z.infer<typeof respondToBrandLinkSchema>;
