import { ComponentProps, useEffect, useState } from 'react';

import LocalStorage from '@data/LocalStorage';
import moment, { Moment, MomentInput } from 'moment';

import { InfoIcon } from '@components/icons';
import DateRangePicker from '@components/inputs/DateRangePicker';
import Tooltip from '@components/layout/Tooltip';

import { getDefaultPrecisionByDateRange } from '@helpers/charts';

import ToolbarDropdown from '../ToolbarDropdown';
import ToolbarGroup from '../ToolbarGroup';

interface OnApplyFn {
    (startDate: Moment, endDate: Moment): void;
}
export interface RangeOption {
    value: string;
    label: string;
    onSelect: (onApply: OnApplyFn) => void;
    isCurrentSelection: (dateStart: MomentInput, dateEnd: MomentInput) => boolean;
}

const customRangeOption: RangeOption = {
    value: 'custom',
    label: 'Custom',
    onSelect: () => {},
    isCurrentSelection: (dateStart, dateEnd) => {
        return dateStart != null && dateEnd != null;
    },
};
export const yearRangeOptions: RangeOption[] = [
    {
        value: 'thisYear',
        label: 'This Year',
        onSelect: (onApply) => {
            const startDate = moment().startOf('year');
            const endDate = moment().endOf('year');
            onApply(startDate, endDate);
        },
        isCurrentSelection: (dateStart, dateEnd) => {
            const startDate = moment().startOf('year');
            const endDate = moment().endOf('year');
            return startDate.isSame(dateStart, 'date') && endDate.isSame(dateEnd, 'date');
        },
    },
    {
        value: 'previousYear',
        label: 'Previous Year',
        onSelect: (onApply) => {
            const startDate = moment().subtract(1, 'year').startOf('year');
            const endDate = moment().subtract(1, 'year').endOf('year');
            onApply(startDate, endDate);
        },
        isCurrentSelection: (dateStart, dateEnd) => {
            const startDate = moment().subtract(1, 'year').startOf('year');
            const endDate = moment().subtract(1, 'year').endOf('year');
            return startDate.isSame(dateStart, 'date') && endDate.isSame(dateEnd, 'date');
        },
    },
];

export const RangeOptions: RangeOption[] = [
    {
        value: '-30days',
        label: 'Last 30 Days',
        onSelect: (onApply) => {
            onApply(moment().subtract(30, 'days'), moment());
        },
        isCurrentSelection: (dateStart, dateEnd) => {
            const rangeDiffIsThirtyDays = moment(dateStart).diff(dateEnd, 'days') === -30;
            const rangeEndsToday = moment(dateEnd).isSame(new Date(), 'day');
            return rangeDiffIsThirtyDays && rangeEndsToday;
        },
    },
    {
        value: 'thisMonth',
        label: 'This Month',
        onSelect: (onApply) => {
            const startDate = moment().startOf('month');
            const endDate = moment().endOf('month');
            onApply(startDate, endDate);
        },
        isCurrentSelection: (dateStart, dateEnd) => {
            const startDate = moment().startOf('month');
            const endDate = moment().endOf('month');
            return startDate.isSame(dateStart, 'date') && endDate.isSame(dateEnd, 'date');
        },
    },
    {
        value: 'lastMonth',
        label: 'Last Month',
        onSelect: (onApply) => {
            const startDate = moment().subtract(1, 'month').startOf('month');
            const endDate = moment().subtract(1, 'month').endOf('month');
            onApply(startDate, endDate);
        },
        isCurrentSelection: (dateStart, dateEnd) => {
            const startDate = moment().subtract(1, 'month').startOf('month');
            const endDate = moment().subtract(1, 'month').endOf('month');
            return startDate.isSame(dateStart, 'date') && endDate.isSame(dateEnd, 'date');
        },
    },
    {
        value: 'thisQuarter',
        label: 'This Quarter',
        onSelect: (onApply) => {
            const startDate = moment().startOf('quarter');
            const endDate = moment().endOf('quarter');
            onApply(startDate, endDate);
        },
        isCurrentSelection: (dateStart, dateEnd) => {
            const startDate = moment().startOf('quarter');
            const endDate = moment().endOf('quarter');
            return startDate.isSame(dateStart, 'date') && endDate.isSame(dateEnd, 'date');
        },
    },
    {
        value: 'lastQuarter',
        label: 'Last Quarter',
        onSelect: (onApply) => {
            const startDate = moment().subtract(1, 'quarter').startOf('quarter');
            const endDate = moment().subtract(1, 'quarter').endOf('quarter');
            onApply(startDate, endDate);
        },
        isCurrentSelection: (dateStart, dateEnd) => {
            const startDate = moment().subtract(1, 'quarter').startOf('quarter');
            const endDate = moment().subtract(1, 'quarter').endOf('quarter');
            return startDate.isSame(dateStart, 'date') && endDate.isSame(dateEnd, 'date');
        },
    },
    customRangeOption,
];

export const getDateRangeLabel = (dateStart: MomentInput, dateEnd: MomentInput): string => {
    return RangeOptions.find((opt) => opt.isCurrentSelection(dateStart, dateEnd))?.label || 'N/A';
};

export const getRangeByStoredValue = (localStorageKey: string, defaultValue: string) => {
    let dateStart;
    let dateEnd;

    const storedValue = localStorageKey ? LocalStorage.get(localStorageKey) : defaultValue;

    if (storedValue) {
        const selected = RangeOptions.find((opt) => opt.value === storedValue);

        if (selected && selected.onSelect) {
            selected.onSelect((_dateStart, _dateEnd) => {
                dateStart = _dateStart;
                dateEnd = _dateEnd;
            });
        }
    }

    return {
        dateStart,
        dateEnd,
        precision: getDefaultPrecisionByDateRange(dateStart, dateEnd),
    };
};

interface IToolbarDateRangeSelectorProps extends ComponentProps<typeof DateRangePicker> {
    alwaysShowDates?: boolean;
    selectionStorageKey?: string;
    additionalRangeOptions?: RangeOption[];
    infoText?: string;
}
export const ToolbarDateRangeSelector = ({
    id,
    className,
    dateStart,
    dateEnd,
    onApply,
    alwaysShowDates = false,
    selectionStorageKey = '',
    label = 'Date Range',
    additionalRangeOptions,
    infoText,
    ...props
}: IToolbarDateRangeSelectorProps) => {
    const [value, setValue] = useState<string>('');
    const combinedRangeOptions = additionalRangeOptions
        ? [
              ...RangeOptions.filter((opt) => opt.value !== 'custom'),
              ...additionalRangeOptions,
              customRangeOption,
          ]
        : RangeOptions;

    useEffect(() => {
        const current = combinedRangeOptions.find(
            (opt) => opt.isCurrentSelection != null && opt.isCurrentSelection(dateStart, dateEnd),
        );

        if (current != null) {
            setValue(current.value);
        }
    }, [dateStart, dateEnd, combinedRangeOptions]);

    const onSelectRange = (selectedValue: string) => {
        const selected = combinedRangeOptions.find((opt) => opt.value === selectedValue);

        if (value !== 'custom') {
            LocalStorage.set(selectionStorageKey, selectedValue);
        }

        if (selected && selected.onSelect) {
            selected.onSelect(onApply);
        }

        setValue(selectedValue);
    };

    return (
        <>
            <ToolbarDropdown
                label={label}
                className={className}
                value={value}
                onSelect={onSelectRange}
                options={combinedRangeOptions}
            />
            {(value === 'custom' || alwaysShowDates) && (
                <ToolbarGroup className="toolbar-custom-range">
                    <DateRangePicker
                        id={id}
                        dateStart={dateStart}
                        dateEnd={dateEnd}
                        onApply={onApply}
                        variant="flat"
                        color="blue"
                        {...props}
                    />
                </ToolbarGroup>
            )}
            {infoText && (
                <Tooltip title={infoText} placement="right">
                    <InfoIcon className="w-3 h-3 cursor-pointer" />
                </Tooltip>
            )}
        </>
    );
};

export default ToolbarDateRangeSelector;
