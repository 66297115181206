import { useEffect, useMemo, useState } from 'react';

import { Model, Question } from 'survey-core';

import { useGetSurveyResponses } from '@features/courses/queries/useGetCourses';
import { useGetSurvey } from '@features/learn-and-earn/queries/useGetSurvey';

import Drawer from '@components/overlays/Drawer';
import Table from '@components/table/Table';
import Toolbar from '@components/toolbar/Toolbar';

import { Card, CardContent } from '@app/componentLibrary/Card';
import Typography from '@app/componentLibrary/Typography';

import { responseRenderers } from './responseRenderers';
import {
    courseResponsesTableHeadCells,
    getQuestionTypeLabel,
    processQuestions,
    tableDefaultOptions,
} from './utils';

const CourseResponses = ({
    surveyId,
    learningResourceId,
}: {
    surveyId: string;
    learningResourceId: string;
}) => {
    const [survey, setSurvey] = useState<Model | null>(null);
    const [activeQuestion, setActiveQuestion] = useState<Question | null>(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [searchFilter, setSearchFilter] = useState('');
    const [selectedType, setSelectedType] = useState('all');

    const { surveyResponses, isLoadingSurveyResponses } = useGetSurveyResponses(learningResourceId);
    const { survey: surveyData, isLoadingSurvey } = useGetSurvey(surveyId);

    const questions = useMemo(
        () => processQuestions(survey, surveyResponses),
        [survey, surveyResponses],
    );

    const questionTypes = useMemo(() => {
        if (!questions?.length) return [];

        const types = new Set(questions.map((q) => q.type));
        return [
            { value: 'all', label: 'All Types' },
            ...Array.from(types).map((type) => ({
                value: type,
                label: getQuestionTypeLabel(type),
            })),
        ];
    }, [questions]);

    const filteredQuestions = useMemo(() => {
        let filtered = questions;

        if (searchFilter) {
            const lowerSearch = searchFilter.toLowerCase();
            filtered = filtered.filter(
                (question) =>
                    question.title?.toLowerCase().includes(lowerSearch) ||
                    question.name?.toLowerCase().includes(lowerSearch),
            );
        }

        if (selectedType !== 'all') {
            filtered = filtered.filter((question) => question.type === selectedType);
        }

        return filtered;
    }, [questions, searchFilter, selectedType]);

    useEffect(() => {
        if (surveyData) {
            const newSurvey = new Model(surveyData.surveyData || {});
            setSurvey(newSurvey);
        }
    }, [surveyData]);

    const ResponseRenderer = activeQuestion
        ? responseRenderers[activeQuestion.type] || responseRenderers.default
        : null;

    return (
        <Card hasBorder={false}>
            <CardContent>
                <Typography variant="lg" className="font-bold mb-4" role="heading">
                    Question Responses
                </Typography>{' '}
                <Toolbar justifyContentStart>
                    <Toolbar.Search
                        name="course-responses-search"
                        onChange={(e) => setSearchFilter(e.target.value)}
                    />
                    <Toolbar.Dropdown
                        label=""
                        value={selectedType}
                        onSelect={setSelectedType}
                        options={questionTypes}
                        clear={{
                            active: selectedType !== 'all',
                            onClear: () => setSelectedType('all'),
                        }}
                    />
                </Toolbar>
                <Table
                    headCells={courseResponsesTableHeadCells}
                    isLoading={isLoadingSurvey || isLoadingSurveyResponses}
                    rows={filteredQuestions}
                    defaultOptions={tableDefaultOptions}
                    variant="raised"
                    showPagination
                    className="w-full rounded"
                >
                    <Table.RenderHead />
                    <Table.RenderBody
                        onRowClick={(row: any) => {
                            setActiveQuestion(row);
                            setIsDrawerOpen(true);
                        }}
                        emptyStateText="No survey questions available."
                    />
                </Table>
                <Drawer
                    title="Question Responses"
                    variant="right"
                    isVisible={isDrawerOpen}
                    onCloseHandler={() => {
                        setIsDrawerOpen(false);
                        setActiveQuestion(null);
                    }}
                >
                    <div className="font-semibold mb-4">
                        {activeQuestion?.orderNumber}.{activeQuestion?.title}
                    </div>
                    {activeQuestion && ResponseRenderer && (
                        <div>
                            <ResponseRenderer
                                responses={activeQuestion.responses}
                                choices={activeQuestion.choices}
                                learningResourceId={learningResourceId}
                                questionId={activeQuestion.name}
                            />
                        </div>
                    )}
                </Drawer>
            </CardContent>
        </Card>
    );
};

export default CourseResponses;
