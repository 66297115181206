import { UseQueryOptions } from 'react-query';

import axios from 'axios';
import { isNaN, isNil, omitBy } from 'lodash';
import { DateTime } from 'luxon';

import {
    EventCountVariant,
    ListEventsQueryParams,
    PagedApiResponseBody,
    UIEvent,
} from '@sparkplug/lib';

import { queryToString } from '@components/router';

import { useAdvancedQuery } from '@hooks/QueryHooks';

import { IAccountUser } from '@app/types/UsersTypes';

import { EVENT_NAVIGATION_QUERY_PARAMS } from '../types';

const EVENT_LIMIT_FALLBACK = 10 as const;

const formatEventForUI = (event: UIEvent) => {
    return {
        ...event,
        startTime: DateTime.fromISO(event.startTime).toISO()!,
        endTime: DateTime.fromISO(event.endTime).toISO()!,
    };
};

export class EventAPI {
    static async getEvents(
        params: Partial<ListEventsQueryParams>,
        locationIds?: string[],
    ): Promise<PagedApiResponseBody<UIEvent>> {
        const normalizedParams = {
            ...omitBy(
                {
                    ...params,
                    locationIds: undefined,
                },
                isNil,
            ),
            offset: params.offset ?? 0,
            limit: params.limit ?? EVENT_LIMIT_FALLBACK,
        };

        const response = (
            await axios.post<PagedApiResponseBody<UIEvent>>(
                `/api/v1/event/list?${queryToString(normalizedParams)}`,
                {
                    locationIds,
                },
            )
        ).data;

        return {
            ...response,
            data: response.data.map(formatEventForUI),
        };
    }

    static async getEvent(eventId: string): Promise<UIEvent> {
        const response = (await axios.get<{ data: UIEvent }>(`/api/v1/event/${eventId}`)).data;
        return formatEventForUI(response.data);
    }

    static async getEventParticipants(eventId: string): Promise<IAccountUser[]> {
        const response = (
            await axios.get<{ participants: IAccountUser[] }>(
                `/api/v1/event/${eventId}/participants`,
            )
        ).data;
        return response.participants;
    }

    static async getEventCount(params: {
        accountId: string;
        variant?: EventCountVariant;
    }): Promise<number> {
        const normalizedParams = {
            ...params,
            variant: params.variant ?? 'all',
        };
        return (
            await axios.get<{ count: number }>(
                `/api/v1/event/count?${queryToString(normalizedParams)}`,
            )
        ).data.count;
    }
}

const normalizeEventQueryParams = (
    eventFilters?: ListEventsQueryParams,
): Partial<ListEventsQueryParams> => {
    return omitBy(eventFilters, (value, key) => {
        if (key === 'limit') {
            const limitNumber = Number(value);
            return !isNaN(limitNumber) && limitNumber <= 0;
        }
        // Navigation query params are not relevant to the event list query
        const ignoredKeys = Object.values(EVENT_NAVIGATION_QUERY_PARAMS);
        return ignoredKeys.includes(key as any);
    });
};

export const getEventsQueryKey = (accountId: string, eventFilters?: ListEventsQueryParams) => [
    'event',
    'list',
    accountId,
    JSON.stringify(eventFilters),
];

export const useGetEventsQuery = ({
    accountId,
    eventFilters,
    locationIds,
}: {
    accountId: string;
    eventFilters?: ListEventsQueryParams;
    locationIds?: string[];
}) => {
    const {
        isLoading: eventsAreLoading,
        data,
        refetch,
        isFetched,
    } = useAdvancedQuery(
        getEventsQueryKey(accountId, eventFilters),
        () => {
            const normalizedEventFilters = normalizeEventQueryParams(eventFilters);
            const limit = eventFilters?.limit ?? EVENT_LIMIT_FALLBACK;
            const offset = eventFilters?.offset ?? 0;

            return EventAPI.getEvents(
                {
                    ...normalizedEventFilters,
                    accountId,
                    offset,
                    limit,
                },
                locationIds,
            );
        },
        {
            enabled: !!accountId,
        },
    );

    return {
        eventsAreLoading,
        events: data?.data ?? [],
        meta: data?.meta,
        refetchEvents: refetch,
        eventsAreReady: isFetched,
    };
};

export const useGetEventCountQuery = ({
    accountId,
    variant,
    enabled = true,
}: {
    accountId: string;
    variant?: EventCountVariant;
    enabled?: boolean;
}) => {
    return useAdvancedQuery(
        ['event', 'count', accountId, variant],
        () => EventAPI.getEventCount({ accountId, variant }),
        {
            enabled,
        },
    );
};

export const useGetEventQuery = (eventId: string, options: UseQueryOptions<UIEvent>) => {
    const {
        isLoading: eventIsLoading,
        isFetched: eventIsReady,
        isError: eventHasError,
        refetch: refetchEvent,
        data: event,
    } = useAdvancedQuery(['event', eventId], () => EventAPI.getEvent(eventId), options);

    return { eventIsLoading, eventIsReady, event, eventHasError, refetchEvent };
};

export const useGetEventParticipantsQuery = (eventId: string) => {
    const {
        isLoading: eventParticipantsAreLoading,
        isFetched: eventParticipantsAreReady,
        isError: eventParticipantsAreError,
        refetch: refetchEventParticipants,
        data: eventParticipants,
    } = useAdvancedQuery(
        ['event', eventId, 'participants'],
        () => EventAPI.getEventParticipants(eventId),
        {
            enabled: !!eventId,
        },
    );

    return {
        eventParticipantsAreLoading,
        eventParticipantsAreReady,
        eventParticipantsAreError,
        refetchEventParticipants,
        eventParticipants,
    };
};
