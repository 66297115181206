import { ReactElement, useState } from 'react';

import { clsx } from 'clsx';
import moment from 'moment-timezone';

import { AccountLink } from '@sparkplug/lib';

import LinkRequestModal from '@features/account-links/components/LinkRequestModal';

import Button from '@components/buttons/Button';
import { Add as AddIcon, Room as MapMarkerIcon, Send as SendIcon } from '@components/icons';
import LabelWithAdditionalChip from '@components/labels/LabelWithAdditionalChip';
import Paper from '@components/layout/Paper';
import { PlaceholderBrandLogo, PlaceholderRetailerLogo } from '@components/logos';

import { getStateNameByAbbreviation } from '@helpers/util';

import { TAccountType } from '@app/types/AccountsTypes';

import './BrandLinkCard.scss';

export const PlaceholderLogo = ({ accountType }: { accountType: TAccountType }) => {
    return accountType === 'brand' ? <PlaceholderBrandLogo /> : <PlaceholderRetailerLogo />;
};

const getActionButtonDetails = (
    accountLink: AccountLink,
): { startIcon: ReactElement; label: string } => {
    const linkType = accountLink.accountType;
    if (accountLink.status === 'pending') {
        return {
            label: linkType === 'retailer' ? 'Requested' : 'Link Pending',
            startIcon: <SendIcon />,
        };
    }

    if (linkType === 'retailer') {
        return {
            label: 'Request Link',
            startIcon: <AddIcon />,
        };
    }

    return {
        label: 'Create Link',
        startIcon: <AddIcon />,
    };
};

interface IBrandLinkCardProps {
    account: AccountLink;
    disableCard: boolean;
    allowLinkCreate?: boolean;
    onButtonClick: (brandAccount: AccountLink) => void;
}

const BrandLinkCard = ({
    account,
    disableCard,
    allowLinkCreate,
    onButtonClick,
}: IBrandLinkCardProps) => {
    const {
        accountName,
        brandLinks = [],
        sharedMarkets = [],
        locations = [],
        photo,
        accountType: linkAccountType,
    } = account ?? {};
    const [showLinkRequestModal, setShowLinkRequestModal] = useState<boolean>(false);
    const [imgIsBroken, setImgIsBroken] = useState<boolean>(false);
    const logoUrl = photo;
    let brandNames: string[] = [];
    if (brandLinks) {
        brandNames = brandLinks.map((b) => b.name);
    }

    const marketsFull = sharedMarkets.map((market) => getStateNameByAbbreviation(market));

    const locationCount = locations?.length ?? 0;
    const locationCountLabel = locationCount === 1 ? 'Location' : 'Locations';
    const locationStr = `${locationCount} ${locationCountLabel}`;

    const actionButtonDetails = getActionButtonDetails(account);

    const handleButtonClick = () => {
        const shouldDisplayLinkRequestCallout =
            linkAccountType === 'retailer' && brandLinks.length === 0;
        if (shouldDisplayLinkRequestCallout) {
            setShowLinkRequestModal(true);
        } else {
            onButtonClick(account);
        }
    };

    return (
        <Paper className={clsx(['account-link-card', logoUrl ? 'has-image' : 'has-no-image'])}>
            {logoUrl && !imgIsBroken ? (
                <div className="brand-link-card_logo mb-4">
                    <img
                        src={logoUrl}
                        alt={accountName}
                        className="rounded"
                        onError={() => setImgIsBroken(true)}
                    />
                </div>
            ) : (
                <div className="brand-link-card_logo-placeholder">
                    <PlaceholderLogo accountType={linkAccountType} />
                </div>
            )}
            <div className="brand-link-card_label">
                <h3>{accountName}</h3>
                <LabelWithAdditionalChip
                    className="brand-link-brands"
                    label={brandNames.slice(0, 3).join(', ')}
                    count={brandNames.length > 2 ? brandNames.length - 3 : 0}
                    tooltip={brandNames.slice().join(', ')}
                    placement="top"
                />
                <div className="markets" data-testid="shared-markets-label">
                    <MapMarkerIcon />
                    <LabelWithAdditionalChip
                        data-testid="shared-markets-label"
                        label={marketsFull.slice(0, 3).join(', ')}
                        count={marketsFull.length > 2 ? marketsFull.length - 3 : 0}
                        tooltip={marketsFull.slice().join(', ')}
                        placement="top"
                    />
                </div>
                {linkAccountType === 'retailer' && (
                    <span className="location-count">{locationStr}</span>
                )}
            </div>
            {allowLinkCreate && (
                <Button
                    disabled={disableCard || account.status === 'pending'}
                    variant="outlined"
                    color="blue"
                    startIcon={actionButtonDetails.startIcon}
                    onClick={handleButtonClick}
                    tooltip={
                        account.status === 'pending'
                            ? `A link request was sent to this retailer on ${moment(
                                  account.createdAt,
                              ).format('MMM D, YYYY')}. Awaiting retailer's response.`
                            : null
                    }
                >
                    <span>{actionButtonDetails.label}</span>
                </Button>
            )}
            {showLinkRequestModal && (
                <LinkRequestModal
                    isVisible
                    onSend={() => {
                        onButtonClick(account);
                        setShowLinkRequestModal(false);
                    }}
                    onClose={() => setShowLinkRequestModal(false)}
                />
            )}
        </Paper>
    );
};

export default BrandLinkCard;
