import EventTable from '@features/events/components/EventTable/EventTable';
import { useEventNavigation } from '@features/events/hooks/useEventNavigation';

import CalloutMessage from '@components/layout/CalloutMessage';

export const EventsOutbox = () => {
    const nav = useEventNavigation();
    return (
        <div data-testid="events-outbox">
            <CalloutMessage
                className="mb-4"
                message="Track your event requests here. Please note that until an event has been approved by the retailer, it's not committed to and should not be planned for."
            />
            <EventTable
                isSentInbox
                onRowClick={(event) => {
                    nav.actions.goToEventDetailsView(event._id);
                }}
            />
        </div>
    );
};
