import { FC, ReactNode } from 'react';

import {
    SparkStatsResponse,
    formatCurrency,
    formatLargeNumber,
    formatShortenedCurrency,
} from '@sparkplug/lib';

import { InfoIcon } from '@components/icons';
import Skeleton from '@components/layout/Skeleton';
import Tooltip from '@components/layout/Tooltip';

import Typography from '@app/componentLibrary/Typography';

interface SparkStatsProps {
    sparkStats?: SparkStatsResponse;
    sparkStatsAreReady: boolean;
}

const StatsBlock: FC<{
    icon?: ReactNode;
    label: string;
    value: string;
    tooltip: string;
    tooltipValue?: string;
}> = ({ icon, label, value, tooltip, tooltipValue }) => (
    <div className="flex flex-col items-center justify-center">
        <div className="flex items-center gap-1 mb-2">
            {icon && (
                <div
                    className="flex items-center [&_svg]:fill-gray-600"
                    data-testid="spark-stats-icon"
                >
                    {icon}
                </div>
            )}{' '}
            <Tooltip title={tooltip} placement="top" disabled={!tooltip}>
                <div className="flex items-center">
                    <Typography variant="sm" className="font-bold text-gray-600 pr-1">
                        {label}
                    </Typography>
                    {tooltip && <InfoIcon />}
                </div>
            </Tooltip>
        </div>
        <Tooltip title={tooltipValue} placement="bottom" disabled={!tooltipValue}>
            <>
                <Typography variant="2xl" className="font-bold text-gray-900">
                    {value}
                </Typography>
            </>
        </Tooltip>
    </div>
);

const currencyFormatter = formatCurrency;
const numberFormatter = (value: number) => value.toLocaleString('en-US');

const StatsDetailsMap: Record<
    keyof SparkStatsResponse,
    {
        label: string;
        tooltip: string;
        icon?: ReactNode;
        valueFormatter: (value: number) => string;
        tooltipValueFormatter?: (value: number) => string;
    }
> = {
    completedSparks: {
        label: 'Completed Sparks',
        tooltip: 'The total number of Sparks successfully completed.',
        valueFormatter: numberFormatter,
    },
    totalParticipants: {
        label: 'Total Participants',
        tooltip:
            'A sum of all participant engagements in Sparks. Individuals may be tracked multiple times if they participate in more than one Spark.',
        valueFormatter: formatLargeNumber,
        tooltipValueFormatter: numberFormatter,
    },
    totalUnitsSold: {
        label: 'Total Units Sold',
        tooltip:
            'This metric uses multi-touch attribution. If the same product is included in overlapping Sparks at the same retailer, sales from each Spark are counted separately.',
        valueFormatter: formatLargeNumber,
        tooltipValueFormatter: numberFormatter,
    },
    totalSales: {
        label: 'Total Sales',
        tooltip:
            'This metric uses multi-touch attribution. If the same product is included in overlapping Sparks at the same retailer, revenue from each Spark is counted separately.',
        valueFormatter: formatShortenedCurrency,
        tooltipValueFormatter: currencyFormatter,
    },
    averagePayoutPerUnit: {
        label: 'Avg Payout per Unit',
        tooltip: 'The average amount paid to participants for each unit sold in Sparks.',
        valueFormatter: formatShortenedCurrency,
        tooltipValueFormatter: currencyFormatter,
    },
    totalCashPayouts: {
        label: 'Total Cash Payouts',
        tooltip: 'The total dollar amount SparkPlug distributed to participants as cash rewards.',
        valueFormatter: formatShortenedCurrency,
        tooltipValueFormatter: currencyFormatter,
    },
    // totalNonCashRewards: {
    //     label: 'Total Non-Cash Rewards',
    //     tooltip: 'The total number of non-cash rewards fulfilled by you to participants.',
    //     valueFormatter: numberFormatter,
    // },
};

const SparkStats: FC<SparkStatsProps> = ({ sparkStats, sparkStatsAreReady }) => {
    return sparkStatsAreReady && sparkStats ? (
        <div className="w-full flex flex-col gap-4 bg-white rounded shadow-[0px_0px_4px_0px_#62676E33] py-3 px-4 mb-4">
            <Typography variant="lg" className="font-bold">
                Spark Summary
            </Typography>
            <div className="flex gap-y-4 w-full justify-center flex-wrap mb-4">
                {Object.entries(sparkStats)
                    .filter(([key]) => key in StatsDetailsMap)
                    .map(([key, value], index, filteredArray) => (
                        <div key={key} className="flex items-center justify-center">
                            <StatsBlock
                                label={StatsDetailsMap[key as keyof SparkStatsResponse].label}
                                value={StatsDetailsMap[
                                    key as keyof SparkStatsResponse
                                ].valueFormatter(value)}
                                tooltipValue={
                                    StatsDetailsMap[key as keyof SparkStatsResponse]
                                        .tooltipValueFormatter
                                        ? StatsDetailsMap[key as keyof SparkStatsResponse]
                                              .tooltipValueFormatter!(value)
                                        : undefined
                                }
                                icon={StatsDetailsMap[key as keyof SparkStatsResponse].icon}
                                tooltip={StatsDetailsMap[key as keyof SparkStatsResponse].tooltip}
                            />
                            {index !== filteredArray.length - 1 && (
                                <div
                                    className="h-full w-[1px] bg-gray-200 mx-4"
                                    data-testid="spark-stats-divider"
                                />
                            )}
                        </div>
                    ))}
            </div>
        </div>
    ) : (
        <Skeleton height={100} className="mb-4" />
    );
};

export default SparkStats;
