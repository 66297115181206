import { useState } from 'react';

import Dropdown from '@components/dropdown/Dropdown';
import { Apps, ExitToApp, Group as GroupIcon, Settings, SwapHoriz } from '@components/icons';
import EditUserProfileModal from '@components/overlays/EditUserProfileModal';
import SwitchAccountModal from '@components/overlays/SwitchAccountModal';

import { useApp, useControlCenterApp } from '@hooks/AppHooks';

import { mapReactFragmentChildrenToArray, openUrl } from '@helpers/ui';

import { IAccount } from '@app/types/AccountsTypes';

import Avatar from '../../Avatar';
import DesktopHelpWidget from '../DesktopHelpWidget';
import NotificationWidget from '../NotificationWidget';

import './UserWidget.scss';

interface UserWidgetProps
    extends Pick<
        ReturnType<typeof useApp>,
        | 'user'
        | 'userIsAdmin'
        | 'userIsSuperAdmin'
        | 'userCanSwitchView'
        | 'refetchUserAppData'
        | 'isAdminApp'
        | 'appIsMobile'
    > {
    isControlCenterApp: boolean;
    account?: IAccount;
    onLogOut: () => void;
    goToControlCenter: () => void;
    goToEditProfile: () => void;
    switchUserViewFn: (show: boolean) => void;
}

export const UserWidget = ({
    isControlCenterApp,
    userIsAdmin,
    userIsSuperAdmin,
    userCanSwitchView,
    isAdminApp,
    user,
    account,
    appIsMobile,
    onLogOut,
    goToControlCenter,
    goToEditProfile,
    refetchUserAppData,
    switchUserViewFn,
}: UserWidgetProps) => {
    const [showSwitchAccountModal, setShowSwitchAccountModal] = useState(false);
    const [showEditUserProfileModal, setShowEditUserProfileModal] = useState(false);

    if (!user?._id) {
        return <></>;
    }

    const line1 = `${user.firstName} ${user.lastName}`;
    const line2 = account != null ? account.name : '--';

    const showNotificationWidget = !isControlCenterApp;
    const showAdminSupportWidget = !!account?.type && !appIsMobile;

    return (
        <div className="user-widget">
            {showNotificationWidget && <NotificationWidget user={user} />}
            {showAdminSupportWidget && <DesktopHelpWidget accountType={account.type} />}
            <Dropdown>
                <Dropdown.Button color="neutral" variant="flat">
                    <>
                        <div className="user-details">
                            <span className="user-name">{line1}</span>
                            <span className="user-location-title">
                                {user.role === 'super-admin' ? 'Super Admin' : line2}
                            </span>
                        </div>
                        <Avatar firstName={user.firstName} src={user.avatarUrl || undefined} />
                    </>
                </Dropdown.Button>
                <Dropdown.Menu>
                    {user.accounts != null && user?.accounts.length > 1 && (
                        <Dropdown.MenuItem
                            StartIcon={SwapHoriz}
                            onClick={() => setShowSwitchAccountModal(true)}
                        >
                            Switch Accounts
                        </Dropdown.MenuItem>
                    )}

                    {userIsAdmin &&
                        isAdminApp &&
                        mapReactFragmentChildrenToArray(
                            <>
                                {userIsSuperAdmin && (
                                    <Dropdown.MenuItem StartIcon={Apps} onClick={goToControlCenter}>
                                        Control Center
                                    </Dropdown.MenuItem>
                                )}
                                <Dropdown.MenuItem
                                    StartIcon={Settings}
                                    onClick={() => {
                                        if (account?._id == null) {
                                            setShowEditUserProfileModal(true);
                                        } else {
                                            goToEditProfile();
                                        }
                                    }}
                                >
                                    Edit Profile
                                </Dropdown.MenuItem>

                                <Dropdown.MenuDivider />
                            </>,
                        )}

                    {(userCanSwitchView || user.role === 'retailer-admin') && (
                        <Dropdown.MenuItem
                            StartIcon={GroupIcon}
                            onClick={() => switchUserViewFn(true)}
                        >
                            Switch User View
                        </Dropdown.MenuItem>
                    )}

                    <Dropdown.MenuItem StartIcon={ExitToApp} onClick={onLogOut}>
                        Log Out
                    </Dropdown.MenuItem>
                </Dropdown.Menu>
            </Dropdown>
            <SwitchAccountModal
                isVisible={showSwitchAccountModal}
                onClose={() => {
                    setShowSwitchAccountModal(false);
                }}
            />
            <EditUserProfileModal
                isVisible={showEditUserProfileModal}
                user={user}
                onClose={(wasSaved?: boolean) => {
                    if (wasSaved) {
                        requestAnimationFrame(() => {
                            refetchUserAppData();
                        });
                    }
                    setShowEditUserProfileModal(false);
                }}
            />
        </div>
    );
};

export default ({ account, switchUserViewFn }: { account?: IAccount; switchUserViewFn?: any }) => {
    const {
        history,
        logOut,
        user,
        userIsAdmin,
        userIsSuperAdmin,
        userCanSwitchView,
        refetchUserAppData,
        isAdminApp,
        appIsMobile,
    } = useApp();

    const { isControlCenterApp } = useControlCenterApp();

    const onLogOut = () => {
        logOut().then(() => {
            history.push('/login');
        });
    };

    const goToControlCenter = () => {
        if (isControlCenterApp) {
            history.push('/control-center');
        } else {
            openUrl('/control-center');
        }
    };

    const goToEditProfile = () => {
        history.push(`/${account?._id}/settings/profile/`);
    };

    const props = {
        user,
        userIsAdmin,
        userIsSuperAdmin,
        userCanSwitchView,
        refetchUserAppData,
        isAdminApp,
        appIsMobile,

        isControlCenterApp,
        account,
        onLogOut,
        goToControlCenter,
        goToEditProfile,
    };

    return <UserWidget {...props} switchUserViewFn={switchUserViewFn} />;
};
