import { useState } from 'react';

import { useSaveW9Form } from '@core/users/mutations';

import { useHistory } from '@components/router';
import toast from '@components/toast';

import { useApp } from '@hooks/AppHooks';

import { W9FormData, W9InputErrors } from './W9Form.types';
import { W9InputForm } from './W9InputForm';
import { SignForm } from './W9SignForm';

const W9Form = () => {
    const [formData, setFormData] = useState<W9FormData>({
        fullName: '',
        streetAddress: '',
        city: '',
        state: '',
        zip: '',
        ssn: '',
        email: '',
    });

    const [errors, setErrors] = useState<W9InputErrors>({
        fullName: false,
        email: false,
        streetAddress: false,
        city: false,
        state: false,
        zip: false,
        ssn: false,
    });

    const { userId, refetchUserAppData } = useApp();
    const { saveW9Form } = useSaveW9Form();
    const history = useHistory();

    const [readyToSign, setReadyToSign] = useState(false);

    const updateForm = (updates: Partial<W9FormData>) => {
        setFormData((prev) => ({
            ...prev,
            ...updates,
        }));
    };

    const updateFormErrors = (updates: Partial<W9InputErrors>) => {
        setErrors((prev) => ({
            ...prev,
            ...updates,
        }));
    };

    const handleNext = () => {
        if (
            !formData.fullName ||
            !formData.streetAddress ||
            !formData.city ||
            !formData.state ||
            !formData.zip ||
            !formData.ssn
        ) {
            toast.error('Please complete required fields.');
            return;
        }

        setReadyToSign(true);
    };

    const handleSignW9Data = () => {
        if (!userId) {
            toast.error('User not found');
            return;
        }
        const finalFormData = {
            ...formData,
            ssn: formData.ssn.replace(/-/g, ''),
        };
        saveW9Form(
            { userId, body: finalFormData },
            {
                onSuccess: async () => {
                    await refetchUserAppData(); // this is needed as the w9 data is in the user document.
                    history.push('/user/wallet?action=openCashout');
                    toast.success('W-9 successfully submitted');
                },
                onError: (error: any) => {
                    if (
                        error.response?.status === 400 &&
                        error.response?.data?.error === 'invalid_address'
                    ) {
                        toast.error('Invalid address');
                        setReadyToSign(false);
                        updateFormErrors({
                            streetAddress: true,
                            city: true,
                            zip: true,
                        });
                        return;
                    }
                    toast.error('Error saving W9 form');
                    history.goBack();
                },
            },
        );
    };

    return (
        <div className="bg-white !max-w-[600px] mx-auto">
            {readyToSign ? (
                <SignForm setReadyToSign={setReadyToSign} handleNext={handleSignW9Data} />
            ) : (
                <W9InputForm
                    formData={formData}
                    formErrors={errors}
                    updateForm={updateForm}
                    updateFormErrors={updateFormErrors}
                    handleNext={handleNext}
                />
            )}
        </div>
    );
};

export default W9Form;
