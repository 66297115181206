import { useQuery } from 'react-query';

import axios from 'axios';
import { isNil, omitBy } from 'lodash';

import { ListSparksQueryParams, SparkStatsResponse } from '@sparkplug/lib';

import { IAccount } from '@app/types/AccountsTypes';

export class SparkStatsApi {
    static async getSparkStats(
        params: ListSparksQueryParams,
        signal?: AbortSignal,
        enabled: boolean = true,
    ) {
        if (!enabled) {
            return undefined;
        }

        const source = axios.CancelToken.source();
        let abortHandler: (() => void) | undefined;

        if (signal) {
            abortHandler = () => {
                source.cancel('Request was aborted');
            };
            signal.addEventListener('abort', abortHandler);
        }

        try {
            const response = await axios.post<SparkStatsResponse>(
                `/api/v1/sparks/stats`,
                {
                    locationId: params.locationId?.split(',').filter(Boolean) ?? [],
                    markets: params.markets?.split(',').filter(Boolean) ?? [],
                    brandIds: params.brandIds?.split(',').filter(Boolean) ?? [],
                    vendorIds: params.vendorIds?.split(',').filter(Boolean) ?? [],
                },
                {
                    params: {
                        ...omitBy(
                            {
                                ...params,
                                locationId: undefined,
                                offset: params.offset ?? 0,
                                limit: params.limit !== undefined ? params.limit : 10,
                            },
                            isNil,
                        ),
                    },
                    cancelToken: source.token,
                },
            );
            return response.data;
        } finally {
            if (signal && abortHandler) {
                signal.removeEventListener('abort', abortHandler);
            }
        }
    }
}

export const useGetSparkStatsQuery = ({
    account,
    sparkFilters,
    isAdminApp,
    enabled,
}: {
    account?: IAccount;
    sparkFilters?: ListSparksQueryParams;
    isAdminApp: boolean;
    enabled: boolean;
}) => {
    const {
        data: sparkStats,
        isLoading: sparkStatsIsLoading,
        isFetched: sparkStatsIsFetched,
        isError: sparkStatsHasError,
        refetch: refetchSparkStats,
    } = useQuery(
        ['spark', 'stats', account?._id ?? '', JSON.stringify(sparkFilters)],
        ({ signal }) => SparkStatsApi.getSparkStats(sparkFilters ?? {}, signal, enabled),
        {
            enabled: isAdminApp && enabled,
        },
    );

    return {
        sparkStats,
        sparkStatsIsLoading,
        sparkStatsIsFetched,
        sparkStatsHasError,
        refetchSparkStats,
    };
};
