import React, { Suspense, lazy, useEffect, useRef } from 'react';

import Mousetrap from 'mousetrap';

import { CCGiftCardsView } from '@features/gift-cards';

import Form from '@components/form/Form';
import {
    Business,
    GiftCardInvertedIcon,
    LocalLibrary,
    OfflineBolt,
    People,
    Search,
} from '@components/icons';
import { SidebarMenuItem } from '@components/layout/AppSidebar/AppSidebar';
import Helmet from '@components/layout/Helmet';
import PageLoading from '@components/layout/PageLoading';
import {
    Redirect,
    Route,
    SuperAdminRoute,
    Switch,
    useRouteMatch,
    withHistory,
} from '@components/router';

import { useApp, withAppSidebar } from '@hooks/AppHooks';

import { IAuthUser } from '@app/types/UsersTypes';

// Lazy load components
const CCAccountsView = lazy(() => import('@views/control-center/CCAccountsView'));
const CCLearningResources = lazy(() => import('@views/control-center/CCLearningResources'));
const CCLearningResourceCreate = lazy(
    () => import('@views/control-center/CCLearningResources/CCLearningResourceCreate'),
);
const CCLearningResourceDetails = lazy(
    () => import('@views/control-center/CCLearningResources/CCLearningResourceDetails'),
);
const CCRecentSparksView = lazy(() => import('@views/control-center/CCRecentSparksView'));
const CCUsersView = lazy(() => import('@views/control-center/CCUsersView'));
const ControlCenterView = lazy(() => import('@views/control-center/ControlCenterView'));
const CCAccountRouter = lazy(() => import('./CCAccountRouter'));

const CCAccountAddView = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <ControlCenterView showAdd />
        </Suspense>
    );
};

const ControlCenterRouter = () => {
    const { path } = useRouteMatch();

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Helmet>
                <link id="favicon" rel="icon" href="/favicon-16x16-bw.png" type="image/png" />
                <link id="favicon" rel="icon" href="/favicon-32x32-bw.png" type="image/png" />
            </Helmet>
            <Switch>
                <SuperAdminRoute path={`${path}/gift-cards`} component={CCGiftCardsView} />
                <SuperAdminRoute
                    path={`${path}/recent-sparks`}
                    render={() => (
                        <Suspense fallback={<PageLoading label="Loading Recent Sparks..." />}>
                            <CCRecentSparksView />
                        </Suspense>
                    )}
                />
                <SuperAdminRoute
                    path={`${path}/users`}
                    render={() => (
                        <Suspense fallback={<PageLoading label="Loading Users..." />}>
                            <CCUsersView />
                        </Suspense>
                    )}
                />
                <SuperAdminRoute path={`${path}/accounts/add`} component={CCAccountAddView} />
                <Route
                    path={`${path}/accounts/:accountId`}
                    render={() => (
                        <Suspense fallback={<PageLoading label="Loading Account..." />}>
                            <CCAccountRouter />
                        </Suspense>
                    )}
                />
                <SuperAdminRoute
                    path={`${path}/accounts`}
                    render={() => (
                        <Suspense fallback={<PageLoading label="Loading Accounts..." />}>
                            <CCAccountsView />
                        </Suspense>
                    )}
                />

                <SuperAdminRoute
                    path={`${path}/courses/:learningResourceId`}
                    render={() => (
                        <Suspense fallback={<PageLoading label="Loading Course Details..." />}>
                            <CCLearningResourceDetails />
                        </Suspense>
                    )}
                />
                <SuperAdminRoute
                    path={`${path}/courses`}
                    render={() => (
                        <Suspense fallback={<PageLoading label="Loading Courses..." />}>
                            <CCLearningResources />
                        </Suspense>
                    )}
                />
                <Redirect path={path} to={`${path}/accounts`} />
            </Switch>
        </Suspense>
    );
};

const AccountSearch = withHistory(
    ({
        history,
        accounts,
        searchRef,
    }: {
        history: any;
        accounts: IAuthUser['accounts'];
        searchRef: any;
    }) => {
        return (
            <Form.SearchSelect
                required
                showClear
                inputRef={searchRef}
                startIcon={<Search />}
                placeholder="Find account"
                mode="simpleSelect"
                value=""
                onChange={(newAccount) => {
                    history.push(`/control-center/accounts/${newAccount?._id}/details`);
                }}
                options={accounts}
            />
        );
    },
);

export default () => {
    const { user } = useApp();

    const searchRef = useRef<any>();

    useEffect(() => {
        Mousetrap.bind(['command+k', 'ctrl+k'], () => {
            if (searchRef.current) {
                searchRef.current.select.focus();
            }
            return false;
        });

        return () => {
            Mousetrap.unbind(['command+k', 'ctrl+k']);
        };
    }, []);

    const sidebarItems: SidebarMenuItem[] = [
        {
            key: '',
            title: '',
            component: <AccountSearch searchRef={searchRef} accounts={user?.accounts || []} />,
        },
        {
            key: 'Accounts',
            startIcon: <Business />,
            title: 'Accounts',
            to: '/control-center/accounts',
        },

        {
            key: 'Recent Sparks',
            startIcon: <OfflineBolt />,
            title: 'Recent Sparks',
            // explicitly setting the initial query params so the page doesn't need to.
            to: '/control-center/recent-sparks?p-0&limit=10&order=asc&orderBy=endDate',
        },
        {
            key: 'Users',
            startIcon: <People />,
            title: 'Users',
            to: '/control-center/users',
        },
        {
            key: 'Gift Cards',
            startIcon: <GiftCardInvertedIcon />,
            title: ' Gift Cards',
            to: '/control-center/gift-cards',
        },
        {
            key: 'Courses',
            startIcon: <LocalLibrary />,
            title: 'Courses',
            to: '/control-center/courses',
        },
    ];

    const WrappedComponent = withAppSidebar({ sidebarItems })(ControlCenterRouter);

    return <WrappedComponent />;
};
