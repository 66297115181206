import axios from 'axios';
import { isNil, omitBy } from 'lodash';

import {
    AccountMarket,
    FinalizeSparkRequestBody,
    GetSparkHistoryResponse,
    GetSparkPathParams,
    GetSparkResponseBody,
    GetSparkRewardsResponse,
    ListSparksQueryParams,
    ListSparksResponseBody,
    PublicSparkPosArchive,
    Spark,
    SparkParticipantGroup,
    UpsertManySparkRewardsRequestBody,
} from '@sparkplug/lib';

import { IOptionTree } from '@app/types/UITypes';

export default class SparksAPI {
    static async getSparks(
        params: ListSparksQueryParams,
        signal?: AbortSignal,
    ): Promise<ListSparksResponseBody> {
        const source = axios.CancelToken.source();
        let abortHandler: (() => void) | undefined;

        if (signal) {
            abortHandler = () => {
                source.cancel('Request was aborted');
            };
            signal.addEventListener('abort', abortHandler);
        }

        try {
            return (
                await axios.post(
                    `/api/v1/pos/sparks/list`,
                    {
                        locationId: params.locationId?.split(',').filter(Boolean) ?? [],
                        markets: params.markets?.split(',').filter(Boolean) ?? [],
                        brandIds: params.brandIds?.split(',').filter(Boolean) ?? [],
                        vendorIds: params.vendorIds?.split(',').filter(Boolean) ?? [],
                    },
                    {
                        params: {
                            ...omitBy(
                                {
                                    ...params,
                                    locationId: undefined,
                                    offset: params.offset ?? 0,
                                    limit: params.limit !== undefined ? params.limit : 10,
                                },
                                isNil,
                            ),
                        },
                        cancelToken: source.token,
                    },
                )
            ).data;
        } finally {
            if (signal && abortHandler) {
                signal.removeEventListener('abort', abortHandler);
            }
        }
    }

    static async getSpark({ sparkId }: GetSparkPathParams): Promise<GetSparkResponseBody> {
        try {
            if (!sparkId) {
                throw new Error('sparkId is required');
            }
            return (await axios.get(`/api/v1/pos/sparks/${sparkId}`)).data;
        } catch (error: any) {
            return error;
        }
    }

    static async createSpark(
        properties: Spark & {
            participantGroups: SparkParticipantGroup[];
            requestForSparkId?: string;
        },
    ) {
        return (await axios.post<Spark>(`/api/v1/pos/sparks`, properties)).data;
    }

    static async updateSpark(
        sparkId: string,
        properties: Partial<Spark> & { participantGroups?: SparkParticipantGroup[] },
    ) {
        return (await axios.put(`/api/v1/pos/sparks/${sparkId}`, properties)).data;
    }

    static async deleteSpark(sparkId: string): Promise<any> {
        return (await axios.delete(`/api/v1/pos/sparks/${sparkId}`)).data;
    }

    static async endRecurringSparkSchedule(sparkId: string): Promise<any> {
        return (await axios.delete(`/api/v1/pos/sparks/end-recurrence/${sparkId}`)).data;
    }

    static getSparkPosArchive(sparkId: string) {
        try {
            return axios.get<PublicSparkPosArchive>(
                `/api/v1/sparkplug/sparks/pos-archives/${sparkId}`,
            );
        } catch (err) {
            if (err instanceof Error) {
                throw new Error(`getSparkPosArchive (${sparkId}) failed: ${err?.message}`);
            }

            throw new Error(`getSparkPosArchive (${sparkId}) failed: ${JSON.stringify(err)}`);
        }
    }

    static async getPendingSparksCount(accountId: string): Promise<number> {
        return (
            await axios.get(`/api/v1/sparks/pending`, {
                params: {
                    accountId,
                },
            })
        ).data;
    }

    static async getTotalSparksCount(accountId: string): Promise<number> {
        return (
            await axios.get(`/api/v1/sparks/total`, {
                params: {
                    accountId,
                },
            })
        ).data;
    }

    static async getAllTimeSparkDateRange(accountId: string) {
        return (
            await axios.get(`/api/v1/sparks/all-time-spark-date-range`, {
                params: {
                    accountId,
                },
            })
        ).data;
    }

    static getSparkRewards(sparkId: string) {
        return axios.get<GetSparkRewardsResponse>(`/api/v1/sparkplug/sparks/${sparkId}/rewards`);
    }

    static upsertManySparkRewards(sparkId: string, payload: UpsertManySparkRewardsRequestBody) {
        return axios.put<void>(`/api/v1/sparkplug/sparks/${sparkId}/rewards`, payload);
    }

    static finalizeSpark(sparkId: string, payload: FinalizeSparkRequestBody) {
        return axios.post<void>(`/api/v1/sparkplug/sparks/${sparkId}/finalize`, payload);
    }

    static getSparkInvoiceUrl(sparkId: string) {
        return axios.get<{ invoiceUrl: string }>(`/api/v1/sparkplug/sparks/${sparkId}/invoice-url`);
    }

    static getSparkHistory(sparkId: string) {
        return axios.get<GetSparkHistoryResponse>(`/api/v1/sparkplug/sparks/${sparkId}/history`);
    }

    static unfinalizeSpark(sparkId: string) {
        return axios.put<void>(`/api/v1/sparks/${sparkId}/unfinalize`);
    }

    static async getSparkRetailers(accountId: string, status?: string) {
        return (
            await axios.get<{ retailers: IOptionTree<{}>[] }>(`/api/v1/sparks/retailers`, {
                params: {
                    accountId,
                    status,
                },
            })
        ).data;
    }

    static async getSparkMarkets(accountId: string, status?: string) {
        return (
            await axios.get<{ markets: AccountMarket[] }>(`/api/v1/sparks/markets`, {
                params: {
                    accountId,
                    status,
                },
            })
        ).data;
    }

    static async getSparkBrands(accountId: string, status?: string) {
        return (
            await axios.get<{ sparkBrands: IOptionTree<{}>[] }>(`/api/v1/sparks/brands`, {
                params: {
                    accountId,
                    status,
                },
            })
        ).data;
    }

    static async getSparkVendors(accountId: string, status?: string) {
        return (
            await axios.get<{ sparkVendors: IOptionTree<{}>[] }>(`/api/v1/sparks/vendors`, {
                params: {
                    accountId,
                    status,
                },
            })
        ).data;
    }
}
