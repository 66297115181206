import { ReactNode } from 'react';

import { DO_NOT_HAVE_PERMISSIONS_MESSAGE } from '@sparkplug/lib';

import Paper from '@components/layout/Paper';
import Tooltip from '@components/layout/Tooltip';
import Modal from '@components/overlays/Modal';

import './SnapTypeModal.scss';

interface SnapTypeOptionProps {
    onClick: () => void;
    imgSrc: string;
    title: string;
    description: string;
    disabled?: boolean;
}
export const SnapTypeOption = ({
    onClick,
    imgSrc,
    title,
    description,
    disabled,
}: SnapTypeOptionProps) => {
    return (
        <Tooltip title={disabled ? DO_NOT_HAVE_PERMISSIONS_MESSAGE : ''} placement="top">
            <div>
                <Paper
                    onClick={disabled ? undefined : onClick}
                    className={`snap-type-option flex flex-col items-center border border-solid border-gray-300 !p-0 !m-0 w-[318px] cursor-pointer rounded-[0px_0px_5px_5px] overflow-hidden ${
                        disabled ? 'opacity-60' : ''
                    }`}
                >
                    <img
                        src={imgSrc}
                        alt="snap type button graphic"
                        className="w-[285px] h-[364px] object-cover"
                    />
                    <section className="h-24 bg-gray-50 flex flex-col gap-1 p-4 w-full text-center border-t border-solid border-gray-500">
                        <h3 className="text-lg font-semibold leading-6 tracking-[0.0175em] text-gray-900">
                            {title}
                        </h3>
                        <p className="text-base font-normal leading-5 tracking-[0.0175em] text-gray-800">
                            {description}
                        </p>
                    </section>
                </Paper>
            </div>
        </Tooltip>
    );
};

export interface SnapTypeModalProps {
    isVisible: boolean;
    onClose: () => void;
    children: ReactNode;
}
export const SnapTypeModal = ({ isVisible, onClose, children }: SnapTypeModalProps) => {
    return (
        <Modal
            isVisible={isVisible}
            onClose={onClose}
            className="snap-type-modal flex justify-center [&_.MuiDialog-paper]:w-full [&_.MuiDialog-paper]:max-w-[728px]"
        >
            <Modal.Title className="text-[#fff] !text-[24px] !bg-bluewood-500" onClose={onClose}>
                Create a Snap
            </Modal.Title>
            <Modal.Content className="flex flex-wrap justify-center gap-7 w-fit !pt-6 !p-8">
                {children}
            </Modal.Content>
        </Modal>
    );
};
