import { FC } from 'react';

import Avatar from '@components/layout/Avatar';

import Typography from '@app/componentLibrary/Typography';

export interface SparkOwnerProps {
    firstName: string;
    lastName: string;
    avatarUrl?: string;
}

export const SparkOwnerDisplay: FC<SparkOwnerProps> = ({ firstName, lastName, avatarUrl }) => {
    return (
        <div className="flex items-center gap-1">
            {avatarUrl ? (
                <Avatar firstName={firstName} src={avatarUrl} sx={{ width: 25, height: 25 }} />
            ) : (
                <div className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-300 text-white">
                    <span className="text-[10px] font-semibold leading-none text-center flex items-center justify-center w-full h-full !mb-0">
                        {firstName.slice(0, 1)}
                        {lastName.slice(0, 1)}
                    </span>
                </div>
            )}
            <Typography variant="base">{`${firstName} ${lastName}`}</Typography>
        </div>
    );
};
