import { SurveyCreator } from 'survey-creator-react';

import { CourseActionButton, CourseStatus } from '@features/courses/types';

const VALID_FILE_TYPES = ['video/mp4', 'video/quicktime', 'image/jpeg', 'image/png'];
const MAX_FILE_SIZE_MB = 20;

export const validateFile = (file: File, requiredFileType?: string) => {
    const { type, size } = file;

    if (requiredFileType) {
        if (type !== requiredFileType) {
            return { valid: false, error: `Invalid file type. Must be a ${requiredFileType}` };
        }
    }

    // Check file type
    if (!VALID_FILE_TYPES.includes(type)) {
        return { valid: false, error: 'Invalid file type' };
    }

    // Check file size
    const fileSizeMB = size / (1024 * 1024);
    if (fileSizeMB > MAX_FILE_SIZE_MB) {
        return { valid: false, error: `File size exceeds ${MAX_FILE_SIZE_MB}MB` };
    }

    return { valid: true };
};

export const getCourseActionButtons = ({
    state,
    isReviewing,
    handlers,
    creator,
}: {
    state: CourseStatus;
    isReviewing: boolean;
    handlers: {
        handleSaveAsDraft: (creator: SurveyCreator) => void;
        handleUnpublishClick: (creator: SurveyCreator) => void;
        handleSaveAsPublished: (creator: SurveyCreator) => void;
        handleReviewCourse: (value: boolean) => void;
    };
    creator: SurveyCreator;
}): CourseActionButton[] => {
    if (isReviewing) {
        return [
            {
                label: 'Back',
                variant: 'flat',
                color: 'white',
                onClick: () => handlers.handleReviewCourse(false),
                tooltip: 'Return to editing mode',
            },
            {
                label: 'Publish Course',
                variant: 'filled',
                color: 'blue',
                onClick: handlers.handleSaveAsPublished,
                tooltip: 'Publish this course',
            },
        ];
    }

    const actionMap: Record<CourseStatus, CourseActionButton[]> = {
        draft: [
            {
                label: 'Save Draft',
                variant: 'flat',
                color: 'white',
                onClick: handlers.handleSaveAsDraft,
                tooltip: 'Save your progress without publishing',
            },
            {
                label: 'Review & Publish',
                variant: 'filled',
                color: 'blue',
                onClick: () => handlers.handleReviewCourse(true),
                tooltip: 'Review and publish this course',
            },
        ],
        published: [
            {
                label: 'Unpublish',
                variant: 'flat',
                color: 'white',
                onClick: handlers.handleUnpublishClick,
                tooltip: 'Make this course unavailable to users',
            },
            {
                label: 'Review Changes',
                variant: 'filled',
                color: 'blue',
                onClick: () => handlers.handleReviewCourse(true),
                tooltip: 'Review your changes before saving',
            },
        ],
        unpublished: [
            {
                label: 'Save',
                variant: 'flat',
                color: 'white',
                onClick: handlers.handleSaveAsDraft,
                tooltip: 'Save your progress without publishing',
            },
            {
                label: 'Review & Publish',
                variant: 'filled',
                color: 'blue',
                onClick: () => handlers.handleReviewCourse(true),
                tooltip: 'Review before making this course available',
            },
        ],
    };

    return actionMap[state] || [];
};
