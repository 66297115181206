import { FC, useEffect } from 'react';

import clsx from 'clsx';

import { EmployeeSpark } from '@sparkplug/lib';

import { useSnapViewQueue } from '@features/spark-snaps/hooks';
import { useSnapsById, useUserSnapEventsQuery } from '@features/spark-snaps/queries';

import ClickableArea from '@components/layout/ClickableArea';
import Skeleton from '@components/layout/Skeleton';

import './SparkCardSnapsLauncher.scss';

interface SparkCardSnapsLauncherProps {
    spark: EmployeeSpark;
    handleViewSnap?: ReturnType<typeof useSnapViewQueue>['handleViewSnap'];
    sparkCardIndex?: number;
}
export const SparkCardSnapsLauncher: FC<
    SparkCardSnapsLauncherProps & { snapsAreReadyToView: boolean }
> = ({ spark, handleViewSnap, snapsAreReadyToView, sparkCardIndex }) => {
    const { userSnapEventsAreReady, userSnapEvents: snapCompleteEvents } = useUserSnapEventsQuery({
        eventType: 'story_complete',
    });

    const sparkCreatorAccountId = spark.originatorGroupId ?? spark.groupId;
    const snapQueue = spark.snaps?.snapIds;
    const launchSnapsViewer = (e: MouseEvent) => {
        if (snapQueue?.length) {
            e.stopPropagation();
            handleViewSnap?.({
                accountId: sparkCreatorAccountId,
                // Always start with the first snap
                selectedSnapId: snapQueue[0],
                snapQueue,
                sparkId: spark._id,
            });
        }
    };
    const snapsAreReady = snapsAreReadyToView && userSnapEventsAreReady;

    useEffect(() => {
        if (snapsAreReady) {
            window.freshpaint?.track('Element Rendered: Snap Trigger - Spark Card', {
                accountId: sparkCreatorAccountId,
                snapIds: snapQueue,
                sparkId: spark._id,
            });
        }
    }, [snapsAreReady]);

    return (
        <div>
            <ClickableArea
                className={clsx('snaps-launcher_container', {
                    '__snaps-viewed': snapQueue?.every((snapId) =>
                        snapCompleteEvents?.some((event) => event.snapId === snapId),
                    ),
                })}
                onClick={launchSnapsViewer}
            >
                <div className="snaps-launcher_img-container">
                    {snapsAreReady ? (
                        <img
                            src={spark?.sparkBrand?.photo ?? spark.retailerLogo}
                            alt={spark.name}
                        />
                    ) : (
                        <div className="snaps-launcher_skeleton">
                            <Skeleton className="skeleton-icon" height={64} width={64} />
                        </div>
                    )}
                </div>
            </ClickableArea>
        </div>
    );
};

// Separated this for easier storybook testing
export default ({ spark, handleViewSnap, sparkCardIndex }: SparkCardSnapsLauncherProps) => {
    const sparkCreatorAccountId = spark.originatorGroupId ?? spark.groupId;
    // Pre-fetch snaps associated with the given spark so they're ready to view on click
    const { snapsAreReady: snapsAreReadyToView } = useSnapsById(
        { accountId: sparkCreatorAccountId, snapIds: spark.snaps?.snapIds ?? [] },
        !!spark.snaps?.snapIds?.length,
    );

    return (
        <SparkCardSnapsLauncher
            spark={spark}
            handleViewSnap={handleViewSnap}
            snapsAreReadyToView={snapsAreReadyToView}
            sparkCardIndex={sparkCardIndex}
        />
    );
};
