import { useCallback, useEffect, useState } from 'react';

import { EventDeclineResponse, eventDeclineOptions } from '@sparkplug/lib';

import { useEventNavigation } from '@features/events/hooks/useEventNavigation';
import { useDeclineEventMutation } from '@features/events/mutations';
import { useGetEventQuery } from '@features/events/queries';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import Skeleton from '@components/layout/Skeleton';
import Modal from '@components/overlays/Modal';
import toast from '@components/toast';

import Typography from '@app/componentLibrary/Typography';

export const DeclineEventModal = ({
    isVisible,
    onClose,
}: {
    isVisible: boolean;
    onClose: () => void;
}) => {
    const nav = useEventNavigation();
    const [declineResponse, setDeclineResponse] = useState<EventDeclineResponse>();
    const { event, eventIsLoading, eventHasError } = useGetEventQuery(nav.state.declineId, {
        enabled: !!nav.state.declineId,
    });
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (eventHasError) {
            toast.error('Something went wrong.');
            onClose();
        }
    }, [eventHasError]);

    const { declineEventAsync } = useDeclineEventMutation();
    const handleDeclineEvent = async () => {
        if (event && declineResponse) {
            await declineEventAsync({ eventId: event._id, declineResponse });
            nav.actions.goToInboxView();
        }
    };

    const handleClose = () => {
        setSubmitted(false);
        onClose();
    };

    const handleValidation = useCallback(() => {
        setSubmitted(true);

        console.log('validationCalled declineResponse', declineResponse);
        console.log('event', event);

        if (!declineResponse?.reason) {
            toast.error('Decline reason required');
            return false;
        } else if (declineResponse?.reason === 'Other' && !declineResponse?.message) {
            toast.error('Please provide some details');
            return false;
        }
        handleDeclineEvent();
        handleClose();
        return true;
    }, [declineResponse]);

    let modalContent = <></>;
    if (eventIsLoading) {
        modalContent = (
            <Modal.Content className="flex items-center max-w-full [&>span]:w-full">
                <Skeleton data-testid="decline-event-skeleton" className="w-full h-[200px]" />
            </Modal.Content>
        );
    } else if (event) {
        modalContent = (
            <>
                <Modal.Content className="flex flex-col !pt-0">
                    <Form.Select
                        required
                        error={submitted && !declineResponse?.reason}
                        label="Why are you declining this Event?"
                        name="event-decline-modal_reason"
                        value={declineResponse?.reason ?? ''}
                        onChange={(event) =>
                            setDeclineResponse({ ...declineResponse, reason: event.target.value })
                        }
                        options={eventDeclineOptions}
                    />
                    <Form.TextField
                        label={`Comments ${
                            declineResponse?.reason !== 'Other' ? '(Optional)' : ''
                        }`}
                        required={declineResponse?.reason === 'Other'}
                        error={
                            submitted &&
                            declineResponse?.reason === 'Other' &&
                            !declineResponse?.message
                        }
                        className="[&_.MuiOutlinedInput-root]:p-0 !mt-4"
                        multiline
                        rows={4}
                        placeholder="Any additional feedback?"
                        name="event-decline-modal_message"
                        value={declineResponse?.message ?? ''}
                        onChange={(event) =>
                            setDeclineResponse({ ...declineResponse, message: event.target.value })
                        }
                    />
                    <Typography variant="sm" className="text-gray-800 mt-1">
                        Provide details around what you would like to see from your Vendor partners
                        or contact info for scheduling.
                    </Typography>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() =>
                            nav.state.fromDetailsModal
                                ? nav.actions.backToEventDetailModal(nav.state.declineId)
                                : handleClose()
                        }
                        color="neutral"
                    >
                        Back
                    </Button>
                    <Button onClick={handleValidation} variant="filled">
                        Submit
                    </Button>
                </Modal.Actions>
            </>
        );
    }

    return (
        <Modal isVisible={isVisible} onClose={() => handleClose()} size="narrow">
            <Form>
                <Modal.Title onClose={() => handleClose()}>Decline Event</Modal.Title>
                {modalContent}
            </Form>
        </Modal>
    );
};
