import { useCallback, useState } from 'react';

import { UserSettings } from '@sparkplug/lib';

import { useSaveUserProfileMutation } from '@core/users';

import UserAvatarUpload from '@components/UserAvatarUpload/UserAvatarUpload';
import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import Modal from '@components/overlays/Modal';

import { setObjectProperty } from '@helpers/util';

import { IAuthUser } from '@app/types/UsersTypes';

import './EditUserProfileModal.scss';

const EditUserProfileModal = ({
    isVisible,
    user,
    onClose,
}: {
    isVisible: boolean;
    user: IAuthUser;
    onClose: (val?: boolean) => void;
}) => {
    const [userData, setUserData] = useState<{
        firstName?: string;
        lastName?: string;
        email?: string;
        phoneNumber?: string;
        password?: string;
        pwdCheck?: string;
        userSettings?: UserSettings;
    }>({});

    const { isSavingUserProfile, saveUserProfile } = useSaveUserProfileMutation({});

    const updateUserData = (path: string, value: any) => {
        setUserData((prevUserData) => setObjectProperty(prevUserData, { path, value }));
    };

    const onSave = useCallback(async () => {
        saveUserProfile(
            { userParams: userData, userId: user?._id },
            { onSuccess: () => onClose(true) },
        );
    }, [userData]);

    // Note: `emailNotificationValue` will initially reference the `user` prop value,
    // but will reference the `userData` state value after the first `onChange` is fired
    const emailNotificationValue = !(userData.userSettings?.emailNotificationsDisabled === undefined
        ? user?.userSettings?.emailNotificationsDisabled
        : userData?.userSettings?.emailNotificationsDisabled);

    return (
        <Modal
            className="edit-user-profile-modal wide"
            isVisible={isVisible}
            onClose={() => {
                setUserData({});
                onClose();
            }}
        >
            <Form>
                <Modal.Title>Edit Profile</Modal.Title>

                <Modal.Content>
                    <Form.SectionTitle>Personal Information</Form.SectionTitle>
                    <Form.Grid>
                        <Form.GridItem xs={12} sm={3}>
                            <UserAvatarUpload />
                        </Form.GridItem>
                        <Form.GridItem xs={12} sm={4.5}>
                            <Form.TextField
                                label="First Name"
                                name="firstName"
                                defaultValue={user.firstName}
                                onChange={(event) => {
                                    updateUserData('firstName', event.target.value);
                                }}
                            />
                        </Form.GridItem>
                        <Form.GridItem xs={12} sm={4.5}>
                            <Form.TextField
                                label="Last Name"
                                name="lastName"
                                defaultValue={user.lastName}
                                onChange={(event) => {
                                    updateUserData('lastName', event.target.value);
                                }}
                            />
                        </Form.GridItem>
                    </Form.Grid>

                    <Form.SectionTitle>Account Information</Form.SectionTitle>
                    <Form.Grid>
                        <Form.GridItem xs={12} sm={5}>
                            <Form.TextField
                                label="Email"
                                name="email"
                                defaultValue={user.email}
                                onChange={(event) => {
                                    updateUserData('email', event.target.value);
                                }}
                            />
                        </Form.GridItem>
                        <Form.GridItem xs={12} sm={4}>
                            <Form.PhoneField
                                label="Phone Number"
                                name="phoneNumber"
                                defaultValue={user.phoneNumber}
                                disabled
                                onChange={(event) => {
                                    updateUserData('phoneNumber', event.target.value);
                                }}
                            />
                        </Form.GridItem>
                        <Form.GridItem xs={12} sm={3}>
                            <Form.TextField
                                label="SMS Status"
                                name="smsStatus"
                                defaultValue={user?.enrollmentStatus || '--'}
                                disabled
                            />
                        </Form.GridItem>
                    </Form.Grid>

                    <Form.SectionTitle>Reset Password</Form.SectionTitle>
                    <Form.Grid>
                        <Form.GridItem xs={12} sm={6}>
                            <Form.TextField
                                type="password"
                                label="New Password"
                                name="password"
                                onChange={(event) => {
                                    updateUserData('password', event.target.value);
                                }}
                            />
                        </Form.GridItem>
                        <Form.GridItem xs={12} sm={6}>
                            <Form.TextField
                                type="password"
                                label="Confirm Password"
                                name="pwdCheck"
                                onChange={(event) => {
                                    updateUserData('pwdCheck', event.target.value);
                                }}
                            />
                        </Form.GridItem>
                    </Form.Grid>

                    <Form.SectionTitle className="notification-section-title">
                        <span className="main">Notifications</span>
                        <span className="email">Email</span>
                    </Form.SectionTitle>
                    <Form.Grid className="email-notification">
                        <Form.GridItem className="passage-container" xs={8} sm={9}>
                            <p>
                                Get notified when there are changes to Sparks and/or Linked Brands.
                            </p>
                        </Form.GridItem>
                        <Form.GridItem xs={4} sm={3} className="switch-container">
                            <Form.Switch
                                value={emailNotificationValue}
                                onChange={(event: any) => {
                                    const isChecked = event.target.checked;
                                    updateUserData(
                                        'userSettings.emailNotificationsDisabled',
                                        !isChecked,
                                    );
                                }}
                            />
                        </Form.GridItem>
                    </Form.Grid>
                </Modal.Content>

                <Modal.Actions>
                    <Button color="neutral" variant="flat" onClick={() => onClose(false)}>
                        Cancel
                    </Button>
                    <Form.Button
                        color="blue"
                        variant="flat"
                        disabled={isSavingUserProfile}
                        onClick={onSave}
                    >
                        Update Profile
                    </Form.Button>
                </Modal.Actions>
            </Form>
        </Modal>
    );
};

export default EditUserProfileModal;
