import { FC } from 'react';

import { PAYMENT_METHOD_TO_DISPLAY, PaymentMethodType } from '@sparkplug/lib';

import Chip from '@components/chips/Chip';
import Tooltip from '@components/layout/Tooltip';

import './PaymentMethodChip.scss';

interface PaymentMethodChipProps {
    paymentMethod: PaymentMethodType;
}

const PaymentMethodChip: FC<PaymentMethodChipProps> = ({ paymentMethod }) => {
    const paymentMethodForDisplay = PAYMENT_METHOD_TO_DISPLAY[paymentMethod];
    return (
        <Tooltip
            title={`Transactions must use the ${paymentMethodForDisplay} payment method to qualify for this Spark`}
        >
            <span>
                <Chip dense className="name-chip payment-method-chip" label="$" color="green" />
            </span>
        </Tooltip>
    );
};

export default PaymentMethodChip;
