import { useEffect, useMemo, useState } from 'react';

import { internalTrackingFieldValueNames } from '@constants/SparkConstants';
import { findLast, startCase, trim } from 'lodash';
import moment from 'moment-timezone';

import { InternalActivity, getDefaultSparkInternalTracking } from '@sparkplug/lib';

import { useUpdateInternalTrackingMutation } from '@core/sparks/mutations/SparkMutations';
import { getUserData } from '@core/users';

import Button from '@components/buttons/Button';
import { ArrowDropDown, ArrowDropUp, Create as EditIcon } from '@components/icons';
import PanelField from '@components/layout/PanelField';
import Paper from '@components/layout/Paper';

import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import { openUrl } from '@helpers/ui';

import InternalSparkTrackingModal from './InternalSparkTrackingModal';

import './InternalSparkTrackingPanel.scss';

const InternalSparkTrackingPanel = () => {
    const { spark, refetchSparkData } = useSpark();

    const [createdBy, setCreatedBy] = useState<string>();
    const [showActivity, setShowActivity] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            const sparkCreator = await getUserData(spark.creatorUserId);

            setCreatedBy(sparkCreator.fullName);
        })();
    }, [spark.creatorUserId]);

    const [isTrackingModalVisible, setIsTrackingModalVisible] = useState<boolean>(false);

    const openTrackingModal = () => setIsTrackingModalVisible(true);

    const closeTrackingModal = () => setIsTrackingModalVisible(false);

    const internalTracking = useMemo(() => getDefaultSparkInternalTracking(spark), [spark]);

    // This function is necessary, as opposed to the `updateSpark` function from `useSpark`,
    // so that we don't cause effects to re-run when updating the spark in context directly
    // Previously, the charts were re-calculating whenever an internal tracking field was
    // updated.

    const { updateInternalTrackingAsync } = useUpdateInternalTrackingMutation({
        sparkId: spark._id,
    });

    const handleClick = async () => {
        updateInternalTrackingAsync({
            sparkId: spark._id,
            internalTrackingUpdates: {
                status: 'ready-for-payout',
            },
        }).then(() => {
            refetchSparkData();
        });
    };

    const openInvoiceUrl = () =>
        internalTracking.invoiceId
            ? openUrl(`https://dashboard.stripe.com/invoices/${internalTracking.invoiceId}`)
            : undefined;
    const ActivityContent = () => (
        <div className="internal-activity-list">
            {internalTracking.changelog?.map((activity) => {
                // Start hack: this was added because some changelog entries have been saved as embedded arrays - with the actual "activity" another layer deep
                // This fixes the issue for now, but further investigation and resolution - including removing this hack - should be tackled with
                // https://linear.app/sparkplug/issue/X-1344/view-activity-button-breaks-on-unfinalized-spark
                const safeActivity = Array.isArray(activity) ? activity[0] : activity;
                if (!safeActivity) {
                    return <></>;
                }
                // End hack
                return (
                    <div
                        className="internal-activity-item"
                        key={safeActivity.timestamp?.toLocaleString()}
                    >
                        <strong>{safeActivity.username}</strong> {safeActivity.action}{' '}
                        {startCase(safeActivity.key)} {safeActivity.action === 'updated' && 'from'}{' '}
                        <strong> {startCase(safeActivity.oldValue)}</strong>{' '}
                        {safeActivity.action === 'updated' && 'to'}{' '}
                        <strong>{startCase(safeActivity.newValue)}</strong>
                        <span className="internal-activity-dot">•</span>
                        <span
                            title={moment(safeActivity.timestamp).format('MMM D, yyy @ hh:MM A ')}
                        >
                            {moment(safeActivity.timestamp).fromNow()}
                        </span>
                    </div>
                );
            })}
        </div>
    );
    const statusUpdatedBy =
        internalTracking.changelog &&
        findLast(internalTracking.changelog, (x: InternalActivity) => x.key === 'status')?.username;
    return (
        <Paper className="internal-spark-tracking-panel">
            <div className="internal-spark-tracking-panel__header">
                <div className="internal-spark-tracking-panel__title">Internal Tracking</div>
                <div className="internal-spark-tracking-panel__buttons">
                    {internalTracking.status !== 'ready-for-payout' && (
                        <Button color="neutral" variant="smooth" disableHover onClick={handleClick}>
                            Ready for Payout
                        </Button>
                    )}
                    <Button
                        onClick={openTrackingModal}
                        color="neutral"
                        variant="smooth"
                        disableHover
                        startIcon={<EditIcon />}
                    >
                        Edit
                    </Button>
                </div>
            </div>

            <div className="fields internal-fields">
                <PanelField label="Created By" value={createdBy ?? '--'} />
                {spark.acceptedByUser && (
                    <PanelField label="Accepted By" value={spark.acceptedByUser} />
                )}

                <PanelField
                    label="Status"
                    value={`${internalTrackingFieldValueNames[internalTracking.status]}${
                        statusUpdatedBy ? ` (${statusUpdatedBy})` : ``
                    }`}
                />

                <PanelField
                    label="Invoice Status"
                    value={internalTrackingFieldValueNames[internalTracking.invoiceStatus]}
                />

                {internalTracking.invoiceId && (
                    <PanelField
                        className={trim(
                            `internal-tracking-invoice-id ${
                                internalTracking.invoiceId ? 'has-invoice-id' : ''
                            }`,
                        )}
                        label="Invoice Number"
                        value={internalTracking.invoiceId ?? '--'}
                        onClick={openInvoiceUrl}
                    />
                )}

                <PanelField
                    label="Payout Status"
                    value={internalTrackingFieldValueNames[internalTracking.payoutStatus]}
                />

                <PanelField
                    className={trim(
                        `internal-tracking-notes ${internalTracking.notes ? 'has-notes' : ''}`,
                    )}
                    label="Notes"
                    value={internalTracking.notes ?? '--'}
                />
            </div>
            <div>
                {internalTracking.changelog && internalTracking.changelog.length > 0 && (
                    <Button
                        onClick={() => setShowActivity(!showActivity)}
                        color={showActivity ? 'blue' : 'neutral'}
                        variant="smooth"
                        disableHover
                        className="internal-activity-button"
                        endIcon={showActivity ? <ArrowDropUp /> : <ArrowDropDown />}
                        dense
                    >
                        View Activity
                    </Button>
                )}
                {showActivity && <ActivityContent />}
            </div>

            {isTrackingModalVisible && (
                <InternalSparkTrackingModal
                    isVisible
                    defaultInternalTracking={internalTracking}
                    onClose={closeTrackingModal}
                />
            )}
        </Paper>
    );
};

export default InternalSparkTrackingPanel;
