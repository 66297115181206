import { FC, PropsWithChildren } from 'react';
import { useLocation } from 'react-router';

import { cn } from '@app/componentLibrary/utils';

import './AppContent.scss';

const Content: FC<PropsWithChildren> = ({ children }) => {
    const pathname = useLocation().pathname;
    const isOnboardingDetails = pathname === '/user/onboarding/details';
    const isOnboarding = pathname === '/user/onboarding';

    return (
        <div
            className={cn(
                'app-content',
                isOnboardingDetails && 'bg-white max-w-full',
                isOnboarding && 'bg-cerulean-600 max-w-full',
            )}
        >
            {children}
        </div>
    );
};

export default Content;
