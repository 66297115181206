import { useCallback, useEffect, useState } from 'react';

import { EmployeeSpark, IPublicReward, Spark } from '@sparkplug/lib';

import { useEmployeeSparks } from '@core/employees/queries';

import SparkStatusButtons from '@features/spark-dashboard/components/SparkStatusButtons';
import {
    FilterFns,
    SparkFilterType,
    useSparksFilters,
} from '@features/spark-dashboard/hooks/useSparkFilters';
import SparksList from '@features/user-home/components/SparksList';

import { NoSparksGraphic } from '@components/graphics';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import Grid from '@components/layout/Grid';
import Skeleton from '@components/layout/Skeleton';

import { useApp } from '@hooks/AppHooks';
import { useCurrentUserRewards } from '@hooks/RewardsHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { useSearch } from '@hooks/UIHooks';

export const applySparkClaimFilters = (
    sparks: Spark[],
    rewards: IPublicReward[],
    value: 'claimed' | 'unclaimed' | 'all',
): Spark[] => {
    return sparks.filter((spark) => {
        if (value === 'all') {
            return true;
        }

        const userPayout = (rewards || []).find((reward) => {
            const isSameSpark = spark?._id === reward?.sparkId;
            return isSameSpark;
        });

        if (!userPayout) {
            return false;
        }

        return value === 'claimed'
            ? userPayout?.status === 'claimed'
            : userPayout?.status === 'confirmed';
    });
};

interface ISparksModuleProps {
    initialSparkStatus?: 'active' | 'upcoming' | 'complete';
}

const SparksModule = ({ initialSparkStatus = 'active' }: ISparksModuleProps) => {
    const { history } = useApp();

    const { sparks, sparksAreReady, refetchSparks } = useEmployeeSparks();
    const { account } = useSparkplugAccount();

    const { sparkFilters, updateSparkFilters } = useSparksFilters({
        initialFilters: {
            location: account?.locations || [],
            type: [],
            status: initialSparkStatus,
            schedule: [],
            search: '',
        },
        account,
    });
    const applyFilters = useCallback(
        (sparksToFilter: EmployeeSpark[]): EmployeeSpark[] =>
            Object.entries(sparkFilters).reduce((res, [filter, value]) => {
                const filterFn = FilterFns[filter as SparkFilterType](value, account?.type);
                return res.filter(filterFn);
            }, sparksToFilter),
        [sparkFilters],
    );
    useEffect(() => {
        if (sparkFilters.status === 'complete') {
            // we want to show sparks associated with disabled locations for complete sparks, so we need to use "allLocations" as they're filtered out of "locations"
            updateSparkFilters({ location: account?.allLocations || [] });
        }
    }, [sparkFilters.status]);

    const { isLoadingCurrentUserRewards, currentUserRewards, refetchCurrentUserRewards } =
        useCurrentUserRewards();

    const { searchFilter, applySearch } = useSearch(['name', 'description']);

    const [filteredSparks, setFilteredSparks] = useState(sparks);

    // tag -> sparkIds
    const [multiLeaderboardSparkIds, setMultiLeaderboardSparkIds] = useState<
        Record<string, string[]>
    >({});

    useEffect(() => {
        refetchSparks();
        refetchCurrentUserRewards();
    }, []);

    useEffect(() => {
        let newFilteredSparks = applyFilters(sparks || []);
        newFilteredSparks = applySearch(newFilteredSparks);

        const _multiLeaderboardSparkIds: Record<string, string[]> = {};
        // First pass to collect all spark IDs by tag
        newFilteredSparks.forEach((spark) => {
            if (spark.tag) {
                if (!_multiLeaderboardSparkIds[spark.tag]) {
                    _multiLeaderboardSparkIds[spark.tag] = [];
                }
                _multiLeaderboardSparkIds[spark.tag].push(spark._id);
            }
        });

        // Second pass to combine sparks and add multiLeaderboardSparks array
        const sparksByTag = newFilteredSparks.reduce((acc, spark) => {
            if (spark.tag) {
                if (!acc[spark.tag]) {
                    // Add multiLeaderboardSparks array to the spark object
                    acc[spark.tag] = {
                        ...spark,
                        multiLeaderboardSparkIds: _multiLeaderboardSparkIds[spark.tag].filter(
                            (id) => id !== spark._id,
                        ),
                    };
                }
            } else {
                acc[spark._id] = spark; // For sparks without tag, use _id as key to keep them in result
            }
            return acc;
        }, {});

        const combinedSparks = Object.values(sparksByTag); // Convert back to array
        setMultiLeaderboardSparkIds(_multiLeaderboardSparkIds); // grouping by tag
        setFilteredSparks(combinedSparks as EmployeeSpark[]);
    }, [sparks, sparkFilters, searchFilter, currentUserRewards]);

    const sparkStatus = sparkFilters.status;

    const emptyLabel = `no ${sparkStatus} sparks found`;

    const onSparkCardSelected = (spark: Pick<Spark, '_id' | 'requestState' | 'tag'>) => {
        let queryParams = '';
        if (spark.tag) {
            let mlbIds = multiLeaderboardSparkIds[spark.tag] || [];
            mlbIds = mlbIds.filter((id) => id !== spark._id); // excluding the spark which is clicked
            queryParams = mlbIds.length > 0 ? `?mlb=${mlbIds.join(',')}` : '';
        }

        history.push(`/user/sparks/${spark._id}${queryParams}`, {
            prevUrl: document.location.pathname,
            prevTitle: `${sparkFilters.status} Sparks`,
        });
    };

    return (
        <div>
            <div className="mb-4 max-sm:[&_.status-tab-group]:flex max-sm:[&_.status-tab-group>.btn]:flex-grow max-sm:[&_button]:justify-center">
                <SparkStatusButtons
                    value={sparkFilters.status || 'active'}
                    onSelect={({ value, to }) => {
                        updateSparkFilters({ status: value });
                        window.history.pushState(null, '', `/user${to}`);
                    }}
                    isBrand={false}
                    isAdmin={false}
                    menuDirection="horizontal"
                />
            </div>
            {sparksAreReady && !isLoadingCurrentUserRewards ? (
                <SparksList
                    isUserSparksView
                    viewMode="grid"
                    showTrainingIcon
                    sparkStatus={sparkStatus}
                    isEmployeeView
                    sparks={filteredSparks}
                    onSparkCardSelected={onSparkCardSelected}
                    emptyStateComponent={
                        <EmptyStateDisplay graphic={<NoSparksGraphic />} label={emptyLabel} />
                    }
                />
            ) : (
                <>
                    <Grid className="app-sparks-list-grid px-[2px]">
                        <Grid.Item>
                            <Skeleton height={177} />
                        </Grid.Item>
                        <Grid.Item>
                            <Skeleton height={177} />
                        </Grid.Item>
                        <Grid.Item>
                            <Skeleton height={177} />
                        </Grid.Item>
                    </Grid>
                </>
            )}
        </div>
    );
};

export default SparksModule;
