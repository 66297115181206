import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';

import { CreateCourseButton } from '../CreateCourseButton';

const content = {
    retailer: {
        title: 'Empower Your Team to Succeed',
        description:
            'Offer on-demand training to help employees excel in customer interactions, master in-store processes, and drive sales for your store.',
    },
    brand: {
        title: 'Empower Employees with Brand Knowledge',
        description:
            'Provide on-demand training to equip retail employees with the knowledge to promote your brand and drive sales',
    },
};

const CoursesEmptyState = ({ accountType }: { accountType: 'retailer' | 'brand' }) => {
    return (
        <EmptyStateDisplay
            layout="horizontal"
            addPaperWrapper
            label={content[accountType].title}
            smallText={content[accountType].description}
            graphic={<img src="/static/images/Vendor-ES-AllSnaps.jpg" alt="placeholder" />}
            className="courses-empty-state"
            actionButton={<CreateCourseButton />}
        />
    );
};

export default CoursesEmptyState;
