import axios from 'axios';

import { EventDeclineResponse } from '@sparkplug/lib';

import { useAdvancedMutation } from '@hooks/QueryHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { getEventsQueryKey } from '../queries';
import { EventFormFields } from '../types';

const API = {
    async declineEvent(eventId: string, declineResponse: EventDeclineResponse) {
        return (
            await axios.post<null>(`/api/v1/event/${eventId}/decline`, {
                reason: declineResponse.reason,
                message: declineResponse.message,
            })
        ).status;
    },
    async approveEvent(eventId: string) {
        return (await axios.post<null>(`/api/v1/event/${eventId}/approve`)).status;
    },
    async deleteEvent(eventId: string) {
        return (await axios.delete<null>(`/api/v1/event/${eventId}`)).status;
    },
    async upsertEvent(eventFormFields: EventFormFields) {
        return (await axios.put<null>(`/api/v1/event`, eventFormFields))?.data;
    },
};

const getInvalidationKey = (accountId?: string) => {
    return !accountId ? ['event'] : getEventsQueryKey(accountId).filter(Boolean);
};

const getInvalidateAllEventsKey = () => {
    return ['event'];
};

export const useDeclineEventMutation = () => {
    const { account } = useSparkplugAccount();

    const { mutateAsync, isLoading } = useAdvancedMutation(
        ({
            eventId,
            declineResponse,
        }: {
            eventId: string;
            declineResponse: EventDeclineResponse;
        }) => API.declineEvent(eventId, declineResponse),
        {
            toastOptions: {
                loading: 'Declining event...',
                success: 'Event declined',
                error: 'Something went wrong.',
            },
            updateQuery: { queryKey: getInvalidationKey(account?._id!) },
        },
    );

    return {
        isDecliningEvent: isLoading,
        declineEventAsync: mutateAsync,
    };
};

export const useApproveEventMutation = () => {
    const { account } = useSparkplugAccount();

    const { mutateAsync, isLoading, isError } = useAdvancedMutation(
        (eventId: string) => API.approveEvent(eventId),
        {
            toastOptions: {
                loading: 'Approving event...',
                success: 'Event approved',
                error: 'Something went wrong.',
            },
            updateQuery: { queryKey: getInvalidationKey(account?._id!) },
        },
    );

    return {
        isApprovingEvent: isLoading,
        approveEventAsync: mutateAsync,
        hasApprovalError: isError,
    };
};

export const useDeleteEventMutation = () => {
    const { account } = useSparkplugAccount();

    const { mutateAsync, isLoading, isError } = useAdvancedMutation(
        (eventId: string) => API.deleteEvent(eventId),
        {
            toastOptions: {
                loading: 'Deleting event...',
                success: 'Event deleted',
                error: 'Something went wrong.',
            },
            updateQuery: { queryKey: getInvalidationKey(account?._id!) },
        },
    );

    return {
        isDeletingEvent: isLoading,
        deleteEventAsync: mutateAsync,
        hasDeleteError: isError,
    };
};

export const useUpsertEventMutation = (toastMessageType: 'create' | 'update' = 'create') => {
    const { mutateAsync, isLoading } = useAdvancedMutation(
        (eventFormFields: EventFormFields) => API.upsertEvent(eventFormFields),
        {
            toastOptions: {
                loading: 'Scheduling event...',
                success: toastMessageType === 'create' ? 'Event scheduled' : 'Event updated',
                error: 'Something went wrong.',
            },
            updateQuery: { queryKey: getInvalidateAllEventsKey() },
        },
    );

    return {
        isUpsertingEvent: isLoading,
        upsertEventAsync: mutateAsync,
    };
};
