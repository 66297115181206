import { z } from 'zod';
import { PosConfig } from './config';
import { PosConfigCredentials, posConfigCredentialsSchema, posToSchemaMap } from './credentials';
import { PosConfigTypes, PosConfigType } from './types';

export interface ListPosConfigsQueryParams {
  groupId: string;
}

export interface ListPosConfigsResponseBody {
  data: PosConfig[];
}

export interface RetailerSupportsInventoryResponseBody {
  data: {
    inventoryEnabled: boolean;
    posConfigId: string;
  };
}

export interface GetPosConfigPathParams {
  configId: string;
}
export interface GetPosConfigResponseBody {
  data: PosConfig;
}

export interface ReimportPosConfigPathParams {
  posConfigId: string;
  locationId?: string;
}

export interface ReimportPosConfigQueryParams {
  locationId?: string;
  lookback?: string;
  notify?: boolean;
  replay?: boolean;
}

export const createPosConfigSchema = z.object({
  groupId: z.string().min(1),
  type: z.enum(PosConfigTypes),
  credentials: posConfigCredentialsSchema,
  enabled: z.boolean(),
  lookback: z.string().min(1),
  reconcileLookbackHours: z.union([
    z.number().default(24 * 7),
    z
      .string()
      .default((24 * 7).toString())
      .transform((hours) => parseInt(hours, 10)),
  ]),
  timeZone: z.string().min(1),
  isFullProductSync: z.boolean().optional(),
  useProductPipeline: z.boolean().optional(),
});
createPosConfigSchema.refine(
  (data) => !!posToSchemaMap[data.type]?.safeParse(data.credentials).success,
  'Failed to successfully validate POS credentials',
);

export type CreatePosConfigRequestBody = z.infer<typeof createPosConfigSchema>;

export type CreatePosConfigResponseBody = PosConfig;

export interface UpdatePosConfigPathParams {
  configId: string;
}

export const updatePosConfigSchema = createPosConfigSchema.partial();
export type UpdatePosConfigRequestBody = z.infer<typeof updatePosConfigSchema>;

export interface VerifyCredsPathParams {
  posType: PosConfigType;
}

export interface VerifyCredsRequestBody {
  credentials: PosConfigCredentials;
}
